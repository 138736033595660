import styles from "app/areas/configuration/components/AuditLayout/AuditStream/AuditStreamCategory.module.less";
import AuditStreamCategoryIconHelper from "app/areas/configuration/components/AuditLayout/AuditStream/AuditStreamCategoryIconHelper";
import classnames from "classnames";
import * as React from "react";
import type { AuditStreamType } from "~/areas/configuration/components/AuditLayout/AuditStream/AuditStreamType";

interface AuditStreamCategoryProps {
    category: AuditStreamType;
    name: string;
    active: boolean;
    isBlurred?: boolean;
    isSelectable?: boolean;
    onCategorySelected: () => void;
}

const AuditStreamCategory: React.FC<AuditStreamCategoryProps> = (props: AuditStreamCategoryProps) => {
    const iconSvgPath = AuditStreamCategoryIconHelper.getCategorySvgElement(props.category, props.active);
    return (
        <li
            className={classnames(styles.auditStreamCategory, props.active ? styles.active : styles.inactive, props.isSelectable ? styles.isFiltering : styles.isSelectable, props.isBlurred ? styles.blurred : null)}
            onClick={props.isSelectable ? undefined : props.onCategorySelected}
        >
            {iconSvgPath}
            <div className={styles.categoryName}>
                <small>{props.name}</small>
            </div>
        </li>
    );
};

export default AuditStreamCategory;
