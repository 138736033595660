/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { ProjectResource, ChannelResource, ResourceCollection, LifecycleResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps, match } from "react-router";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/withProjectContext";
import { withProjectContext } from "~/areas/projects/context/withProjectContext";
import { repository } from "~/clientInstance";
import { NavigationButton, NavigationButtonType } from "~/components/Button";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import List from "~/components/List";
import PaperLayout from "~/components/PaperLayout/index";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import * as tenantTagsets from "~/components/tenantTagsets";
import type { TagIndex } from "~/components/tenantTagsets";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { RecentProjects } from "~/utils/RecentProjects/RecentProjects";
import { ProjectStatus } from "../ProjectStatus/ProjectStatus";
import Channel from "./Channel";

interface ChannelsState extends DataBaseComponentState {
    project: ProjectResource;
    channelsResponse: ResourceCollection<ChannelResource>;
    lifecycles: Lifecycles;
    tagIndex: TagIndex;
}

export interface Lifecycles {
    [name: string]: LifecycleResource;
}

class ChannelsList extends List<ChannelResource> {}

type ChannelsProps = RouteComponentProps<ProjectRouteParams> & WithProjectContextInjectedProps;

class ChannelsInternal extends DataBaseComponent<ChannelsProps, ChannelsState> {
    private match: match<ProjectRouteParams> = null!;

    constructor(props: ChannelsProps) {
        super(props);
        this.match = this.props.match;
        this.state = {
            project: null!,
            channelsResponse: null!,
            lifecycles: {},
            tagIndex: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(
            async () => {
                const { model: project } = this.props.projectContext.state;
                await RecentProjects.getInstance().UpdateAccessedProjectIntoLocalStorage(project.Id);

                const [channelsResponse, lifecycleResources, tagIndex] = await Promise.all([repository.Projects.getChannels(project, 0, 30), repository.Lifecycles.all(), tenantTagsets.getTagIndex()]);

                const lifecycles: Lifecycles = {};
                lifecycleResources.forEach((lifecycle) => {
                    lifecycles[lifecycle.Id] = lifecycle;
                });

                this.setState({
                    channelsResponse,
                    lifecycles,
                    tagIndex,
                    project,
                });
            },
            { timeOperationOptions: timeOperationOptions.forInitialLoad(this.props.projectContext.state.model.IsVersionControlled) }
        );
    }

    render() {
        const addChannelButton = (
            <PermissionCheck permission={Permission.ProcessEdit} project={this.state.project && this.state.project.Id} tenant="*">
                <NavigationButton type={NavigationButtonType.Primary} label="Add Channel" href={`${this.props.match.url}/create`} />
            </PermissionCheck>
        );

        return (
            <PaperLayout busy={this.state.busy} errors={this.errors} title="Channels" breadcrumbTitle={this.state.project?.Name} sectionControl={addChannelButton} statusSection={<ProjectStatus doBusyTask={this.doBusyTask} />}>
                {this.state.channelsResponse && (
                    <ChannelsList
                        initialData={this.state.channelsResponse}
                        onRow={(item) => this.buildRow(item)}
                        match={this.match}
                        onRowRedirectUrl={(channel: ChannelResource) => `${this.match.url}/edit/${channel.Id}`}
                        onRowAccessibleName={(channel: ChannelResource) => `${channel.Name}`}
                        onFilter={this.filter}
                        filterSearchEnabled={true}
                        apiSearchParams={["partialName"]}
                        filterHintText="Filter by name..."
                    />
                )}
            </PaperLayout>
        );
    }

    private filter(filter: string, resource: ChannelResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }

    private buildRow(channel: ChannelResource) {
        return <Channel key={channel.Id} channel={channel} project={this.state.project} lifecycles={this.state.lifecycles} tagIndex={this.state.tagIndex} />;
    }
}

export const Channels = withProjectContext(ChannelsInternal);
