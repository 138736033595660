import type { PackageNoteResult } from "@octopusdeploy/octopus-server-client";

export interface PackageModel {
    ActionName: string;
    PackageId: string;
    PackageReferenceName: string;
    ProjectName: string; // Only used if the package is actually a project (i.e. Deploy Release Step)
    FeedName: string;
    FeedId: string;
    Version: string;
    Notes: PackageNoteResult;
}

export enum VersionType {
    latest = "latest",
    last = "last",
    specific = "specific",
}

export interface PackageEditInfo {
    ActionName: string;
    PackageReferenceName?: string;
    PackageId: string;
    FeedId: string;
    FeedName: string;
    LatestVersion: string;
    SpecificVersion: string;
    IsResolvable: boolean;
    LastReleaseVersion: string;
    VersionType: VersionType;
    IsLastReleaseVersionValid: boolean;
    ProjectName: string;
}
