/* eslint-disable @typescript-eslint/consistent-type-assertions */

import type { ScopeValues } from "@octopusdeploy/octopus-server-client";
import { keyBy } from "lodash";
import type { AvailableScopeLookup, ScopeLookupsMap } from "./model";

export function createAvailableScopeLookup(scopes: ScopeValues): AvailableScopeLookup {
    const keys = Object.keys(scopes) as Array<keyof ScopeValues>;
    const initial: ScopeLookupsMap<ScopeValues> = {
        Actions: {},
        Channels: {},
        Environments: {},
        Machines: {},
        Processes: {},
        Roles: {},
        TenantTags: {},
    };

    const lookups = keys.reduce((prev, current) => ({ ...prev, [current]: scopes[current] ? keyBy(scopes[current], (x) => x.Id) : {} }), initial);
    const lookup: AvailableScopeLookup = (type, id) => lookups[type][id] as ReturnType<AvailableScopeLookup>;
    return lookup;
}
