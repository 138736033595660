import { Permission } from "@octopusdeploy/octopus-server-client";
import { client } from "~/clientInstance";
import { pageId } from "~/utils/pageId";
import insightsRouteLinks from "./insightsRouteLinks";

const insightsLinks = insightsRouteLinks(client.spaceId ?? "");

export default () => ({
    upsell: pageId({ area: "Insights", id: "Insights.Upsell", name: "Insights Reports Upsell", routeLink: insightsLinks.root, routePermission: { permission: Permission.None } }),
    reports: pageId({ area: "Insights", id: "Insights.Reports", name: "Insights Reports", routeLink: insightsLinks.reports, routePermission: { permission: Permission.InsightsReportView, wildcard: true } }),
    report: {
        overview: pageId({ area: "Insights", id: "Insights.Report.Overview", name: "Insights Report Overview", routeLink: null, routePermission: { permission: Permission.InsightsReportView, wildcard: true } }),
        frequency: pageId({ area: "Insights", id: "Insights.Report.DeploymentFrequency", name: "Insights Report Deployment Frequency", routeLink: null, routePermission: { permission: Permission.InsightsReportView, wildcard: true } }),
        leadTime: pageId({ area: "Insights", id: "Insights.Report.DeploymentLeadTime", name: "Insights Report Deployment Lead Time", routeLink: null, routePermission: { permission: Permission.InsightsReportView, wildcard: true } }),
        failureRate: pageId({ area: "Insights", id: "Insights.Report.DeploymentFailureRate", name: "Insights Report Failure Rate", routeLink: null, routePermission: { permission: Permission.InsightsReportView, wildcard: true } }),
        timeToRecovery: pageId({ area: "Insights", id: "Insights.Report.MeanTimeToRecovery", name: "Insights Report Mean Time to Recovery", routeLink: null, routePermission: { permission: Permission.InsightsReportView, wildcard: true } }),
        deploymentDuration: pageId({ area: "Insights", id: "Insights.Report.DeploymentDuration", name: "Insights Report Deployment Duration", routeLink: null, routePermission: { permission: Permission.InsightsReportView, wildcard: true } }),
        settings: pageId({ area: "Insights", id: "Insights.Report.Settings", name: "Insights Report Settings", routeLink: null, routePermission: { permission: Permission.InsightsReportEdit, wildcard: true } }),
    },
});
