/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { TagResource, TagSetResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import BaseComponent from "~/components/BaseComponent/index";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type FormFieldProps from "~/components/form/FormFieldProps";
import * as tenantTagsets from "~/components/tenantTagsets";
import Tag from "../Tag";

interface TenantTagMultiSelectionProps extends FormFieldProps<string[]> {
    doBusyTask: DoBusyTask;
    items?: TagSetResource[] | (() => TagSetResource[]);
    autoFocus?: boolean;
}

interface TenantTagSelectionState {
    tagSets: TagSetResource[];
}

const TagResourceMultiSelect = MultiSelect<TagResource>();

export class TenantTagMultiSelect extends BaseComponent<TenantTagMultiSelectionProps, TenantTagSelectionState> {
    constructor(props: TenantTagMultiSelectionProps) {
        super(props);
        this.state = {
            tagSets: this.props.items ? (typeof this.props.items === "function" ? this.props.items() : this.props.items).sort((t1, t2) => t1.SortOrder - t2.SortOrder) : null!,
        };
    }

    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            if (!this.state.tagSets) {
                const tagSets = await tenantTagsets.getAll();
                this.setState({ tagSets: tagSets.sort((t1, t2) => t1.SortOrder - t2.SortOrder) });
            }
        });
    }

    chipRenderer = (tagSet: TagSetResource, r: TagResource | SelectItem, onRequestDelete: () => void) => {
        const tag = tagSet.Tags.find((t) => t.Id === r.Id);
        return <Tag onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${tag!.Name}`} description={tag!.Description} tagName={tag!.Name} tagColor={tag!.Color} />;
    };

    onChange(tagSet: TagSetResource, tagIds: string[]) {
        const oldState = tenantTagsets.groupByTagSet(this.props.value!);
        const tagSetFromState = oldState.find((x) => x.name === tagSet.Name);
        const squashedList = tagIds.map((id) => tagSet.Tags.find((p) => p.Id === id)!.CanonicalTagName);
        if (tagSetFromState) {
            tagSetFromState.tags = squashedList;
        } else {
            oldState.push({ name: tagSet.Name, tags: squashedList });
        }
        const newState = tenantTagsets.flattenGroup(oldState);
        this.props.onChange!(newState);
    }

    render() {
        if (!this.state.tagSets) {
            return null;
        }

        const options = this.state.tagSets.map((ts, index) => {
            // No point showing the selector if this tagset has no tags.
            if (!ts.Tags || ts.Tags.length === 0) {
                return null;
            }

            const selected = this.props.value ? ts.Tags.filter((t) => this.props.value!.indexOf(t.CanonicalTagName) !== -1).map((t) => t.Id) : [];

            return (
                <TagResourceMultiSelect
                    onChange={(items) => this.onChange(ts, items)}
                    key={ts.Id}
                    value={selected}
                    items={ts.Tags ? ts.Tags.sort((t1, t2) => t1.SortOrder - t2.SortOrder) : null!}
                    renderChip={(r: TagResource | SelectItem, dc) => this.chipRenderer(ts, r, dc)}
                    fieldName={`${ts.Name} tags`}
                    description={ts.Description}
                    autoFocus={this.props.autoFocus && index === 0}
                />
            );
        });
        return <div>{options}</div>;
    }
}
