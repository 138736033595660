/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-eq-null */

import { logger } from "@octopusdeploy/logging";
import type { EnabledFeatureToggles, FeatureToggle, FeaturesConfigurationResource, UserPermissionSetResource, UserResource } from "@octopusdeploy/octopus-server-client";
import { UserPermissions } from "@octopusdeploy/octopus-server-client";
import { configurationActions } from "~/areas/configuration/reducers/configurationArea";
import store from "~/store";

type OnChangeSubscription = () => void;

export class Session {
    currentUser: UserResource | null = undefined!;
    currentPermissions: UserPermissions | null = undefined!;
    featureToggles: ReadonlyArray<FeatureToggle> | null = null;
    features: FeaturesConfigurationResource | null = undefined!;

    private onChangeSubscriptions: OnChangeSubscription[] = [];

    start(user: UserResource, featureConfigurations: FeaturesConfigurationResource, featureToggles: EnabledFeatureToggles) {
        logger.info("Starting session for {user}.", { user });
        this.currentUser = user;
        this.featureToggles = featureToggles.EnabledFeatureToggles;
        this.features = featureConfigurations;
        store.dispatch(configurationActions.featuresFetched(featureConfigurations));
        this.onChange();
    }

    end() {
        if (this.currentUser) {
            logger.info("Ending session for {user}.", { user: this.currentUser });
        }
        this.currentUser = null;
        this.currentPermissions = null;
        this.featureToggles = null;
        this.features = null;
        store.dispatch(configurationActions.featuresReset());
        this.onChange();
    }

    refreshPermissions(userPermission: UserPermissionSetResource) {
        this.currentPermissions = UserPermissions.Create(userPermission.SpacePermissions, userPermission.SystemPermissions, userPermission.Teams);
        this.onChange();
    }

    isAuthenticated() {
        return this.currentUser != null;
    }

    subscribe(onChange: OnChangeSubscription) {
        this.onChangeSubscriptions.push(onChange);

        return () => {
            this.onChangeSubscriptions = this.onChangeSubscriptions.filter((s) => s !== onChange);
        };
    }

    onChange() {
        this.onChangeSubscriptions.forEach((s) => s());
    }
}

export default Session;
