import { Permission } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import * as React from "react";
import type { ActionEvent } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import ActionButton, { ActionButtonType, IconPosition } from "~/components/Button/ActionButton";
import { DropDownIcon } from "~/components/Button/DropDownIcon/DropDownIcon";
import { PermissionCheck } from "~/components/PermissionCheck/index";
import { CustomMenu } from "~/primitiveComponents/navigation/Menu/CustomMenu";
import { useMenuState } from "~/primitiveComponents/navigation/Menu/useMenuState";
import { MenuItemInternalLink } from "~/primitiveComponents/navigation/MenuItems/MenuItemInternalLink/MenuItemInternalLink";

function SampleProcessButtonWithRedirect({ classNames, href }: { classNames?: string[]; href: (processType: "ps" | "bash") => string }) {
    const dispatchAction = useAnalyticActionDispatch();
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();

    const redirectToSampleProcess = (processType: "ps" | "bash") => {
        const ev: ActionEvent = {
            action: Action.Save,
            resource: "Deployment Process",
        };

        dispatchAction("Load Sample Deployment Process", ev);
    };

    return (
        <PermissionCheck permission={Permission.ProcessEdit}>
            <ActionButton className={cn(classNames)} type={ActionButtonType.Secondary} icon={<DropDownIcon />} iconPosition={IconPosition.Right} label="Use Sample" onClick={openMenu} menuButtonAttributes={buttonAriaAttributes} />
            <CustomMenu accessibleName={"Deploy release"} menuState={menuState}>
                <MenuItemInternalLink path={href("ps")} label={"Hello world (PowerShell)"} onClick={() => redirectToSampleProcess("ps")}></MenuItemInternalLink>
                <MenuItemInternalLink path={href("bash")} label={"Hello world (Bash)"} onClick={() => redirectToSampleProcess("bash")}></MenuItemInternalLink>
            </CustomMenu>
        </PermissionCheck>
    );
}

export default SampleProcessButtonWithRedirect;
