import type { InsightsDurationMetric } from "@octopusdeploy/octopus-server-client";
import moment from "moment";

export type InsightsUnitOfTime = "days" | "hours" | "minutes" | "seconds";

export interface ParsedInsightsDurationMetric {
    duration: string | null;
    days: number | null;
    hours: number | null;
    minutes: number | null;
    seconds: number | null;
    unitOfTime: "days" | "hours" | "minutes" | "seconds";
}

export interface ParsedInsightsDurationMetrics {
    mean: ParsedInsightsDurationMetric;
    median: ParsedInsightsDurationMetric;
    min: ParsedInsightsDurationMetric;
    max: ParsedInsightsDurationMetric;
    ninetyFifth: ParsedInsightsDurationMetric;
}

// Moment needs its day value to be prefixed by a dot - nodatime returns it with a colon
// https://momentjs.com/docs/#/durations/
function createMomentDuration(duration: string) {
    return moment.duration(duration);
}

function getBestUnitOfTimeKeyForChart(duration: moment.Duration) {
    if (duration.asDays() > 1) return "days";
    if (duration.asHours() > 1) return "hours";
    if (duration.asMinutes() > 1) return "minutes";
    return "seconds";
}

function parseDuration(duration: string): ParsedInsightsDurationMetric {
    const momentDuration = createMomentDuration(duration);
    return {
        duration: momentDuration.toISOString(), // We want this to be parsable by moment
        days: momentDuration.asDays(),
        hours: momentDuration.asHours(),
        minutes: momentDuration.asMinutes(),
        seconds: momentDuration.asSeconds(),
        unitOfTime: getBestUnitOfTimeKeyForChart(momentDuration),
    };
}

const emptyAggregateSet: ParsedInsightsDurationMetric = {
    duration: "",
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    unitOfTime: "seconds",
};

export function parseInsightsDurationMetrics(aggregates: InsightsDurationMetric | null) {
    if (aggregates === null)
        return {
            min: emptyAggregateSet,
            max: emptyAggregateSet,
            mean: emptyAggregateSet,
            median: emptyAggregateSet,
            ninetyFifth: emptyAggregateSet,
        };

    return {
        min: parseDuration(aggregates.Min),
        max: parseDuration(aggregates.Max),
        mean: parseDuration(aggregates.Mean),
        median: parseDuration(aggregates.Median),
        ninetyFifth: parseDuration(aggregates.NinetyFifth),
    };
}

// Each parsed duration aggregate has a recommended unit of time key - this function loops through the visible keys to find the the most appropriate unit of time to use as the Y axis for a chart.
export function getRecommendedUnitOfTimeKey(aggregates: ParsedInsightsDurationMetrics[], visibleKeys: (keyof ParsedInsightsDurationMetrics)[] = ["mean"]) {
    if (visibleKeys.some((k) => aggregates.some((a) => a[k].unitOfTime === "days"))) return "days";
    if (visibleKeys.some((k) => aggregates.some((a) => a[k].unitOfTime === "hours"))) return "hours";
    if (visibleKeys.some((k) => aggregates.some((a) => a[k].unitOfTime === "minutes"))) return "minutes";
    return "seconds";
}
