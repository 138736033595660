/* eslint-disable @typescript-eslint/no-non-null-assertion,@typescript-eslint/consistent-type-assertions */
import type { FeedResource, MavenFeedResource } from "@octopusdeploy/octopus-server-client";
import { FeedType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ExternalLink from "../../../../../components/Navigation/ExternalLink";
import BasicFeedCredentialsFormSection from "../BasicFeedCredentialsFormSection";
import RetryAttemptsFormSection from "../RetryAttemptsFormSection";
import type { FeedEditProps } from "./feedTypeRegistry";
import feedTypeRegistry from "./feedTypeRegistry";

class MavenFeed extends React.Component<FeedEditProps<MavenFeedResource>> {
    render() {
        return (
            <React.Fragment>
                <RetryAttemptsFormSection
                    backoffSeconds={this.props.feed.DownloadRetryBackoffSeconds}
                    onBackoffSecondsChanged={(seconds) => this.props.onChange({ ...this.props.feed, DownloadRetryBackoffSeconds: seconds })}
                    attemptCount={this.props.feed.DownloadAttempts}
                    onAttemptCountChanged={(count) => this.props.onChange({ ...this.props.feed, DownloadAttempts: count })}
                />
                <BasicFeedCredentialsFormSection
                    model={{ username: this.props.feed.Username!, password: this.props.feed.Password! }}
                    onChanged={(model) => this.props.onChange({ ...this.props.feed, Password: model.password, Username: model.username })}
                />
            </React.Fragment>
        );
    }
}

feedTypeRegistry.registerFeedType({
    type: FeedType.Maven,
    text: "Maven Feed",
    edit: MavenFeed as React.ComponentType<FeedEditProps<FeedResource>>,
    uriNotes: (
        <span>
            The URL to your Maven repository. This URL is the same value defined in the <code>repositories/repository/url</code> element of a Maven <code>settings.xml</code> file. The documentation for Maven repository managers such as{" "}
            <ExternalLink href="NexusMavenRepo">Nexus</ExternalLink>, <ExternalLink href="ArtifactoryMavenRepo">Artifactory</ExternalLink> and <ExternalLink href="MyGetMavenRepo">MyGet</ExternalLink> contain examples of the URL to be entered here.
        </span>
    ),
    uriDefault: "https://repo.maven.apache.org/maven2/",
});
