/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { ResourceCollection, ScopedUserRoleResource, SpaceResource, LicenseStatusResource, TeamResource } from "@octopusdeploy/octopus-server-client";
import { PermissionsMode, Permission } from "@octopusdeploy/octopus-server-client";
import { fromPairs, isEqual } from "lodash";
import MobileDetect from "mobile-detect";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { repository, session } from "~/clientInstance";
import { AdvancedFilterCheckbox } from "~/components/AdvancedFilterLayout";
import AdvancedFilterLayout from "~/components/AdvancedFilterLayout/AdvancedFilterLayout";
import { ActionButtonType } from "~/components/Button/ActionButton";
import { SpaceChip } from "~/components/Chips";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import FilterSearchBox from "~/components/FilterSearchBox";
import { SpaceMultiSelect } from "~/components/MultiSelect/SpaceMultiSelect";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import type { IQuery } from "~/components/QueryStringFilters/QueryStringFilters";
import { QueryStringFilters } from "~/components/QueryStringFilters/QueryStringFilters";
import { RestrictedPermissionsCallout } from "~/components/RestrictedPermissionsCallout";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { arrayValueFromQueryString } from "~/utils/ParseHelper/ParseHelper";
import Callout, { CalloutType } from "../../../../primitiveComponents/dataDisplay/Callout";
import routeLinks from "../../../../routeLinks";
import AddTeam from "./AddTeam";
import TeamList from "./TeamList";
import styles from "./style.module.less";

interface Filter {
    spaces: string[];
    name: string;
    includeSystem: boolean;
}

interface TeamsQuery extends IQuery {
    spaces?: string[];
    name?: string;
    includeSystem?: string;
}

interface TeamsState extends DataBaseComponentState {
    teamsResponse?: ResourceCollection<TeamResource>;
    initialTeamsScopedUserRoles?: Record<string, ScopedUserRoleResource[]>;
    spaces?: SpaceResource[];
    redirectTo?: string;
    filter: Filter;
    licenseStatus?: LicenseStatusResource;
}

class FilterLayout extends AdvancedFilterLayout<Filter> {}

const TeamsQueryStringFilters = QueryStringFilters.For<Filter, TeamsQuery>();

type TeamsProps = RouteComponentProps<{}>;

export class Teams extends DataBaseComponent<TeamsProps, TeamsState> {
    private autoFocus: boolean;
    private defaultFilter: Filter;

    constructor(props: TeamsProps) {
        super(props);
        this.defaultFilter = this.createDefaultFilter();

        this.state = {
            filter: this.defaultFilter,
        };

        const md = new MobileDetect(window.navigator.userAgent);
        this.autoFocus = md.isPhoneSized() ? false : true;
    }

    async componentDidMount() {
        return this.doBusyTask(
            async () => {
                // Spaces here are used as a lookup for scoped user roles
                // You will only be able to see a scoped user role if you have access *within* the space
                // for which the scoped user role applies
                // Therefore only need to load spaces that you have access *within*
                // rather than any space you can see (i.e. if you had SpaceView permission)
                const spacesAsync = repository.Users.getSpaces(session.currentUser!);
                this.setState({
                    spaces: await spacesAsync,
                    licenseStatus: await repository.Licenses.getCurrentStatus(),
                });
                await this.reload();
            },
            { timeOperationOptions: timeOperationOptions.forInitialLoad() }
        );
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true} />;
        }

        const hasSystemTeamView = session.currentPermissions!.scopeToSystem().isAuthorized({ permission: Permission.TeamView });

        const filterSections = [
            {
                render: (
                    <div>
                        {hasSystemTeamView && <AdvancedFilterCheckbox label="Include system teams" value={this.state.filter.includeSystem} onChange={(includeSystem) => this.search({ ...this.state.filter, includeSystem })} />}
                        {this.spaceSelector()}
                    </div>
                ),
            },
        ];

        const stateFilter = <FilterSearchBox placeholder="Filter by name..." value={this.state.filter.name} autoFocus={this.autoFocus} onChange={(name: string) => this.search({ ...this.state.filter, name })} />;

        const isFiltered = !isEqual(this.state.filter, this.defaultFilter);
        const filterResult = this.state.teamsResponse && isFiltered ? { numberOfMatches: this.state.teamsResponse.TotalResults, singleText: "team", pluralText: "teams" } : null;

        return (
            <PaperLayout
                title="Teams"
                busy={this.state.busy}
                errors={this.errors}
                sectionControl={
                    this.state.spaces && [
                        <PermissionCheck key="TeamCreate" permission={Permission.TeamCreate}>
                            <OpenDialogButton label="Add Team" type={ActionButtonType.Primary}>
                                <AddTeam currentSpace={this.getCurrentSpace()!} onTeamCreated={(id) => this.setState({ redirectTo: routeLinks.configuration.team(id) })} />
                            </OpenDialogButton>
                        </PermissionCheck>,
                    ]
                }
            >
                <RestrictedPermissionsCallout isVisible={(this.state.licenseStatus && this.state.licenseStatus.PermissionsMode === PermissionsMode.Restricted)!} />

                {this.state.teamsResponse && (
                    <>
                        <TeamsQueryStringFilters filter={this.state.filter} getQuery={this.queryFromFilters} getFilter={this.getFilterFromQuery} onFilterChange={this.search} />
                        <FilterLayout
                            filter={this.state.filter}
                            defaultFilter={this.createDefaultFilter()}
                            additionalHeaderFilters={[stateFilter]}
                            onFilterReset={this.search}
                            filterSections={filterSections}
                            filterResult={filterResult!}
                            filterByChips={this.filterByChips()}
                            renderContent={() => (
                                <div className={styles.lists}>
                                    {this.state.teamsResponse && this.state.spaces && (
                                        <TeamList
                                            initialTeams={this.state.teamsResponse}
                                            additionalRequestParams={this.getAdditionalRequestParams()}
                                            initialScopedUserRolesLookup={this.state.initialTeamsScopedUserRoles!}
                                            spaces={this.state.spaces}
                                            loadScopedUserRolesForTeams={this.loadScopedUserRolesForTeams}
                                        />
                                    )}
                                </div>
                            )}
                        />
                    </>
                )}
            </PaperLayout>
        );
    }

    private spaceSelector(): JSX.Element {
        const hasTeamViewInAnySpace = session.currentPermissions!.isAuthorizedInAnySpace({ permission: Permission.TeamView });
        if (!hasTeamViewInAnySpace) {
            return (
                <div style={{ margin: "1rem 0 0 0" }}>
                    <Callout type={CalloutType.Information} title={"Permission required"}>
                        You do not have {Permission.TeamView} permission in any given Space.
                    </Callout>
                </div>
            );
        }

        return <SpaceMultiSelect items={this.state.spaces || []} onChange={(spaces) => this.search({ ...this.state.filter, spaces })} value={this.state.filter.spaces} />;
    }

    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getAdditionalRequestParams(): Map<string, any> {
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        const additionalRequestParams = new Map<string, any>();

        additionalRequestParams.set("includeSystem", this.state.filter.includeSystem);
        additionalRequestParams.set("spaces", this.state.filter.spaces);
        additionalRequestParams.set("partialName", this.state.filter.name);

        return additionalRequestParams;
    }

    private filterByChips(): React.ReactNode {
        const nodes: React.ReactNode[] = [];

        if (this.state.spaces) {
            this.state.filter.spaces.map((spaceId) => {
                const teamSpace: SpaceResource = this.state.spaces!.find((s) => s.Id === spaceId) || null!;
                if (teamSpace) {
                    nodes.push(<SpaceChip key={spaceId} space={teamSpace} />);
                }
            });
        }

        if (nodes.length > 0) {
            nodes.push(<div className={styles.filterText}>space{nodes.length === 1 ? "" : "s"}</div>);
            nodes.push(<div className={styles.filterText}>and</div>);
        }

        nodes.push(
            <div className={styles.filterText}>
                <strong>{this.state.filter.includeSystem ? "includes" : "excludes"}</strong>
            </div>
        );
        nodes.push(<div className={styles.filterText}>system teams</div>);

        return <React.Fragment>{nodes}</React.Fragment>;
    }

    private queryFromFilters = (filter: Filter): TeamsQuery => {
        return {
            spaces: filter.spaces,
            name: filter.name,
            includeSystem: filter.includeSystem ? "true" : "false",
        };
    };

    private getFilterFromQuery = (query: TeamsQuery): Filter => {
        return {
            spaces: arrayValueFromQueryString(query.spaces),
            name: query.name || "",
            includeSystem: query.includeSystem === "true",
        };
    };

    private reload = async () => {
        const teamsResponse = await repository.Teams.list({
            includeSystem: this.state.filter.includeSystem,
            partialName: this.state.filter.name,
            spaces: getSpacesFilter(this.state.filter),
        });
        const initialTeamsScopedUserRoles = await this.loadScopedUserRolesForTeams(teamsResponse.Items);

        this.setState({
            teamsResponse,
            initialTeamsScopedUserRoles,
        });

        function getSpacesFilter(filter: Filter) {
            const hasTeamViewInAnySpace = session.currentPermissions!.isAuthorizedInAnySpace({ permission: Permission.TeamView });

            if (filter.spaces.length === 0) {
                if (hasTeamViewInAnySpace) {
                    return ["all"];
                } else {
                    return [];
                }
            }

            return filter.spaces;
        }
    };

    private search = (filter: Filter) => {
        this.setState({ filter }, () => this.doBusyTask(this.reload));
    };

    private createDefaultFilter = (): Filter => {
        const hasTeamViewInCurrentSpace = session.currentPermissions!.scopeToSpace(repository.spaceId).isAuthorized({ permission: Permission.TeamView });
        const shouldFilterToCurrentSpace = repository.spaceId && hasTeamViewInCurrentSpace;
        return {
            includeSystem: true,
            name: "",
            spaces: shouldFilterToCurrentSpace ? [repository.spaceId!] : [],
        };
    };

    private getCurrentSpace = () => {
        const currentSpace = this.state.spaces!.find((s) => s.Id === repository.spaceId);

        return currentSpace;
    };

    private async loadScopedUserRolesForTeams(team: TeamResource[]): Promise<Record<string, ScopedUserRoleResource[]>> {
        const teamsAndScopedUserRoles = await Promise.all(
            team.map(async (t) => {
                const scopedUserRoles = await repository.Teams.listScopedUserRoles(t);
                return { team: t, scopedUserRoles };
            })
        );
        return fromPairs<ScopedUserRoleResource[]>(teamsAndScopedUserRoles.map<[string, ScopedUserRoleResource[]]>((a) => [a.team.Id, a.scopedUserRoles.Items]));
    }
}

export default Teams;
