import type { FeedResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import useRequiredContext from "~/hooks/useRequiredContext";

type ProcessFeedsContextValue = FeedResource[];
type ProcessFeedsRefreshContextValue = () => Promise<unknown>;

const ProcessFeedsContext = React.createContext<ProcessFeedsContextValue>([]);
const RefreshProcessFedsContext = React.createContext<ProcessFeedsRefreshContextValue | undefined>(undefined);

export function useFeedsFromContext(): ProcessFeedsContextValue {
    return useRequiredContext(ProcessFeedsContext, "ProcessFeedsContext");
}

export function useRefreshFeedsFromContext(): ProcessFeedsRefreshContextValue {
    return useRequiredContext(RefreshProcessFedsContext, "RefreshProcessFeedsContext");
}

export function useFeedFromContext(id: string) {
    const feeds = useFeedsFromContext();

    return React.useMemo(() => {
        return feeds.find((f) => f.Id === id);
    }, [feeds, id]);
}

type ProcessFeedsContextProviderProps = {
    feeds: FeedResource[];
    refreshFeeds: () => Promise<unknown>;
};

type ProcessFeedConsumerProps = {
    feedId: string;
    children: (feed: FeedResource | undefined) => React.ReactElement;
};

export function ProcessFeedLookup(props: ProcessFeedConsumerProps) {
    const feed = useFeedFromContext(props.feedId);
    return props.children(feed);
}

export function ProcessFeedsContextProvider(props: React.PropsWithChildren<ProcessFeedsContextProviderProps>) {
    return (
        <ProcessFeedsContext.Provider value={props.feeds}>
            <RefreshProcessFedsContext.Provider value={props.refreshFeeds}>{props.children}</RefreshProcessFedsContext.Provider>
        </ProcessFeedsContext.Provider>
    );
}
