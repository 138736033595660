import React from "react";
import type { EmbeddedImageDescription, VariantDescription } from "~/components/DevTools/Images/EmbeddedImageDescription";
import * as baseIcons from "../../../primitiveComponents/dataDisplay/Icon/OctopusIcon/BaseIcons";

const variants: VariantDescription[] = Object.entries(baseIcons).map(([componentName, Icon]) => ({
    variantDescription: componentName,
    content: <Icon />,
}));

// This represents every usage of <SvgIcon> in a react component that isn't covered by embeddedSvgDescriptions
export const embeddedSvgIconDescriptions: EmbeddedImageDescription[] = [{ componentName: "BaseIcons", variants }];
