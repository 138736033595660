/* eslint-disable @typescript-eslint/consistent-type-assertions */

import type {
    AccountResource,
    AmazonWebServicesAccountResource,
    AzureEnvironment,
    AzureServicePrincipalAccountResource,
    AzureSubscriptionAccountResource,
    EnvironmentResource,
    GoogleCloudAccountResource,
    SshKeyPairAccountResource,
    TenantResource,
    TokenAccountResource,
    UsernamePasswordAccountResource,
} from "@octopusdeploy/octopus-server-client";
import { AccountType, Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import BaseComponent from "~/components/BaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { ErrorPanel } from "~/components/form";
import { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";
import AmazonWebServicesAccountEdit from "./AmazonWebServicesAccountEdit";
import AzureAccountEdit from "./AzureAccountEdit";
import GoogleCloudAccountEdit from "./GoogleCloudAccountEdit";
import SshKeyPairAccountEdit from "./SshKeyPairAccountEdit";
import TokenAccountEdit from "./TokenAccountEdit";
import UsernamePasswordAccountEdit from "./UsernamePasswordAccountEdit";

type AccountEditProps = {
    initialData: InitialData;
    trackAction: AnalyticTrackedActionDispatcher;
};

interface NewAccountData {
    accountType: AccountType;
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    azureEnvironments: AzureEnvironment[];
}

interface AccountData extends NewAccountData {
    account: AccountResource;
}

type InitialData = NewAccountData | AccountData;

const Title = "Accounts";
const AccountEditFormPage = FormPage<InitialData>();

const IsNew = "IsNew";

interface NewAccountEditProps {
    createNew: true;
    accountType: AccountType;
}

interface ExistingAccountEditProps {
    createNew: false;
    accountId: string;
}

const AccountEdit: React.FC<NewAccountEditProps | ExistingAccountEditProps> = (props) => {
    const trackAction = useAnalyticTrackedActionDispatch();

    return (
        <AccountEditFormPage
            title={Title}
            load={async () => {
                const environments = repository.Environments.all();
                const tenants = isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all() : Promise.resolve([]);
                const azureEnvironments = repository.Accounts.getIsolatedAzureEnvironments();

                if (!props.createNew) {
                    const account = await repository.Accounts.get(props.accountId);
                    return {
                        environments: await environments,
                        tenants: await tenants,
                        azureEnvironments: await azureEnvironments,
                        account,
                        accountType: account.AccountType,
                    };
                } else {
                    return {
                        environments: await environments,
                        tenants: await tenants,
                        azureEnvironments: await azureEnvironments,
                        accountType: props.accountType,
                    };
                }
            }}
            reloadOnPropChanges={true} // Our accountType prop needs to force a reload.
            renderWhenLoaded={(data) => <AccountEditInternal trackAction={trackAction} initialData={data} />}
            renderAlternate={(args) => <InfrastructureLayoutBusy title={Title} {...args} />}
        />
    );
};

class AccountEditInternal extends BaseComponent<AccountEditProps> {
    constructor(props: AccountEditProps) {
        super(props);
    }

    hasAccount(check: object): check is AccountData {
        return (check as AccountData).account !== undefined;
    }

    render() {
        const data = this.props.initialData;
        const account = this.hasAccount(data) ? data.account : IsNew;

        switch (this.props.initialData.accountType) {
            case AccountType.SshKeyPair:
                return <SshKeyPairAccountEdit trackAction={this.props.trackAction} account={account as SshKeyPairAccountResource} environments={data.environments} tenants={data.tenants} azureEnvironments={data.azureEnvironments} />;
            case AccountType.Token:
                return <TokenAccountEdit trackAction={this.props.trackAction} account={account as TokenAccountResource} environments={data.environments} tenants={data.tenants} azureEnvironments={data.azureEnvironments} />;
            case AccountType.UsernamePassword:
                return <UsernamePasswordAccountEdit trackAction={this.props.trackAction} account={account as UsernamePasswordAccountResource} environments={data.environments} tenants={data.tenants} azureEnvironments={data.azureEnvironments} />;
            case AccountType.AzureSubscription:
            case AccountType.AzureServicePrincipal:
                return (
                    <AzureAccountEdit
                        trackAction={this.props.trackAction}
                        account={account as AzureServicePrincipalAccountResource | AzureSubscriptionAccountResource}
                        environments={data.environments}
                        tenants={data.tenants}
                        azureEnvironments={data.azureEnvironments}
                    />
                );
            case AccountType.AmazonWebServicesAccount:
                return <AmazonWebServicesAccountEdit trackAction={this.props.trackAction} account={account as AmazonWebServicesAccountResource} environments={data.environments} tenants={data.tenants} azureEnvironments={data.azureEnvironments} />;
            case AccountType.GoogleCloudAccount:
                return <GoogleCloudAccountEdit trackAction={this.props.trackAction} account={account as GoogleCloudAccountResource} environments={data.environments} tenants={data.tenants} azureEnvironments={data.azureEnvironments} />;
            default:
                return <ErrorPanel message="Invalid path" />;
        }
    }
}

export default AccountEdit;
