import * as React from "react";
import Card from "~/components/Card/Card";
import SectionHeading from "~/primitiveComponents/dataDisplay/Section/SectionHeading";
import styles from "./KnownUsedFontAwesomeIcons.module.less";

type FontAwesomeIconType = "regular" | "solid";

type FontAwesomeIcon = {
    type: FontAwesomeIconType;
    icon: string;
};

const wellKnownUsedIcons: FontAwesomeIcon[] = [
    { icon: "fa-sort", type: "solid" },
    { icon: "fa-chevron-up", type: "solid" },
    { icon: "fa-exclamation-circle", type: "solid" },
    { icon: "fa-exclamation-triangle", type: "solid" },
    { icon: "fa-eye", type: "solid" },
    { icon: "fa-user", type: "solid" },
    { icon: "fa-spinner", type: "solid" },
    { icon: "fa-clock", type: "regular" },
    { icon: "fa-xmark", type: "solid" },
    { icon: "fa-user-times", type: "solid" },
    { icon: "fa-check", type: "solid" },
    { icon: "fa-triangle-exclamation", type: "solid" },
    { icon: "fa-circle-info", type: "solid" },
    { icon: "fa-ban", type: "solid" },
    { icon: "fa-star", type: "solid" },
    { icon: "fa-pencil", type: "solid" },
    { icon: "fa-download", type: "solid" },
    { icon: "fa-minus", type: "solid" },
    { icon: "fa-smile", type: "regular" },
    { icon: "fa-caret-down", type: "solid" },
    { icon: "fa-external-link", type: "solid" },
    { icon: "fa-chain", type: "solid" },
    { icon: "fa-key", type: "solid" },
    { icon: "fa-list-ol", type: "solid" },
    { icon: "fa-check-square", type: "regular" },
    { icon: "fa-users", type: "solid" },
    { icon: "fa-bars", type: "solid" },
    { icon: "fa-square", type: "solid" },
    { icon: "fa-check-circle", type: "solid" },
];

export function KnownUsedFontAwesomeIcons() {
    return (
        <>
            <SectionHeading title={"Known Used Font Awesome Icons"} />
            <CardList>
                {wellKnownUsedIcons.map((icon) => (
                    <IconCard key={`${icon.type}-${icon.icon}`} icon={icon.icon} iconType={icon.type} />
                ))}
            </CardList>
        </>
    );
}

function IconCard({ icon, iconType }: { icon: string; iconType: FontAwesomeIconType }) {
    return <Card className={styles.cardContainer} logo={null} header={<h4>{icon}</h4>} content={<IconCardContent icon={icon} iconType={iconType} />} footerText={iconType} />;
}

function IconCardContent({ icon, iconType }: { icon: string; iconType: FontAwesomeIconType }) {
    return (
        <div className={styles.cardContentContainer}>
            <i className={`fa-${iconType} ${icon} fa-2xl ${styles.icon}`} />
        </div>
    );
}

function CardList(props: React.PropsWithChildren<{}>) {
    return <div className={styles.cardsList}>{props.children}</div>;
}
