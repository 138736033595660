/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import StickyNode from "react-stickynode";

// This is a monkey patch to fix this issue: https://github.com/yahoo/react-stickynode/issues/46
// We should go back and submit a PR to that repo when we have more time
// This is required for the variable editor headers
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
(StickyNode.prototype as any).getTargetHeight = (target: any) => {
    return (target && target.offsetHeight + target.getBoundingClientRect().top) || 0;
};

export type StickyStatus = StickyNode.StatusCode;
export const StickyStatus = {
    STATUS_ORIGINAL: 0,
    STATUS_RELEASED: 1,
    STATUS_FIXED: 2,
} as const;

interface StickyProps {
    top?: string | number;
    bottomBoundary?: string | number;
    innerZ?: number;
    enableTransforms?: boolean;
    onStateChange?(state: StickyNode.Status): void;
}

const Sticky: React.SFC<StickyProps> = (props: StickyProps) => {
    return <StickyNode {...props} />;
};

export default Sticky;
