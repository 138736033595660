import type { ClientConfiguration, ClientResponseDetails, ApiVersionCallbacks } from "@octopusdeploy/octopus-server-client";
import { Client, Repository, serverVersionNotificationCheck } from "@octopusdeploy/octopus-server-client";
import URI from "urijs";
import octopusDynamicExtensions from "~/areas/dynamicExtensions";
import * as notificationLocalState from "~/components/Navbar/NotificationPoller/notificationLocalState";
import { notificationActions, NotificationType } from "~/components/Navbar/notifications.redux";
import Session from "~/session";
import store from "~/store";
import { resolveApplicationRootUri } from "~/utils/resolveApplicationRootUri";

const browserURI = URI(window.location);
const configuration: ClientConfiguration = {
    serverEndpoint: resolveApplicationRootUri(window.location),
};
const cookieName = "OctopusDevExperience.Server";
const octopusServerURI = browserURI.search(true)["octopus.server"];
if (!octopusServerURI) {
    document.cookie = `${cookieName}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
} else {
    document.cookie = `${cookieName}=${octopusServerURI}`;
}

octopusDynamicExtensions.setApiDocumentRootUrl(`${configuration.serverEndpoint}/api`);

const notificationId = "Notification/ClientVersionOutdated";

const apiVersionCallbacks: ApiVersionCallbacks = {
    onMatchingVersionsDetected: () => {
        notificationLocalState.clearValue(notificationId);
        store.dispatch(notificationActions.dismissNotification(notificationId));
    },
    onMismatchedVersionsDetected: (apiVersion, clientVersion) => {
        const description = `The current web portal is running as version ${clientVersion} however Octopus Server is
    running ${apiVersion}. Refresh this page to ensure you have the latest portal version.`;

        store.dispatch(
            notificationActions.raiseNotification({
                id: notificationId,
                value: apiVersion,
                type: NotificationType.info,
                description,
                text: "Server version updated",
                links: [{ type: "Refresh", label: "Reload Site" }],
            })
        );
    },
};

const session = new Session();
const client = Client.Create(
    configuration,
    () => session.isAuthenticated(),
    () => session.end()
);

client.subscribeToResponses("ServerVersionNotificationCheck", (clientResponseDetails: ClientResponseDetails) => {
    serverVersionNotificationCheck(clientResponseDetails.request, client, apiVersionCallbacks);
});

const repository = new Repository(client);

export { client, repository, session };
