/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import type { AuthenticationProviderElement, IdentityMetadataResource, ClaimsBasedIdentity, UserResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { ProviderGroups } from "~/areas/configuration/components/Users/ProviderGroups";
import { session, repository } from "~/clientInstance";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent/FormBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout";
import { Section } from "~/components/Section/Section";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";

interface UserEditModel {
    original: UserResource;
    identities: ClaimsBasedIdentity[];
}

interface UserLoginsState extends OptionalFormBaseComponentState<UserEditModel> {
    user: UserResource;
    enabledAuthenticationProviders: AuthenticationProviderElement[] | null;
    enabledProvidersMetadata: IdentityMetadataResource[] | null;
    canCurrentUserEditIdentitiesForUser?: boolean;
}

export default class UserLogins extends FormBaseComponent<any, UserLoginsState, UserEditModel> {
    constructor(props: any) {
        super(props);

        this.state = {
            user: null!,
            model: null!,
            cleanModel: null!,
            canCurrentUserEditIdentitiesForUser: true,
            enabledAuthenticationProviders: null, //start as null so we can only show no providers once it loads
            enabledProvidersMetadata: null,
        };
    }

    currentUserId(): string {
        return session && session.currentUser ? session.currentUser.Id : null!;
    }

    async componentDidMount() {
        await this.doBusyTask(
            async () => {
                const user = this.currentUserId() ? await repository.Users.get(this.currentUserId()) : null;

                const configDoc = await repository.UserIdentityMetadata.authenticationConfiguration(user!.Id);
                const metadataDoc = await repository.UserIdentityMetadata.all();

                this.setState({
                    user: user!,
                    model: this.buildModel(user!),
                    cleanModel: this.buildModel(user!),
                    enabledAuthenticationProviders: configDoc.AuthenticationProviders || [],
                    canCurrentUserEditIdentitiesForUser: configDoc.CanCurrentUserEditIdentitiesForUser,
                    enabledProvidersMetadata: metadataDoc.Providers,
                });
            },
            { timeOperationOptions: timeOperationOptions.forInitialLoad() }
        );
    }

    handleAddIdentity(identity: any) {
        this.setState((state) => ({
            model: {
                ...state!.model,
                identities: [identity, ...state!.model!.identities],
            },
        }));
        return true;
    }

    render() {
        const onlyUsernameAndPasswordEnabled = this.state.enabledAuthenticationProviders && this.state.enabledAuthenticationProviders?.every((p) => p.IdentityType === "UsernamePassword");

        return (
            <FormPaperLayout title={"My Logins"} busy={this.state.busy} errors={this.errors} model={this.state.model} cleanModel={this.state.cleanModel} onSaveClick={this.handleSaveClick}>
                {this.state.model && this.state.enabledAuthenticationProviders!.length > 0 && !onlyUsernameAndPasswordEnabled ? (
                    <ProviderGroups
                        userIdentities={this.state.model.identities}
                        enabledAuthenticationProviders={this.state.enabledAuthenticationProviders!}
                        canCurrentUserEditIdentitiesForUser={this.state.canCurrentUserEditIdentitiesForUser!}
                        enabledProvidersMetadata={this.state.enabledProvidersMetadata!}
                        isServiceAccount={this.state.user.IsService}
                        onChange={(identities) => this.setModelState({ identities })}
                    />
                ) : (
                    this.state.enabledAuthenticationProviders && <Section>There are no providers that support external identities enabled.</Section>
                )}
            </FormPaperLayout>
        );
    }

    buildModel(user: UserResource): UserEditModel {
        return user
            ? {
                  original: user,
                  identities: user.Identities,
              }
            : {
                  original: null!,
                  identities: [],
              };
    }

    handleSaveClick = async () => {
        const user: UserResource = {
            ...this.state.user,
            Identities: this.state.model!.identities,
        };

        await this.doBusyTask(async () => {
            const result = await repository.Users.save(user);

            this.setState({
                cleanModel: this.buildModel(user),
                model: this.buildModel(user),
            });
        });
    };
}
