import React from "react";
import { BooleanRadioButtonGroup, ExpandableFormSection, RadioButton, Summary } from "~/components/form";

interface Props {
    isEnabled: boolean;
    onChange: (isEnabled: boolean) => void;
}

export function NavigationVisualUpliftFeature({ isEnabled, onChange }: Props) {
    return (
        <ExpandableFormSection
            key="NavigationVisualUplift"
            errorKey="NavigationVisualUplift"
            title="Navigation visual uplift"
            summary={isEnabled ? Summary.summary("Enabled") : Summary.default("Disabled")}
            help="Enable/Disable the navigation visual uplift"
        >
            <BooleanRadioButtonGroup value={isEnabled} onChange={onChange}>
                <RadioButton value={true} label="Enabled" />
                <RadioButton value={false} label="Disabled" isDefault={true} />
            </BooleanRadioButtonGroup>
        </ExpandableFormSection>
    );
}
