import type { EnvironmentsSummaryResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import ActionList from "~/components/ActionList";
import { NavigationButton, NavigationButtonType } from "~/components/Button";
import DataBaseComponent from "~/components/DataBaseComponent";
import routeLinks from "~/routeLinks";

interface InfraOverviewOnboardingProps {
    environmentsSummaryResourcePromise: Promise<EnvironmentsSummaryResource> | undefined;
}
type InfraOverviewOnboardingState = {
    environmentsSummaryResource: EnvironmentsSummaryResource | undefined;
};

export class InfraOverviewOnboardingActions extends DataBaseComponent<InfraOverviewOnboardingProps, InfraOverviewOnboardingState> {
    constructor(props: InfraOverviewOnboardingProps) {
        super(props);
        this.state = {
            environmentsSummaryResource: undefined,
        };
    }
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const resource = await this.props.environmentsSummaryResourcePromise;
            if (resource !== undefined) {
                this.setState({
                    environmentsSummaryResource: resource,
                });
            }
        });
    }
    render() {
        return (
            <ActionList
                actions={[this.state.environmentsSummaryResource?.EnvironmentSummaries?.length === 0 && <NavigationButton label="Go to Environments" href={routeLinks.infrastructure.environments.root} type={NavigationButtonType.Primary} />]}
            />
        );
    }
}
