import React from "react";
import { ActionButtonType, OpenDeleteDialogButton } from "~/components/Button";

export type DynamicEnvironmentDeprovisionDialogButtonProps = {
    environmentId: string;
    environmentName: string;
    onDeprovisionEnvironment: (environmentId: string) => Promise<void>;
};

export function DynamicEnvironmentDeprovisionDialogButton({ environmentId, environmentName, onDeprovisionEnvironment }: DynamicEnvironmentDeprovisionDialogButtonProps) {
    return (
        <OpenDeleteDialogButton
            dialogTitle={`Are you sure you want to deprovision environment '${environmentName}'?`}
            dialogButtonLabel="Deprovision"
            dialogButtonBusyLabel="Deprovision"
            disabled={false}
            onDeleteClick={async () => {
                await onDeprovisionEnvironment(environmentId);
                return true;
            }}
            label="Deprovision..."
            type={ActionButtonType.Secondary}
        >
            <div>
                <p>This action will deprovision both the environment and all associated projects.</p>
                <p>Do you wish to continue?</p>
            </div>
        </OpenDeleteDialogButton>
    );
}
