import type { DynamicEnvironmentOverviewProject, DynamicEnvironmentOverviewProjectState, DynamicEnvironmentOverviewState, GetDynamicEnvironmentOverviewResponseV1 } from "@octopusdeploy/octopus-server-client";
import { TaskState } from "@octopusdeploy/octopus-server-client";
import { exhaustiveCheck } from "@octopusdeploy/step-runtime-inputs";
import { TaskStatusIcon } from "app/areas/projects/components/TaskStatusIcon/TaskStatusIcon";
import classnames from "classnames";
import React from "react";
import { TaskStatusDetails } from "~/areas/projects/components/ProjectDashboard/TaskStatusDetails/TaskStatusDetails";
import RunbookTaskStatusDetails from "~/areas/projects/components/Runbooks/RunbookTaskStatusDetails/RunbookTaskStatusDetails";
import ActionList from "~/components/ActionList";
import AdvancedFilterLayout from "~/components/AdvancedFilterLayout";
import BusyFromPromise from "~/components/BusyFromPromise";
import ActionButton from "~/components/Button";
import { FilterTextChip } from "~/components/Chips";
import FilterSearchBox from "~/components/FilterSearchBox";
import Logo from "~/components/Logo";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import InternalLink from "~/components/Navigation/InternalLink";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenu } from "~/components/OverflowMenu/OverflowMenu";
import PaperLayout from "~/components/PaperLayout";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import { Select } from "~/components/form";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import { DataTable, DataTableBody, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import type { DropdownMenuOption } from "~/primitiveComponents/form/Select/DropDownMenu";
import routeLinks from "~/routeLinks";
import DateFormatter from "~/utils/DateFormatter/index";
import { DynamicEnvironmentDeleteDialogButton } from "../DynamicEnvironmentDialogs/DynamicEnvironmentDeleteDialogButton";
import { DynamicEnvironmentDeprovisionDialogButton } from "../DynamicEnvironmentDialogs/DynamicEnvironmentDeprovisionDialogButton";
import { DynamicEnvironmentProjectStateIconWithDescription } from "../DynamicEnvironmentIcons/DynamicEnvironmentProjectStateIconWithDescription";
import { DynamicEnvironmentStateIconWithDescription } from "../DynamicEnvironmentIcons/DynamicEnvironmentStateIconWithDescription";
import { getDynamicEnvironmentProjectStateDescription } from "../DynamicEnvironmentIcons/getDynamicEnvironmentProjectStateDescription";
import { deleteEnvironmentMenuItem } from "../DynamicEnvironmentMenuItems/deleteEnvironmentMenuItem";
import { deprovisionProjectMenuItem } from "../DynamicEnvironmentMenuItems/deprovisionProjectMenuItem";
import styles from "./style.module.less";

function DynamicEnvironmentOverviewAssociatedProjectsHeader() {
    return <h4 className={styles.dynamicEnvironmentOverviewAssociatedProjectsHeading}>Associated Projects</h4>;
}

type DynamicEnvironmentOverviewAssociatedProjectsFooterProps = {
    projects: DynamicEnvironmentOverviewProject[];
    totalCount: number | undefined;
    busy: Promise<void> | undefined;
    onLoadMore: () => void;
};

function DynamicEnvironmentOverviewAssociatedProjectsFooter({ projects, totalCount, busy, onLoadMore }: DynamicEnvironmentOverviewAssociatedProjectsFooterProps) {
    const loadMoreEnabled = totalCount != undefined && totalCount > projects.length;

    return <div className={styles.dynamicEnvironmentOverviewProjectFooter}>{loadMoreEnabled && <BusyFromPromise promise={busy}>{(isBusy: boolean) => <ActionButton label="Load more" onClick={onLoadMore} disabled={isBusy} />}</BusyFromPromise>}</div>;
}

export type DynamicEnvironmentOverviewProjectSortOptions = "latestActivityAsc" | "latestActivityDesc" | "projectNameAsc" | "projectNameDesc" | "deprovisioningOrder";

export type DynamicEnvironmentOverviewProjectSortOrderOption = {
    value: DynamicEnvironmentOverviewProjectSortOptions;
    text: string;
};

export function getSortOrderDescription(sortOrder: DynamicEnvironmentOverviewProjectSortOptions) {
    switch (sortOrder) {
        case "latestActivityAsc":
            return "Last activity: oldest - latest";
        case "latestActivityDesc":
            return "Last activity: latest - oldest";
        case "projectNameAsc":
            return "Project name: A - Z";
        case "projectNameDesc":
            return "Project name: Z - A";
        case "deprovisioningOrder":
            return "Deprovisioning order";
        default:
            exhaustiveCheck(sortOrder, "unknown sort order");
    }
}

type DynamicEnvironmentOverviewProjectsSortProps = {
    sortOrder: DynamicEnvironmentOverviewProjectSortOptions;
    onSortOrderChange: (sort: DynamicEnvironmentOverviewProjectSortOptions) => void;
    environment: GetDynamicEnvironmentOverviewResponseV1;
};

function DynamicEnvironmentOverviewAssociatedProjectsSort({ sortOrder, onSortOrderChange, environment }: DynamicEnvironmentOverviewProjectsSortProps) {
    function selectionRenderer(_: string, menuItem: DropdownMenuOption) {
        return <div className={styles.sortBySelection}>{menuItem.text}</div>;
    }

    function onSortOrderChangeInternal(newValue: string | undefined) {
        if (newValue === undefined) return;

        if (newValue === "latestActivityAsc") onSortOrderChange("latestActivityAsc");
        else if (newValue === "latestActivityDesc") onSortOrderChange("latestActivityDesc");
        else if (newValue === "projectNameAsc") onSortOrderChange("projectNameAsc");
        else if (newValue === "projectNameDesc") onSortOrderChange("projectNameDesc");
        else if (newValue === "deprovisioningOrder") onSortOrderChange("deprovisioningOrder");
        else throw new Error("Unknown sort option");
    }

    const availableSortOrders: DynamicEnvironmentOverviewProjectSortOptions[] =
        environment.EnvironmentState === "Active" ? ["latestActivityDesc", "latestActivityAsc", "projectNameAsc", "projectNameDesc"] : ["deprovisioningOrder", "projectNameAsc", "projectNameDesc"];

    return (
        <label className={styles.dynamicEnvironmentOverviewProjectSortBySelect}>
            <div className={styles.dynamicEnvironmentOverviewProjectSortByLabel}>Sort by</div>
            <Select
                className={styles.sortSelect}
                allowClear={false}
                items={availableSortOrders.map<DynamicEnvironmentOverviewProjectSortOrderOption>((s) => ({ text: getSortOrderDescription(s), value: s }))}
                value={sortOrder}
                onChange={onSortOrderChangeInternal}
                sortItems={false}
                selectionRenderer={selectionRenderer}
            />
        </label>
    );
}

type DynamicEnvironmentOverviewAssociatedProjectsTableProps = {
    environment: GetDynamicEnvironmentOverviewResponseV1;
    onDeprovisionProject: (environmentId: string, projectId: string) => Promise<void>;
};

function DynamicEnvironmentOverviewAssociatedProjectsTable({ environment, onDeprovisionProject }: DynamicEnvironmentOverviewAssociatedProjectsTableProps) {
    function CreateOverflowMenuItems(environmentId: string, environmentState: DynamicEnvironmentOverviewState, projectId: string, projectName: string, projectState: DynamicEnvironmentOverviewProjectState) {
        const overflowMenuItems: Array<MenuItem> = [];

        if ((environmentState === "Active" || environmentState === "DeprovisioningFailed") && (projectState === "Provisioned" || projectState === "DeprovisioningFailed")) {
            overflowMenuItems.push(deprovisionProjectMenuItem(environmentId, projectId, projectName, onDeprovisionProject));
        }
        return overflowMenuItems;
    }

    return (
        <DataTable title="Associated Projects">
            <DataTableHeader>
                <DataTableRow>
                    <DataTableHeaderColumn>Project name</DataTableHeaderColumn>
                    <DataTableHeaderColumn>State</DataTableHeaderColumn>
                    <DataTableHeaderColumn>{environment.EnvironmentState === "Active" ? "Last activity" : "Deprovisioning task"}</DataTableHeaderColumn>
                    {environment.EnvironmentState !== "Active" && <DataTableHeaderColumn>Task duration</DataTableHeaderColumn>}
                    <DataTableHeaderColumn />
                </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
                {environment.AssociatedProjects.map((project) => (
                    <DataTableRow key={project.ProjectId} className={styles.dynamicEnvironmentOverviewProjectTableRow}>
                        <DataTableRowColumn className={styles.dynamicEnvironmentOverviewProjectTableRowColumn}>
                            <div className={styles.dynamicEnvironmentOverviewProjectTableRowColumnData}>
                                <InternalLink to={routeLinks.project(project.ProjectId).deployments.root} className={styles.projectLink}>
                                    {project.ProjectLogoUrl && <Logo url={project.ProjectLogoUrl} size="2.5rem" />}
                                    {project.ProjectName}
                                </InternalLink>
                            </div>
                        </DataTableRowColumn>
                        <DataTableRowColumn className={styles.dynamicEnvironmentOverviewProjectTableRowColumn}>
                            <div className={classnames(styles.dynamicEnvironmentOverviewProjectTableRowColumnData, styles.dynamicEnvironmentOverviewProjectStateCell)}>
                                <DynamicEnvironmentProjectStateIconWithDescription state={project.ProjectState} />
                            </div>
                        </DataTableRowColumn>
                        <DataTableRowColumn className={styles.dynamicEnvironmentOverviewProjectTableRowColumn}>
                            <div className={styles.dynamicEnvironmentOverviewProjectTableRowColumnData}>
                                {project.Deployment && (
                                    <div className={styles.dynamicEnvironmentOverviewProjectTaskColumnData}>
                                        <TaskStatusDetails item={project.Deployment} channelName={undefined} projectSlug={project.ProjectId} deploymentId={project.Deployment.DeploymentId} additionalDetails={false} />
                                    </div>
                                )}
                                {project.Provisioning && (
                                    <div className={styles.dynamicEnvironmentOverviewProjectTaskColumnData}>
                                        <RunbookTaskStatusDetails item={project.Provisioning} />
                                    </div>
                                )}
                                {project.Deprovisioning && (
                                    <div className={styles.dynamicEnvironmentOverviewProjectTaskColumnData}>
                                        <RunbookTaskStatusDetails item={project.Deprovisioning} />
                                    </div>
                                )}
                                {project.ProjectState === "Deprovisioned" && !project.Deprovisioning && (
                                    <div className={styles.dynamicEnvironmentNoRunbookTask}>
                                        <TaskStatusIcon
                                            iconClassName={styles.dynamicEnvironmentNoRunbookTaskIcon}
                                            item={{ State: TaskState.Success, HasPendingInterruptions: false, HasWarningsOrErrors: false, IsCompleted: true }}
                                            smallIcon={false}
                                            iconOnly={true}
                                        />
                                        <div className={styles.dynamicEnvironmentNoRunbookTaskDetails}>
                                            <span className={styles.dynamicEnvironmentNoRunbookTaskTitle}>No runbook configured</span>
                                            <span className={styles.dynamicEnvironmentNoRunbookTaskSubtitle}>{DateFormatter.dateToShortFormat(project.DeprovisionedDateTime)}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </DataTableRowColumn>
                        {environment.EnvironmentState !== "Active" && (
                            <DataTableRowColumn className={styles.dynamicEnvironmentOverviewProjectTableRowColumn}>{project.Deprovisioning && <span>{project.Deprovisioning.Duration}</span>}</DataTableRowColumn>
                        )}
                        <DataTableRowColumn className={styles.dynamicEnvironmentOverviewProjectTableRowColumn}>
                            <div className={classnames(styles.dynamicEnvironmentOverviewProjectTableRowColumnData, styles.dynamicEnvironmentOverviewProjectOverflowMenuCell)}>
                                <OverflowMenu
                                    accessibleName="dynamicEnvironmentOverviewProjectOverflowMenu"
                                    menuItems={CreateOverflowMenuItems(environment.EnvironmentId, environment.EnvironmentState, project.ProjectId, project.ProjectName, project.ProjectState)}
                                />
                            </div>
                        </DataTableRowColumn>
                    </DataTableRow>
                ))}
            </DataTableBody>
        </DataTable>
    );
}

export type DynamicEnvironmentOverviewAssociatedProjectsFilter = {
    projectName?: string;
    projectState: Array<DynamicEnvironmentOverviewProjectState>;
};

export const defaultDynamicEnvironmentOverviewAssociatedProjectsFilter: DynamicEnvironmentOverviewAssociatedProjectsFilter = {
    projectState: [],
};

class DynamicEnvironmentsOverviewAdvancedFilterLayout extends AdvancedFilterLayout<DynamicEnvironmentOverviewAssociatedProjectsFilter> {}

const DynamicEnvironmentOverviewProjectStateMultiSelect = MultiSelect<SelectItem>();

const allDynamicEnvironmentOverviewProjectStates: Array<DynamicEnvironmentOverviewProjectState> = ["Provisioning", "ProvisioningFailed", "Provisioned", "Deprovisioned", "Deprovisioning", "DeprovisioningFailed"];

const chipRenderer = (r: SelectItem, onRequestDelete: () => void) => <FilterTextChip onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${r.Name}`} filterText={r.Name} />;

type DynamicEnvironmentOverviewAssociatedProjectsProps = {
    environment: GetDynamicEnvironmentOverviewResponseV1;
    filter: DynamicEnvironmentOverviewAssociatedProjectsFilter;
    sortOrder: DynamicEnvironmentOverviewProjectSortOptions;
    busy: Promise<void> | undefined;
    onDeprovisionProject: (environmentId: string, projectId: string) => Promise<void>;
    onFilterChange: (filter: DynamicEnvironmentOverviewAssociatedProjectsFilter) => void;
    onSortOrderChange: (sort: DynamicEnvironmentOverviewProjectSortOptions) => void;
    onLoadMore: () => void;
};

function DynamicEnvironmentOverviewAssociatedProjects({ filter, environment, sortOrder, busy, onDeprovisionProject, onFilterChange, onSortOrderChange, onLoadMore }: DynamicEnvironmentOverviewAssociatedProjectsProps) {
    function getFilterChips() {
        const chips = [];

        chips.push(...filter.projectState.map((s) => <FilterTextChip filterText={getDynamicEnvironmentProjectStateDescription(s)} />));

        return chips.length > 0 ? chips : null;
    }

    return (
        <>
            <DynamicEnvironmentOverviewAssociatedProjectsHeader />
            <DynamicEnvironmentsOverviewAdvancedFilterLayout
                filter={filter}
                defaultFilter={defaultDynamicEnvironmentOverviewAssociatedProjectsFilter}
                onFilterReset={() => onFilterChange(defaultDynamicEnvironmentOverviewAssociatedProjectsFilter)}
                additionalHeaderFilters={[
                    <FilterSearchBox
                        placeholder={"Search by project name..."}
                        onChange={(newValue) => {
                            onFilterChange({
                                ...filter,
                                projectName: newValue,
                            });
                        }}
                    />,
                ]}
                filterSections={[
                    {
                        render: (
                            <DynamicEnvironmentOverviewProjectStateMultiSelect
                                accessibleName="dynamicEnvironmentOverviewProjectStateFilter"
                                items={allDynamicEnvironmentOverviewProjectStates.map((s) => ({ Id: s, Name: getDynamicEnvironmentProjectStateDescription(s) }))}
                                value={filter.projectState}
                                onChange={(newStates) => {
                                    onFilterChange({
                                        ...filter,
                                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                                        projectState: newStates as DynamicEnvironmentOverviewProjectState[],
                                    });
                                }}
                                renderChip={chipRenderer}
                                label="State"
                            />
                        ),
                    },
                ]}
                filterByChips={getFilterChips()}
                additionalHeaderControls={[<DynamicEnvironmentOverviewAssociatedProjectsSort sortOrder={sortOrder} onSortOrderChange={onSortOrderChange} environment={environment} />]}
                renderContent={() => (
                    <>
                        <DynamicEnvironmentOverviewAssociatedProjectsTable environment={environment} onDeprovisionProject={onDeprovisionProject} />
                        <DynamicEnvironmentOverviewAssociatedProjectsFooter projects={environment.AssociatedProjects} onLoadMore={onLoadMore} busy={busy} totalCount={environment.AssociatedProjectsTotalCount} />
                    </>
                )}
            />
        </>
    );
}

type DynamicEnvironmentSummaryCardProps = {
    header: string;
    children: React.ReactNode;
};

function DynamicEnvironmentSummaryCard({ header, children }: DynamicEnvironmentSummaryCardProps) {
    const palette = useThemePaletteType();

    const cardClassName = classnames(styles.dynamicEnvironmentOverviewSummaryCard, {
        [styles.dynamicEnvironmentOverviewSummaryCardLight]: palette === "light",
        [styles.dynamicEnvironmentOverviewSummaryCardDark]: palette === "dark",
    });

    const headerClassName = classnames(styles.dynamicEnvironmentOverviewSummaryCardHeader, {
        [styles.dynamicEnvironmentOverviewSummaryCardHeaderLight]: palette === "light",
        [styles.dynamicEnvironmentOverviewSummaryCardHeaderDark]: palette === "dark",
    });

    return (
        <div className={cardClassName}>
            <div className={headerClassName}>{header}</div>
            <div className={styles.dynamicEnvironmentOverviewSummaryCardContent}>{children}</div>
        </div>
    );
}

type DynamicEnvironmentOverviewSummaryProps = {
    environment: GetDynamicEnvironmentOverviewResponseV1;
};

function DynamicEnvironmentOverviewSummary({ environment }: DynamicEnvironmentOverviewSummaryProps) {
    const palette = useThemePaletteType();

    const containerClassName = classnames(styles.dynamicEnvironmentOverviewSummaryContainer, {
        [styles.dynamicEnvironmentOverviewSummaryContainerLight]: palette === "light",
        [styles.dynamicEnvironmentOverviewSummaryContainerDark]: palette === "dark",
    });

    return (
        <div className={containerClassName}>
            <h4 className={styles.dynamicEnvironmentOverviewSummaryHeading}>Dynamic Environment Overview</h4>
            <div className={styles.dynamicEnvironmentOverviewSummaryCardContainer}>
                <DynamicEnvironmentSummaryCard header="State">
                    <DynamicEnvironmentStateIconWithDescription state={environment.EnvironmentState} />
                </DynamicEnvironmentSummaryCard>
                <DynamicEnvironmentSummaryCard header="Active Projects">{environment.ActiveProjectCount}</DynamicEnvironmentSummaryCard>
            </div>
        </div>
    );
}

type DynamicEnvironmentOverviewCalloutProps = {
    environment: GetDynamicEnvironmentOverviewResponseV1;
};

function DynamicEnvironmentOverviewCallout({ environment }: DynamicEnvironmentOverviewCalloutProps) {
    if (environment.EnvironmentState === "Deprovisioning") {
        return (
            <Callout title="This dynamic environment is being deprovisioned" type={CalloutType.Information}>
                This dynamic environment is currently being deprovisioned, releases can no longer be deployed to it.
            </Callout>
        );
    } else if (environment.EnvironmentState === "DeprovisioningFailed") {
        return (
            <Callout title="This dynamic environment failed to deprovision" type={CalloutType.Danger}>
                Please review any task logs associated with deprovisioning. Deprovisioning can be attempted again once any issues are resolved.
            </Callout>
        );
    } else if (environment.EnvironmentState === "Deprovisioned") {
        return (
            <Callout title="This dynamic environment has been deprovisioned" type={CalloutType.Information}>
                Task and deployment history will be available until the environment is deleted.
            </Callout>
        );
    }

    return null;
}

type DynamicEnvironmentOverviewProps = {
    environment?: GetDynamicEnvironmentOverviewResponseV1;
    filter: DynamicEnvironmentOverviewAssociatedProjectsFilter;
    sortOrder: DynamicEnvironmentOverviewProjectSortOptions;
    busy: Promise<void> | undefined;
    onDeprovisionEnvironment: (environmentId: string) => Promise<void>;
    onDeleteEnvironment: (environmentId: string) => Promise<void>;
    onDeprovisionProject: (environmentId: string, projectId: string) => Promise<void>;
    onFilterChange: (filter: DynamicEnvironmentOverviewAssociatedProjectsFilter) => void;
    onSortOrderChange: (sort: DynamicEnvironmentOverviewProjectSortOptions) => void;
    onLoadMore: () => void;
};

export function DynamicEnvironmentOverview({ filter, environment, sortOrder, busy, onDeprovisionEnvironment, onDeleteEnvironment, onDeprovisionProject, onFilterChange, onSortOrderChange, onLoadMore }: DynamicEnvironmentOverviewProps) {
    const actions = [
        environment && (environment.EnvironmentState === "Active" || environment.EnvironmentState === "DeprovisioningFailed") && (
            <DynamicEnvironmentDeprovisionDialogButton environmentId={environment.EnvironmentId} environmentName={environment.EnvironmentName} onDeprovisionEnvironment={onDeprovisionEnvironment} />
        ),
        environment && (environment.EnvironmentState === "Deprovisioning" || environment.EnvironmentState === "Deprovisioned") && (
            <DynamicEnvironmentDeleteDialogButton environmentId={environment.EnvironmentId} environmentName={environment.EnvironmentName} disabled={environment.EnvironmentState === "Deprovisioning"} onDeleteEnvironment={onDeleteEnvironment} />
        ),
        environment && (environment.EnvironmentState === "Active" || environment.EnvironmentState === "DeprovisioningFailed") && (
            <OverflowMenu accessibleName="dynamicEnvironmentOverviewOverflowMenu" menuItems={[deleteEnvironmentMenuItem(environment.EnvironmentId, environment.EnvironmentName, onDeleteEnvironment)]} />
        ),
    ];

    const actionList = <ActionList actions={actions} />;

    return (
        <PaperLayout title={environment?.EnvironmentName} busy={busy} enableLessIntrusiveLoadingIndicator={environment !== undefined} sectionControl={actionList}>
            {environment && (
                <>
                    <DynamicEnvironmentOverviewCallout environment={environment} />
                    <DynamicEnvironmentOverviewSummary environment={environment} />
                    <DynamicEnvironmentOverviewAssociatedProjects
                        environment={environment}
                        busy={busy}
                        sortOrder={sortOrder}
                        filter={filter}
                        onSortOrderChange={onSortOrderChange}
                        onFilterChange={onFilterChange}
                        onDeprovisionProject={onDeprovisionProject}
                        onLoadMore={onLoadMore}
                    />
                </>
            )}
        </PaperLayout>
    );
}
