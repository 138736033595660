import type { ActionProperties } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { ValueInPropertiesOrErrorsHasChanged } from "~/utils/ShouldUpdate/ValueInPropertiesHasChanged";
import Note from "../../../primitiveComponents/form/Note/Note";
import type { ActionEditProps } from "../../Actions/pluginRegistry";
import pluginRegistry from "../../Actions/pluginRegistry";
import { BaseComponent } from "../../BaseComponent/BaseComponent";
import ExternalLink from "../../Navigation/ExternalLink";
import { ExpandableFormSection, Summary } from "../../form";
import { VariableLookupText } from "../../form/VariableLookupText";

/* Note on Octopus Variable names:
Currently we don't have a good solution to supporting both old and new Octopus Variable names
so for the time being, even though this feature has been renamed to structured configuration variables
from json configuration variables, we're going to continue using the json based variables names
*/

const StringProperties = {
    "Octopus.Action.Package.JsonConfigurationVariablesTargets": "",
};

type StructuredConfigurationProperties = { [P in keyof typeof StringProperties]: string };

export default class StructuredConfigurationVariablesEdit extends BaseComponent<ActionEditProps<StructuredConfigurationProperties>, never> {
    shouldComponentUpdate(nextProps: ActionEditProps<StructuredConfigurationProperties>) {
        return ValueInPropertiesOrErrorsHasChanged(StringProperties, nextProps, this.props);
    }

    summary() {
        const json = this.props.properties["Octopus.Action.Package.JsonConfigurationVariablesTargets"] || "";
        if (json.length > 0) {
            return Summary.summary(<span>Variable replacement will be performed on the target files</span>);
        } else {
            return Summary.placeholder("No target files provided");
        }
    }

    render() {
        const properties = this.props.properties;

        return (
            <ExpandableFormSection
                errorKey="jsonvariables"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Structured Configuration Variables"
                summary={this.summary()}
                help="Specify JSON, YAML, XML, and Java Properties file for variable replacements."
            >
                <VariableLookupText
                    localNames={this.props.localNames}
                    value={properties["Octopus.Action.Package.JsonConfigurationVariablesTargets"]}
                    onChange={(x) => this.props.setProperties({ ["Octopus.Action.Package.JsonConfigurationVariablesTargets"]: x })}
                    multiline={true}
                    error={this.props.getFieldError("Octopus.Action.Package.JsonConfigurationVariablesTargets")}
                    label="Target files"
                />
                <Note>
                    Target files need to be new line seperated, relative to the package contents. Extended wildcard syntax is supported. E.g., <em>appsettings.json</em>, <em>Config\*.xml</em>, <em>**\specific-folder\*.yaml.</em> Learn more about the{" "}
                    <ExternalLink href="StructuredConfigurationVariables">Structured Configuration Variables</ExternalLink> feature and view <ExternalLink href="StructuredVariables"> Structured Variables</ExternalLink> examples.
                </Note>
            </ExpandableFormSection>
        );
    }
}

pluginRegistry.registerFeature({
    featureName: "Octopus.Features.JsonConfigurationVariables",
    title: "Structured Configuration Variables",
    description: "Replace settings in JSON, YAML, XML, and Java Properties files with variables defined in Octopus.",
    edit: StructuredConfigurationVariablesEdit,
    priority: 15,
    disable: (properties: ActionProperties) => {
        delete properties["Octopus.Action.Package.JsonConfigurationVariablesTargets"];
    },
});
