import type { DeploymentSettingsResource, DeploymentActionResource, ProjectResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import InternalLink from "~/components/Navigation/InternalLink";
import routeLinks from "~/routeLinks";

export function isVersioningConfigurationValid(project: ProjectResource, deploymentSettings: DeploymentSettingsResource, actions: DeploymentActionResource[]) {
    if (deploymentSettings.VersioningStrategy.DonorPackage) {
        const action = actions.filter((step) => step.Name === deploymentSettings.VersioningStrategy.DonorPackage?.DeploymentAction);
        if (action && action.length > 0 && action[0].IsDisabled) {
            return (
                <div>
                    Step <InternalLink to={routeLinks.project(project).deployments.process.step(action[0].Id)}>{action[0].Name}</InternalLink> is currently used for release versioning, but it has been disabled.
                    <br />
                    Please re-enable the step or adjust the <InternalLink to={routeLinks.project(project.Slug).deployments.settings}>release versioning</InternalLink> configuration.
                </div>
            );
        }
    }

    return null;
}
