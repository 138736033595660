/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { logger } from "@octopusdeploy/logging";
import type { AuthenticationProviderElement, LoginCommand, LoginState, UserResource } from "@octopusdeploy/octopus-server-client";
import { OctopusError, AuthenticationError } from "@octopusdeploy/octopus-server-client";
import { Environment } from "@octopusdeploy/utilities";
import cn from "classnames";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import URI from "urijs";
import { repository } from "~/clientInstance";
import BaseComponent from "~/components/BaseComponent";
import BusyIndicator from "~/components/BusyIndicator";
import { ActionButton, ActionButtonType } from "~/components/Button";
import ErrorPanel from "~/components/ErrorPanel/ErrorPanel";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { locationStateIncludesFrom } from "~/components/SecureRoute/localStateIncludedFrom";
import { Text } from "~/components/form";
import Checkbox from "~/primitiveComponents/form/Checkbox/Checkbox";
import type { TextInput } from "~/primitiveComponents/form/Text/Text";
import PageTitleHelper from "~/utils/PageTitleHelper";
import { Octopus } from "../../../components/Images/Resources/Octopus";
import { StarFish } from "../../../components/Images/SignIn/StarFish";
import InternalRedirect from "../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../routeLinks";
import AuthProvider from "../AuthProvider";
import loginStateCalculator from "./loginStateCalculator";
import styles from "./style.module.less";

interface SignInState {
    redirectToReferrer: boolean;
    userName: string;
    password: string;
    rememberMeEnabled: boolean;
    rememberMe: boolean;
    loginState: LoginState;
    showAccountTypeSelector: boolean;
    busyIndicator?: Promise<void>;
    shouldAutoSignIn: boolean;
    autoSignInProviderName: string;
    authenticationError?: AuthenticationError;
    hasLoadedAuthProviders: boolean;
}

interface SignInProps extends RouteComponentProps {
    onSessionStarted: (user: UserResource) => Promise<void>;
}

export default class SignIn extends BaseComponent<SignInProps, SignInState> {
    private authenticationProviders: AuthenticationProviderElement[] = undefined!;
    private anyFormsAuthenticationProviders: boolean = undefined!;
    private anyAuthenticationProviders: boolean = undefined!;
    private formsProvidersWithLinks: AuthenticationProviderElement[] = undefined!;
    private anyNonFormsAuthenticationProviders: boolean = undefined!;
    private nonFormsAuthenticationProviders: AuthenticationProviderElement[] = undefined!;
    private isGuestProviderEnabled: boolean = undefined!;
    private passwordField: TextInput | null = null;

    constructor(props: SignInProps) {
        super(props);
        PageTitleHelper.setRootPageTitle();

        // The from here is referring to the state provided by SecureRoute, which is `History.Location`.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions,@typescript-eslint/no-explicit-any
        const { from } = this.props.location && this.props.location.state ? (this.props.location.state as any) : { from: { pathname: "/" } };

        this.state = {
            redirectToReferrer: false,
            showAccountTypeSelector: false,
            shouldAutoSignIn: false,
            autoSignInProviderName: "",
            userName: "",
            password: "",
            rememberMeEnabled: true,
            rememberMe: false,
            loginState: this.calculateLoginState(from.pathname + (from.search || "")),
            hasLoadedAuthProviders: false,
        };
    }

    async UNSAFE_componentWillReceiveProps(nextProps: SignInProps) {
        await this.connectClient();
    }

    async componentDidMount() {
        const error = this.getErrorFromCookie();
        if (error) {
            this.setError(error);
        }

        await this.connectClient();
    }

    getErrorFromCookie(): AuthenticationError | null {
        return AuthenticationError.createFromCookie(document.cookie);
    }

    async connectClient() {
        const alreadyAuthenticated = await this.continueIfAlreadyAuthenticated();
        if (!alreadyAuthenticated) {
            const browserURI = URI(window.location);
            browserURI.hasQuery("error", (error: { message: string }) => {
                if (!error) {
                    return;
                }
                this.setError(new AuthenticationError(error.message));
            });

            await this.setupAuthenticationProviders();
            await this.checkIfRememberMeIsEnabled();
        }
    }

    render() {
        const { from } = locationStateIncludesFrom(this.props.location) ? this.props.location.state : { from: { pathname: routeLinks.root } };

        if (this.state.redirectToReferrer) {
            return <InternalRedirect to={from} />;
        }

        return (
            <div className={styles.container}>
                {!this.state.hasLoadedAuthProviders ? (
                    <div>
                        <BusyIndicator show={true} inline={true} />
                    </div>
                ) : !this.anyAuthenticationProviders ? (
                    this.noAuthenticationProvidersEnabled()
                ) : (
                    this.renderSignIn()
                )}
            </div>
        );
    }

    renderSignIn() {
        return (
            <div>
                {this.state.showAccountTypeSelector ? (
                    this.accountTypeSelection()
                ) : (
                    <div>
                        <div className={styles.content}>
                            <div className={styles.logo}>
                                <div>
                                    <em className="fontoctopus-octopus" />
                                </div>
                                {!this.state.authenticationError && <div>{this.state.shouldAutoSignIn ? <h4>Signing in, please wait...</h4> : <h4>Welcome! Please sign in.</h4>}</div>}
                            </div>
                            {this.state.authenticationError && this.showError(this.state.authenticationError)}
                            {(this.anyFormsAuthenticationProviders || this.formsProvidersWithLinks.length > 0) && (
                                <div>
                                    {this.anyFormsAuthenticationProviders && (
                                        <form onSubmit={this.signIn} className={styles.form}>
                                            <Text value={this.state.userName} label="Username" onChange={(userName) => this.setState({ userName })} autoFocus={true} id="userName" />
                                            <Text
                                                label="Password"
                                                textInputRef={(ref) => (this.passwordField = ref)}
                                                value={this.state.password}
                                                id="password"
                                                type="password"
                                                onChange={(password) => this.setState({ password })}
                                                autoComplete="new-password"
                                            />
                                            {this.state.rememberMeEnabled && <Checkbox label="Remember me on this computer" onChange={(rememberMe) => this.setState({ rememberMe })} value={this.state.rememberMe} className={styles.rememberMe} />}
                                            <ActionButton className={styles.formActionButton} type={ActionButtonType.Primary} label="SIGN IN" busyLabel="SIGNING IN..." onClick={this.signIn} />
                                        </form>
                                    )}
                                    {this.formsProvidersWithLinks.map((p) => (
                                        <div className={styles.externalAuthProvider} key={p.Name}>
                                            <AuthProvider
                                                provider={p}
                                                shouldAutoSignIn={this.state.shouldAutoSignIn}
                                                autoSignInProviderName={this.state.autoSignInProviderName}
                                                loginState={this.state.loginState}
                                                onError={this.onExternalAuthenticationProviderError}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                            {this.anyNonFormsAuthenticationProviders && (
                                <div className={styles.externalNonFormsProviders}>
                                    {this.nonFormsAuthenticationProviders.map((p) => (
                                        <div className={styles.externalAuthProvider} key={p.Name}>
                                            <AuthProvider
                                                provider={p}
                                                shouldAutoSignIn={this.state.shouldAutoSignIn}
                                                autoSignInProviderName={this.state.autoSignInProviderName}
                                                loginState={this.state.loginState}
                                                onError={this.onExternalAuthenticationProviderError}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        {this.isGuestProviderEnabled && (
                            <div className={styles.guestProvider} onClick={this.signInAsGuest}>
                                <a>I AM A GUEST</a>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }

    private setError(error: unknown) {
        const authenticationError = createAuthenticationError(error);
        logger.error(authenticationError, "Authentication error during sign in");

        this.setState({ authenticationError });
    }

    private accountTypeSelection() {
        return (
            <div className={cn(styles.accountTypeSelection, styles.areas)}>
                <div className={styles.guestAccount} onClick={this.signInAsGuest}>
                    <StarFish height="4rem" className={styles.accountTypeIcon} />
                    <h2>I am a guest</h2>
                </div>
                <div className={styles.authenticatedAccount} onClick={this.hideAccountTypeSelector}>
                    <Octopus height="4rem" className={styles.accountTypeIcon} />
                    <h2>I have an account</h2>
                </div>
            </div>
        );
    }

    private async setupAuthenticationProviders() {
        const document = await repository.Authentication.get();
        this.authenticationProviders = document.AuthenticationProviders;

        this.nonFormsAuthenticationProviders = this.authenticationProviders.filter((p) => {
            return !p.FormsLoginEnabled && !this.isActiveDirectory(p) && p.IdentityType !== "Guest";
        });

        const formsAuthenticationProviders = this.authenticationProviders.filter((p) => {
            return p.FormsLoginEnabled && p.IdentityType !== "Guest";
        });
        this.anyFormsAuthenticationProviders = formsAuthenticationProviders.length > 0;
        this.anyNonFormsAuthenticationProviders = this.nonFormsAuthenticationProviders.length > 0;

        this.formsProvidersWithLinks = this.authenticationProviders.filter(this.isActiveDirectory);

        this.anyAuthenticationProviders = this.anyFormsAuthenticationProviders || this.anyNonFormsAuthenticationProviders || this.formsProvidersWithLinks.length > 0;

        this.isGuestProviderEnabled = this.authenticationProviders.filter((p) => p.IdentityType === "Guest").length > 0;

        const singleNonFormsAuthenticationProvider = !this.anyFormsAuthenticationProviders && this.nonFormsAuthenticationProviders.length + this.formsProvidersWithLinks.length === 1;
        const showAccountTypeSelector = this.isGuestProviderEnabled && !this.state.authenticationError;
        let shouldAutoSignIn = document.AutoLoginEnabled && !this.state.authenticationError && singleNonFormsAuthenticationProvider && !showAccountTypeSelector;
        let autoSignInProviderName = "";
        if (shouldAutoSignIn) {
            autoSignInProviderName = this.authenticationProviders[0].Name;
        }

        if (this.props.location.search) {
            const provider = await repository.Authentication.wasLoginInitiated(this.props.location.search);

            if (provider && provider.WasLoginInitiated) {
                shouldAutoSignIn = true;
                autoSignInProviderName = provider.ProviderName;
                if (provider.ProviderName === "Octopus - Guest") {
                    await this.signInAsGuest();
                }
            }
        }

        this.setState({ showAccountTypeSelector, shouldAutoSignIn, autoSignInProviderName, hasLoadedAuthProviders: true });
    }

    private async checkIfRememberMeIsEnabled() {
        const document = await repository.Authentication.get();
        this.setState({ rememberMeEnabled: document.RememberMeEnabled });
    }

    private async authenticationSucceeded(user: UserResource) {
        try {
            await this.props.onSessionStarted(user);
            this.setState({ redirectToReferrer: true });
        } catch (error) {
            this.setError(error);
        }
    }

    private authenticate = async (userName: string, password: string, rememberMe: boolean) => {
        try {
            logger.info("Attempting to sign in: {userName}", { userName, rememberMe });
            const loginCommand: LoginCommand = {
                Username: userName,
                Password: password,
                RememberMe: rememberMe,
                State: this.state.loginState,
            };
            const user = await repository.Users.signIn(loginCommand);

            await this.authenticationSucceeded(user);
        } catch (error) {
            this.setError(error);
        }
    };

    private isActiveDirectory(provider: AuthenticationProviderElement) {
        return provider.Name === "Active Directory";
    }

    private onExternalAuthenticationProviderError = (error: AuthenticationError) => {
        this.setError(error);
    };

    private signIn = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const busyIndicator = this.authenticate(this.state.userName, this.state.password, this.state.rememberMe);
        this.setState({ busyIndicator });
        await busyIndicator;
    };

    private signInAsGuest = async () => {
        await this.authenticate("guest", "guest", true);
    };

    private hideAccountTypeSelector = () => {
        this.setState({ showAccountTypeSelector: false });
    };

    private async continueIfAlreadyAuthenticated() {
        try {
            const user = await repository.Users.getCurrent();
            logger.info("{user} already authenticated", { user });
            await this.authenticationSucceeded(user);
            return true;
        } catch (authenticationError) {
            // Errors from above are expected in cases where the user is not logged in or the server is down
            // Logging as info to avoid noise in the error logs
            logger.info("User was not authenticated or there was an error checking if the user was authenticated", { error: authenticationError });
        }

        return false;
    }

    private noAuthenticationProvidersEnabled() {
        return (
            <div>
                There are no authentication providers enabled. Learn about enabling <ExternalLink href="AuthenticationProviders">authentication providers</ExternalLink>
            </div>
        );
    }

    private calculateLoginState(redirectToPath: string) {
        return loginStateCalculator(location.href, redirectToPath, Environment.isInDevelopmentMode());
    }

    private showError(authenticationError: AuthenticationError) {
        return (
            <div className={styles.authenticationError}>
                <ErrorPanel message={authenticationError.message} errors={authenticationError.Errors} parsedHelpLinks={authenticationError.DetailLinks} />
            </div>
        );
    }
}

function createAuthenticationError(error: unknown): AuthenticationError {
    if (error instanceof AuthenticationError) {
        return error;
    } else if (error instanceof OctopusError) {
        return new AuthenticationError(error.ErrorMessage, error.Errors, error.ParsedHelpLinks);
    } else {
        return new AuthenticationError("There was a problem with your request.");
    }
}
