import cn from "classnames";
import React from "react";
import styles from "./style.module.less";

interface ActiveStatusIconProps {
    active: boolean;
    style?: React.CSSProperties;
}

export const ActiveStatusIcon = ({ active, style }: ActiveStatusIconProps): JSX.Element => (
    <svg className={cn({ [styles.activeStatusIcon]: true, [styles.active]: active })} style={style} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4" cy="4" r="4" />
    </svg>
);
