import type { CloudConnectionType } from "@octopusdeploy/octopus-server-client";
import type { InputJsonSchema } from "@octopusdeploy/step-runtime-inputs";
import type { DeploymentTargetUI } from "@octopusdeploy/step-ui";

export interface StepPackageDeploymentTarget<StepInputs> {
    deploymentTargetTypeId: string;
    name: string;
    ui: DeploymentTargetUI<StepInputs>;
    inputJsonSchema: InputJsonSchema;
    version: string;
    targetDiscoveryCloudConnectionTypes: Array<CloudConnectionType>;
}

export type UnknownStepPackageDeploymentTarget = StepPackageDeploymentTarget<unknown>;

export function asUnknownStepPackageDeploymentTarget<StepInputs>(stepPackage: StepPackageDeploymentTarget<StepInputs>): UnknownStepPackageDeploymentTarget {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return stepPackage as unknown as UnknownStepPackageDeploymentTarget;
}
