import type { ReactNode } from "react";
import * as React from "react";
import { ActionButton, ActionButtonType } from "~/components/Button/ActionButton";
import type { DataBaseComponentState, DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";
import FormComponent from "../FormComponent/FormComponent";
import styles from "./style.module.less";

interface DeleteDialogLayoutProps {
    open: boolean;
    title: string;
    deleteButtonLabel?: string;
    deleteButtonBusyLabel?: string;
    deleteButtonDisabled?: boolean;
    renderContent(doBusyTask: DoBusyTask): ReactNode;
    onDeleteClick(): Promise<boolean> | void;
    onClose(): void;
}

class DeleteDialogLayout extends DataBaseComponent<DeleteDialogProps, DataBaseComponentState> {
    constructor(props: DeleteDialogProps) {
        super(props);
        this.state = {};
    }

    UNSAFE_componentWillReceiveProps(nextProps: DeleteDialogProps) {
        if (this.props.open !== nextProps.open) {
            this.clearErrors();
        }
    }

    private onDeleteClick = () => {
        if (this.isDisabled()) {
            return;
        }

        return this.doBusyTask(async () => {
            const result = await this.props.onDeleteClick();
            if (result) {
                this.props.onClose();
            }
        });
    };

    private isDisabled = () => this.props.deleteButtonDisabled || this.state.busy;

    render() {
        const del = (
            <ActionButton
                key="Continue"
                label={this.props.deleteButtonLabel || "Delete"}
                type={ActionButtonType.Delete}
                busyLabel={this.props.deleteButtonBusyLabel || "Deleting..."}
                disabled={this.isDisabled()}
                onClick={() => this.onDeleteClick()}
            />
        );

        const cancel = <ActionButton key="Cancel" label="Cancel" disabled={this.state.busy} onClick={() => this.props.onClose()} />;

        return (
            <DialogLayout actions={[cancel, del]} title={this.props.title} busy={this.state.busy} headerClassName={styles.dialogHeader} closeDialog={this.props.onClose} errors={this.errors}>
                <FormComponent onFormSubmit={this.onDeleteClick}>{this.props.open && this.props.renderContent(this.doBusyTask)}</FormComponent>
            </DialogLayout>
        );
    }
}

type DeleteDialogProps = DeleteDialogLayoutProps;
//We need to ensure our dialog layout lives below the `Dialog` in the component hierarchy
//to ensure busy task errors bubble up to the correct error context as part of `DoBusyTask` usages.
export default function DeleteDialog(props: DeleteDialogProps) {
    return (
        <Dialog open={props.open || false}>
            <DeleteDialogLayout {...props} />
        </Dialog>
    );
}
