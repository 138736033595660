/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */

import type { ScriptingLanguage } from "@octopusdeploy/octopus-server-client";
import { List, ListItem } from "material-ui/List";
import * as React from "react";
import type { IScriptActionContext } from "~/components/Actions/script/ScriptActionContext";
import { ScriptActionContext } from "~/components/Actions/script/ScriptActionContext";
import IconButton, { Icon } from "~/components/IconButton/IconButton";
import { Popover } from "~/primitiveComponents/dataDisplay/Popover/Popover";
import { codeEditorVariablesList } from "~/utils/ScriptIntellisense/scriptIntellisense";
import BaseComponent from "../../BaseComponent";
import FilterSearchBox from "../../FilterSearchBox";
import styles from "./style.module.less";

interface InsertVariableButtonProps {
    localNames?: string[];
    syntax?: ScriptingLanguage;
    onSelected(value: string): void;
}

interface InsertVariableButtonState {
    serverNames: string[];
    filter: string;
    isInsertPopupOpen: boolean;
}

export default class InsertVariableButton extends BaseComponent<InsertVariableButtonProps, InsertVariableButtonState> {
    private currentVariablesKey?: string;
    static contextType = ScriptActionContext;
    showInsertPopupButton: HTMLElement = undefined!;
    context: IScriptActionContext | undefined;

    constructor(props: InsertVariableButtonProps) {
        super(props);
        this.state = {
            serverNames: [],
            isInsertPopupOpen: false,
            filter: "",
        };
    }

    async componentDidMount() {
        await this.reloadVariables();
    }

    async componentDidUpdate(prevProps: InsertVariableButtonProps) {
        if (this.context?.key != this.currentVariablesKey) {
            await this.reloadVariables();
        }
    }

    async reloadVariables() {
        let variableNames: string[] = [];

        if (this.context) {
            this.currentVariablesKey = this.context.key;
            variableNames = await this.context.loadVariables();
        }

        this.setState({
            serverNames: variableNames,
        });
    }

    showInsertPopup = (event: React.MouseEvent) => {
        event.preventDefault();

        this.setState({
            isInsertPopupOpen: true,
        });
    };

    hideInsertPopup = () => {
        this.setState({
            isInsertPopupOpen: false,
        });
    };

    handleSelected = (value: string) => {
        this.props.onSelected(value);
        this.hideInsertPopup();
    };

    render() {
        //TODO Insert variable icon to be added
        const results = codeEditorVariablesList(this.state.serverNames, this.props.localNames ?? [], this.props.syntax, this.state.filter);
        const names = results.map((n, i) => <ListItem key={`item-${i}`} primaryText={n.display} onClick={() => this.handleSelected(n.code)} disableTouchRipple={DISABLE_LEGACY_MUI_RIPPLES} disableFocusRipple={DISABLE_LEGACY_MUI_RIPPLES} />);

        return (
            <div ref={this.setRef}>
                <IconButton toolTipContent="Insert a variable" onClick={this.showInsertPopup} icon={Icon.InsertVariable} accessibleName={`Insert Variable`} />
                <Popover open={this.state.isInsertPopupOpen} anchorEl={this.showInsertPopupButton} onClose={this.hideInsertPopup} anchorOrigin={{ horizontal: "center", vertical: "bottom" }} transformOrigin={{ horizontal: "center", vertical: "top" }}>
                    <div className={styles.container} onKeyDown={this.onKeyDown}>
                        <div className={styles.filter}>
                            <FilterSearchBox autoFocus={true} value={this.state.filter} placeholder="Find..." onChange={(filter) => this.setState({ filter })} />
                        </div>
                        {this.state.isInsertPopupOpen && <List className={styles.menuContainer}>{names}</List>}
                    </div>
                </Popover>
            </div>
        );
    }

    private onKeyDown = (event: React.KeyboardEvent) => {
        if (event.keyCode === 27 /*esc*/) {
            this.setState({ isInsertPopupOpen: false });
        }
        if (event.keyCode === 13 /*enter*/) {
            event.preventDefault();
        }
    };

    private setRef = (el: HTMLDivElement) => {
        this.showInsertPopupButton = el;
    };
}
