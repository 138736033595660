import type { PackageNotesList } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import type { PackageModel } from "~/areas/projects/components/Releases/packageModel";
import { repository } from "~/clientInstance";
import isBound from "~/components/form/BoundField/isBound";

const splitPackagesIntoBoundOrUnboundFeeds = (allPackages: PackageModel[]) =>
    // Create an object with two arrays, one containing bound packages, and the
    // other containing unbound packages.
    allPackages.reduce<{ bound: PackageModel[]; unBound: PackageModel[] }>(
        (container, pkg) => {
            (isBound(pkg.PackageId, false) ? container.bound : container.unBound).push(pkg);
            return container;
        },
        { bound: [], unBound: [] }
    );

const getNotesForPackages = async (packages: PackageModel[]): Promise<PackageNotesList[]> => {
    // This is how many concurrent packages to query with each request. This
    // is based on a fixed number of concurrent requests, which is limited
    // by the browser. Chrome has a limit of 5, but we need to leave a few requests
    // for other calls made by this page, so we devote 3 to the package notes.
    // We limit the batch size to 30 though. If there are hundreds of packages
    // we don't want to max out the URL length.
    const batchSize = Math.min(Math.ceil(packages.length / 3), 30);

    // This is the array that will hold chunks of the allPackages array. So an
    // array of PackageModel[30] becomes PackageModel[3][10] (roughly speaking).
    const splitAllPackages: PackageModel[][] = [];

    // Split the original array into a bunch of smaller arrays which will be
    // processed in parallel.
    while (packages.length > 0) {
        splitAllPackages.push(packages.splice(0, batchSize));
    }

    return await Promise.all(_.flatMap(splitAllPackages, (pkgs) => repository.Packages.getNotes(pkgs)));
};

export { getNotesForPackages, splitPackagesIntoBoundOrUnboundFeeds };
