/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import type {
    ActionProperties,
    PackageReference,
    ActionTemplateParameterResource,
    ActionTemplateResource,
    ActionUpdateRemovedPackageUsage,
    ActionUpdateResultResource,
    PropertyValueResource,
    ProcessType,
    ProjectResource,
} from "@octopusdeploy/octopus-server-client";
import { ActionUpdateOutcome, ActionUpdatePackageUsedBy, ControlType, isSensitiveValue, PersistenceSettingsType, OctopusError } from "@octopusdeploy/octopus-server-client";
import { flatten, mapValues } from "lodash";
import * as React from "react";
import type { StoredModelState } from "~/areas/projects/components/Process/types";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/withProjectContext";
import { withProjectContext } from "~/areas/projects/context/withProjectContext";
import { repository } from "~/clientInstance";
import type { ProjectSourceItems } from "~/components/ActionTemplateParameterInput/ActionTemplateParameterInput";
import ActionTemplateParameterInput from "~/components/ActionTemplateParameterInput/ActionTemplateParameterInput";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import InternalLink from "~/components/Navigation/InternalLink";
import { FormSectionHeading, UnstructuredFormSection } from "~/components/form";
import { JsonUtils } from "~/utils/jsonUtils";
import { CalloutType, default as Callout } from "../../primitiveComponents/dataDisplay/Callout/Callout";
import Note from "../../primitiveComponents/form/Note/Note";
import routeLinks from "../../routeLinks";
import ParseHelper from "../../utils/ParseHelper";
import ActionTemplateParameterInputExpandableFormElement from "../ActionTemplateParameterInput/ActionTemplateParameterInputExpandableFormElement";
import { ActionButtonType, default as ActionButton } from "../Button/ActionButton";
import Markdown from "../Markdown";
import SimpleDataTable from "../SimpleDataTable";
import ActionPropertyTable from "./ActionPropertyTable";

interface ActionTemplateEditorProps {
    actionTemplate: ActionTemplateResource;
    actionId: string;
    properties: ActionProperties;
    process: Readonly<StoredModelState>;
    processType: ProcessType;
    localNames?: string[];
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    setProperties(properties: Partial<ActionProperties>): void;
    setPackages(packages: Array<PackageReference<any>>, initialise?: boolean): void;
    onActionTemplateUpdate: () => Promise<boolean>;
}

interface ActionTemplateEditorState {
    manualMergeRequired: boolean;
    actionTemplateVersion: ActionTemplateResource;
    currentPropertyValues: ActionProperties;
    overrides: ActionProperties;
    latestRemovesPackagesInUse: boolean;
    removedPackageUsages: ActionUpdateRemovedPackageUsage[];
    sourceItems: ProjectSourceItems;
    project: ProjectResource;
}

function convertSensitivePropertyValues(properties: ActionProperties): ActionProperties {
    return mapValues(properties, (value) => (isSensitiveValue(value) ? "********" : value));
}

function getTemplateVersion(properties: ActionProperties): number {
    return ParseHelper.safeParseInt(properties["Octopus.Action.Template.Version"] as string);
}

function isTemplateUpToDate(providedTemplate: ActionTemplateResource, currentProperties: ActionProperties) {
    return providedTemplate.Version === getTemplateVersion(currentProperties);
}

type ActionTemplateEditorWithProjectContextProps = ActionTemplateEditorProps & WithProjectContextInjectedProps;

class ActionTemplateEditor extends BaseComponent<ActionTemplateEditorWithProjectContextProps, ActionTemplateEditorState> {
    static getDerivedStateFromProps(props: ActionTemplateEditorProps, state: ActionTemplateEditorState): ActionTemplateEditorState {
        return {
            ...state,
            currentPropertyValues: convertSensitivePropertyValues(props.properties),
        };
    }

    constructor(props: ActionTemplateEditorWithProjectContextProps) {
        super(props);

        const actionNames: string[] = props.process ? flatten(Object.values(props.process.actions.byId).map((a) => a.Name)) : [];
        this.state = {
            currentPropertyValues: {},
            actionTemplateVersion: null!,
            manualMergeRequired: false,
            sourceItems: {
                stepNames: actionNames,
                projectId: this.props.projectContext.state.model.Id,
                gitRef: this.props.projectContext.state.gitRef,
                packages: {
                    items: this.props.actionTemplate.Packages || [],
                    feeds: [],
                    onRequestRefresh: this.refreshFeeds,
                    setPackages: this.setPackages,
                },
            },
            overrides: {},
            removedPackageUsages: [],
            latestRemovesPackagesInUse: false,
            project: props.projectContext.state.model,
        };
    }

    async componentDidMount() {
        await this.refreshActionTemplate();
    }

    async refreshActionTemplate() {
        await this.props.doBusyTask(async () => {
            const isUpToDate = isTemplateUpToDate(this.props.actionTemplate, this.props.properties);
            const versionGetter = isUpToDate ? Promise.resolve(this.props.actionTemplate) : repository.ActionTemplates.getVersion(this.props.actionTemplate, getTemplateVersion(this.props.properties));
            const feedsGetter = repository.Feeds.all();

            try {
                const actionTemplateVersion = await versionGetter;
                const feeds = await feedsGetter;
                this.setState({
                    actionTemplateVersion,
                    sourceItems: {
                        ...this.state.sourceItems,
                        packages: {
                            ...this.state.sourceItems.packages!,
                            feeds,
                        },
                    },
                });
            } catch (error) {
                if (error.StatusCode !== 404) {
                    throw error;
                }
            }
        });
    }

    setPackages = (packages: Array<PackageReference<any>>, initialise?: boolean) => {
        if (!this.state.manualMergeRequired) {
            this.props.setPackages(packages, initialise);
        }
        this.setState({
            sourceItems: {
                ...this.state.sourceItems,
                packages: {
                    ...this.state.sourceItems.packages!,
                    items: packages,
                },
            },
        });
    };

    refreshFeeds = async () => {
        await this.props.doBusyTask(async () => {
            const feedsGetter = repository.Feeds.all();
            try {
                const feeds = await feedsGetter;
                this.setState({
                    sourceItems: {
                        ...this.state.sourceItems,
                        packages: {
                            ...this.state.sourceItems.packages!,
                            feeds,
                        },
                    },
                });
            } catch (error) {
                if (error.StatusCode !== 404) {
                    throw error;
                }
            }
        });
    };

    render() {
        const isUpToDate = isTemplateUpToDate(this.props.actionTemplate, this.props.properties);
        const version = getTemplateVersion(this.props.properties);

        const canUpdateAutomatically = !isUpToDate && !this.state.manualMergeRequired && !this.state.latestRemovesPackagesInUse;
        return (
            <div>
                <FormSectionHeading title={this.props.actionTemplate.Name} />

                <UnstructuredFormSection>
                    <span>
                        This step is based on a {this.props.actionTemplate.CommunityActionTemplateId ? "community" : "custom"}
                        <InternalLink to={routeLinks.library.stepTemplate(this.props.actionTemplate.Id).root}>
                            <strong> {this.props.actionTemplate.Name} </strong>
                        </InternalLink>
                        step template.
                    </span>
                </UnstructuredFormSection>

                {!isUpToDate && (
                    <Callout type={CalloutType.Warning} title={"This step is out of date"}>
                        '{this.props.actionTemplate.Name}' step template has changed since this step was configured.
                        {canUpdateAutomatically && <span> Update now to merge the latest changes.</span>}
                        <br />
                        {!this.state.actionTemplateVersion && (
                            <p>
                                Version '{version}' of '{this.props.actionTemplate.Name}' step template is not available and properties can only be displayed in read-only mode. To start storing all versions of '{this.props.actionTemplate.Name}' step
                                template and safely update automatically in the future, update to the latest version now.
                            </p>
                        )}
                        {canUpdateAutomatically && <ActionButton type={ActionButtonType.Primary} label={this.state.project.IsVersionControlled ? "Update and Commit" : "Update"} busyLabel="Updating..." onClick={this.mergeLatest} />}
                        {this.state.manualMergeRequired && (
                            <React.Fragment>
                                <p>Unfortunately the step must be updated manually because we don't have enough information to do it automatically. Please, review carefully the new property values below and confirm them when you are ready.</p>
                                <ActionButton type={ActionButtonType.Primary} label="Confirm" onClick={this.resolveConflicts} />
                            </React.Fragment>
                        )}
                        {this.state.latestRemovesPackagesInUse && (
                            <React.Fragment>
                                <p>This step cannot be automatically updated. The latest version of the step template has removed {this.packagePlural(this.state.removedPackageUsages.length)} which this project depends on.</p>
                                <ul>{this.state.removedPackageUsages.map((pkg) => this.renderUsedPackage(pkg))}</ul>
                                <p>The above usages must be removed before this step can be updated.</p>
                            </React.Fragment>
                        )}
                    </Callout>
                )}
                {!this.state.manualMergeRequired && !this.state.actionTemplateVersion && <ActionPropertyTable key="actiontable" actionProperties={this.props.properties} currentPropertyValues={this.state.currentPropertyValues} />}
                {!this.state.manualMergeRequired && !!this.state.actionTemplateVersion && (
                    <div>
                        {this.state.actionTemplateVersion.Parameters.map((parameter) => {
                            return (
                                <ActionTemplateParameterInputExpandableFormElement
                                    parameter={parameter}
                                    key={parameter.Name}
                                    projectId={this.props.projectContext.state.model.Id}
                                    localNames={this.props.localNames}
                                    value={this.props.properties[parameter.Name]}
                                    doBusyTask={this.props.doBusyTask}
                                    sourceItems={this.state.sourceItems}
                                    actionType={this.props.actionTemplate.ActionType}
                                    onChange={(x) => this.props.setProperties({ [parameter.Name]: x })}
                                />
                            );
                        })}
                    </div>
                )}

                {this.state.manualMergeRequired && <div>{this.resolveTable()}</div>}
            </div>
        );
    }

    private resolveTable = () => {
        return <SimpleDataTable data={this.props.actionTemplate.Parameters} headerColumns={["Property Name", "Current Property Value", "New Property Value"]} onRow={this.buildRow} />;
    };

    private buildRow = (parameter: ActionTemplateParameterResource) => {
        return [
            <span>{parameter.Label || parameter.Name}</span>,
            parameter.DisplaySettings["Octopus.ControlType"] === ControlType.Package ? this.displayCurrentPackageParameterPropertyValues(this.state.currentPropertyValues[parameter.Name]) : this.state.currentPropertyValues[parameter.Name],
            <div>
                <ActionTemplateParameterInput
                    projectId={this.props.projectContext.state.model.Id}
                    parameter={parameter}
                    doBusyTask={this.props.doBusyTask}
                    value={this.state.overrides[parameter.Name]}
                    sourceItems={this.state.sourceItems}
                    actionType={this.props.actionTemplate.ActionType}
                    onChange={(x) => this.handleParameterInputChanged(parameter.Name, x)}
                />
                <Note>
                    <Markdown markup={parameter.HelpText} />
                </Note>
            </div>,
        ];
    };

    private displayCurrentPackageParameterPropertyValues = (value: PropertyValueResource) => {
        if (typeof value === "string" && JsonUtils.tryParseJson(value)) {
            const pkg: Partial<PackageReference<any>> = JSON.parse(value);
            if (pkg.PackageId) {
                const ret = [];
                if (pkg.FeedId) {
                    ret.push(
                        <div>
                            Feed ID: <strong>{pkg.FeedId}</strong>
                        </div>
                    );
                }

                ret.push(
                    <div>
                        Package ID: <strong>{pkg.PackageId}</strong>
                    </div>
                );
                return React.Children.toArray(ret);
            }
        }

        return value;
    };

    private handleParameterInputChanged = (name: string, value: any) => {
        this.setState((state: ActionTemplateEditorState) => ({ overrides: { ...state.overrides, [name]: value } }));
    };

    private resolveConflicts = async () => {
        await this.props.doBusyTask(async () => {
            //Empty values are not usually sent to the server but in this case we really need to know that the user decided to set something to an empty value.
            const overrides = { ...this.state.overrides };
            this.props.actionTemplate.Parameters.forEach((p) => {
                if (overrides[p.Name] === undefined) {
                    overrides[p.Name] = null;
                }
            });

            this.setState({ overrides });

            const branchName = this.branchName();

            await repository.ActionTemplates.updateActions(this.props.actionTemplate, [{ ProcessId: this.props.process.process!.Id, ProcessType: this.props.processType, ActionIds: [this.props.actionId], GitRef: branchName }], null!, overrides);
            await this.props.onActionTemplateUpdate();
        });
    };

    private mergeLatest = async () => {
        await this.props.doBusyTask(async () => {
            try {
                const branchName = this.branchName();
                await repository.ActionTemplates.updateActions(this.props.actionTemplate, [{ ProcessId: this.props.process.process!.Id, ProcessType: this.props.processType, ActionIds: [this.props.actionId], GitRef: branchName }]);
                await this.props.onActionTemplateUpdate();
            } catch (error) {
                // If it's a conflict from attempting to update the action template
                if (error.StatusCode === 400 && OctopusError.isOctopusError(error) && error.Details) {
                    const result = error.Details[0];
                    if (this.isActionUpdateResult(result)) {
                        switch (result.Outcome) {
                            case ActionUpdateOutcome.RemovedPackageInUse: {
                                this.setState({ latestRemovesPackagesInUse: true, removedPackageUsages: result.RemovedPackageUsages });
                                break;
                            }
                            default: {
                                const overrides: ActionProperties = {};
                                this.props.actionTemplate.Parameters.forEach((p) => {
                                    const propertyValue = this.props.properties[p.Name];
                                    if (propertyValue !== undefined) {
                                        if (p.DisplaySettings["Octopus.ControlType"] === ControlType.Package && !JsonUtils.tryParseJson(propertyValue as string)) {
                                            const pkg = this.props.actionTemplate.Packages.find((atp) => atp.Properties["PackageParameterName"] === p.Name);
                                            if (pkg && pkg.Properties && pkg.Properties["PackageParameterName"] === p.Name) {
                                                overrides[p.Name] = JSON.stringify({ PackageId: pkg.PackageId, FeedId: pkg.FeedId });
                                            }
                                        } else {
                                            overrides[p.Name] = propertyValue;
                                        }
                                    } else if (p.DisplaySettings["Octopus.ControlType"] === ControlType.Package) {
                                        const pkg = this.props.actionTemplate.Packages.find((atp) => atp.Properties["PackageParameterName"] === p.Name);
                                        if (pkg && pkg.Properties && pkg.Properties["PackageParameterName"] === p.Name) {
                                            overrides[p.Name] = JSON.stringify({ PackageId: pkg.PackageId, FeedId: pkg.FeedId });
                                        }
                                    }
                                });

                                this.setState({ overrides, manualMergeRequired: true });
                                break;
                            }
                        }
                    }
                } else {
                    throw error;
                }
            }
        });
    };

    private branchName() {
        let branchName = "";

        if (this.props.projectContext.state.model.PersistenceSettings.Type === PersistenceSettingsType.VersionControlled) {
            const branch = this.props.projectContext.state.gitRef;
            branchName = branch ? branch.Name : "";
        }
        return branchName;
    }

    private renderUsedPackage(pkgUsage: ActionUpdateRemovedPackageUsage) {
        return (
            <li>
                Package {pkgUsage.PackageReference} is used by {this.renderUsedPackageLink(pkgUsage)}
            </li>
        );
    }

    private renderUsedPackageLink(pkgUsage: ActionUpdateRemovedPackageUsage) {
        switch (pkgUsage.UsedBy) {
            case ActionUpdatePackageUsedBy.ProjectVersionStrategy: {
                return (
                    <InternalLink to={routeLinks.project(this.props.projectContext.state.model.Id!).settings.root} openInSelf={false}>
                        project versioning strategy
                    </InternalLink>
                );
            }
            case ActionUpdatePackageUsedBy.ProjectReleaseCreationStrategy: {
                return (
                    <InternalLink to={routeLinks.project(this.props.projectContext.state.model.Id!).triggers} openInSelf={false}>
                        project release creation strategy
                    </InternalLink>
                );
            }
            case ActionUpdatePackageUsedBy.ChannelRule: {
                return (
                    <InternalLink to={routeLinks.channel(pkgUsage.UsedById)} openInSelf={false}>
                        rule in channel '{pkgUsage.UsedByName}'
                    </InternalLink>
                );
            }
            // We should never get here, but we'll attempt to handle gracefully anyway
            default: {
                return <span>{pkgUsage.UsedByName}</span>;
            }
        }
    }

    private isActionUpdateResult(result: any): result is ActionUpdateResultResource {
        return (result as ActionUpdateResultResource).Outcome !== undefined;
    }

    private packagePlural = (n: number) => {
        return n === 1 ? "a package" : "packages";
    };
}

export default withProjectContext(ActionTemplateEditor);
