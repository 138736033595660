import type { VariablesScopedToDocumentResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { SinglyScopedVariableWarnings } from "~/areas/variables/SinglyScopedVariableWarnings/SinglyScopedVariableWarnings";
import { ScopeType } from "~/areas/variables/VariableSorting/sortVariables";

interface DeleteMachineProps {
    variablesScopedToThisMachine: VariablesScopedToDocumentResource | undefined;
}

export const DeleteMachineLayout = ({ variablesScopedToThisMachine }: DeleteMachineProps) => (
    <React.Fragment>
        {variablesScopedToThisMachine && <SinglyScopedVariableWarnings variablesScopedToThisDocument={variablesScopedToThisMachine} documentType={ScopeType.Machine} />}
        <p>Deleting this machine is permanent. There is no going back.</p>
        <p>Do you wish to continue?</p>
    </React.Fragment>
);
