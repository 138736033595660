import type { OctopusTheme as DesignSystemTheme } from "@octopusdeploy/portal-design-system-tokens";
import { color, darkTheme, lightTheme } from "@octopusdeploy/portal-design-system-tokens";

export interface OctopusTheme extends DesignSystemTheme {
    // Constants
    whiteConstant: string;
    blackConstant: string;
    cyanConstant: string;
    transparent: string;

    //Shadows - these colours don't change between themes
    shadow02: string;
    shadow04: string;
    shadow06: string;
    shadow10: string;
    shadow40: string;
    shadow60: string;
    shadow20: string;
    shadow12: string;
    shadow14: string;
    shadow25: string;
    shadow22: string;
    shadow34: string;

    // Paper
    paper0: string;
    paper1: string;
    paper2: string;
    paper3: string;

    // Text
    primaryText: string;
    secondaryText: string;
    ternaryText: string;
    highlightText: string;
    highlightTextBackground: string;

    // Text links
    linkText: string;
    linkTextHover: string;
    sideMenuHover: string;
    sideMenuNestedText: string;
    sidebarMenuSelectedText: string;

    // Nav
    navBackground: string;
    navItemHover: string;
    navItemActive: string;
    navText: string;
    navTextHover: string;
    navTextActive: string;
    spaceMenuBackground: string;
    spaceMenuText: string;

    // Tabs
    tabActiveIndicator: string;

    // Buttons
    primaryButtonBackground: string;
    primaryButtonText: string;

    secondaryButtonBackground: string;
    secondaryButtonText: string;

    ternaryButton: string;
    ternaryButtonHover: string;
    categoryButtonBackground: string;

    disabledButtonBorder: string;
    disabledButtonBackground: string;
    disabledButtonText: string;

    createReleaseBackground: string;
    createReleaseText: string;

    deleteButtonText: string;
    deleteButtonBackground: string;

    cancelButtonBackground: string;
    cancelButtonText: string;

    subtleButtonBackground: string;
    subtleButtonBorder: string;

    // Paging
    pagingButtonBackground: string;
    pagingButtonBackgroundHover: string;
    pagingButtonBackgroundActive: string;

    // Dashboard
    tenantCount: string;

    // Section
    SectionBodyText: string;
    groupHeaderBackground: string;
    groupHeader: string;
    statusBarBackground: string;
    statusBarItemBackground: string;
    contextualHelpBackground: string;

    // Backgrounds & dividers
    primaryBackground: string;
    disabledBackground: string;
    secondaryBackground: string;
    secondaryBackgroundLight: string;
    ternaryBackground: string;
    hover: string;
    divider: string;
    dividerLight: string;
    secondaryDivider: string;
    teritaryDivider: string;
    logoBackground: string;
    stepLogoBackground: string;
    parentStepBackground: string;
    loadingIndicator: string;
    loadingIndicatorBackground: string;
    userOnboardingHelpPanelBackground: string;

    // Cards
    cardBackground: string;
    cardHover: string;
    cardFooterBuiltIn: string;
    cardFooterInstalled: string;
    cardFooterCommunity: string;

    // Form
    focus: string;
    selectBorder: string;
    focusIconButton: string;

    // Chips
    chipBackground: string;
    chipHighlight: string;
    chipText: string;
    chipIcon: string;
    chipDeleteButton: string;
    avatarBackground: string;

    // Code Editor hints
    hintHighlight: string;
    hintActive: string;
    placeholder: string;
    placeholderActive: string;

    // Icon Editor
    iconEditorIconHover: string;
    iconEditorColorPaletteInnerBorder: string;
    iconEditorColorPaletteHover: string;
    iconEditorColorPaletteSelected: string;

    // Callouts
    info: string;
    infoConstant: string;
    infoBackground: string;
    infoHeaderText: string;
    infoText: string;
    infoHighlight: string;
    infoBorder: string;

    success: string;
    successConstant: string;
    successBackground: string;
    successCalloutBackground: string;
    successHeaderText: string;
    successText: string;
    successHighlight: string;
    successBorder: string;

    danger: string;
    dangerConstant: string;
    dangerBackground: string;
    dangerCalloutBackground: string;
    dangerHeaderText: string;
    dangerText: string;
    dangerHighlight: string;
    dangerBorder: string;

    alert: string;
    alertBackground: string;
    alertCalloutBackground: string;
    alertConstant: string;
    alertHeaderText: string;
    alertText: string;
    alertHighlight: string;
    alertBorder: string;
    alertIcon: string;

    sunshineText: string;

    darkBackground: string;
    darkText: string;

    auditHighlight: string;
    codeHighlight: string;
    codeText: string;
    codeTextBright: string;

    featureText: string;
    featureBackground: string;
    featureCalloutBackground: string;
    featureBorder: string;

    // Task log
    successTaskLogBackground: string;
    successTaskLogBorder: string;
    successTaskLogText: string;
    dangerTaskLogBackground: string;
    dangerTaskLogBorder: string;
    dangerTaskLogText: string;
    alertTaskLogBackground: string;
    alertTaskLogText: string;
    unknownTaskLogBackground: string;
    unknownTaskLogBorder: string;
    unknownTaskLogText: string;
    runningTaskLogText: string;

    //difs
    diffAddedBackground: string;
    diffAddedColor: string;
    diffWordAddedBackground: string;
    diffWordAddedColor: string;
    diffRemovedBackground: string;
    diffRemovedColor: string;
    diffWordRemovedBackground: string;
    diffWordRemovedColor: string;
    diffGutterBackground: string;
    diffAddedGutterColor: string;
    diffAddedGutterBackground: string;
    diffRemovedGutterColor: string;
    diffRemovedGutterBackground: string;
    diffGutterColor: string;
    diffHighlight: string;

    // Form validation
    errorText: string;

    // icons
    iconNavy: string;
    iconDark: string;
    iconLight: string;
    iconNeutral: string;
    iconLifeCycle: string;

    // Tooltip
    tooltipText: string;
    tooltipBackground: string;

    // Primary color
    mainBackground: string;
    mainText: string;

    // Will be superceeded
    primary: string;
    primaryLight: string;
    primaryDark: string;
    primaryDarkest: string;
    primaryLightest: string;
    secondaryDark: string;

    // Images
    imgNavy: string;
    imgCyan: string;
    imgCyan50: string;
    imgWhite: string;
    imgBlueGrey: string;
    imgLightGrey: string;
    imgGrey: string;
    imgLight: string;
    imgDark: string;
    imgGreen: string;
    imgDanger: string;

    // Wizard
    wizardStepBackground: string;
    wizardStepBackgroundActive: string;
}

export const LightTheme: OctopusTheme = {
    //Primary - these colours don't change between themes
    whiteConstant: color.scale.white,
    blackConstant: color.scale.black,
    cyanConstant: color.scale.blue["500"],
    transparent: "transparent",

    //Shadows - these colours don't change between themes
    shadow02: "rgba(0, 0, 0, 0.02)",
    shadow04: "rgba(0, 0, 0, 0.04)",
    shadow06: "rgba(0, 0, 0, 0.06)",
    shadow10: "rgba(0, 0, 0, 0.1)",
    shadow60: "rgba(0, 0, 0, 0.6)",
    shadow40: "rgba(0, 0, 0, 0.4)",
    shadow34: "rgba(0, 0, 0, 0.34)",
    shadow25: "rgba(0, 0, 0, 0.25)",
    shadow22: "rgba(0, 0, 0, 0.22)",
    shadow20: "rgba(0, 0, 0, 0.2)",
    shadow14: "rgba(0, 0, 0, 0.14)",
    shadow12: "rgba(0, 0, 0, 0.12)",

    paper0: color.scale.white,
    paper1: color.scale.white, // Paper background
    paper2: color.scale.white, // Card background
    paper3: color.scale.white, // Card hover

    // Text
    primaryText: color.scale.grey["800"],
    secondaryText: color.scale.grey["500"],
    ternaryText: color.scale.grey["600"],
    highlightText: color.scale.yellow["200"],
    highlightTextBackground: color.scale.black,

    // Text links
    linkText: color.scale.blue["500"],
    linkTextHover: color.scale.blue["600"],
    sideMenuHover: color.scale.white,
    sideMenuNestedText: color.scale.grey["600"],
    sidebarMenuSelectedText: color.scale.blue["500"],

    // Nav
    navBackground: color.scale.blue["500"],
    navItemHover: color.scale.blue["300"],
    navItemActive: color.scale.blue["600"],
    navText: color.scale.white,
    navTextHover: color.scale.white,
    navTextActive: color.scale.white,
    spaceMenuBackground: color.scale.navy["900"],
    spaceMenuText: color.scale.white,

    // Tabs
    tabActiveIndicator: color.scale.blue["500"],

    // Buttons
    primaryButtonBackground: color.scale.green["500"],
    primaryButtonText: color.scale.white,

    secondaryButtonBackground: color.scale.blue["100"],
    secondaryButtonText: color.scale.blue["500"],

    ternaryButton: color.scale.blue["500"],
    ternaryButtonHover: color.scale.blue["600"],
    categoryButtonBackground: color.scale.blue["500"],

    disabledButtonBorder: color.scale.grey["300"],
    disabledButtonBackground: color.scale.grey["200"],
    disabledButtonText: color.scale.grey["500"],

    createReleaseBackground: color.scale.navy["900"],
    createReleaseText: color.scale.white,

    deleteButtonText: color.scale.white,
    deleteButtonBackground: color.scale.red["500"],

    cancelButtonBackground: color.scale.orange["500"],
    cancelButtonText: color.scale.white,

    subtleButtonBackground: color.scale.slate["200"],
    subtleButtonBorder: color.scale.blue["100"],

    // Paging
    pagingButtonBackground: color.scale.grey["300"],
    pagingButtonBackgroundHover: color.scale.grey["500"],
    pagingButtonBackgroundActive: color.scale.grey["500"],

    // Dashboard
    tenantCount: color.scale.white,

    // Section
    SectionBodyText: color.scale.grey["800"],
    groupHeaderBackground: color.scale.white,
    groupHeader: color.scale.slate[900],
    statusBarBackground: "#ECF0F3",
    statusBarItemBackground: color.scale.white,
    contextualHelpBackground: color.scale.white,

    // Backgrounds & dividers
    primaryBackground: color.scale.grey["100"],
    disabledBackground: color.scale.grey["200"],
    secondaryBackground: color.scale.grey["100"],
    secondaryBackgroundLight: color.scale.grey["200"],
    ternaryBackground: color.scale.grey["200"],
    hover: color.scale.grey["200"],
    divider: color.scale.grey["400"],
    dividerLight: color.scale.grey["200"],
    secondaryDivider: color.scale.grey["300"],
    teritaryDivider: color.scale.grey["200"],
    logoBackground: "transparent",
    stepLogoBackground: color.scale.white,
    parentStepBackground: color.scale.blue["600"],
    loadingIndicator: color.scale.blue["300"],
    loadingIndicatorBackground: color.scale.grey["300"],
    userOnboardingHelpPanelBackground: "rgb(18, 37, 54)",

    // Cards
    cardBackground: color.scale.white,
    cardHover: color.scale.navy[100],
    cardFooterBuiltIn: color.scale.blue["500"],
    cardFooterInstalled: color.scale.grey["400"],
    cardFooterCommunity: color.scale.white,

    // Form
    focus: color.scale.blue["500"],
    selectBorder: color.scale.grey["500"],
    focusIconButton: color.scale.blue["300"],

    // Chips
    chipBackground: color.scale.grey["200"],
    chipHighlight: color.scale.navy["800"],
    chipText: color.scale.grey["800"],
    chipIcon: color.scale.grey["800"],
    chipDeleteButton: color.scale.grey["500"],
    avatarBackground: color.scale.grey["300"],

    // Code Editor hints
    hintHighlight: color.scale.blue["500"],
    hintActive: color.scale.blue["300"],
    placeholder: color.scale.black,
    placeholderActive: color.scale.black,

    // Icon Editor
    iconEditorIconHover: color.scale.grey["100"],
    iconEditorColorPaletteInnerBorder: color.scale.white,
    iconEditorColorPaletteHover: color.scale.grey["300"],
    iconEditorColorPaletteSelected: color.scale.grey["500"],

    // Callouts
    info: color.scale.blue["500"],
    infoConstant: color.scale.blue["500"],
    infoBackground: color.scale.blue["100"],
    infoBorder: color.scale.blue["500"],
    infoHeaderText: color.scale.blue[600],
    infoText: color.scale.blue["500"],
    infoHighlight: color.scale.blue["100"],

    success: color.scale.green["500"],
    successConstant: color.scale.green["500"],
    successBackground: color.scale.green["100"],
    successCalloutBackground: color.scale.green["100"],
    successBorder: color.scale.green["500"],
    successHeaderText: color.scale.green[600],
    successText: color.scale.green[600],
    successHighlight: color.scale.green["100"],

    danger: color.scale.red["500"],
    dangerConstant: color.scale.red["500"],
    dangerBackground: color.scale.red["100"],
    dangerCalloutBackground: color.scale.red["100"],
    dangerBorder: color.scale.red["500"],
    dangerHeaderText: color.scale.red[600],
    dangerText: color.scale.red["500"],
    dangerHighlight: color.scale.red["100"],

    alert: color.scale.orange["500"],
    alertBackground: color.scale.orange["100"],
    alertCalloutBackground: color.scale.yellow[100],
    alertBorder: color.scale.orange["500"],
    alertConstant: color.scale.orange["500"],
    alertHeaderText: color.scale.yellow[600],
    alertText: color.scale.orange["500"],
    alertHighlight: color.scale.orange["100"],
    alertIcon: color.scale.orange["500"],

    sunshineText: color.scale.yellow["300"],

    darkBackground: color.scale.grey["800"],
    darkText: color.scale.white,

    auditHighlight: color.scale.grey["200"],
    codeHighlight: color.scale.purple["100"],
    codeText: color.scale.purple["500"],
    codeTextBright: color.scale.purple["500"],

    featureText: color.scale.navy[600],
    featureBackground: color.scale.blue["100"],
    featureCalloutBackground: color.scale.navy[100],
    featureBorder: "transparent",

    // Task log
    successTaskLogBackground: color.scale.green[100],
    successTaskLogBorder: color.scale.green[400],
    successTaskLogText: color.scale.green[600],
    dangerTaskLogBackground: color.scale.red[100],
    dangerTaskLogBorder: color.scale.red[400],
    dangerTaskLogText: color.scale.red[600],
    alertTaskLogBackground: color.scale.yellow[100],
    alertTaskLogText: color.scale.yellow[600],
    unknownTaskLogBackground: color.scale.slate[100],
    unknownTaskLogBorder: color.scale.slate[400],
    unknownTaskLogText: color.scale.slate[600],
    runningTaskLogText: color.scale.blue[600],

    //Diffs
    diffAddedBackground: color.scale.green["200"],
    diffAddedColor: color.scale.green["700"],
    diffWordAddedBackground: color.scale.green["300"],
    diffWordAddedColor: color.scale.green["700"],
    diffRemovedBackground: color.scale.red["200"],
    diffRemovedColor: color.scale.red["700"],
    diffWordRemovedBackground: color.scale.red["300"],
    diffWordRemovedColor: color.scale.red["700"],
    diffGutterBackground: color.scale.white,
    diffGutterColor: color.scale.grey["800"],
    diffRemovedGutterBackground: color.scale.red["300"],
    diffRemovedGutterColor: color.scale.red["700"],
    diffAddedGutterBackground: color.scale.green["300"],
    diffAddedGutterColor: color.scale.green["700"],
    diffHighlight: color.scale.grey["200"],

    // Form validation
    errorText: color.scale.red["400"],

    // icons
    iconNavy: color.scale.navy["900"],
    iconDark: color.scale.navy["900"],
    iconLight: color.scale.white,
    iconNeutral: color.scale.grey["600"],
    iconLifeCycle: color.scale.grey["400"],

    // Tooltip
    tooltipText: color.scale.white,
    tooltipBackground: color.scale.grey["800"],

    // Primary color
    mainBackground: color.scale.blue["500"],
    mainText: color.scale.blue["500"],

    // Will be superceeded
    primary: color.scale.blue["500"],
    primaryLight: color.scale.blue["300"],
    primaryDark: color.scale.blue["600"],
    primaryDarkest: color.scale.navy["800"],
    primaryLightest: color.scale.slate["200"],
    secondaryDark: color.scale.blue["800"],

    // Images
    imgNavy: color.scale.navy["900"],
    imgCyan: color.scale.blue["500"],
    imgCyan50: color.scale.blue["600"],
    imgWhite: color.scale.white,
    imgBlueGrey: color.scale.slate["200"],
    imgLightGrey: color.scale.grey["200"],
    imgGrey: color.scale.grey["100"],
    imgLight: color.scale.white,
    imgDark: color.scale.navy["900"],
    imgGreen: color.scale.green["500"],
    imgDanger: color.scale.red["500"],

    wizardStepBackground: color.scale.grey["200"],
    wizardStepBackgroundActive: color.scale.blue["500"],

    ...lightTheme,
};

export const DarkTheme: OctopusTheme = {
    //Primary - these colours don't change between themes
    blackConstant: color.scale.black,
    whiteConstant: color.scale.slate["200"],
    cyanConstant: color.scale.blue["700"],
    transparent: "transparent",

    //Shadows - these colours don't change between themes
    shadow02: "rgba(0, 0, 0, 0.02)",
    shadow04: "rgba(0, 0, 0, 0.04)",
    shadow06: "rgba(0, 0, 0, 0.06)",
    shadow10: "rgba(0, 0, 0, 0.1)",
    shadow60: "rgba(0, 0, 0, 0.6)",
    shadow40: "rgba(0, 0, 0, 0.4)",
    shadow34: "rgba(0, 0, 0, 0.34)",
    shadow25: "rgba(0, 0, 0, 0.25)",
    shadow22: "rgba(0, 0, 0, 0.22)",
    shadow20: "rgba(0, 0, 0, 0.2)",
    shadow14: "rgba(0, 0, 0, 0.14)",
    shadow12: "rgba(0, 0, 0, 0.12)",

    // Paper
    paper0: color.scale.navy["900"],
    paper1: color.scale.navy["800"],
    paper2: color.scale.navy["700"],
    paper3: color.scale.navy["900"],

    // Text
    primaryText: color.scale.blue["100"],
    secondaryText: color.scale.navy["300"],
    ternaryText: color.scale.navy["200"],
    highlightText: color.scale.yellow["200"],
    highlightTextBackground: color.scale.black,

    // Text links
    linkText: color.scale.blue["300"],
    linkTextHover: color.scale.white,
    sideMenuHover: color.scale.navy["800"],
    sideMenuNestedText: color.scale.blue["100"],
    sidebarMenuSelectedText: color.scale.blue["300"],

    // Nav
    navBackground: color.scale.navy["900"],
    navItemHover: color.scale.blue["800"],
    navItemActive: color.scale.blue["700"],
    navText: color.scale.navy["200"],
    navTextHover: color.scale.white,
    navTextActive: color.scale.slate["200"],
    spaceMenuBackground: color.scale.blue["800"],
    spaceMenuText: color.scale.white,

    // Tabs
    tabActiveIndicator: color.scale.blue["500"],

    // Buttons
    primaryButtonBackground: color.scale.green["500"],
    primaryButtonText: color.scale.white,

    secondaryButtonBackground: color.scale.navy["900"],
    secondaryButtonText: color.scale.blue["500"],

    ternaryButton: color.scale.blue["300"],
    ternaryButtonHover: color.scale.slate["200"],

    categoryButtonBackground: color.scale.blue["500"],

    disabledButtonBorder: color.scale.navy["300"],
    disabledButtonBackground: color.scale.navy["700"],

    disabledButtonText: color.scale.navy["300"],

    createReleaseBackground: color.scale.blue["700"],
    createReleaseText: color.scale.slate["200"],

    deleteButtonText: color.scale.white,
    deleteButtonBackground: color.scale.red["600"],

    cancelButtonBackground: color.scale.orange["300"],
    cancelButtonText: color.scale.white,

    subtleButtonBackground: color.scale.navy["900"],
    subtleButtonBorder: color.scale.slate["500"],

    // Paging
    pagingButtonBackground: color.scale.blue["800"],
    pagingButtonBackgroundHover: color.scale.blue["500"],
    pagingButtonBackgroundActive: color.scale.blue["500"],

    // Dashboard
    tenantCount: color.scale.grey["800"],

    // Section
    SectionBodyText: color.scale.navy["200"],
    groupHeaderBackground: color.scale.navy[800],
    groupHeader: color.scale.navy[100],
    statusBarBackground: color.scale.navy["900"],
    statusBarItemBackground: color.scale.navy["800"],
    contextualHelpBackground: color.scale.navy["900"],

    // Backgrounds & dividers
    primaryBackground: color.scale.navy["900"],
    disabledBackground: "rgba(153, 153, 153, 0.7)",
    secondaryBackground: color.scale.navy["700"],
    secondaryBackgroundLight: color.scale.navy["600"],
    ternaryBackground: color.scale.navy["900"],

    hover: "rgba(15, 36, 52, 0.9)",
    divider: color.scale.slate["500"],
    dividerLight: color.scale.navy["700"],
    secondaryDivider: color.scale.navy["300"],
    teritaryDivider: color.scale.slate["500"],
    logoBackground: color.scale.blue["100"],
    stepLogoBackground: color.scale.slate["200"],
    parentStepBackground: color.scale.blue["600"],
    loadingIndicator: color.scale.blue["700"],
    loadingIndicatorBackground: color.scale.navy["900"],
    userOnboardingHelpPanelBackground: "rgb(18, 37, 54)",

    // Cards
    cardBackground: color.scale.navy[700],
    cardHover: color.scale.navy[600],
    cardFooterBuiltIn: color.scale.blue["500"],
    cardFooterInstalled: color.scale.navy["900"], //paper0,
    cardFooterCommunity: color.scale.navy["900"], // paper3,

    // Form
    focus: color.scale.blue["500"],
    selectBorder: color.scale.grey["500"],
    focusIconButton: color.scale.blue["500"],

    // Chips
    chipBackground: color.scale.navy["700"],
    chipHighlight: color.scale.blue["300"],
    chipText: color.scale.blue["100"],
    chipIcon: color.scale.blue["100"],
    chipDeleteButton: color.scale.grey["300"],
    avatarBackground: color.scale.blue["600"],

    // Code Editor hints
    hintHighlight: color.scale.red["400"],
    hintActive: color.scale.blue["700"],
    placeholder: color.scale.white,
    placeholderActive: color.scale.white,

    // Icon Editor
    iconEditorIconHover: color.scale.grey["100"],
    iconEditorColorPaletteInnerBorder: color.scale.white,
    iconEditorColorPaletteHover: color.scale.grey["300"],
    iconEditorColorPaletteSelected: color.scale.grey["500"],

    // Callouts
    info: color.scale.blue["500"],
    infoConstant: color.scale.blue["500"],
    infoBackground: color.scale.blue["700"],
    infoBorder: color.scale.blue["300"],
    infoHeaderText: color.scale.blue["300"],
    infoText: color.scale.blue["300"],
    infoHighlight: "rgba(0, 0, 0, 0)",

    success: color.scale.green["500"],
    successConstant: color.scale.green["500"],
    successBackground: color.scale.green["800"],
    successCalloutBackground: color.scale.green[700],
    successBorder: color.scale.green["700"],
    successHeaderText: color.scale.green[300], //For use on successBackground
    successText: color.scale.green["400"], //For use on navy background
    successHighlight: "rgba(0, 0, 0, 0)",

    danger: color.scale.red["500"],
    dangerConstant: color.scale.red["500"],
    dangerBackground: color.scale.red["800"],
    dangerCalloutBackground: color.scale.red["700"],
    dangerBorder: color.scale.red["700"],
    dangerHeaderText: color.scale.red["300"], //For use on dangerBackground
    dangerText: color.scale.red["400"], //For use on navy background
    dangerHighlight: "rgba(0, 0, 0, 0)",

    alert: color.scale.orange["500"],
    alertBackground: color.scale.orange["800"],
    alertCalloutBackground: color.scale.yellow[700],
    alertBorder: color.scale.orange["200"],
    alertConstant: color.scale.orange["500"],
    alertHeaderText: color.scale.yellow[300], //For use on alertBackground
    alertText: color.scale.orange["300"], //For use on navy background
    alertHighlight: color.scale.yellow[700],
    alertIcon: color.scale.orange["200"],

    sunshineText: color.scale.yellow["300"],

    darkBackground: color.scale.white,
    darkText: color.scale.navy["900"],

    auditHighlight: color.scale.navy["700"],

    codeHighlight: color.scale.purple["800"],
    codeText: color.scale.purple["200"],
    codeTextBright: color.scale.purple["300"],

    featureText: color.scale.navy[300],
    featureBackground: color.scale.navy["800"],
    featureCalloutBackground: color.scale.navy[700],
    featureBorder: color.scale.slate["200"],

    // Task log
    successTaskLogBackground: color.scale.green[900],
    successTaskLogBorder: color.scale.green[600],
    successTaskLogText: color.scale.green[400],
    dangerTaskLogBackground: color.scale.red[900],
    dangerTaskLogBorder: color.scale.red[600],
    dangerTaskLogText: color.scale.red[400],
    alertTaskLogBackground: color.scale.yellow[900],
    alertTaskLogText: color.scale.yellow[400],
    unknownTaskLogBackground: color.scale.slate[900],
    unknownTaskLogBorder: color.scale.slate[600],
    unknownTaskLogText: color.scale.slate[400],
    runningTaskLogText: color.scale.blue[400],

    //Audit diffs
    diffAddedBackground: color.scale.green["800"],
    diffAddedColor: color.scale.green["300"],
    diffWordAddedBackground: color.scale.green["700"],
    diffWordAddedColor: color.scale.green["300"],
    diffRemovedBackground: color.scale.red["800"],
    diffRemovedColor: color.scale.red["300"],
    diffWordRemovedBackground: color.scale.red["700"],
    diffWordRemovedColor: color.scale.red["300"],
    diffGutterBackground: color.scale.navy["900"],
    diffGutterColor: color.scale.blue["100"],
    diffRemovedGutterBackground: color.scale.red["700"],
    diffRemovedGutterColor: color.scale.red["300"],
    diffAddedGutterBackground: color.scale.green["700"],
    diffAddedGutterColor: color.scale.green["300"],
    diffHighlight: color.scale.grey["200"],

    // Form validation
    errorText: color.scale.red["400"],

    // icons
    iconNavy: color.scale.navy["900"],
    iconDark: color.scale.blue["100"],
    iconLight: color.scale.navy["900"],
    iconNeutral: color.scale.grey["300"],
    iconLifeCycle: color.scale.slate["500"],

    // Tooltip
    tooltipText: color.scale.grey["800"],
    tooltipBackground: color.scale.white,

    // Primary color
    primary: color.scale.blue["300"],
    mainBackground: color.scale.navy["600"],
    mainText: color.scale.blue["300"],

    primaryLight: color.scale.slate["500"],
    primaryDark: color.scale.blue["100"],
    primaryDarkest: color.scale.navy["900"],
    primaryLightest: color.scale.blue["100"],
    secondaryDark: color.scale.blue["500"],

    // Images
    imgNavy: color.scale.navy["900"],
    imgCyan: color.scale.blue["500"],
    imgCyan50: color.scale.blue["600"],
    imgWhite: color.scale.white,
    imgBlueGrey: color.scale.navy["800"],
    imgLightGrey: color.scale.navy["300"],
    imgGrey: color.scale.navy["900"],
    imgLight: color.scale.navy["900"],
    imgDark: color.scale.white,
    imgGreen: color.scale.green["500"],
    imgDanger: color.scale.red["500"],

    wizardStepBackground: color.scale.navy["600"],
    wizardStepBackgroundActive: color.scale.blue["300"],

    ...darkTheme,
};
