import { configureConsoleLoggersToForwardToGlobalLogger, globalLogConfiguration, globalLogContext } from "@octopusdeploy/logging";
import { v4 } from "uuid";
import { configureSerilogIngestionSink } from "~/utils/logging/configureSerilogIngestion";
import { portalLoggingContextProvider, globalPortalLogContext } from "~/utils/logging/portalLoggingContext";
import { browserLoggingContextProvider, setupGlobalBrowserLogContext } from "./browserLoggingContext";

globalLogConfiguration.attachContextProvider(portalLoggingContextProvider);
globalLogConfiguration.attachContextProvider(browserLoggingContextProvider);
setupGlobalBrowserLogContext();
globalPortalLogContext.setBrowserSessionId(v4());
configureSerilogIngestionSink();
configureConsoleLoggersToForwardToGlobalLogger();
globalLogContext.setApplication("Octopus.Server.Portal");
