import { usePage } from "~/components/Page/Page";
import type IPageWrapper from "~/utils/pageId";
import type { AnalyticFields, PageFields } from "./AnalyticSession";
import { useAnalyticSession } from "./AnalyticSession";

export type PageEventDispatcher = (name: string, eventFields: AnalyticFields) => void;

export function getPageFields(page: IPageWrapper): PageFields {
    // Strip any " (Keywords)" from the Area string.
    return {
        "Page Area": page?.Area.replace(/ *\(.*\)/, ""),
        "Page Name": page?.Name,
    };
}

export function usePageEventDispatcher(): PageEventDispatcher {
    const session = useAnalyticSession();
    const page = usePage();

    return (name: string, eventFields: AnalyticFields) => {
        if (!page) {
            return;
        }

        const fields: PageFields = {
            ...getPageFields(page),
            ...eventFields,
        };

        session.track(name, fields);
    };
}
