/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { logger } from "@octopusdeploy/logging";
import * as React from "react";
import { clearUnhandledErrors, raiseUnhandledError } from "~/components/UnhandledError/reducers";
import store from "~/store";

class BaseComponent<Props = {}, State = {}> extends React.Component<Props, State> {
    private static methodsToCapture = ["componentDidMount", "componentWillMount", "componentDidUpdate", "componentWillUnmount", "shouldComponentUpdate", "componentWillUpdate", "componentWillReceiveProps"];

    protected unmounted = false;

    constructor(props: Props) {
        super(props);

        this.provideErrorHandlingToTopLevelMethods();
        this.addUnmountedHook();
    }

    //TODO: @Architecture. These `anys` here are killing us and we're losing type safely on setState calls everywhere. This is a mini-mountain
    // of work to refactor properly, but it would help our type safety significantly.

    setState<K extends keyof State>(f: (prevState: Readonly<State>, props: Readonly<Props>) => Pick<State, K> | State | null, callback?: () => any): void;
    setState<K extends keyof State>(state: Pick<State, K> | State | null, callback?: () => any): void;
    setState<K extends keyof State>(m: ((prevState: Readonly<State>, props: Readonly<Props>) => Pick<State, K> | State | null) | (Pick<State, K> | State | null), callback?: () => any): void {
        if (this.unmounted) {
            return;
        }

        super.setState(m, callback);
    }

    protected setChildState1<KeyOfState extends keyof State, Child extends State[KeyOfState], KeyOfChild extends keyof Child>(first: KeyOfState, state: Pick<Child, KeyOfChild>, callback?: () => void) {
        this.setState(
            (prev) => ({
                [first]: { ...prev[first], ...state },
            }),
            callback
        );
    }

    protected setChildState2<KeyOfState extends keyof State, Child extends NonNullable<State[KeyOfState]>, KeyOfChild extends keyof Child, GrandChild extends NonNullable<Child[KeyOfChild]>, KeyOfGrandChild extends keyof GrandChild>(
        first: KeyOfState,
        second: KeyOfChild,
        state: Pick<GrandChild, KeyOfGrandChild>,
        callback?: () => void
    ) {
        this.setState(
            (prev) => ({
                [first]: {
                    ...prev[first],
                    [second]: {
                        ...(prev[first] as Child)[second],
                        ...state,
                    },
                },
            }),
            callback
        );
    }

    protected setChildState3<
        KeyOfState extends keyof State,
        Child extends NonNullable<State[KeyOfState]>,
        KeyOfChild extends keyof Child,
        GrandChild extends NonNullable<Child[KeyOfChild]>,
        KeyOfGrandChild extends keyof GrandChild,
        GreatGrandChild extends NonNullable<GrandChild[KeyOfGrandChild]>,
        KeyOfGreatGrandChild extends keyof GreatGrandChild
    >(first: KeyOfState, second: KeyOfChild, third: KeyOfGrandChild, state: Pick<GreatGrandChild, KeyOfGreatGrandChild>, callback?: () => void) {
        this.setState(
            (prev) => ({
                [first]: {
                    ...prev[first],
                    [second]: {
                        ...(prev[first] as Child)[second],
                        [third]: {
                            ...((prev[first] as Child)[second] as GrandChild)[third],
                            ...(state as object),
                        },
                    },
                },
            }),
            callback
        );
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Type instantiation is excessively deep and possibly infinite
    protected setChildState4<
        KeyOfState extends keyof State,
        Child extends NonNullable<State[KeyOfState]>,
        KeyOfChild extends keyof Child,
        GrandChild extends NonNullable<Child[KeyOfChild]>,
        KeyOfGrandChild extends keyof GrandChild,
        GreatGrandChild extends NonNullable<GrandChild[KeyOfGrandChild]>,
        KeyOfGreatGrandChild extends keyof GreatGrandChild,
        GreatGreatGrandChild extends NonNullable<GreatGrandChild[KeyOfGreatGrandChild]>,
        KeyOfGreatGreatGrandChild extends keyof GreatGreatGrandChild
    >(first: KeyOfState, second: KeyOfChild, third: KeyOfGrandChild, fourth: KeyOfGreatGrandChild, state: Pick<GreatGreatGrandChild, KeyOfGreatGreatGrandChild>, callback?: () => void) {
        this.setState(
            (prev) => ({
                [first]: {
                    ...prev[first],
                    [second]: {
                        ...(prev[first] as Child)[second],
                        [third]: {
                            ...((prev[first] as Child)[second] as GrandChild)[third],
                            [fourth]: {
                                ...(((prev[first] as Child)[second] as GrandChild)[third] as GreatGrandChild)[fourth],
                                ...(state as object),
                            },
                        },
                    },
                },
            }),
            callback
        );
    }

    protected provideErrorHandling(func: (...args: any[]) => any) {
        const name = this.findNameOf(func);
        this.provideErrorHandlingByName(name);
    }

    protected clearError() {
        store.dispatch(clearUnhandledErrors());
    }

    private provideErrorHandlingByName(name: string) {
        const originalMethod = (this as any)[name].bind(this);
        const wrapper = (...args: any[]) => {
            try {
                const result = originalMethod(...args);
                if (result instanceof Promise) {
                    return result.catch((error) => {
                        this.handleError(error, name);
                    });
                } else {
                    return result;
                }
            } catch (error) {
                this.handleError(error, name);
                throw error;
            }
        };

        (this as any)[name] = wrapper;
    }

    private handleError(error: any, name: string) {
        logger.error(error, "Error occurred in {name}", { name });
        store.dispatch(raiseUnhandledError(error));
        throw error;
    }

    private addUnmountedHook() {
        const componentWillUnmount = this["componentWillUnmount"];
        const originalMethod = componentWillUnmount && componentWillUnmount.bind(this);
        this["componentWillUnmount"] = async (...args: any[]) => {
            this.unmounted = true;
            if (originalMethod) {
                return originalMethod();
            }
        };
    }

    private findNameOf(func: (...args: any[]) => Promise<any>): string {
        return Object.getOwnPropertyNames(this).filter((name) => (this as any)[name] === func)[0];
    }

    private provideErrorHandlingToTopLevelMethods() {
        Object.getOwnPropertyNames(Object.getPrototypeOf(this)).forEach((name) => {
            if (BaseComponent.methodsToCapture.includes(name)) {
                this.provideErrorHandlingByName(name);
            }
        });
    }
}

export { BaseComponent };
