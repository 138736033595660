import type { SpaceSearchResult } from "@octopusdeploy/octopus-server-client";
import React from "react";
import type { PageFields } from "~/analytics/AnalyticSession";
import { useAnalyticSession } from "~/analytics/AnalyticSession";
import { usePageFields } from "~/analytics/usePageFields";
import { IsPageSearchResult } from "~/globalSearch/GlobalSearchHelpers";
import type { GlobalSearchResult, PageSearchResult } from "~/globalSearch/GlobalSearchHelpers";

const GlobalSearchActivatedName = "Global search item selected";

export function useNotifyGlobalSearchItemSelected() {
    const session = useAnalyticSession();
    const pageFields = usePageFields();

    return React.useCallback(
        (searchResult: GlobalSearchResult) => {
            if (!pageFields) {
                return;
            }

            session.track(GlobalSearchActivatedName, mapOptionsToFields(searchResult, pageFields));
        },
        [pageFields, session]
    );
}

interface GlobalSearchPageItemSelectedFields extends PageFields {
    "Selection Type": "Page";
    "Selected Page": string;
    "Selected Page Area": string;
}

interface GlobalSearchSpaceResourceItemSelectedFields extends PageFields {
    "Selection Type": "Space Resource";
    "Selected Resource Type": string;
}

type GlobalSearchItemSelectedFields = GlobalSearchPageItemSelectedFields | GlobalSearchSpaceResourceItemSelectedFields;

function mapOptionsToFields(searchResult: GlobalSearchResult, pageFields: PageFields): GlobalSearchItemSelectedFields {
    if (IsPageSearchResult(searchResult)) {
        return mapPageSelectedOptionsToFields(searchResult, pageFields);
    }

    return mapSpaceResourceSelectedOptionsToFields(searchResult, pageFields);
}

function mapPageSelectedOptionsToFields(options: PageSearchResult, pageFields: PageFields): GlobalSearchPageItemSelectedFields {
    return {
        "Selection Type": "Page",
        "Selected Page": options.page.Name,
        "Selected Page Area": options.page.Area,
        ...pageFields,
    };
}

function mapSpaceResourceSelectedOptionsToFields(options: SpaceSearchResult, pageFields: PageFields): GlobalSearchSpaceResourceItemSelectedFields {
    return {
        "Selection Type": "Space Resource",
        "Selected Resource Type": options.Type,
        ...pageFields,
    };
}
