import { Permission } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";

export function deprovisionEnvironmentMenuItem(environmentId: string, environmentName: string, onDeprovisionEnvironment: (environmentId: string) => Promise<void>): MenuItem {
    return OverflowMenuItems.destructiveItem(
        "Deprovision environment",
        `Are you sure you want to deprovision dynamic environment '${environmentName}'?`,
        "Deprovision",
        "Deprovisioning",
        async () => {
            await onDeprovisionEnvironment(environmentId);
            return true;
        },
        <div>
            <p>This action will deprovision both the environment and all associated projects.</p>
            <p>Do you wish to continue?</p>
        </div>,
        { permission: Permission.EnvironmentDelete, environment: "*" }
    );
}
