import type { AuditStreamConfigurationResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import EditAuditStreamDialogLayout from "~/areas/configuration/components/AuditLayout/AuditStream/EditAuditStreamDialogLayout";
import newStreamImage from "~/areas/configuration/components/AuditLayout/assets/img-newstream.svg";
import DialogWithHelpPanel from "~/components/DialogWithHelpPanel/DialogWithHelpPanel";
import styles from "./style.module.less";

export interface EditAuditStreamDialogProps {
    open: boolean;
    close: () => void;
    auditStreamConfiguration: AuditStreamConfigurationResource;
    onUpdate: (value: AuditStreamConfigurationResource, message: string) => void;
}

export function EditAuditStreamDialog({ open, close, auditStreamConfiguration, onUpdate }: EditAuditStreamDialogProps) {
    const helpPanelContent = (
        <span>
            <strong>Audit streaming</strong> lets you stream audit and Octopus events data from Octopus to an external management system
        </span>
    );
    return (
        <DialogWithHelpPanel contentPanelClassName={styles.testPanel} helpPanelImage={newStreamImage} helpPanelContent={helpPanelContent} helpPanelAltImageText={"Configure Audit Stream"} open={open} close={close}>
            <EditAuditStreamDialogLayout close={close} onUpdate={onUpdate} auditStreamConfiguration={auditStreamConfiguration} />
        </DialogWithHelpPanel>
    );
}
