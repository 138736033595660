import { Environment } from "@octopusdeploy/utilities";
import React from "react";
import { TaskLogViewer } from "~/areas/configuration/components/Diagnostics/TaskLogViewer";
import FeaturesLayout from "~/areas/configuration/components/FeaturesLayout/FeaturesLayout";
import { client } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDevToolsState } from "~/components/DevTools/DevToolsContext";
import { AllPngImageFiles } from "~/components/DevTools/Images/AllPngImageFiles";
import { AllSvgImageFiles } from "~/components/DevTools/Images/AllSvgImageFiles";
import { EmbeddedImages } from "~/components/DevTools/Images/EmbeddedImages";
import { useProfilerCaching } from "~/components/DevTools/MiniProfiler/ProfilerCache/index";
import { MiniProfilerPanel } from "~/components/DevTools/MiniProfiler/index";
import { DevToolsOverview } from "~/components/DevTools/OverviewTab/index";
import ToggleControls from "~/components/DevTools/Toggles/ToggleControls";
import { KnownUsedFontAwesomeIcons } from "./Icons/KnownUsedFontAwesomeIcons";

interface Tab {
    label: string; // This label is used as a key, so it is assumed to be globally unique across all tabs
    content: React.ReactNode;
}

export function useDevDrawerTabs(doBusyTask: DoBusyTask) {
    return [devToolsTab, togglesTab, featuresTab, iconsTab, embeddedImagesTab, allSvgImagesTab, allPngImagesTab, ...useDynamicTabs(), ...useDevelopmentModeOnlyTabs(doBusyTask)];
}

const devToolsTab: Tab = {
    label: "Dev Tools",
    content: <DevToolsOverview />,
};

const togglesTab: Tab = {
    label: "Toggles",
    content: <ToggleControls />,
};

const iconsTab: Tab = {
    label: "Icons",
    content: <KnownUsedFontAwesomeIcons />,
};

const embeddedImagesTab: Tab = {
    label: "Embedded Images",
    content: <EmbeddedImages />,
};

const allSvgImagesTab: Tab = {
    label: "All *.svg images",
    content: <AllSvgImageFiles />,
};

const allPngImagesTab: Tab = {
    label: "All *.png images",
    content: <AllPngImageFiles />,
};

const featuresTab: Tab = {
    label: "Features",
    content: <FeaturesLayout fullWidth={true} dirtyTrackingDisabled={true} />,
};

const taskLogViewerTab: Tab = {
    label: "Task Log Viewer",
    content: <TaskLogViewer />,
};

function useDynamicTabs(): Tab[] {
    const state = useDevToolsState();

    if (state) {
        return Array.from(state.devToolsTabs.tabs.entries()).map(([key, value]) => ({
            label: key,
            content: value,
        }));
    }
    return [];
}

function useDevelopmentModeOnlyTabs(doBusyTask: DoBusyTask): Tab[] {
    const { pagedRequests, actions, isCapturing } = useProfilerCaching(client, doBusyTask);

    if (Environment.isInDevelopmentMode()) {
        return [
            {
                label: "Profiler",
                content: <MiniProfilerPanel pageSessions={pagedRequests} actions={actions} enabled={isCapturing} />,
            },
            taskLogViewerTab,
        ];
    }
    return [];
}
