/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { Collapse } from "@material-ui/core";
import React from "react";
import Note from "~/primitiveComponents/form/Note/Note";
import styles from "./style.module.less";

export interface MoreInfoProps {
    showLabel?: string;
    hideLabel?: string;
}

const MoreInfo: React.FC<MoreInfoProps> = ({ showLabel = "Show more information", hideLabel = "Hide more information", children }) => {
    const [expanded, setExpanded] = React.useState(false);

    return (
        <>
            <Note>
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setExpanded(!expanded);
                    }}
                >
                    {expanded ? hideLabel : showLabel}
                </a>
            </Note>
            <Collapse in={expanded} timeout={"auto"}>
                <div className={styles.moreInformation}>{children}</div>
            </Collapse>
        </>
    );
};

export default MoreInfo;
