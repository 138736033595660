import type { PropsWithChildren } from "react";
import React from "react";
import type { EmbeddedImageDescription, VariantDescription } from "~/components/DevTools/Images/EmbeddedImageDescription";
import { embeddedSvgDescriptions } from "~/components/DevTools/Images/embeddedSvgDescriptions";
import { embeddedSvgIconDescriptions } from "~/components/DevTools/Images/embeddedSvgIconDescriptions";
import { embeddedSvgImageDescriptions } from "~/components/DevTools/Images/embeddedSvgImageDescriptions";

export function EmbeddedImages() {
    const allImages = [...embeddedSvgImageDescriptions, ...embeddedSvgDescriptions, ...embeddedSvgIconDescriptions];
    return (
        <div style={{ paddingLeft: "1rem" }}>
            <p>These are the images that are embedded in react components, rather than available as separate SVG or PNG files. Images that come from the design system are not shown.</p>
            {allImages.map((image) => (
                <EmbeddedImage key={image.componentName} imageDescription={image} />
            ))}
        </div>
    );
}

function EmbeddedImage({ imageDescription }: PropsWithChildren<{ imageDescription: EmbeddedImageDescription }>) {
    return (
        <div>
            <h2>{imageDescription.componentName}</h2>
            <div style={{ paddingLeft: "1rem" }}>{"variants" in imageDescription ? imageDescription.variants.map((v) => <Variant key={v.variantDescription} variant={v} />) : <Image>{imageDescription.content}</Image>}</div>
        </div>
    );
}

function Variant({ variant }: PropsWithChildren<{ variant: VariantDescription }>) {
    return (
        <>
            <p>Variant: {variant.variantDescription}</p>
            <Image>{variant.content}</Image>
        </>
    );
}

function Image({ children }: PropsWithChildren<{}>) {
    return <div style={{ maxHeight: "500px", maxWidth: "500px" }}>{children}</div>;
}
