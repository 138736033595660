import { buildProvenance } from "@octopusdeploy/build-provenance";
import React from "react";
import { repository } from "~/clientInstance";
import styles from "./style.module.less";

export const DevToolsOverview: React.FC = () => {
    const serverInformation = repository.getServerInformation();
    const version = serverInformation.version;

    return (
        <table className={styles.versionTable}>
            <tbody>
                <tr>
                    <th>Server version:</th>
                    <td>{version}</td>
                </tr>
                <tr>
                    <th>Portal version:</th>
                    <td>{buildProvenance.version}</td>
                </tr>
                <tr>
                    <th>Portal commit hash:</th>
                    <td>{buildProvenance.commitHash}</td>
                </tr>
                <tr>
                    <th>Portal commit branch:</th>
                    <td>{buildProvenance.branchName}</td>
                </tr>
                <tr>
                    <th>Build Date:</th>
                    <td>{buildProvenance.buildDate}</td>
                </tr>
            </tbody>
        </table>
    );
};

export default DevToolsOverview;
