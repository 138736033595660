import type { ObjectInputPaths } from "@octopusdeploy/step-inputs";
import type { ObjectRuntimeInputs, PathToInput, PlainObjectTypeDefinition } from "@octopusdeploy/step-runtime-inputs";
import type { DeploymentTargetInputComponent } from "@octopusdeploy/step-ui";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import * as React from "react";
import type { DeploymentTargetInputDependencies } from "~/components/StepPackageDeploymentTargetEditor/DeploymentTargetInputDependencies";
import { DeploymentTargetCheckbox, getDeploymentTargetCheckboxContentSummary } from "~/components/StepPackageDeploymentTargetEditor/Inputs/Checkbox/Checkbox";
import { DeploymentTargetSensitive } from "~/components/StepPackageDeploymentTargetEditor/Inputs/Sensitive/Sensitive";
import { DeploymentTargetText } from "~/components/StepPackageDeploymentTargetEditor/Inputs/Text/Text";
import type { RenderedComponentAndSummary } from "~/components/StepPackageEditor/EditStepPackageInputs";
import { AccountSelector, getAccountSelectorSummary } from "~/components/StepPackageEditor/Inputs/Components/AccountSelector/AccountSelector";
import { getRadioButtonsSummary, RadioButtons } from "~/components/StepPackageEditor/Inputs/Components/DiscriminatorComponents/RadioButtons/RadioButtons";
import { getSelectSummary, StepPackageSelect } from "~/components/StepPackageEditor/Inputs/Components/DiscriminatorComponents/Select/Select";
import { getSensitiveContentSummary } from "~/components/StepPackageEditor/Inputs/Components/Sensitive/Sensitive";
import { getTextContentSummary } from "~/components/StepPackageEditor/Inputs/Components/Text/Text";
import { DeploymentTargetNumber, getDeploymentTargetNumberSummary } from "./Number/Number";

export function createGetRenderedComponentAndSummaryForDeploymentTargetFactory<StepInputs>(
    inputs: ObjectRuntimeInputs<StepInputs>,
    setInputs: (inputs: ObjectRuntimeInputs<StepInputs>) => void,
    getInputSchema: (inputs: ObjectRuntimeInputs<StepInputs>) => PlainObjectTypeDefinition,
    inputPaths: ObjectInputPaths<StepInputs>,
    getFieldError: (name: PathToInput) => string,
    inputDependencies: DeploymentTargetInputDependencies
) {
    return (component: DeploymentTargetInputComponent): RenderedComponentAndSummary => {
        switch (component.type) {
            case "sensitive":
                return {
                    summary: getSensitiveContentSummary(component, inputs),
                    renderedComponent: <DeploymentTargetSensitive<StepInputs> configuredStepUIProps={component} inputs={inputs} setInputs={setInputs} getFieldError={getFieldError} />,
                };
            case "text":
                return {
                    summary: getTextContentSummary(component, inputs),
                    renderedComponent: <DeploymentTargetText<StepInputs> configuredStepUIProps={component} inputs={inputs} setInputs={setInputs} getFieldError={getFieldError} />,
                };
            case "radio-buttons":
                return {
                    summary: getRadioButtonsSummary(inputs, component, getInputSchema),
                    renderedComponent: <RadioButtons<StepInputs> configuredStepUIProps={component} inputs={inputs} setInputs={setInputs} getInputSchema={getInputSchema} getFieldError={getFieldError} />,
                };
            case "account":
                return {
                    summary: getAccountSelectorSummary(component, inputs, inputDependencies.accounts),
                    renderedComponent: <AccountSelector configuredStepUIProps={component} inputs={inputs} setInputs={setInputs} getInputSchema={getInputSchema} getFieldError={getFieldError} dependencies={inputDependencies} />,
                };
            case "checkbox":
                return {
                    summary: getDeploymentTargetCheckboxContentSummary(component, inputs),
                    renderedComponent: <DeploymentTargetCheckbox<StepInputs> configuredStepUIProps={component} inputs={inputs} setInputs={setInputs} getFieldError={getFieldError} />,
                };
            case "select":
                return {
                    summary: getSelectSummary(inputs, component, getInputSchema),
                    renderedComponent: (
                        <StepPackageSelect<StepInputs>
                            input={component.input}
                            label={component.label}
                            options={component.options}
                            inputs={inputs}
                            setInputs={setInputs}
                            getInputSchema={getInputSchema}
                            getFieldError={getFieldError}
                            note={component.note}
                        />
                    ),
                };
            case "number":
                return {
                    summary: getDeploymentTargetNumberSummary(component, inputs),
                    renderedComponent: <DeploymentTargetNumber<StepInputs> configuredStepUIProps={component} inputs={inputs} setInputs={setInputs} getFieldError={getFieldError} />,
                };
            default:
                return exhaustiveCheck(component, `Step package component type not supported for deployment targets`);
        }
    };
}
