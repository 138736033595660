/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { ProjectResource, ReleaseResource, ChannelResource, ResourceCollection } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import { useProjectContext } from "~/areas/projects/context/ProjectContext";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/withProjectContext";
import { repository } from "~/clientInstance";
import { NavigationButton, NavigationButtonType } from "~/components/Button";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import PaperLayout from "~/components/PaperLayout";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import useLocalStorage from "~/hooks/useLocalStorage";
import routeLinks from "~/routeLinks";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { RecentProjects } from "~/utils/RecentProjects/RecentProjects";
import { Callout, CalloutType } from "../../../../primitiveComponents/dataDisplay/Callout/Callout";
import { ProjectStatus } from "../ProjectStatus/ProjectStatus";
import Onboarding from "./Onboarding";
import ReleasesTable from "./ReleasesTable";
import styles from "./style.module.less";

interface ReleasesState extends DataBaseComponentState {
    project: ProjectResource;
    releases: ResourceCollection<ReleaseResource>;
    channels: ChannelResource[];
    hasDeploymentProcess: boolean;
    selectedChannel: string;
    versionFilter: string;
    pageSize: number;
}

type ReleasesProps = RouteComponentProps<ProjectRouteParams>;

const pageSizeControlKey = "Octopus.PageSize.Releases";
const defaultPageSize = 30;

interface ReleasesPropsInternal extends ReleasesProps, WithProjectContextInjectedProps {
    dispatchAction: AnalyticActionDispatcher;
    storedPageSize?: number;

    setStoredPageSize(pageSize?: number): void;
}

class ReleasesInternal extends DataBaseComponent<ReleasesPropsInternal, ReleasesState> {
    constructor(props: ReleasesPropsInternal) {
        super(props);
        this.state = {
            project: null!,
            releases: null!,
            channels: null!,
            hasDeploymentProcess: false,
            selectedChannel: null!,
            versionFilter: null!,
            pageSize: props.storedPageSize && props.storedPageSize > 0 ? props.storedPageSize : defaultPageSize,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(
            async () => {
                const { model: project, projectContextRepository } = this.props.projectContext.state;
                await RecentProjects.getInstance().UpdateAccessedProjectIntoLocalStorage(project.Id);

                const [releases, channels] = await Promise.all([repository.Projects.getReleases(project, { take: this.state.pageSize }), repository.Projects.getChannels(project)]);

                const deploymentProc = !project.IsVersionControlled && (await projectContextRepository.DeploymentProcesses.get());
                const hasDeploymentProcess: boolean = deploymentProc && deploymentProc.Steps.length > 0;

                this.setState({
                    project,
                    releases,
                    channels: channels.Items,
                    hasDeploymentProcess,
                });
            },
            { timeOperationOptions: timeOperationOptions.forInitialLoad() }
        );
    }

    isFiltering() {
        return !!this.state.selectedChannel || !!this.state.versionFilter;
    }

    onPageSizeChange = (pageSize: number | undefined) => {
        if (pageSize === undefined || pageSize <= 0) {
            pageSize = defaultPageSize;
        }
        this.setState({ pageSize }, async () => {
            this.props.setStoredPageSize(pageSize);
            await this.refreshReleases();
        });
    };

    renderBody() {
        const createReleaseIsAllowed = this.state.hasDeploymentProcess || this.state.project?.IsVersionControlled;
        return createReleaseIsAllowed && (this.isFiltering() || this.state.releases.Items.length > 0) ? (
            <ReleasesTable
                releases={this.state.releases}
                channels={this.state.channels}
                onChannelFilterChange={async (selectedChannel) => {
                    this.setState({ selectedChannel }, async () => {
                        await this.refreshReleases();
                    });
                }}
                onVersionFilterChange={async (versionFilter) => {
                    this.setState({ versionFilter }, async () => {
                        await this.refreshReleases();
                    });
                }}
                pageSize={this.state.pageSize}
                setPageSize={this.onPageSizeChange}
                {...this.props}
            />
        ) : (
            <>
                <div className={styles.noProcessCallout}>
                    {!createReleaseIsAllowed && (
                        <Callout title="Deployment process required." type={CalloutType.Information}>
                            <div>
                                <PermissionCheck permission={Permission.ProcessEdit} project={this.state.project?.Id} tenant="*">
                                    <span>
                                        Before you can release and deploy your software, you will need to{" "}
                                        <strong>
                                            <InternalLink to={routeLinks.project(this.state.project).deployments.process.root}>create a deployment process</InternalLink>
                                        </strong>
                                        .
                                    </span>
                                </PermissionCheck>
                            </div>
                        </Callout>
                    )}
                </div>
                <Onboarding
                    project={this.state.project}
                    actionButtons={
                        createReleaseIsAllowed && (
                            <PermissionCheck permission={Permission.ReleaseCreate} project={this.state.project && this.state.project.Id} tenant="*">
                                <NavigationButton
                                    type={NavigationButtonType.Primary}
                                    label="Create release"
                                    href={`${this.props.match.url}/create`}
                                    disabled={this.state.project && this.state.project.IsDisabled}
                                    onClick={() => this.props.dispatchAction("Create Release", { action: Action.Add, resource: "Create Release" })}
                                />
                            </PermissionCheck>
                        )
                    }
                />
            </>
        );
    }

    render() {
        const hasReleases = this.state.releases && this.state.releases.Items.length > 0;
        return (
            <PaperLayout
                sectionControl={
                    (this.state.hasDeploymentProcess || this.state.project?.IsVersionControlled) &&
                    hasReleases && (
                        <PermissionCheck permission={Permission.ReleaseCreate} project={this.state.project && this.state.project.Id} tenant="*">
                            <NavigationButton
                                type={NavigationButtonType.Primary}
                                label="Create release"
                                href={`${this.props.match.url}/create`}
                                disabled={this.state.project && this.state.project.IsDisabled}
                                onClick={() => this.props.dispatchAction("Create a release", { action: Action.Add, resource: "Create Release" })}
                            />
                        </PermissionCheck>
                    )
                }
                busy={this.state.busy}
                errors={this.errors}
                title="Releases"
                breadcrumbTitle={this.state.project?.Name}
                statusSection={<ProjectStatus doBusyTask={this.doBusyTask} />}
            >
                {this.state.project && this.state.project.IsDisabled && (
                    <Callout type={CalloutType.Warning} title="Warning">
                        This project is currently disabled, so releases cannot be created.
                    </Callout>
                )}
                {this.state.releases && this.renderBody()}
            </PaperLayout>
        );
    }

    private refreshReleases = async () => {
        const selectedChannel = this.state.selectedChannel;
        const searchByVersion = this.state.versionFilter;
        const take = this.state.pageSize;

        await this.doBusyTask(
            async () => {
                const releases = await (selectedChannel
                    ? repository.Channels.getReleases(this.state!.channels.find((c) => c.Id === selectedChannel)!, { searchByVersion, take })
                    : repository.Projects.getReleases(this.state.project, { searchByVersion, take }));
                this.setState({ releases });
            },
            { timeOperationOptions: timeOperationOptions.forRefresh() }
        );
    };
}

export function Releases(props: ReleasesProps) {
    const projectContext = useProjectContext();
    const dispatchAction = useAnalyticActionDispatch(projectContext.state.model.Id);

    const [storedPageSize, setStoredPageSize] = useLocalStorage<number | undefined>(pageSizeControlKey, undefined);

    return <ReleasesInternal {...props} storedPageSize={storedPageSize} setStoredPageSize={setStoredPageSize} projectContext={projectContext} dispatchAction={dispatchAction} />;
}
