import type { GetDynamicEnvironmentOverviewResponseV1 } from "@octopusdeploy/octopus-server-client";
import { GetDynamicEnvironmentOverviewRequestV1SortField, GetDynamicEnvironmentOverviewRequestV1SortOrder, Permission } from "@octopusdeploy/octopus-server-client";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import { compact } from "lodash";
import React, { useState } from "react";
import type { RouteComponentProps } from "react-router";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import DataBaseComponent, { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import ErrorPanel from "~/components/ErrorPanel";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import type { NavItem } from "~/components/NavigationSidebarLayout";
import NavigationSidebarLayout, { Navigation } from "~/components/NavigationSidebarLayout";
import { useRefreshLoop } from "~/hooks/useRefreshLoop";
import routeLinks from "~/routeLinks";
import type { DynamicEnvironmentOverviewAssociatedProjectsFilter, DynamicEnvironmentOverviewProjectSortOptions } from "./DynamicEnvironmentOverview";
import { defaultDynamicEnvironmentOverviewAssociatedProjectsFilter, DynamicEnvironmentOverview } from "./DynamicEnvironmentOverview";

const defaultTake = 10;

function getSortField(sortOrder: DynamicEnvironmentOverviewProjectSortOptions): GetDynamicEnvironmentOverviewRequestV1SortField {
    switch (sortOrder) {
        case "latestActivityAsc":
        case "latestActivityDesc":
            return GetDynamicEnvironmentOverviewRequestV1SortField.LatestActivity;

        case "projectNameAsc":
        case "projectNameDesc":
            return GetDynamicEnvironmentOverviewRequestV1SortField.ProjectName;
        case "deprovisioningOrder":
            return GetDynamicEnvironmentOverviewRequestV1SortField.DeprovisioningOrder;
        default:
            exhaustiveCheck(sortOrder, "Unhandled sort option");
    }
}

function getSortOrder(sortOrder: DynamicEnvironmentOverviewProjectSortOptions): GetDynamicEnvironmentOverviewRequestV1SortOrder {
    switch (sortOrder) {
        case "latestActivityAsc":
        case "projectNameAsc":
        case "deprovisioningOrder":
            return GetDynamicEnvironmentOverviewRequestV1SortOrder.Ascending;

        case "latestActivityDesc":
        case "projectNameDesc":
            return GetDynamicEnvironmentOverviewRequestV1SortOrder.Descending;

        default:
            exhaustiveCheck(sortOrder, "Unhandled sort option");
    }
}

type DynamicEnvironmentLayoutRouteParams = {
    environmentId: string;
};

type LayoutProps = {
    environmentId: string;
    errors: Errors | undefined;
    doBusyTask: DoBusyTask;
    busy?: Promise<void>;
};

type InternalLayoutProps = {
    errors: Errors | undefined;
    navLinks: NavItem[];
    renderContent: React.ReactElement | null;
};

function Layout({ errors, navLinks, renderContent }: InternalLayoutProps) {
    return (
        <main id="maincontent">
            <AreaTitle breadcrumbTitle="Infrastructure" breadcrumbPath={routeLinks.infrastructure.root} title={"Dynamic Environments"} link={routeLinks.infrastructure.dynamicEnvironments.root()} />
            {errors ? (
                <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink} statusCode={errors.statusCode} />
            ) : (
                <NavigationSidebarLayout navLinks={navLinks} content={renderContent} />
            )}
        </main>
    );
}

function DynamicEnvironmentLayoutInternal({ environmentId, doBusyTask, busy, errors }: LayoutProps) {
    const [environment, setEnvironment] = useState<GetDynamicEnvironmentOverviewResponseV1 | undefined>(undefined);
    const [sortOrder, setSortOrder] = useState<DynamicEnvironmentOverviewProjectSortOptions>("latestActivityDesc");
    const [filter, setFilter] = useState<DynamicEnvironmentOverviewAssociatedProjectsFilter>(defaultDynamicEnvironmentOverviewAssociatedProjectsFilter);
    const [take, setTake] = useState<number>(defaultTake);
    const navigation = useSpaceAwareNavigation();
    const refresh = useDoBusyTaskEffect(
        doBusyTask,
        async () => {
            const environment = await repository.DynamicEnvironments.getDynamicEnvironmentOverview({
                environmentId: environmentId,
                take: take,
                skip: 0,
                sortOrder: getSortOrder(sortOrder),
                sortField: getSortField(sortOrder),
                projectName: filter.projectName,
                projectState: filter.projectState,
            });

            setEnvironment(environment);
            if (environment.EnvironmentState !== "Active" && (sortOrder === "latestActivityAsc" || sortOrder === "latestActivityDesc")) {
                setSortOrder("deprovisioningOrder");
            }
        },
        [environmentId, take, filter, sortOrder]
    );

    useRefreshLoop(refresh, 6000);

    async function onDeprovisionProject(environmentId: string, projectId: string) {
        await repository.DynamicEnvironments.deprovisionProject(environmentId, projectId);
        await refresh();
    }

    async function onDeprovisionEnvironment(environmentId: string) {
        await repository.DynamicEnvironments.deprovision(environmentId);
        await refresh();
    }

    async function onDeleteEnvironment(environmentId: string) {
        await repository.DynamicEnvironments.del(environmentId);
        navigation.navigate(routeLinks.infrastructure.dynamicEnvironments.root(true));
    }

    function onLoadMore() {
        setTake(take + defaultTake);
    }

    return (
        <Layout
            navLinks={compact([
                Navigation.navItem("Overview", routeLinks.infrastructure.dynamicEnvironment(environmentId).overview, undefined, {
                    permission: Permission.EnvironmentView,
                }),
            ])}
            errors={errors}
            renderContent={
                <DynamicEnvironmentOverview
                    environment={environment}
                    busy={busy}
                    sortOrder={sortOrder}
                    filter={filter}
                    onSortOrderChange={(sortOrder) => setSortOrder(sortOrder)}
                    onFilterChange={(filter) => setFilter(filter)}
                    onDeprovisionEnvironment={onDeprovisionEnvironment}
                    onDeleteEnvironment={onDeleteEnvironment}
                    onDeprovisionProject={onDeprovisionProject}
                    onLoadMore={onLoadMore}
                />
            }
        ></Layout>
    );
}

export class DynamicEnvironmentLayout extends DataBaseComponent<RouteComponentProps<DynamicEnvironmentLayoutRouteParams>> {
    constructor(props: RouteComponentProps<DynamicEnvironmentLayoutRouteParams>) {
        super(props);
        this.state = {};
    }

    render() {
        return <DynamicEnvironmentLayoutInternal environmentId={this.props.match.params.environmentId} doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors} />;
    }
}
