export type DynamicEnvironmentSelection = {
    type: "Dynamic";
    environmentId: string;
    name: string;
};

export type StaticEnvironmentSelection = {
    type: "Static";
    environmentId: string;
    name: string;
};

export type NewEnvironmentSelection = {
    type: "New";
    name: string;
};

export type EnvironmentSelection = StaticEnvironmentSelection | DynamicEnvironmentSelection | NewEnvironmentSelection;

export function getStaticEnvironments(environments: EnvironmentSelection[]) {
    return environments.filter<StaticEnvironmentSelection>(isEnvironmentSelectionStatic);
}

export function getDynamicEnvironments(environments: EnvironmentSelection[]) {
    return environments.filter<DynamicEnvironmentSelection>(isEnvironmentSelectionDynamic);
}

export function getNewEnvironments(environments: EnvironmentSelection[]) {
    return environments.filter<NewEnvironmentSelection>(isEnvironmentSelectionNew);
}

export function getEnvironmentIds(environments: EnvironmentSelection[]) {
    const staticEnvironments = getStaticEnvironments(environments);
    const dynamicEnvironments = getDynamicEnvironments(environments);

    return staticEnvironments.map((e) => e.environmentId).concat(dynamicEnvironments.map((e) => e.environmentId));
}

export function isEnvironmentSelectionStatic(environment: EnvironmentSelection): environment is StaticEnvironmentSelection {
    return environment.type === "Static";
}

export function isEnvironmentSelectionDynamic(environment: EnvironmentSelection): environment is DynamicEnvironmentSelection {
    return environment.type === "Dynamic";
}

export function isEnvironmentSelectionNew(environment: EnvironmentSelection): environment is NewEnvironmentSelection {
    return environment.type === "New";
}
