import type { FeedResource } from "@octopusdeploy/octopus-server-client";
import { FeedType } from "@octopusdeploy/octopus-server-client";
import type { OctopusServerEnricherMappedReturnType, OctopusServerEnricherSupportedRuntimeTypes, RuntimeContainerImageSelection, RuntimePackageSelection } from "@octopusdeploy/step-runtime-inputs";
import { useFeedsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import isBound from "~/components/form/BoundField/isBound";

const isPackageSelection = (data: OctopusServerEnricherSupportedRuntimeTypes): data is RuntimePackageSelection => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return "packageId" in (data as RuntimePackageSelection);
};

const isContainerImageSelection = (data: OctopusServerEnricherSupportedRuntimeTypes): data is RuntimeContainerImageSelection => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return "imageName" in (data as RuntimePackageSelection);
};

interface EnricherFactoryProps {
    feeds: FeedResource[];
}

export const enricherFactory = ({ feeds }: EnricherFactoryProps) => {
    const enrich = <T extends OctopusServerEnricherSupportedRuntimeTypes>(data: T): OctopusServerEnricherMappedReturnType<T> => {
        if (isPackageSelection(data)) {
            const feed = feeds.find((f) => f.Id === data.feedId);
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const enrichedData = {
                packageName: data.packageId, // find package name by package id?
                referenceId: data.referenceId,
                packageExtractionPathExpression: (name) => `#{Octopus.Action.Package[${name}].ExtractedPath}`,
            } as OctopusServerEnricherMappedReturnType<T>;
            if (!isBound(data.feedId ?? "") && feed !== undefined) {
                enrichedData.feed = {
                    id: feed.Id,
                    name: feed.Name,
                };
            } else {
                const feedId = data.feedId ?? "";
                enrichedData.feed = {
                    id: isBound(feedId) ? feedId : feed?.Id ?? "",
                    name: isBound(feedId) ? feedId : feed?.Name ?? "",
                };
            }

            return enrichedData;
        }
        if (isContainerImageSelection(data)) {
            const feed = feeds.find((f) => f.Id === data.feedId);
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const enrichedData = {
                imageName: data.imageName,
                referenceId: data.referenceId,
                imagePathExpression: (name) => `#{Octopus.Action.Package[${name}].Image}`,
            } as OctopusServerEnricherMappedReturnType<T>;

            if (!isBound(data.feedId ?? "") && feed?.FeedType === FeedType.Docker) {
                enrichedData.feed = {
                    id: feed.Id,
                    name: feed.Name,
                    url: feed.FeedUri,
                };
            } else {
                const feedId = data.feedId ?? "";
                enrichedData.feed = {
                    id: isBound(feedId) ? feedId ?? "" : feed?.Id ?? "",
                    name: isBound(feedId) ? feedId : feed?.Name ?? "",
                    url: "",
                };
            }

            return enrichedData;
        }

        // We might want to think more about the case where package type cannot be inferred,
        // as current implementation will break input conversion functions
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return null as unknown as OctopusServerEnricherMappedReturnType<T>;
    };

    return {
        enrich,
    };
};

export const useStepDataEnricher = function () {
    const feeds = useFeedsFromContext();
    const enricher = enricherFactory({ feeds });

    return enricher;
};
