import cn from "classnames";
import * as React from "react";
import { useEffect, useState } from "react";
import ActionButton, { ActionButtonType, IconPosition } from "~/components/Button/ActionButton";
import { CustomDialog } from "~/components/Dialog/CustomDialog";
import CustomDialogContent from "~/components/DialogLayout/Custom/CustomDialogContent";
import { CustomDialogActions, FrameWrapper } from "~/components/DialogLayout/Custom/index";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import successfulDeploymentImage from "./assets/img-successfuldeployment.svg";
import styles from "./styles.module.less";

interface SuccessfulDeploymentDialogProps {
    open: boolean;
    close: () => void;
}

const ExternalLinkIcon: JSX.Element = <em className={cn("fa-solid", "fa-external-link", styles.externalLinkIcon)} aria-hidden="true" />;

const SuccessfulDeploymentDialog = ({ open, close }: SuccessfulDeploymentDialogProps) => {
    const [showDialog, setShowDialog] = useState(false);
    useEffect(() => {
        setTimeout(() => setShowDialog(open), 3000);
    }, [open]);
    return (
        <CustomDialog
            close={close}
            open={showDialog}
            render={(renderProps) => (
                <FrameWrapper>
                    <div className={cn(styles.successfulDeploymentDialog)}>
                        <div className={styles.successfulDeploymentDialogContent}>
                            <p className={styles.successfulDeploymentDialogTitle}>Congratulations</p>
                            <CustomDialogContent className={styles.successfulDeploymentDialogText}>
                                <p>You've successfully completed your first deployment.</p>
                                <p>If you have a minute, tell us about your deployment experience and we'll mail you a pack of laptop stickers as a thank you.</p>
                                <p>Happy deployments!</p>
                                <ExternalLink href="OnboardingFeedbackForm" showIcon={false}>
                                    <ActionButton label={"FEEDBACK FORM"} type={ActionButtonType.Secondary} icon={ExternalLinkIcon} iconPosition={IconPosition.Right} />
                                </ExternalLink>
                            </CustomDialogContent>
                            <CustomDialogActions actions={<ActionButton key="close" onClick={close} label={"Close"} type={ActionButtonType.Primary} />} />
                        </div>

                        <img src={successfulDeploymentImage} className={cn(styles.successfulDeploymentDialogImage)} alt={"Successful Deployment"} />
                    </div>
                </FrameWrapper>
            )}
        />
    );
};

export default SuccessfulDeploymentDialog;
