/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type {
    ChannelResource,
    EnvironmentResource,
    IconMetadataResource,
    IconSvgResource,
    ProjectGroupResource,
    ProjectResource,
    ServerTimezoneResource,
    TagSetResource,
    TenantResource,
    NamedResource,
    InsightsEnvironmentGroup,
    InsightsReportResource,
} from "@octopusdeploy/octopus-server-client";
import { Permission, InsightsReportTenantMode } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { useSelector } from "react-redux";
import type { AnalyticInsightsDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticInsightsDispatch } from "~/analytics/Analytics";
import IconAndLogoEditLayout, { LogoTypeSelection } from "~/areas/infrastructure/components/IconAndLogoEditLayout/IconAndLogoEditLayout";
import { repository } from "~/clientInstance";
import { AdvancedTenantsAndTenantTagsSelector } from "~/components/AdvancedTenantSelector";
import { ActionButton, ActionButtonType } from "~/components/Button";
import { environmentChipList, projectChipList, projectGroupChipList, channelChipList } from "~/components/Chips";
import FormBaseComponent from "~/components/FormBaseComponent";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import FormPaperLayout from "~/components/FormPaperLayout";
import { ChannelMultiSelect } from "~/components/MultiSelect/ChannelMultiSelect";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
import { ProjectGroupMultiSelect } from "~/components/MultiSelect/ProjectGroupMultiSelect";
import { ProjectMultiSelect } from "~/components/MultiSelect/ProjectMultiSelect";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { PermissionCheck } from "~/components/PermissionCheck";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import RemovableExpandersList from "~/components/RemovableExpandersList";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import type { LogoEditorSettings, SummaryNode } from "~/components/form";
import { Select, RadioButtonGroup, Note, RadioButton, LogoEditor, UnstructuredFormSection, FormSectionHeading, MarkdownEditor } from "~/components/form";
import type { IconEditorSettings } from "~/components/form/IconEditor/IconEditor";
import IconEditor, { IconEditorDefaultColor } from "~/components/form/IconEditor/IconEditor";
import timezoneSummary from "~/components/timezoneSummary";
import routeLinks from "~/routeLinks";
import CommonSummaryHelper from "~/utils/CommonSummaryHelper";
import Logo from "../../../../components/Logo/Logo";
import { ExpandableFormSection, Summary } from "../../../../components/form";
import { required } from "../../../../components/form/Validators";
import Text from "../../../../primitiveComponents/form/Text/Text";

interface InsightsReportModel extends InsightsReportResource {
    logo: LogoEditorSettings;
    icon: IconEditorSettings;
}

interface ReportSettingsInnerState extends FormBaseComponentState<InsightsReportModel> {
    deleted: boolean;
    logoTypeSelection: LogoTypeSelection;
}

interface InitialData {
    report: InsightsReportResource;
    projects: ProjectResource[];
    channels: ChannelResource[];
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    tagSets: TagSetResource[];
    timezones: ServerTimezoneResource[];
    iconSvgResources: IconSvgResource[];
    iconMetadata: IconMetadataResource;
    projectGroups: ProjectGroupResource[];
}

interface ReportSettingsProps {
    report: InsightsReportResource;
    refreshReport: () => Promise<void>;
}

interface ReportSettingsInnerProps {
    initialData: InitialData;
    refreshReport: () => Promise<void>;
    dispatchAction: AnalyticInsightsDispatcher;
    isInsightsExperimentalEnabled: boolean;
}

const InsightsReportSettingsFormPage = FormPage<InitialData>();
const title = "Settings";

export function ReportSettings({ report, refreshReport }: ReportSettingsProps) {
    const dispatchAction = useAnalyticInsightsDispatch();
    const isInsightsExperimentalEnabled = useSelector((state: GlobalState) => state.configurationArea.features.isInsightsEnabled);

    return (
        <InsightsReportSettingsFormPage
            title={title}
            load={async () => {
                const projectsRequest = repository.Projects.all();
                const channelsRequest = repository.Channels.all();
                const environmentsRequest = repository.Environments.all();
                const tenantsRequest = isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all() : Promise.resolve([]);
                const tagSetsRequest = repository.TagSets.all();
                const timezonesRequest = repository.ServerStatus.getTimezones();
                const projectGroupsRequest = repository.ProjectGroups.all();
                const iconsRequest = repository.Icons.getIcons();
                const iconMetadataRequest = repository.Icons.getIconMetadata();

                return {
                    report,
                    projects: await projectsRequest,
                    channels: await channelsRequest,
                    environments: await environmentsRequest,
                    tenants: await tenantsRequest,
                    tagSets: await tagSetsRequest,
                    timezones: await timezonesRequest,
                    iconSvgResources: await iconsRequest,
                    iconMetadata: await iconMetadataRequest,
                    projectGroups: await projectGroupsRequest,
                };
            }}
            renderWhenLoaded={(initialData) => <ReportSettingsInner initialData={initialData} refreshReport={refreshReport} dispatchAction={dispatchAction} isInsightsExperimentalEnabled={isInsightsExperimentalEnabled} />}
        />
    );
}

class ReportSettingsInner extends FormBaseComponent<ReportSettingsInnerProps, ReportSettingsInnerState, InsightsReportModel> {
    constructor(props: ReportSettingsInnerProps) {
        super(props);

        const model = this.buildModel(this.props.initialData.report);

        this.state = {
            model,
            cleanModel: model,
            deleted: false,
            logoTypeSelection: LogoTypeSelection.NotSet,
        };
    }

    buildModel(report: InsightsReportResource): InsightsReportModel {
        return {
            ...report,
            logo: { file: undefined, reset: false },
            icon: {
                iconId: report.IconId ?? "",
                iconColor: report.IconColor ?? IconEditorDefaultColor,
            },
        };
    }

    handleSaveClick = async () => {
        const { model } = this.state;
        const { refreshReport } = this.props;

        await this.doBusyTask(async () => {
            const isUntenanted = this.state.model.TenantMode === InsightsReportTenantMode.Untenanted;

            const newModel = {
                ...model,
                TenantIds: isUntenanted ? [] : model.TenantIds,
                TenantTags: isUntenanted ? [] : model.TenantTags,
            };

            if (this.state.logoTypeSelection === LogoTypeSelection.Icon) {
                await repository.Logos.saveIcon(model, this.state.model!.icon.iconId, this.state.model!.icon.iconColor);
            } else if (this.state.logoTypeSelection === LogoTypeSelection.CustomImage) {
                await repository.Logos.saveLogo(model, this.state.model!.logo.file!, this.state.model!.logo.reset);
            }

            const result = await repository.InsightsReports.save(newModel);

            // Send Amplitude event
            this.props.dispatchAction("Save Insights Report Settings", { action: Action.Save });

            // Refresh report at top level
            refreshReport();

            this.setState({
                model: this.buildModel(result),
                cleanModel: this.buildModel(result),
            });
        });
    };

    logoSummary(): SummaryNode {
        if (!this.state.model || this.state.model?.logo.reset) {
            return Summary.placeholder("Default logo");
        }

        if (this.state.model?.logo.file) {
            return Summary.summary(this.state.model.logo.file.name);
        }

        return Summary.summary(<Logo url={this.state.model.Links.Logo} size="2.5em" />);
    }

    projectSummary(): SummaryNode {
        const {
            initialData: { projects },
        } = this.props;

        return this.state.model.ProjectIds.length ? Summary.summary(projectChipList(projects, this.state.model.ProjectIds)) : Summary.placeholder("No projects selected");
    }

    projectGroupSummary(): SummaryNode {
        const {
            initialData: { projectGroups },
        } = this.props;

        return this.state.model.ProjectGroupIds.length ? Summary.summary(projectGroupChipList(projectGroups, this.state.model.ProjectGroupIds)) : Summary.placeholder("No project groups selected");
    }

    channelSummary(): SummaryNode {
        const {
            initialData: { channels, projects },
        } = this.props;

        const channelsWithProjectName = this.getChannelsIncludingProjectName(channels, projects);

        return this.state.model.ChannelIds.length ? Summary.summary(channelChipList(channelsWithProjectName, this.state.model.ChannelIds)) : Summary.placeholder("No channels selected");
    }

    getChannelsIncludingProjectName(channels: ChannelResource[], projects: ProjectResource[]): NamedResource[] {
        const getUpdatedName = (channel: ChannelResource) => {
            const projectName = projects.find((p) => p.Id === channel.ProjectId)?.Name;
            return `${projectName} - ${channel.Name}`;
        };

        return channels.map((c) => ({ Id: c.Id, Name: getUpdatedName(c), Links: {} }));
    }

    environmentGroupSummary(group: InsightsEnvironmentGroup): SummaryNode {
        const {
            initialData: { environments },
        } = this.props;

        return group.Environments.length
            ? Summary.summary(
                  <>
                      <span style={{ fontWeight: 700 }}>{group.Name}: </span>
                      {environmentChipList(environments, group.Environments)}
                  </>
              )
            : Summary.placeholder("No selection");
    }

    private handleAddEnvironmentClick = () => {
        const environmentGroup: InsightsEnvironmentGroup = {
            Id: "",
            Name: "",
            Environments: [],
        };
        this.setModelState({ EnvironmentGroups: [...this.state.model!.EnvironmentGroups, environmentGroup] });
    };

    private handleEnvironmentNameChange = (name: string, index: number) => {
        this.setState((state) => {
            const environments = [...state.model!.EnvironmentGroups];
            environments[index] = { ...environments[index], Name: name };
            return {
                model: {
                    ...state.model,
                    EnvironmentGroups: environments,
                },
            };
        });
    };

    private handleEnvironmentSelectionsChange = (newEnvironments: string[], index: number) => {
        this.setState((state) => {
            const environments = [...state.model.EnvironmentGroups];
            environments[index] = { ...environments[index], Environments: newEnvironments };

            return {
                model: {
                    ...state.model,
                    EnvironmentGroups: environments,
                },
            };
        });
    };

    private handleEnvironmentDeleteByIndex = (index: number) => {
        this.setState((state) => {
            const environments = state.model!.EnvironmentGroups.filter((_, i) => i !== index);
            return {
                model: {
                    ...state.model,
                    EnvironmentGroups: environments,
                },
            };
        });
    };

    private handleAddProjectGroup = (projectGroups: string[]) => this.setModelState({ ProjectGroupIds: projectGroups });

    private handleAddProject = (projects: string[]) => this.setModelState({ ProjectIds: projects });

    private handleAddChannel = (channels: string[]) => this.setModelState({ ChannelIds: channels });

    renderEnvironmentGroup = (environmentGroup: InsightsEnvironmentGroup, index: number) => {
        const {
            initialData: { environments },
        } = this.props;

        const selectedEnvironments = this.state.model.EnvironmentGroups.flatMap((g) => g.Environments);
        const remainingEnvironments = this.props.initialData.environments.filter((e) => !selectedEnvironments.includes(e.Id));

        return (
            <div>
                <Text value={this.state.model.EnvironmentGroups[index].Name} onChange={(name) => this.handleEnvironmentNameChange(name, index)} label="Environment group name" validate={required("Please enter an environment group name")} />
                <EnvironmentMultiSelect
                    environments={remainingEnvironments}
                    lookupCollection={environments}
                    value={this.state.model.EnvironmentGroups[index].Environments}
                    onChange={(environments) => this.handleEnvironmentSelectionsChange(environments, index)}
                />
            </div>
        );
    };

    renderEnvironmentHelp = () => <div>Create groups of environments that have a shared purpose. E.g. "Production"</div>;

    private handleDeleteConfirm = async () => {
        await repository.InsightsReports.del(this.state.model);

        this.setState(() => {
            return {
                model: undefined,
                cleanModel: undefined,
                deleted: true,
            };
        });

        return true;
    };

    private tenantParticipationSummary(): SummaryNode {
        const { TenantIds, TenantTags, TenantMode } = this.state.model;

        const hasTags = TenantIds.length > 0;
        const hasTenants = TenantTags.length > 0;
        const hasTagOrTenant = hasTags || hasTenants;

        switch (TenantMode) {
            case InsightsReportTenantMode.Untenanted:
                return Summary.default(<span>Only including untenanted deployments</span>);

            case InsightsReportTenantMode.TenantedAndUntenanted:
                return hasTagOrTenant
                    ? Summary.summary(<span>Including untenanted deployments, and deployments to the associated tenants</span>)
                    : Summary.summary(
                          <span>
                              <strong>Only including untenanted deployments</strong> until you choose some tenants
                          </span>
                      );

            case InsightsReportTenantMode.Tenanted:
                return hasTagOrTenant
                    ? Summary.summary(<span>Only including deployments to the associated tenants</span>)
                    : Summary.summary(
                          <span>
                              <strong>Not including any deployments</strong> until you choose some tenants
                          </span>
                      );
        }
    }

    private tenantSummary() {
        return CommonSummaryHelper.tenantSummary(this.state.model.TenantIds, this.state.model.TenantTags, this.props.initialData.tenants);
    }

    render() {
        if (this.state.deleted) {
            return <InternalRedirect to={routeLinks.insights.reports} />;
        }

        const {
            initialData: { projectGroups, projects, channels, tenants },
            isInsightsExperimentalEnabled,
        } = this.props;

        const environmentActions = [<ActionButton type={ActionButtonType.Secondary} onClick={this.handleAddEnvironmentClick} label="Add" />];
        const overFlowActions = [OverflowMenuItems.deleteItemDefault("report", this.handleDeleteConfirm, { permission: Permission.InsightsReportDelete })];

        const channelsWithProjectName = this.getChannelsIncludingProjectName(channels, projects);

        return (
            <FormPaperLayout
                title={title}
                busy={this.state.busy}
                errors={this.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                onSaveClick={this.handleSaveClick}
                overFlowActions={overFlowActions}
                savePermission={this.editPermission()}
                saveText="Report details updated"
            >
                <TransitionAnimation>
                    <ExpandableFormSection errorKey="logo" title="Logo" summary={this.logoSummary()} help="Choose an icon or upload a custom image.">
                        <IconAndLogoEditLayout
                            iconEditor={
                                <IconEditor
                                    icons={this.props.initialData.iconSvgResources}
                                    iconMetadata={this.props.initialData.iconMetadata}
                                    selectedIconId={this.state.model.icon?.iconId}
                                    selectedIconColor={this.state.model.icon?.iconColor}
                                    onIconIdChange={(iconId) => {
                                        this.setState({ logoTypeSelection: LogoTypeSelection.Icon });
                                        this.setModelState({ icon: { iconId, iconColor: this.state.model?.icon?.iconColor ?? IconEditorDefaultColor }, logo: { file: undefined, reset: false } });
                                    }}
                                    onIconColorChange={(iconColor) => {
                                        this.setState({ logoTypeSelection: LogoTypeSelection.Icon });
                                        this.setModelState({ icon: { iconId: this.state.model?.icon?.iconId ?? "", iconColor } });
                                    }}
                                />
                            }
                            logoEditor={
                                <LogoEditor
                                    value={this.state.model.logo}
                                    onChange={(logo) => {
                                        this.setState({ logoTypeSelection: LogoTypeSelection.CustomImage });
                                        this.setModelState({ logo, icon: { iconId: "", iconColor: IconEditorDefaultColor } });
                                    }}
                                />
                            }
                            onTabChange={(logoType) => this.setState({ logoTypeSelection: logoType })}
                        />
                    </ExpandableFormSection>
                    <ExpandableFormSection
                        errorKey="name"
                        title="Name"
                        focusOnExpandAll
                        summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your report")}
                        help="Enter a name for your report."
                    >
                        <Text value={this.state.model.Name} onChange={(name) => this.setModelState({ Name: name })} label="Report name" validate={required("Please enter a report name")} autoFocus />
                    </ExpandableFormSection>
                    <ExpandableFormSection
                        errorKey="description"
                        title="Description"
                        summary={this.state.model.Description ? Summary.summary(this.state.model.Description) : Summary.placeholder("No description provided")}
                        help="Enter a description for your report."
                    >
                        <MarkdownEditor value={this.state.model.Description} label="Report description" onChange={(description) => this.setModelState({ Description: description })} />
                    </ExpandableFormSection>
                    <ExpandableFormSection errorKey="Timezone" title="Timezone" help="Select timezone for the report" summary={timezoneSummary(this.props.initialData.timezones, this.state.model.TimeZone)}>
                        <Select
                            value={this.state.model.TimeZone}
                            allowClear={true}
                            onChange={(TimeZone) => {
                                if (TimeZone) {
                                    this.setModelState({ TimeZone });
                                }
                            }}
                            items={this.props.initialData.timezones.map((pg) => ({ value: pg.Id, text: pg.Name }))}
                            label="Select timezone"
                        />
                    </ExpandableFormSection>
                    {isInsightsExperimentalEnabled ? (
                        <>
                            <FormSectionHeading title="Projects and Channels" />
                            <UnstructuredFormSection>Select projects and channels to include in this report. A selected project's default channel will be used if one is not explicitly chosen.</UnstructuredFormSection>
                        </>
                    ) : (
                        <>
                            <FormSectionHeading title="Projects" />
                            <UnstructuredFormSection>Select projects to include in this report.</UnstructuredFormSection>
                        </>
                    )}
                    <ExpandableFormSection title="Project groups" errorKey="projectGroups" summary={this.projectGroupSummary()} isExpandedByDefault>
                        <ProjectGroupMultiSelect items={projectGroups} value={this.state.model.ProjectGroupIds} onChange={this.handleAddProjectGroup} />
                    </ExpandableFormSection>
                    <ExpandableFormSection title="And/or projects" errorKey="projects" summary={this.projectSummary()} isExpandedByDefault>
                        <ProjectMultiSelect items={projects} value={this.state.model.ProjectIds} onChange={this.handleAddProject} />
                    </ExpandableFormSection>
                    <Note style={{ padding: "1rem" }}>
                        Only{" "}
                        <ExternalLink href="InsightsReportSettings" showIcon={false}>
                            data from the default channel
                        </ExternalLink>{" "}
                        for each project is included. This is to avoid pre-release and prior-version channels from skewing the data.
                    </Note>
                    {isInsightsExperimentalEnabled && (
                        <>
                            <ExpandableFormSection title="And/or channels" errorKey="channels" summary={this.channelSummary()} isExpandedByDefault>
                                <ChannelMultiSelect items={channelsWithProjectName} value={this.state.model.ChannelIds} onChange={this.handleAddChannel} />
                            </ExpandableFormSection>
                        </>
                    )}
                    <FormSectionHeading title="Environments" />
                    <RemovableExpandersList<InsightsEnvironmentGroup>
                        helpElement={<>Create groups of environments that have a shared purpose. E.g. "Production"</>}
                        typeDisplayName="Environment Group"
                        data={this.state.model.EnvironmentGroups}
                        listActions={environmentActions}
                        onRow={this.renderEnvironmentGroup}
                        onRowSummary={(group: InsightsEnvironmentGroup) => this.environmentGroupSummary(group)}
                        onRowHelp={() => "Select environments for this group"}
                        onRemoveRowByIndex={this.handleEnvironmentDeleteByIndex}
                    />
                    {tenants.length > 0 && (
                        <PermissionCheck permission={Permission.TenantView} tenant="*">
                            <FormSectionHeading title="Tenants" />
                            <ExpandableFormSection errorKey="TenantMode" title="Tenant Participation" summary={this.tenantParticipationSummary()} help="Choose which tenants contribute to this report's data." isExpandedByDefault>
                                <TenantParticipationSelector tenantMode={this.state.model.TenantMode} onChange={(mode) => this.setModelState({ TenantMode: mode })} />
                            </ExpandableFormSection>
                            {this.state.model.TenantMode !== InsightsReportTenantMode.Untenanted && (
                                <ExpandableFormSection errorKey="Tenants" title="Associated Tenants" summary={this.tenantSummary()} help="Choose tenants this report should be associated with." isExpandedByDefault>
                                    <AdvancedTenantsAndTenantTagsSelector
                                        tenants={this.props.initialData.tenants}
                                        selectedTenantIds={this.state.model.TenantIds}
                                        selectedTenantTags={this.state.model.TenantTags}
                                        doBusyTask={this.doBusyTask}
                                        onChange={(TenantIds, TenantTags) => this.setModelState({ TenantIds, TenantTags })}
                                        showPreviewButton={true}
                                    />
                                </ExpandableFormSection>
                            )}
                        </PermissionCheck>
                    )}
                </TransitionAnimation>
            </FormPaperLayout>
        );
    }

    private editPermission(): PermissionCheckProps {
        return {
            permission: Permission.InsightsReportEdit,
        };
    }
}

interface TenantParticipationSelectorProps {
    tenantMode: InsightsReportTenantMode;
    onChange: (mode: InsightsReportTenantMode) => void;
}

function TenantParticipationSelector({ tenantMode, onChange }: TenantParticipationSelectorProps) {
    return (
        <RadioButtonGroup accessibleName="Which tenants contribute to this report's data" value={tenantMode} onChange={(e) => onChange(e)}>
            <RadioButton value={InsightsReportTenantMode.Untenanted} isDefault={true} label="Exclude tenanted deployments" />
            <Note>The report data will not include tenanted deployments.</Note>
            <RadioButton value={InsightsReportTenantMode.Tenanted} label="Only include tenanted deployments" />
            <Note>The report will only include deployments to the associated tenants. It will exclude untenanted deployments.</Note>
            <RadioButton value={InsightsReportTenantMode.TenantedAndUntenanted} label="Include both tenanted and untenanted deployments" />
            <Note>The report will include untenanted deployments, and deployments to the associated tenants.</Note>
        </RadioButtonGroup>
    );
}
