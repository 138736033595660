import type { BranchSpecifier } from "@octopusdeploy/octopus-server-client";

export function getURISafeBranchSpecifier(branch: BranchSpecifier): BranchSpecifier {
    // Checking for ":" because the template is ":branchName", we don't want to encode that.
    if (typeof branch === "string" && branch[0] !== ":") {
        return encodeURIComponent(branch);
    }

    return branch;
}
