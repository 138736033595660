/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */

import type { ProjectResource, LibraryVariableSetResource } from "@octopusdeploy/octopus-server-client";
import { VariableSetContentType } from "@octopusdeploy/octopus-server-client";
import { List as MaterialList } from "material-ui";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import VariableSetSectionHeading from "~/areas/variables/VariableSetSectionHeading/VariableDisplayerSectionHeading";
import { repository } from "~/clientInstance";
import ActionTemplateParameterResourceSummary from "~/components/ActionTemplateParametersList/ActionTemplateParameterResourceSummary";
import BorderedListItem from "~/components/BorderedListItem";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import type { GroupedExpandableProps } from "~/components/GroupedExpandable";
import { default as GroupedExpandable } from "~/components/GroupedExpandable";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import { Section } from "~/components/Section/Section";
import SectionNote from "~/components/SectionNote/SectionNote";
import ExpansionButtons from "~/components/form/Sections/ExpansionButtons";
import Divider from "~/primitiveComponents/dataDisplay/Divider/Divider";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import routeLinks from "../../../../../routeLinks";

interface CommonVariableTemplatesState extends DataBaseComponentState {
    project?: ProjectResource;
    libraryVariableSets?: ReadonlyArray<LibraryVariableSetResource>;
}

type Props = RouteComponentProps<ProjectRouteParams> & GroupedExpandableProps;

class CommonVariableTemplates extends DataBaseComponent<Props, CommonVariableTemplatesState> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        await this.doBusyTask(
            async () => {
                const project = await repository.Projects.get(this.props.match.params.projectSlug);
                const libraryVariableSets = (
                    await repository.LibraryVariableSets.all({
                        ids: project.IncludedLibraryVariableSetIds,
                        contentType: VariableSetContentType.Variables,
                    })
                ).filter((libraryVariable) => libraryVariable.Templates.length > 0);

                this.setState({
                    project,
                    libraryVariableSets,
                });
                this.props.registerAllExpanders(libraryVariableSets.map((v) => v.Id));
            },
            { timeOperationOptions: timeOperationOptions.forInitialLoad() }
        );
    }

    render() {
        return (
            <PaperLayout title="Common Variable Templates" breadcrumbTitle={this.state.project?.Name} busy={this.state.busy} errors={this.errors}>
                <SectionNote>
                    Variable templates define which variable values are required for each tenant connected to this project. They are defined in library variable sets and allow tenants to provide a common variable value that is shared across projects
                    and environments. Learn more about <ExternalLink href="MultiTenantVariables">tenant-specific variables</ExternalLink>.
                </SectionNote>
                {this.state.libraryVariableSets && this.state.project && this.state.libraryVariableSets.length > 0 && (
                    <Section>
                        To change the common variable templates required by this project <InternalLink to={routeLinks.project(this.state.project).variables.library}>choose different library variable sets</InternalLink>.
                    </Section>
                )}
                <ExpansionButtons />
                {this.state.libraryVariableSets &&
                    this.state.libraryVariableSets.map((lvs, index) => {
                        return (
                            <div key={lvs.Id}>
                                <Divider fullHeight={true} isDarkColor={true} />
                                <VariableSetSectionHeading
                                    variableSetId={lvs.Id}
                                    variableSetName={lvs.Name}
                                    isExpanded={this.props.getExpanderValueForKey(lvs.Id)!}
                                    onExpandedChanged={(expanded) => this.props.onExpandedChanged(lvs.Id, expanded)}
                                    variableSetTab="variableTemplates"
                                >
                                    <MaterialList>
                                        {lvs.Templates.map((template, templateIndex) => {
                                            return (
                                                <BorderedListItem key={templateIndex} disabled={true}>
                                                    <ActionTemplateParameterResourceSummary parameter={template} />
                                                </BorderedListItem>
                                            );
                                        })}
                                    </MaterialList>
                                </VariableSetSectionHeading>
                            </div>
                        );
                    })}
                <Divider fullHeight={true} isDarkColor={true} />
            </PaperLayout>
        );
    }
}

export default GroupedExpandable("common_variable_templates", CommonVariableTemplates);
