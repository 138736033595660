/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { ServerTimezoneResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { ExpandableFormSection, Select, Note } from "~/components/form";
import timezoneSummary from "~/components/timezoneSummary";

interface ScheduleTimezoneProps {
    timezones: ServerTimezoneResource[];
    timezone: string;
    onTimezoneChanged(timezone: string): void;
}

export default class ScheduleTimezone extends React.Component<ScheduleTimezoneProps> {
    render() {
        return (
            <div>
                <ExpandableFormSection errorKey="ScheduleTimezones" title="Schedule Timezone" summary={timezoneSummary(this.props.timezones, this.props.timezone)} help="Select the timezone used for the schedule.">
                    <Select value={this.props.timezone} allowClear={true} onChange={(timezone) => this.props.onTimezoneChanged(timezone!)} items={this.props.timezones.map((pg) => ({ value: pg.Id, text: pg.Name }))} label="Select timezone" />
                    <Note>Schedule run times will be calculated using this timezone.</Note>
                </ExpandableFormSection>
            </div>
        );
    }
}
