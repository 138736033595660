import type { TaskResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import ActionButton from "~/components/Button";
import InternalLink from "~/components/Navigation/InternalLink";
import { useOctopusTheme } from "~/components/Theme";
import { Note } from "~/components/form";
import routeLinks from "~/routeLinks";
import DateFormatter from "~/utils/DateFormatter";
import styles from "./TriggerSyncTask.module.less";

type TriggerSyncTaskProps = {
    isSyncEnabled: boolean;
    lastSyncedTask?: TaskResource<{}>;
    busy?: Promise<unknown> | boolean;
    onSyncClick: () => void;
};

export function TriggerSyncTask({ lastSyncedTask, busy, onSyncClick, isSyncEnabled }: TriggerSyncTaskProps) {
    const theme = useOctopusTheme();

    if (!lastSyncedTask) {
        return (
            <Note className={styles.wrapper}>
                <span className={styles.notRun}>Not run</span>
                <ActionButton label={"Sync now"} disabled={busy || !isSyncEnabled} onClick={onSyncClick} />
            </Note>
        );
    }

    return (
        <Note className={styles.wrapper}>
            {!lastSyncedTask.FinishedSuccessfully ? (
                <span className={styles.taskFailed}>
                    <em className="fa-solid fa-exclamation-triangle" style={{ color: theme.danger }} /> Last sync failed
                </span>
            ) : (
                <span className={styles.taskSucceeded}>
                    <em className="fa-solid fa-check" style={{ color: theme.success }} /> Last sync succeeded
                </span>
            )}
            <InternalLink to={routeLinks.task(lastSyncedTask).root} className={styles.taskTime}>
                {" "}
                {lastSyncedTask.CompletedTime && DateFormatter.momentAgo(lastSyncedTask.CompletedTime)}
            </InternalLink>
            <ActionButton label={"Sync now"} disabled={busy || !isSyncEnabled} onClick={onSyncClick} />
        </Note>
    );
}
