/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { AzureWebSiteSlot } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import BusyRefreshContainer from "~/components/BusyRefreshContainer";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { default as IconButton, Icon } from "~/components/IconButton/IconButton";
import InputWithActions from "~/components/InputWithActions";
import isBound from "~/components/form/BoundField/isBound";
import Note from "~/primitiveComponents/form/Note/Note";
import { BoundSelect } from "~/primitiveComponents/form/Select/Select";
import ParseHelper from "~/utils/ParseHelper/ParseHelper";

interface AzureWebSlotSelectorProps {
    resourceGroupName: string;
    webAppName: string;
    webAppSlotName: string;
    projectId: string;
    localNames: string[];
    isAccountBound: boolean;
    isWebAppBound: boolean;
    accountId: string;
    webAppSlotNameError: string;
    doBusyTask: DoBusyTask;
    onWebAppSlotNameChanged(value: string): void;
}

interface AzureWebSlotSelectorState {
    reset: {
        slotName: string;
    };
    slotIsBound: boolean;
    slots: AzureWebSiteSlot[];
    slotItems: Array<{ value: string; text: string }>;
    selectedSlotIndex: string;
    busy: boolean; //TODO: move busy back out into props and use a HOC/Render prop component to manage this state
}

const toggleBusy = (value?: boolean) => (prev: AzureWebSlotSelectorState, props: AzureWebSlotSelectorProps) => ({ ...prev, busy: value ? value : !prev.busy });

class AzureWebSlotSelector extends BaseComponent<AzureWebSlotSelectorProps, AzureWebSlotSelectorState> {
    constructor(props: AzureWebSlotSelectorProps) {
        super(props);
        this.state = {
            reset: {
                slotName: null!,
            },
            slotIsBound: isBound(props.webAppSlotName, false),
            slots: [],
            slotItems: [],
            selectedSlotIndex: null!,
            busy: false,
        };
    }

    canQuerySlots(props: AzureWebSlotSelectorProps) {
        return !props.isAccountBound && !props.isWebAppBound && !this.state.slotIsBound;
    }

    async componentDidMount() {
        await this.getWebSlotsIfNotBound(this.props);

        if (this.canQuerySlots(this.props)) {
            this.setState({ slots: [], slotItems: [], selectedSlotIndex: null! });
        }
    }

    UNSAFE_componentWillReceiveProps(newprops: AzureWebSlotSelectorProps) {
        if ((newprops.isAccountBound || newprops.isWebAppBound) && !this.state.slotIsBound) {
            this.setState({ slotIsBound: true });
        }
    }

    async getWebSlotsIfNotBound(props: AzureWebSlotSelectorProps) {
        if (this.canQuerySlots(props)) {
            await this.getWebSlots(props.accountId, props.resourceGroupName, props.webAppName);
        }
    }

    render() {
        return (
            <div>
                <BusyRefreshContainer busy={this.state.busy}>
                    <InputWithActions
                        input={
                            <BoundSelect
                                variableLookup={{
                                    localNames: this.props.localNames,
                                }}
                                resetValue={this.state.selectedSlotIndex}
                                isBound={this.state.slotIsBound}
                                onIsBoundChanged={(value: boolean) => this.setState({ slotIsBound: (this.props.isAccountBound && this.props.isWebAppBound) || value })}
                                hideBindButton={this.props.isAccountBound || this.props.isWebAppBound}
                                value={this.state.slotIsBound ? this.props.webAppSlotName : this.state.selectedSlotIndex}
                                onChange={(value) => (this.state.slotIsBound ? this.props.onWebAppSlotNameChanged(value!) : this.handleSelectedSlotChanged(value!))}
                                items={this.state.slotItems}
                                allowClear={true}
                                error={this.props.webAppSlotNameError}
                                label="Web App Slot"
                            />
                        }
                        actions={<>{!this.state.slotIsBound && <IconButton disabled={this.state.busy} onClick={() => this.getWebSlotsIfNotBound(this.props)} toolTipContent="Refresh" icon={Icon.Refresh} />}</>}
                    />
                </BusyRefreshContainer>
                <Note>The name of your Azure Web App Deployment Slot.</Note>
            </div>
        );
    }

    private handleSelectedSlotChanged = (value: string) => {
        const index = ParseHelper.safeParseInt(value, undefined);
        const selectedSlot = !index ? this.state.slots[index] : null;

        this.props.onWebAppSlotNameChanged(selectedSlot ? selectedSlot.Name : null!);
        this.setState({ selectedSlotIndex: !index ? index.toString() : null! });
    };

    private async getWebSlots(accountId: string, resourceGroupName: string, siteName: string) {
        this.setState(toggleBusy(true));
        try {
            await this.props.doBusyTask(async () => {
                if (!(accountId && siteName && resourceGroupName)) {
                    this.setState({
                        slots: [],
                        slotItems: [],
                        selectedSlotIndex: null!,
                    });
                    return;
                }

                const account = await repository.Accounts.get(accountId);
                const slots = await repository.Accounts.getWebSiteSlots(account, resourceGroupName, siteName);

                let selectedSlotIndex: string | null = null;
                if (this.props.webAppSlotName) {
                    const selectedSite = slots.find((s) => s.Name === this.props.webAppSlotName && s.ResourceGroupName === resourceGroupName && s.Site === siteName);
                    if (selectedSite) {
                        const index = slots.indexOf(selectedSite);
                        selectedSlotIndex = index < 0 ? null : index.toString();
                    }
                }

                const slotItems = slots.map((slot, index) => {
                    return {
                        value: index.toString(),
                        text: slot.Name,
                    };
                });

                this.setState({
                    slots,
                    slotItems,
                    selectedSlotIndex: selectedSlotIndex!,
                });
            });
        } finally {
            this.setState(toggleBusy(false));
        }
    }
}

export default AzureWebSlotSelector;
