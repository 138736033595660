/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { TaskResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { SFC } from "react";
import { ActionButton, ActionButtonType } from "~/components/Button/ActionButton";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import { Section } from "~/components/Section/Section";
import TimeFromNowLabel from "~/components/TimeLabels/TimeFromNowLabel";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import routeLinks from "../../../../routeLinks";

interface SystemIntegrityCheckProps {
    latestCheck: TaskResource<{}>;
    performIntegrityCheck(): void;
}

const SystemIntegrityCheck: SFC<SystemIntegrityCheckProps> = (props) => {
    const getCheckStatus = () => {
        const check = props.latestCheck;
        if (!check) {
            return <p>The check has never run</p>;
        }

        if (!check.IsCompleted) {
            return (
                <p>
                    The check is currently <InternalLink to={routeLinks.task(props.latestCheck).root}>{props.latestCheck.State}</InternalLink> and was queued
                    <TimeFromNowLabel time={props.latestCheck.QueueTime!} />
                </p>
            );
        }

        if (check.FinishedSuccessfully) {
            return (
                <p>
                    The check last ran <TimeFromNowLabel time={props.latestCheck.CompletedTime!} /> and found <InternalLink to={routeLinks.task(props.latestCheck.Id).root}>no problems</InternalLink>
                </p>
            );
        }

        return (
            <Callout type={CalloutType.Warning} title={"Problems found"}>
                The check last ran <TimeFromNowLabel time={props.latestCheck.CompletedTime!} /> and found <InternalLink to={routeLinks.task(props.latestCheck.Id).root}>some problems</InternalLink>. Please check the results and if necessary
                contact&nbsp;
                <ExternalLink href="HelpReportAProblem">Octopus support</ExternalLink>.
            </Callout>
        );
    };

    return (
        <Section sectionHeader="System Integrity Check">
            <p>The system integrity checks your Octopus database schema and data for inconsistencies.</p>
            {getCheckStatus()}
            <PermissionCheck permission={Permission.AdministerSystem}>
                <ActionButton label="Run Check Now" onClick={() => props.performIntegrityCheck()} type={ActionButtonType.Ternary} />
            </PermissionCheck>
        </Section>
    );
};

SystemIntegrityCheck.displayName = "SystemDiagnosticReport";

export default SystemIntegrityCheck;
