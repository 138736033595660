import OnboardingLoader from "app/components/GettingStarted/onboardingLoader";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useProjectContext } from "~/areas/projects/context/index";
import SuccessfulDeploymentDialog from "~/components/ProjectBasedActivation/SuccessfulDeploymentDialog";

export function GettingStartedFooter() {
    const { state: projectState } = useProjectContext();
    const loader = useMemo(() => new OnboardingLoader(projectState.model.Id, projectState.model.SpaceId), [projectState.model.Id, projectState.model.SpaceId]);
    const [showDialog, setShowDialog] = useState(false);
    const [hasBeenSuccessfullyDeployed, setHasBeenSuccessfullyDeployed] = useState<boolean | undefined>(undefined);
    const [hasSuccessfulDeploymentsInSpace, setHasSuccessfulDeploymentsInSpace] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        const unsubscribe = loader.subscribe((status) => {
            if (hasBeenSuccessfullyDeployed === false && hasSuccessfulDeploymentsInSpace === false && status.HasBeenSuccessfullyDeployed === true) {
                setShowDialog(true);
            }

            setHasBeenSuccessfullyDeployed(status.HasBeenSuccessfullyDeployed);
            setHasSuccessfulDeploymentsInSpace(status.HasSuccessfulDeploymentsInSpace);
        });

        return unsubscribe;
    }, [loader, hasBeenSuccessfullyDeployed, hasSuccessfulDeploymentsInSpace]);
    const dismissDialog = () => {
        setShowDialog(false);
    };

    return <SuccessfulDeploymentDialog open={showDialog} close={dismissDialog} />;
}
