/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { getTaskStartTime } from "@octopusdeploy/octopus-server-client";
import type { TaskResource } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import * as React from "react";
import { TaskStatusIcon } from "~/areas/projects/components/TaskStatusIcon/TaskStatusIcon";
import DateFormatter from "~/utils/DateFormatter";
import styles from "./style.module.less";

interface TaskDetailsProps {
    task: TaskResource;
    stripTopBottomPadding?: boolean;
}

const TaskDetails: React.StatelessComponent<TaskDetailsProps> = (props: TaskDetailsProps) => {
    const task = props.task;

    return (
        <div className={cn(styles.taskDetailsContainer, { [styles.stripTopBottomPadding]: props.stripTopBottomPadding })}>
            <TaskStatusIcon item={task} />
            <div className={styles.details}>
                <span className={styles.description}>{task.Description}</span>
                <span title={DateFormatter.dateToLongFormat(getTaskStartTime(task))!} className={styles.date}>
                    {DateFormatter.dateToCustomFormat(getTaskStartTime(task), "MMMM Do YYYY")}
                </span>
            </div>
        </div>
    );
};

TaskDetails.displayName = "TaskDetails";

export default TaskDetails;
