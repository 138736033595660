import type { BranchSpecifier, ProjectResource, ProjectSummary } from "@octopusdeploy/octopus-server-client";
import { ShouldUseDefaultBranch, HasVariablesInGit, Permission } from "@octopusdeploy/octopus-server-client";
import { compact } from "lodash";
import * as React from "react";
import { useEnabledFeatureToggle } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { Navigation } from "~/components/NavigationSidebarLayout/index";
import type { NavItem } from "~/components/NavigationSidebarLayout/index";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import routeLinks from "~/routeLinks";
import { IsAllowedToSeeDeploymentsOverview } from "../ProjectsRoutes/ProjectToOverviewRedirect";
import { GitProjectLink, GitProjectLinkWithErrorStatus, IndentedProjectLink } from "./GitProjectLink";

export function ProjectLinks(projectSlug: string, branch: BranchSpecifier, project: Readonly<ProjectResource>, projectSummary: ProjectSummary, isMultiTenancyEnabled: boolean | undefined, hasConnectionError: boolean) {
    const projectId = project.Id;
    const projectLinks = routeLinks.project(projectSlug);
    const navigationLinks: Array<NavItem | null> = [];
    const hasNoProcessOrRunbooks = projectSummary.HasDeploymentProcess === false && projectSummary.HasRunbooks === false;

    const deploymentsSubLinks: Array<NavItem | null> = [];
    deploymentsSubLinks.push(
        NavigationSubItem("Overview", projectLinks.deployments.root, true, {
            permission: Permission.ReleaseView,
            project: projectId,
            tenant: "*",
        })
    );
    deploymentsSubLinks.push(
        GitNavigationItem("Process", projectLinks.deployments.process.root, routeLinks.projectBranch(projectSlug, branch).deploymentProcess.root, undefined, {
            permission: Permission.ProcessView,
            project: projectId,
            tenant: "*",
        })
    );
    deploymentsSubLinks.push(
        NavigationSubItem("Channels", projectLinks.deployments.channels, undefined, {
            permission: Permission.ProcessView,
            project: projectId,
            tenant: "*",
        })
    );
    deploymentsSubLinks.push(
        NavigationSubItem("Releases", projectLinks.deployments.releases, undefined, {
            permission: Permission.ReleaseView,
            project: projectId,
            tenant: "*",
        })
    );
    deploymentsSubLinks.push(
        NavigationSubItem("Triggers", projectLinks.triggers, undefined, {
            permission: Permission.TriggerView,
            project: projectId,
        })
    );

    deploymentsSubLinks.push(GitNavigationItem("Settings", projectLinks.deployments.settings, routeLinks.projectBranch(projectSlug, branch).deploymentSettings));

    if (IsAllowedToSeeDeploymentsOverview(projectId) && deploymentsSubLinks.length > 0) {
        navigationLinks.push(Navigation.navGroup("Deployments", projectLinks.deployments.root, compact(deploymentsSubLinks), undefined, hasNoProcessOrRunbooks || projectSummary.HasDeploymentProcess)); // Strip any that failed permission checks.
    }

    const operationsSubLinks: Array<NavItem | null> = [];
    operationsSubLinks.push(
        NavigationSubItem("Overview", projectLinks.operations.root, true, {
            permission: Permission.RunbookView,
            project: projectId,
            wildcard: true,
        })
    );
    operationsSubLinks.push(
        NavigationSubItem("Runbooks", projectLinks.operations.runbooks, undefined, {
            permission: Permission.RunbookView,
            project: projectId,
            wildcard: true,
        })
    );
    operationsSubLinks.push(
        NavigationSubItem("Triggers", projectLinks.operations.triggers, undefined, {
            permission: Permission.TriggerView,
            project: projectId,
        })
    );

    navigationLinks.push(
        Navigation.navGroup(
            "Operations",
            projectLinks.operations.root,
            compact(operationsSubLinks), // Strip any that failed permission checks.
            {
                permission: Permission.RunbookView,
                project: projectId,
                wildcard: true,
            },
            hasNoProcessOrRunbooks || projectSummary.HasRunbooks
        )
    );
    const variableSubLinks: Array<NavItem | null> = [];
    const variablesLinks = ShouldUseDefaultBranch(branch) || !HasVariablesInGit(project.PersistenceSettings) ? projectLinks.variables : routeLinks.projectBranch(projectSlug, branch).variables;
    const variableLinksPermissions = {
        permission: Permission.VariableView,
        project: projectId,
        wildcard: true,
    };

    variableSubLinks.push(GitVariablesNavigationItem("Project", variablesLinks.root, variablesLinks.root, true, variableLinksPermissions));
    if (isMultiTenancyEnabled) {
        variableSubLinks.push(NavigationSubItem("Project Templates", projectLinks.variables.projectTemplates));
        variableSubLinks.push(NavigationSubItem("Common Templates", projectLinks.variables.commonTemplates));
    }
    variableSubLinks.push(NavigationSubItem("Library Sets", projectLinks.variables.library));
    variableSubLinks.push(GitVariablesNavigationItem("All", variablesLinks.all, variablesLinks.all, undefined, variableLinksPermissions));
    variableSubLinks.push(GitVariablesNavigationItem("Preview", variablesLinks.preview, variablesLinks.preview, undefined, variableLinksPermissions));

    navigationLinks.push(
        Navigation.navGroup(
            "Variables",
            variablesLinks.root,
            compact(variableSubLinks), // Strip any that failed permission checks.
            variableLinksPermissions
        )
    );

    navigationLinks.push(
        Navigation.navItem("Tasks", projectLinks.tasks, undefined, {
            permission: Permission.TaskView,
            project: projectId,
            projectGroup: "*",
            tenant: "*",
        })
    );

    const insightsSubLinks = [
        NavigationSubItem("Overview", projectLinks.insights.overview, true),
        NavigationSubItem("Deployment frequency", projectLinks.insights.frequency),
        NavigationSubItem("Deployment lead time", projectLinks.insights.leadTime),
        NavigationSubItem("Deployment failure rate", projectLinks.insights.failureRate),
        NavigationSubItem("Mean time to recovery", projectLinks.insights.timeToRecovery),
        NavigationSubItem("Deployment duration", projectLinks.insights.deploymentDuration),
    ];

    navigationLinks.push(
        Navigation.navGroup(
            "Insights",
            projectLinks.insights.overview,
            compact(insightsSubLinks),
            {
                permission: Permission.ProjectView,
                project: projectId,
                projectGroup: "*",
                tenant: "*",
            },
            false
        )
    );

    const settingSubLinks: Array<NavItem | null> = [];

    settingSubLinks.push(NavigationSubItem("General", projectLinks.settings.root, true));
    settingSubLinks.push(GitNavigationItemWithErrorStatus("Version Control", projectLinks.settings.versionControl, projectLinks.settings.versionControl));
    if (useEnabledFeatureToggle("DynamicEnvironmentsFeatureToggle")) {
        settingSubLinks.push(NavigationSubItem(<React.Fragment>Dynamic Environments</React.Fragment>, projectLinks.settings.dynamicEnvironments));
    }
    navigationLinks.push(
        Navigation.navGroup(
            "Settings",
            projectLinks.settings.root,
            compact(settingSubLinks),
            {
                permission: Permission.ProjectView,
                project: projectId,
                projectGroup: "*",
                tenant: "*",
            },
            true
        )
    );

    return compact(navigationLinks);

    // Helpers
    function GitVariablesNavigationItem(label: string | JSX.Element, dbLink: string, gitLink: string, exact?: boolean, permission?: PermissionCheckProps) {
        const labelElement = HasVariablesInGit(project.PersistenceSettings) ? <GitProjectLink label={label} /> : <IndentedProjectLink label={label} />;
        const link = HasVariablesInGit(project.PersistenceSettings) ? gitLink : dbLink;

        return Navigation.navItem(labelElement, link, exact, permission);
    }

    function GitNavigationItem(label: string | JSX.Element, dbLink: string, gitLink: string, exact?: boolean, permission?: PermissionCheckProps) {
        const labelElement = project.IsVersionControlled ? <GitProjectLink label={label} /> : <IndentedProjectLink label={label} />;
        const link = project.IsVersionControlled ? gitLink : dbLink;

        return Navigation.navItem(labelElement, link, exact, permission);
    }

    function GitNavigationItemWithErrorStatus(label: string | JSX.Element, dbLink: string, gitLink: string, exact?: boolean, permission?: PermissionCheckProps) {
        const labelElement = project.IsVersionControlled ? <GitProjectLinkWithErrorStatus label={label} hasError={hasConnectionError} /> : <IndentedProjectLink label={label} />;
        const link = project.IsVersionControlled ? gitLink : dbLink;

        return Navigation.navItem(labelElement, link, exact, permission);
    }

    function NavigationSubItem(label: string | JSX.Element, dbLink: string, exact?: boolean, permission?: PermissionCheckProps) {
        return Navigation.navItem(<IndentedProjectLink label={label} />, dbLink, exact, permission);
    }
}
