import type { ReferenceDataItem } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { MissingChip, StepChip, ChipIcon } from "~/components/Chips";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { FormFieldProps } from "~/components/form";
import Lookup from "../Lookup";

interface StepMultiSelectProps extends FormFieldProps<string[]> {
    items: ReferenceDataItem[];
    customLabel?: string;
    openOnFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
}

const StepTypedMultiSelect = MultiSelect<ReferenceDataItem>();

export const StepMultiSelect: React.FC<StepMultiSelectProps> = (props) => {
    const chipRenderer = (r: ReferenceDataItem, onRequestDelete: () => void) => {
        return (
            <Lookup
                lookupCollection={props.items}
                lookupId={r.Id}
                getIdFromElement={(element) => element.Id}
                render={(item) => <StepChip onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${item.Name}`} stepName={item.Name} />}
                renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.Step} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete} />}
            />
        );
    };

    return <StepTypedMultiSelect fieldName="deployment steps" renderChip={chipRenderer} {...props} />;
};
