import { Permission } from "@octopusdeploy/octopus-server-client";
import { client } from "~/clientInstance";
import { pageId } from "~/utils/pageId";
import libraryRouteLinks from "./libraryRouteLinks";

const libraryLinks = libraryRouteLinks(client.spaceId ?? "");

export default () => {
    return {
        root: pageId({
            area: "Library",
            id: "Library",
            name: "Library",
            routeLink: libraryLinks.root,
            routePermission: {
                permission: [Permission.FeedView, Permission.LifecycleEdit, Permission.VariableView, Permission.ActionTemplateView, Permission.CertificateView],
                wildcard: true,
            },
        }),
        feeds: pageId({ area: "Library", id: "Library.Feeds", name: "External Feeds", routeLink: libraryLinks.feeds, routePermission: { permission: Permission.FeedView } }),
        feedCreate: pageId({ area: "Library", id: "Library.FeedsNew", name: "Add New External Feed", routeLink: libraryLinks.feedCreate, routePermission: { permission: Permission.FeedEdit } }),
        feed: {
            root: pageId({ area: "Library", id: "Library.Feed", name: "External Feed", routeLink: null }),
            test: pageId({ area: "Library", id: "Library.FeedTest", name: "Test External Feed", routeLink: null }),
            redirect: pageId({ area: "Library", id: "Library.FeedRedirect", name: "Redirect External Feed", routeLink: null }),
        },
        stepTemplates: {
            root: pageId({ area: "Library", id: "Library.StepTemplates", name: "Library Step Templates", routeLink: libraryLinks.stepTemplates.root, routePermission: { permission: Permission.ActionTemplateView } }),
            builtIn: pageId({ area: "Library", id: "Library.StepTemplatesBuiltIn", name: "Built-in Step Templates", routeLink: libraryLinks.stepTemplates.builtIn, routePermission: { permission: Permission.ActionTemplateView } }),
            community: pageId({ area: "Library", id: "Library.StepTemplatesCommunity", name: "Community Step Templates", routeLink: libraryLinks.stepTemplates.community, routePermission: { permission: Permission.ActionTemplateView } }),
            installedStep: pageId({ area: "Library", id: "Library.StepTemplatesInstalledStep", name: "Step Templates Installation", routeLink: null }),
            new: pageId({ area: "Library", id: "Library.StepTemplateNew", name: "Add New Step Template", routeLink: null }),
            communityTemplate: {
                root: pageId({ area: "Library", id: "Library.StepTemplatesCommunityTemplate", name: "Community Step Template", routeLink: null }),
                details: pageId({ area: "Library", id: "Library.StepTemplatesCommunityTemplateDetails", name: "Community Step Template Details", routeLink: null }),
            },
        },
        stepTemplate: {
            root: pageId({ area: "Library", id: "Library.StepTemplate", name: "Step Template", routeLink: null }),
            usage: pageId({ area: "Library", id: "Library.StepTemplateUsage", name: "Step Template Usage", routeLink: null }),
            run: pageId({ area: "Library", id: "Library.StepTemplateRun", name: "Run Step Template", routeLink: null }),
        },
        lifecycles: pageId({ area: "Library", id: "Library.Lifecycles", name: "Lifecycles", routeLink: libraryLinks.lifecycles, routePermission: { permission: Permission.LifecycleView, wildcard: true } }),
        lifecyclesCreate: pageId({ area: "Library", id: "Library.LifecyclesNew", name: "Add New Lifecycle", routeLink: libraryLinks.lifecyclesCreate, routePermission: { permission: Permission.LifecycleCreate, wildcard: true } }),
        lifecycle: pageId({ area: "Library", id: "Library.Lifecycle", name: "Lifecycle", routeLink: null }),
        certificates: {
            root: pageId({ area: "Library", id: "Library.Certificates", name: "Certificates", routeLink: libraryLinks.certificates.root, routePermission: { permission: Permission.CertificateView, wildcard: true } }),
            archive: pageId({ area: "Library", id: "Library.CertificatesArchive", name: "Certificates Archive", routeLink: libraryLinks.certificates.archive, routePermission: { permission: Permission.CertificateView, wildcard: true } }),
            create: pageId({ area: "Library", id: "Library.CertificatesNew", name: "Add New Certificate", routeLink: libraryLinks.certificates.create, routePermission: { permission: Permission.CertificateCreate, wildcard: true } }),
        },
        certificate: pageId({ area: "Library", id: "Library.Certificate", name: "Certificate", routeLink: null }),
        variableSets: {
            root: pageId({ area: "Library", id: "Library.VariableSets", name: "Variable Sets", routeLink: libraryLinks.variableSets, routePermission: { permission: [Permission.VariableView, Permission.LibraryVariableSetView], wildcard: true } }),
            create: pageId({ area: "Library", id: "Library.VariableSetsNew", name: "Add New Variable Set", routeLink: null }),
        },
        variableSet: pageId({ area: "Library", id: "Library.VariableSet", name: "Variable Set", routeLink: null }),
        tagSets: {
            root: pageId({ area: "Library", id: "Library.TenantTagSets", name: "Tenant Tag Sets", routeLink: libraryLinks.tagSets.root, routePermission: { permission: Permission.VariableView, wildcard: true } }),
            create: pageId({ area: "Library", id: "Library.TenantTagSetsNew", name: "Add New Tenant Tag Set", routeLink: libraryLinks.tagSets.create, routePermission: { permission: Permission.TagSetCreate } }),
        },
        tagSet: pageId({ area: "Library", id: "Library.TenantTagSet", name: "Tenant Tag Set", routeLink: null }),
        builtInRepository: {
            root: pageId({ area: "Library", id: "Library.BuiltInRepository", name: "Packages", routeLink: libraryLinks.builtInRepository.root, routePermission: { permission: Permission.FeedView } }),
            package: pageId({ area: "Library", id: "Library.BuiltInRepositoryPackage", name: "Package Details", routeLink: null }),
            versions: pageId({ area: "Library", id: "Library.BuiltInRepositoryPackageVersions", name: "Package Versions", routeLink: null }),
        },
        buildInformationRepository: {
            root: pageId({ area: "Library", id: "Library.BuildInformationRepository", name: "Build Information", routeLink: libraryLinks.buildInformationRepository.root, routePermission: { permission: Permission.FeedView } }),
            package: pageId({ area: "Library", id: "Library.BuildInformationRepositoryPackage", name: "Build Information Details", routeLink: null }),
            versions: pageId({ area: "Library", id: "Library.BuildInformationRepositoryPackageVersions", name: "Build Information Versions", routeLink: null }),
        },
        scriptModules: {
            root: pageId({ area: "Library", id: "Library.ScriptModules", name: "Script Modules", routeLink: libraryLinks.scripts.root, routePermission: { permission: Permission.LibraryVariableSetView, wildcard: true } }),
            create: pageId({ area: "Library", id: "Library.ScriptModulesNew", name: "Add New Script Module", routeLink: libraryLinks.scripts.create, routePermission: { permission: Permission.LibraryVariableSetCreate, wildcard: true } }),
        },
        scriptModule: pageId({ area: "Library", id: "Library.ScriptModule", name: "Script Module", routeLink: null }),
        gitCredentials: {
            root: pageId({ area: "Library", id: "Library.GitCredentials", name: "Git Credentials", routeLink: libraryLinks.gitCredentials.root, routePermission: { permission: [Permission.GitCredentialView], wildcard: true } }),
            create: pageId({ area: "Library", id: "Library.GitCredentialsNew", name: "Add New Git Credential", routeLink: libraryLinks.gitCredentials.root, routePermission: { permission: [Permission.GitCredentialEdit], wildcard: true } }),
        },
        gitCredential: {
            root: pageId({ area: "Library", id: "Library.GitCredential", name: "Git Credential", routeLink: null }),
            usage: pageId({ area: "Library", id: "Library.GitCredentialUsage", name: "Git Credential Usage", routeLink: null }),
        },
    };
};
