/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Permission, VariableSetContentType } from "@octopusdeploy/octopus-server-client";
import type { LibraryVariableSetResource, ResourceCollection } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import MarkdownDescription from "~/components/MarkdownDescription";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { NavigationButton, NavigationButtonType } from "../../../../components/Button/NavigationButton";
import { List } from "../../../../components/List/List";
import PaperLayout from "../../../../components/PaperLayout/PaperLayout";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";

interface ScriptModulesListState extends DataBaseComponentState {
    scriptModulesResponse: ResourceCollection<LibraryVariableSetResource>;
}

class LibraryVariableSetResourceList extends List<LibraryVariableSetResource> {}

export class ScriptModulesList extends DataBaseComponent<RouteComponentProps<{}>, ScriptModulesListState> {
    constructor(props: RouteComponentProps<{}>) {
        super(props);
        this.state = {
            scriptModulesResponse: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(
            async () => {
                const getScriptModules = repository.LibraryVariableSets.list({ contentType: VariableSetContentType.ScriptModule });
                const scriptModulesResponse = await getScriptModules;
                this.setState({ scriptModulesResponse });
            },
            { timeOperationOptions: timeOperationOptions.forInitialLoad() }
        );
    }

    render() {
        const addButton = (
            <PermissionCheck permission={Permission.LibraryVariableSetCreate}>
                <NavigationButton type={NavigationButtonType.Primary} label="Add Script Module" href={`${this.props.match.url}/create`} />
            </PermissionCheck>
        );
        return (
            <LibraryLayout {...this.props}>
                <PaperLayout title="Script Modules" sectionControl={addButton} busy={this.state.busy} errors={this.errors}>
                    {this.state.scriptModulesResponse && (
                        <LibraryVariableSetResourceList
                            initialData={this.state.scriptModulesResponse}
                            onRow={(module) => this.buildRow(module)}
                            onFilter={this.filterThings}
                            filterSearchEnabled={true}
                            apiSearchParams={["partialName"]}
                            match={this.props.match}
                            filterHintText="Filter by name..."
                            additionalRequestParams={new Map([["contentType", ["ScriptModule"]]])}
                        />
                    )}
                </PaperLayout>
            </LibraryLayout>
        );
    }

    private buildRow = (scriptModule: LibraryVariableSetResource) => {
        return [
            <div key={scriptModule.Id}>
                <ListTitle>{scriptModule.Name}</ListTitle>
                <MarkdownDescription markup={scriptModule.Description} />
            </div>,
        ];
    };

    private filterThings(filter: string, resource: LibraryVariableSetResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }
}

export default ScriptModulesList;
