import { flatten } from "lodash";
import type * as React from "react";
import { isUrlActive } from "../Navigation/isUrlActive";

export interface MenuNodeItem {
    url: string;
    text: string;
    title?: string;
    icon?: JSX.Element;
    exact?: boolean;
    accessoryView?: React.ReactNode;
    linkClassName?: string;
}

export interface MenuNodeGroup {
    label: string;
    children: MenuNode[];
}

export type MenuNode = MenuNodeGroup | MenuNodeItem;

export function isMenuGroup(node: MenuNode): node is MenuNodeGroup {
    return "children" in node;
}

export function isMenuItem(node: MenuNode): node is MenuNodeItem {
    return !isMenuGroup(node);
}

export function hasActiveDescendants(pathname: string, spaceId: string, group: MenuNodeGroup): boolean {
    const allDescendantLinks = (_: MenuNodeGroup): Array<MenuNodeItem> =>
        flatten(
            _.children.map((c: MenuNode) => {
                if (isMenuGroup(c)) {
                    return allDescendantLinks(c);
                }
                return [c];
            })
        );

    return allDescendantLinks(group).some((l) => isUrlActive(pathname, spaceId, l.url, Boolean(l.exact)));
}
