import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import ExternalVideoLink from "~/components/Navigation/ExternalLink/ExternalVideoLink";
import createReleaseImgDark from "~/components/ProjectBasedActivation/assets/dark/darkimg-createrelease.svg";
import createReleaseImg from "~/components/ProjectBasedActivation/assets/img-createrelease.svg";
import OnboardingPage from "../../../../components/GettingStarted/OnboardingPage";

const Onboarding = (props: { project: ProjectResource; actionButtons: React.ReactNode }) => {
    return (
        <OnboardingPage
            title="Create a release of your software to deploy"
            intro="Make a deployment by creating a release. The release uses your deployment process to deploy your software."
            learnMore={
                <>
                    <ExternalLink href="OnboardingReleasesLearnMore">Docs</ExternalLink>
                    <ExternalVideoLink href="OnboardingCreateReleaseDeploymentVideo">Video (3 mins)</ExternalVideoLink>
                </>
            }
            image={createReleaseImg}
            imageDark={createReleaseImgDark}
            imageAlt={"Create Release"}
            actionButtons={props.actionButtons}
        />
    );
};

export default Onboarding;
