import { logger } from "@octopusdeploy/logging";
import _ from "lodash";
import URI from "urijs";
import { repository } from "~/clientInstance";
import type IPageWrapper from "~/utils/pageId";

export interface TimeOperationOptions {
    operationName: string;
    isVersionControlled: boolean | undefined;
}

interface PageIdLookupItem {
    path: string;
    pageId: string;
}

const pageIdLookup: PageIdLookupItem[] = [];

export const timeOperationOptions = {
    forInitialLoad(isVersionControlled?: boolean): TimeOperationOptions {
        return {
            operationName: "InitialLoad",
            isVersionControlled,
        };
    },
    forRefresh(): TimeOperationOptions {
        return {
            operationName: "Refresh",
            isVersionControlled: undefined,
        };
    },
    forSave(): TimeOperationOptions {
        return {
            operationName: "Save",
            isVersionControlled: undefined,
        };
    },
    for(operationName: string, isVersionControlled?: boolean) {
        return {
            operationName,
            isVersionControlled,
        };
    },
};

export async function timeOperation<T>({ operationName, isVersionControlled }: TimeOperationOptions, action: () => Promise<T>): Promise<T> {
    // The page ID may not have been set by the time the operation starts. It may also have changed by the time it finishes.
    // So we keep track of the path the user was on and use that to look up the pageId after the operation has finished.
    const path = getPath();

    const start = Date.now();

    const result = await action();

    const duration = Date.now() - start;

    const pageIdItem = pageIdLookup.filter((item) => item.path === path)[0];
    if (!pageIdItem) {
        logger.warn("Could not find the page ID for {path}", { path });
        return result;
    }

    const resultsDiscarded = path !== getPath();

    const fireTelemetryAndMostlyForget = async () => {
        try {
            await repository.Telemetry.recordLoadDuration({
                operation: operationName,
                source: pageIdItem.pageId,
                isVersionControlled,
                resultsDiscarded,
                duration,
            });
        } catch (error) {
            logger.warn(error, "Failed to send portal operation telemetry");
        }
    };

    // noinspection ES6MissingAwait Fire and forget
    fireTelemetryAndMostlyForget();

    return result;
}

export function setPageForOperationTimer(page: IPageWrapper) {
    const path = getPath();
    if (!path) return;

    if (pageIdLookup.length > 100) {
        pageIdLookup.splice(0, 1);
    }

    _.remove(pageIdLookup, (item) => item.path === path);

    pageIdLookup.push({
        path,
        pageId: page.Id,
    });
}

function getPath() {
    const uri = URI(window.location);
    if (uri.path() === "/octopus.html") {
        const match = uri.hash().match(/^[^?]+/);
        return match ? match[0] : null;
    }
    return uri.path();
}
