import { InsightsMetricsSplit } from "@octopusdeploy/octopus-server-client";
import type { InsightsReportResource } from "@octopusdeploy/octopus-server-client";
import type { ReportMetricsPageProps } from "app/areas/insights/components/Reports/ReportMetricsLayout/ReportMetricsLayout";
import { ReportMetricsLayout } from "app/areas/insights/components/Reports/ReportMetricsLayout/ReportMetricsLayout";
import React, { useEffect } from "react";
import { useAnalyticSimpleActionDispatch } from "~/analytics/Analytics";
import { getChartAxisDateFormatter } from "../../dataTransformation/dateHelpers";
import { getChartReadyInsightsData, getYAxisUnitOfTimeKeys } from "../../dataTransformation/getChartReadyInsightsData";
import { getEvenlySpacedTickArray, getYAxisDataMax } from "../../dataTransformation/getYAxisDataMax";
import { formatDurationInWords } from "../../dataTransformation/stringHelpers";
import { insightsCadenceLookup, insightsCadenceXAxisTickInterval } from "../../insightsCadence";
import { InsightsTooltip } from "../ChartTooltips/InsightsTooltip/InsightsTooltip";
import LineChart from "../Charts/LineChart";
import { DownloadReportCsvButton } from "../DownloadReportCsvButton";
import { InsightsChartCard } from "../InsightsChartCard";

type Props = ReportMetricsPageProps;

function ReportDeploymentDurationInner({ report, bffResponse, split, cadence, busy }: Props) {
    const dispatchAction = useAnalyticSimpleActionDispatch();

    const chartData = getChartReadyInsightsData(bffResponse.Series);

    const { SuccessfulDeploymentDurationKey, DeploymentQueuedDurationKey } = getYAxisUnitOfTimeKeys(chartData);

    const maxRunDurationYAxisValue = getYAxisDataMax(chartData, `SuccessfulDeploymentDuration.mean.${SuccessfulDeploymentDurationKey}`);
    const maxQueueDurationYAxisValue = getYAxisDataMax(chartData, `DeploymentQueuedDuration.mean.${DeploymentQueuedDurationKey}`);

    const currentGranularity = insightsCadenceLookup[cadence].granularity;

    const dateFormatter = getChartAxisDateFormatter(currentGranularity);

    const showSeries = split !== InsightsMetricsSplit.None;

    useEffect(() => {
        dispatchAction("View Insights Report Deployment Duration");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <main>
            <section style={{ padding: "1rem" }}>
                <div style={{ marginBottom: "2rem" }}>
                    <h2>Successful deployment duration</h2>
                    <InsightsChartCard height={500}>
                        <LineChart
                            data={chartData}
                            loading={Boolean(busy)}
                            dataKey={`SuccessfulDeploymentDuration.mean.${SuccessfulDeploymentDurationKey}`}
                            yAxisLabel={`Avg. no. of ${SuccessfulDeploymentDurationKey.toLowerCase()}`}
                            xAxisDataKey="StartOfInterval"
                            xAxisType="category"
                            yAxisType="number"
                            yAxisDomain={[0, maxRunDurationYAxisValue]}
                            yAxisTickArray={getEvenlySpacedTickArray(maxRunDurationYAxisValue)}
                            xTickFormatter={dateFormatter}
                            xAxisTickInterval={insightsCadenceXAxisTickInterval[cadence]}
                            tooltip={<InsightsTooltip showSeries={showSeries} dataKey="SuccessfulDeploymentDuration.mean.duration" valueFormatter={formatDurationInWords} cadence={cadence} />}
                            showLegend={showSeries}
                        />
                    </InsightsChartCard>
                </div>
                <div style={{ marginBottom: "2rem" }}>
                    <h2>Deployment queued duration</h2>
                    <InsightsChartCard height={500}>
                        <LineChart
                            data={chartData}
                            loading={Boolean(busy)}
                            dataKey={`DeploymentQueuedDuration.mean.${DeploymentQueuedDurationKey}`}
                            yAxisLabel={`Avg. no. of ${DeploymentQueuedDurationKey.toLowerCase()}`}
                            xAxisDataKey="StartOfInterval"
                            xAxisType="category"
                            yAxisType="number"
                            yAxisDomain={[0, maxQueueDurationYAxisValue]}
                            yAxisTickArray={getEvenlySpacedTickArray(maxQueueDurationYAxisValue)}
                            xTickFormatter={dateFormatter}
                            xAxisTickInterval={insightsCadenceXAxisTickInterval[cadence]}
                            tooltip={<InsightsTooltip showSeries={showSeries} dataKey="DeploymentQueuedDuration.mean.duration" valueFormatter={formatDurationInWords} cadence={cadence} />}
                            showLegend={showSeries}
                        />
                    </InsightsChartCard>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 8 }}>
                    <DownloadReportCsvButton report={report} />
                </div>
            </section>
        </main>
    );
}

export function ReportDeploymentDuration({ report }: { report: InsightsReportResource }) {
    return (
        <ReportMetricsLayout title="Deployment duration" report={report}>
            {(props) => <ReportDeploymentDurationInner {...props} />}
        </ReportMetricsLayout>
    );
}
