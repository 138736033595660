import type { GetDynamicEnvironmentsOverviewActiveProjectFilter, DynamicEnvironmentOverview } from "@octopusdeploy/octopus-server-client";
import { GetDynamicEnvironmentsOverviewRequestV1SortField, GetDynamicEnvironmentsOverviewRequestV1SortOrder } from "@octopusdeploy/octopus-server-client";
import { exhaustiveCheck } from "@octopusdeploy/step-runtime-inputs";
import React, { useState } from "react";
import type { RouteComponentProps } from "react-router";
import URI from "urijs";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import DataBaseComponent, { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import PaperLayout from "~/components/PaperLayout";
import { useRefreshLoop } from "~/hooks/useRefreshLoop";
import { Snackbar } from "~/primitiveComponents/feedback/Snackbar";
import InfrastructureLayout from "../InfrastructureLayout/InfrastructureLayout";
import type { DynamicEnvironmentsOverviewFilter, SortOptions } from "./DynamicEnvironmentsOverview";
import { defaultDynamicEnvironmentsOverviewFilter, DynamicEnvironmentsOverview } from "./DynamicEnvironmentsOverview";

const Title = "Dynamic Environments";

const defaultTake = 10;

function getSortField(sortOption: SortOptions): GetDynamicEnvironmentsOverviewRequestV1SortField {
    switch (sortOption) {
        case "latestActivityAsc":
        case "latestActivityDesc":
            return GetDynamicEnvironmentsOverviewRequestV1SortField.LatestActivity;

        case "activeProjectCountAsc":
        case "activeProjectCountDesc":
            return GetDynamicEnvironmentsOverviewRequestV1SortField.ActiveProjectCount;

        case "environmentNameAsc":
        case "environmentNameDesc":
            return GetDynamicEnvironmentsOverviewRequestV1SortField.EnvironmentName;

        default:
            exhaustiveCheck(sortOption, "Unhandled sort option");
    }
}

function getSortOrder(sortOption: SortOptions): GetDynamicEnvironmentsOverviewRequestV1SortOrder {
    switch (sortOption) {
        case "activeProjectCountAsc":
        case "latestActivityAsc":
        case "environmentNameAsc":
            return GetDynamicEnvironmentsOverviewRequestV1SortOrder.Ascending;

        case "activeProjectCountDesc":
        case "latestActivityDesc":
        case "environmentNameDesc":
            return GetDynamicEnvironmentsOverviewRequestV1SortOrder.Descending;

        default:
            exhaustiveCheck(sortOption, "Unhandled sort option");
    }
}

type DynamicEnvironmentsLayoutInternalProps = {
    spaceId: string;
    doBusyTask: DoBusyTask;
    busy?: Promise<void>;
    errors: Errors | undefined;
    environmentDeleted: boolean;
};

function DynamicEnvironmentsLayoutInternal({ spaceId, doBusyTask, busy, errors, environmentDeleted }: DynamicEnvironmentsLayoutInternalProps) {
    const [dynamicEnvironments, setDynamicEnvironments] = useState<DynamicEnvironmentOverview[] | undefined>(undefined);
    const [totalCount, setTotalCount] = useState<number | undefined>(undefined);
    const [sortOrder, setSortOrder] = useState<SortOptions>("latestActivityDesc");
    const [filter, setFilter] = useState<DynamicEnvironmentsOverviewFilter>(defaultDynamicEnvironmentsOverviewFilter);
    const [take, setTake] = useState<number>(defaultTake);
    const [deleteEnvironmentSnackbarOpen, setDeleteEnvironmentSnackbarOpen] = useState(environmentDeleted);
    const refresh = useDoBusyTaskEffect(
        doBusyTask,
        async () => {
            await refreshOverview();
        },
        [spaceId, sortOrder, filter, take]
    );
    useRefreshLoop(refresh, 6000);

    async function refreshOverview() {
        let activeProjectsFilter: GetDynamicEnvironmentsOverviewActiveProjectFilter | undefined = undefined;

        // The filter supports selecting multiple options as this makes it easier
        // for the user to visualise the options. The api call only supports a single
        // option however as the behaviour is mutually exclusive. If both happen to be
        // selected then that really means no filtering, same as if none are selected.
        if (filter.activeProjects.length === 1) {
            if (filter.activeProjects.includes("WithActiveProjects")) activeProjectsFilter = "WithActiveProjects";
            else if (filter.activeProjects.includes("WithoutActiveProjects")) activeProjectsFilter = "WithoutActiveProjects";
        }

        const latestOverview = await repository.DynamicEnvironments.getDynamicEnvironmentsOverview({
            spaceId,
            skip: 0,
            take: take,
            sortField: getSortField(sortOrder),
            sortOrder: getSortOrder(sortOrder),
            name: filter.name,
            state: filter.state,
            activeProjects: activeProjectsFilter,
        });
        setDynamicEnvironments(latestOverview.DynamicEnvironmentOverviews);
        setTotalCount(latestOverview.TotalCount);
    }

    function onLoadMore() {
        setTake(take + defaultTake);
    }

    async function onDeleteEnvironment(environmentId: string) {
        await repository.DynamicEnvironments.del(environmentId);
        await refresh();
        setDeleteEnvironmentSnackbarOpen(true);
    }

    async function onDeprovisionEnvironment(environmentId: string) {
        await repository.DynamicEnvironments.deprovision(environmentId);
        await refresh();
    }

    return (
        <InfrastructureLayout>
            <PaperLayout title={Title} busy={busy} enableLessIntrusiveLoadingIndicator={dynamicEnvironments !== undefined}>
                {dynamicEnvironments && (
                    <DynamicEnvironmentsOverview
                        dynamicEnvironments={dynamicEnvironments}
                        busy={busy}
                        sortOrder={sortOrder}
                        totalCount={totalCount}
                        errors={errors}
                        filter={filter}
                        onDeleteEnvironment={onDeleteEnvironment}
                        onDeprovisionEnvironment={onDeprovisionEnvironment}
                        onLoadMore={onLoadMore}
                        onSortOrderChange={setSortOrder}
                        onFilterChange={setFilter}
                    />
                )}
            </PaperLayout>
            <Snackbar open={deleteEnvironmentSnackbarOpen} autoHideDuration={3000} content="Environment deleted" onClose={() => setDeleteEnvironmentSnackbarOpen(false)} textAlign="center" />
        </InfrastructureLayout>
    );
}

type DynamicEnvironmentsLayoutRouteParams = {
    spaceId: string;
};

export class DynamicEnvironmentsLayout extends DataBaseComponent<RouteComponentProps<DynamicEnvironmentsLayoutRouteParams>> {
    constructor(props: RouteComponentProps<DynamicEnvironmentsLayoutRouteParams>) {
        super(props);
        this.state = {};
    }

    render() {
        const search = new URI(this.props.location.search).search(true);
        return <DynamicEnvironmentsLayoutInternal spaceId={this.props.match.params.spaceId} environmentDeleted={search["environmentDeleted"] === "true"} doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors} />;
    }
}
