import type { LinkElement, LinkProps } from "@octopusdeploy/routing";
import React from "react";
import { Link } from "react-router-dom";

export const DesignSystemLink = React.forwardRef<LinkElement, LinkProps>((props, ref) => {
    const innerRef = React.useRef<HTMLAnchorElement>(null);

    React.useImperativeHandle(ref, () => ({
        focus: () => innerRef.current?.focus(),
    }));

    return (
        // eslint-disable-next-line react/forbid-elements
        <Link className={props.className} to={props.href} target="_self" tabIndex={props.tabIndex} innerRef={innerRef}>
            {props.children}
        </Link>
    );
});
