/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { WorkerPoolResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import BusyIndicator from "~/components/BusyIndicator/BusyIndicator";
import BusyRefreshContainer from "~/components/BusyRefreshContainer";
import OpenDialogIconButton from "~/components/Dialog/OpenDialogIconButton";
import { Icon, default as IconButton } from "~/components/IconButton/IconButton";
import InputWithActions from "~/components/InputWithActions/InputWithActions";
import type { OctopusTheme } from "~/components/Theme";
import { withTheme } from "~/components/Theme";
import type FormFieldProps from "~/components/form/FormFieldProps";
import { WorkerPoolIcon } from "~/primitiveComponents/dataDisplay/Icon";
import Select from "~/primitiveComponents/form/Select/Select";
import routeLinks from "../../../routeLinks";
import WorkerPoolSelector from "./WorkerPoolSelector";
import styles from "./style.module.less";

interface WorkerPoolSelectProps extends FormFieldProps<string> {
    allowClear?: boolean;
    disabled?: boolean;
    label?: string | JSX.Element;
    error?: string;
    warning?: string;
    validate?(value: string): string;
    onValidate?(value: string): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    items: () => Promise<WorkerPoolResource[]>;
    onRequestRefresh(): Promise<boolean>;
}

interface TextState {
    error?: string;
    showSearchDialog: boolean;
    pools: WorkerPoolResource[];
    isDataLoaded: boolean;
    busy: boolean; //TODO: move busy back out into props and use a HOC/Render prop component to manage this state
}

const toggleBusy = (value?: boolean) => (prev: TextState, props: WorkerPoolSelectProps) => ({ ...prev, busy: value ? value : !prev.busy });

class WorkerPoolSelect extends React.Component<WorkerPoolSelectProps, TextState> {
    constructor(props: WorkerPoolSelectProps) {
        super(props);
        this.state = {
            error: null!,
            showSearchDialog: false,
            pools: [],
            isDataLoaded: false,
            busy: false,
        };
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        try {
            this.setState(toggleBusy(true));
            await this.props.doBusyTask(async () => {
                const pools = await this.props.items();
                this.setState({ pools, isDataLoaded: true });
            });
        } finally {
            this.setState(toggleBusy(false));
        }
    }

    handleChange = (poolId: string | undefined) => {
        const value = poolId === "" ? null : poolId;
        if (this.props.validate) {
            const result = this.props.validate(value!);
            this.setState({ error: result });
            if (this.props.onValidate) {
                this.props.onValidate(result);
            }
        }
        this.props.onChange!(value!);
    };

    getItems(theme: OctopusTheme) {
        return this.state.pools.map((pool) => {
            return {
                value: pool.Id,
                text: pool.Name,
                icon: <WorkerPoolIcon />,
            };
        });
    }

    selectionRenderer = (poolId: string, theme: OctopusTheme) => {
        const pool = this.state.pools.find((p) => p.Id === poolId);
        if (!pool) {
            return poolId;
        }
        return (
            <div>
                <span className={styles.selectedIcon}>
                    <WorkerPoolIcon />
                </span>
                {pool.Name}
            </div>
        );
    };

    render() {
        if (!this.state.isDataLoaded) {
            return <BusyIndicator show={true} inline={true} />;
        }

        const { onChange, onValidate, doBusyTask, onRequestRefresh, ...otherProps } = this.props;

        return withTheme((theme) => (
            <BusyRefreshContainer busy={this.state.busy}>
                <InputWithActions
                    input={<Select label="Select worker pool" {...otherProps} allowFilter={true} onChange={this.handleChange} items={this.getItems(theme)} selectionRenderer={this.selectionRenderer} sortItems={false} />}
                    actions={
                        <div className={styles.buttons}>
                            <OpenDialogIconButton toolTipContent="Search" wideDialog={true} icon={Icon.Search}>
                                <WorkerPoolSelector onSelected={(pool) => this.handleChange(pool.Id)} selectedWorkerPoolId={this.props.value!} />
                            </OpenDialogIconButton>
                            <IconButton disabled={this.state.busy} onClick={() => onRequestRefresh()} toolTipContent="Refresh" icon={Icon.Refresh} />
                            <IconButton toolTipContent="Add" onClick={this.goToWorkerPools} icon={Icon.Add} />
                        </div>
                    }
                />
            </BusyRefreshContainer>
        ));
    }

    private goToWorkerPools = () => {
        window.open(`#${routeLinks.infrastructure.workerPools.root}`, "_blank");
    };
}

export default WorkerPoolSelect;
