import type { AccountResource, ActionTemplateParameterResource, GitRefResource, FeedResource, PackageReference } from "@octopusdeploy/octopus-server-client";

export type SetPackages = (packages: (previous: Array<PackageReference>) => Array<PackageReference>) => void;

interface CommonStepInputDependencies {
    setPackages: SetPackages;
    feeds: FeedResource[];
    refreshFeeds: () => Promise<void>;
    accounts: AccountResource[];
    refreshAccounts: () => Promise<unknown>;
}

export interface ProjectStepInputDependencies extends CommonStepInputDependencies {
    projectId: string;
    gitRef: GitRefResource | undefined;
}

export interface ActionTemplateStepInputDependencies extends CommonStepInputDependencies {
    parameters: ActionTemplateParameterResource[];
    localNames: string[];
}

export type StepInputDependencies = ProjectStepInputDependencies | ActionTemplateStepInputDependencies;

export function isProjectStepInputDependencies(dependencies: StepInputDependencies): dependencies is ProjectStepInputDependencies {
    const key: keyof ProjectStepInputDependencies = "projectId";
    return key in dependencies;
}
