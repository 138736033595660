import { InsightsGranularity } from "@octopusdeploy/octopus-server-client";
import moment from "moment";

export const getChartAxisDateFormatter =
    (granularity: InsightsGranularity = InsightsGranularity.Monthly) =>
    (date: string | number) => {
        switch (granularity) {
            case InsightsGranularity.Weekly:
            case InsightsGranularity.Daily:
                return moment.utc(date).format("D MMM");
            case InsightsGranularity.Monthly:
            default:
                return moment.utc(date).format("MMM");
        }
    };
