import type { NamedResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { ChipIcon } from "~/components/Chips";
import { MissingChip } from "~/components/Chips";
import Lookup from "../Lookup/Lookup";

interface LookupResourceChipProps<T extends NamedResource> {
    lookupCollection: T[];
    lookupId: string;
    type: ChipIcon;
    chipRender: (item: T) => React.ReactElement;
    getIdFromElement?: (item: T) => string;
    renderFallback?: React.ReactElement;
}

type LookupResourceChip<T extends NamedResource> = React.FC<LookupResourceChipProps<T>>;

export function LookupResourceChipComponent<T extends NamedResource>(): LookupResourceChip<T> {
    return (props) => {
        const { getIdFromElement = (element) => element.Id, renderFallback = <MissingChip lookupId={props.lookupId} type={props.type} /> } = props;
        return <Lookup lookupCollection={props.lookupCollection} lookupId={props.lookupId} getIdFromElement={getIdFromElement} render={(item) => props.chipRender(item)} renderFallback={renderFallback} />;
    };
}

export default LookupResourceChipComponent;
