import type { DynamicEnvironmentOverviewProjectState } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { DynamicEnvironmentActiveIcon } from "./DynamicEnvironmentActiveIcon";
import { DynamicEnvironmentDeprovisionedIcon } from "./DynamicEnvironmentDeprovisionedIcon";
import { DynamicEnvironmentDeprovisioningIcon } from "./DynamicEnvironmentDeprovisioningIcon";
import { DynamicEnvironmentErrorIcon } from "./DynamicEnvironmentErrorIcon";
import { DynamicEnvironmentProvisioningIcon } from "./DynamicEnvironmentProvisioningIcon";
import { getDynamicEnvironmentProjectStateDescription } from "./getDynamicEnvironmentProjectStateDescription";

export type DynamicEnvironmentProjectStateIconProps = {
    state: DynamicEnvironmentOverviewProjectState;
};

export function DynamicEnvironmentProjectStateIcon({ state }: DynamicEnvironmentProjectStateIconProps) {
    switch (state) {
        case "Provisioning":
            return <DynamicEnvironmentProvisioningIcon title={getDynamicEnvironmentProjectStateDescription("Provisioning")} />;

        case "Provisioned":
            return <DynamicEnvironmentActiveIcon title={getDynamicEnvironmentProjectStateDescription("Provisioned")} />;

        case "Deprovisioned":
            return <DynamicEnvironmentDeprovisionedIcon title={getDynamicEnvironmentProjectStateDescription("Deprovisioned")} />;

        case "Deprovisioning":
            return <DynamicEnvironmentDeprovisioningIcon title={getDynamicEnvironmentProjectStateDescription("Deprovisioning")} />;

        case "ProvisioningFailed":
            return <DynamicEnvironmentErrorIcon title={getDynamicEnvironmentProjectStateDescription("ProvisioningFailed")} />;

        case "DeprovisioningFailed":
            return <DynamicEnvironmentErrorIcon title={getDynamicEnvironmentProjectStateDescription("DeprovisioningFailed")} />;

        default:
            return null;
    }
}
