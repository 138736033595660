/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import type { AzureResourceGroupSelectorProperties } from "@octopusdeploy/legacy-action-properties";
import type { AccountResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import BusyIndicator from "~/components/BusyIndicator";
import { Icon, default as IconButton } from "~/components/IconButton/IconButton";
import { BoundSelectWithActions } from "~/components/form/SelectWithActions/SelectWithActions";
import Note from "~/primitiveComponents/form/Note/Note";

interface AzureResourceGroupSelectorProps {
    projectId: string;
    localNames: string[];
    isAccountBound: boolean;
    isResourceGroupBound: boolean;
    accountId: string;
    properties: AzureResourceGroupSelectorProperties;
    getFieldError(field: string): string;
    setProperties(properties: Partial<AzureResourceGroupSelectorProperties>): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    onIsBoundChanged: (value: boolean) => void;
}

interface AzureResourceGroupSelectorState {
    account: AccountResource;
    resourceGroups: any[];
    busy: boolean; //TODO: move busy back out into props and use a HOC/Render prop component to manage this state
}

const toggleBusy = (value?: boolean) => (prev: AzureResourceGroupSelectorState, props: AzureResourceGroupSelectorProps) => ({ ...prev, busy: value ? value : !prev.busy });

export default class AzureResourceGroupSelector extends React.Component<AzureResourceGroupSelectorProps, AzureResourceGroupSelectorState> {
    constructor(props: AzureResourceGroupSelectorProps) {
        super(props);
        this.state = {
            account: null!,
            resourceGroups: [],
            busy: false,
        };
    }

    async componentDidMount() {
        await this.loadData();
    }

    async componentDidUpdate(prevProps: AzureResourceGroupSelectorProps, prevState: AzureResourceGroupSelectorState) {
        if (prevProps.accountId !== this.props.accountId) {
            await this.loadData();
        }
    }

    async loadData() {
        this.setState(toggleBusy(true));
        try {
            await this.props.doBusyTask(async () => {
                if (!this.props.isAccountBound && this.props.accountId) {
                    const account = await repository.Accounts.get(this.props.accountId);
                    const resourceGroups: any = await repository.Accounts.getResourceGroups(account);
                    this.setState({
                        account,
                        resourceGroups: Object.keys(resourceGroups).map((item) => resourceGroups[item]),
                    });
                } else {
                    this.setState({
                        account: null!,
                        resourceGroups: [],
                    });
                }
            });
        } finally {
            this.setState(toggleBusy(false));
        }
    }

    private actions() {
        return [this.state.busy ? <BusyIndicator show={true} inline={true} /> : <IconButton onClick={() => this.loadData()} toolTipContent="Refresh" icon={Icon.Refresh} />];
    }

    render() {
        return (
            <div>
                <BoundSelectWithActions
                    variableLookup={{
                        localNames: this.props.localNames,
                    }}
                    resetValue={this.props.properties["Octopus.Action.Azure.ResourceGroupName"]}
                    isBound={this.props.isResourceGroupBound}
                    value={this.props.properties["Octopus.Action.Azure.ResourceGroupName"]}
                    onChange={(val) => this.props.setProperties({ ["Octopus.Action.Azure.ResourceGroupName"]: val })}
                    onIsBoundChanged={(value: boolean) => this.props.onIsBoundChanged(value)}
                    items={this.state.resourceGroups.map((item) => ({ value: item.Name, text: item.Name }))}
                    label="Resource Group"
                    allowClear={true}
                    allowFilter={true}
                    error={this.props.getFieldError("Octopus.Action.Azure.ResourceGroupName")}
                    actions={this.actions()}
                />
                <Note>The name of the Azure Resource Group. The Resource Group must exist.</Note>
                <Note>A Resource Group can be created through the Azure Portal, or by using the New-AzureRMResourceGroup PowerShell cmdlet.</Note>
            </div>
        );
    }
}
