import type { FeatureToggle } from "@octopusdeploy/octopus-server-client";
import type { ReactNode } from "react";
import React from "react";
import { useEnabledFeatureToggle } from "~/components/FeatureToggle/New/FeatureToggleContext";

const FeatureToggleVisibility = ({ toggle, children, disabledContent }: React.PropsWithChildren<{ toggle: FeatureToggle; disabledContent?: ReactNode }>) => {
    const isFeatureToggleEnabled = useEnabledFeatureToggle(toggle);

    if (isFeatureToggleEnabled) {
        return <>{children}</>;
    }

    if (disabledContent) {
        return <>{disabledContent}</>;
    }

    return null;
};

export default FeatureToggleVisibility;
