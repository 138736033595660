import type { TerraformProperties } from "@octopusdeploy/legacy-action-properties";
import { ActionExecutionLocation } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import Roles from "~/components/Actions/Roles";
import type { ActionSummaryProps } from "~/components/Actions/actionSummaryProps";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import pluginRegistry from "~/components/Actions/pluginRegistry";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import type { ScriptPackageProperties } from "../script/ScriptPackageReferenceDialog";
import TerraformActionEdit from "./terraformAction";
import { TerraformJSONPlan } from "./terraformJSONPlan";

class TerraformPlanDestroyActionSummary extends BaseComponent<ActionSummaryProps> {
    render() {
        return (
            <div>
                Plan the destruction of Terraform resources
                {this.props.targetRolesAsCSV && (
                    <span>
                        {" "}
                        on behalf of targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} />
                    </span>
                )}
            </div>
        );
    }
}

class TerraformApplyActionEdit extends BaseComponent<ActionEditProps<TerraformProperties, ScriptPackageProperties>, never> {
    render() {
        return (
            <div>
                <TerraformActionEdit
                    projectId={this.props.projectId}
                    gitRef={this.props.gitRef}
                    properties={this.props.properties}
                    packages={this.props.packages}
                    setProperties={this.props.setProperties}
                    setPackages={this.props.setPackages}
                    plugin={this.props.plugin}
                    errors={this.props.errors}
                    getFieldError={this.props.getFieldError}
                    doBusyTask={this.props.doBusyTask}
                    busy={this.props.busy}
                    actionName={"plan -destroy"}
                    expandedByDefault={this.props.expandedByDefault}
                    additionalParametersLink="TerraformPlanDestroy"
                    parameters={this.props.parameters}
                />
                <TerraformJSONPlan
                    busy={this.props.busy}
                    doBusyTask={this.props.doBusyTask}
                    errors={this.props.errors}
                    expandedByDefault={this.props.expandedByDefault}
                    getFieldError={this.props.getFieldError}
                    plugin={this.props.plugin}
                    properties={this.props.properties}
                    packages={this.props.packages}
                    setPackages={this.props.setPackages}
                    setProperties={this.props.setProperties}
                />
            </div>
        );
    }
}

pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.TerraformPlanDestroy",
    summary: (properties, targetRolesAsCSV) => <TerraformPlanDestroyActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    canHaveChildren: (step) => true,
    canBeChild: true,
    edit: TerraformApplyActionEdit,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => false,
    getInitialProperties: () => ({
        "Octopus.Action.GoogleCloud.UseVMServiceAccount": "True",
        "Octopus.Action.GoogleCloud.ImpersonateServiceAccount": "False",
    }),
});
