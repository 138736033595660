import React from "react";
import { useAnalyticsViewHelpDispatch } from "~/analytics/Analytics";
import ExternalVideoLink from "~/components/Navigation/ExternalLink/ExternalVideoLink";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import type { Placement } from "~/primitiveComponents/dataDisplay/PopoverHelp/PopoverHelp";
import PopoverHelp from "~/primitiveComponents/dataDisplay/PopoverHelp/PopoverHelp";
import lifecyclesImage from "./assets/img-lifecycle.svg";
import styles from "./styles.module.less";
const targetTagsVideo = require("~/resources/videos/help/contextualhelp-targettags.mp4");

export const TargetTagsContextualHelp = ({ position = "right-start", absolutePosition = false }: { position?: Placement; absolutePosition?: boolean }) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();

    return (
        <PopoverHelp trigger="click" onOpen={() => dispatchAction("View Contextual Help Popover", { context: "Target Tags" })} placement={position} absolutePosition={absolutePosition}>
            <p>
                <video src={targetTagsVideo} playsInline={true} autoPlay={true} loop={true} muted={true} className={styles.video} />
            </p>
            <p>
                <strong>Target roles</strong> select specific deployment targets in an environment.
            </p>

            <p>You can create roles in this step and assign them to deployment targets later, in Infrastructure.</p>
            <div className={styles.links}>
                <ExternalVideoLink href="MachineRolesVideo">Video (4 min)</ExternalVideoLink>
                <ExternalLink href="TargetRoles">Docs</ExternalLink>
            </div>
        </PopoverHelp>
    );
};

export const LifecycleContextualHelp = ({ position = "right-start", absolutePosition = false }: { position?: Placement; absolutePosition?: boolean }) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();

    return (
        <PopoverHelp trigger="click" onOpen={() => dispatchAction("View Contextual Help Popover", { context: "Lifecycle" })} placement={position} absolutePosition={absolutePosition}>
            <p>
                <img src={lifecyclesImage} />
            </p>
            <p>
                <strong>Lifecycles</strong> give you control over the way releases of your software are promoted between your environments.
            </p>
            <div className={styles.links}>
                <ExternalVideoLink href="OnboardingLifecyclesVideo">Video (4 min)</ExternalVideoLink>
                <ExternalLink href="OnboardingLifecycles">Docs</ExternalLink>
            </div>
        </PopoverHelp>
    );
};

export const CloudConnectionsContextualHelp = ({ position = "right-start", absolutePosition = false }: { position?: Placement; absolutePosition?: boolean }) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();

    return (
        <PopoverHelp trigger="click" onOpen={() => dispatchAction("View Contextual Help Popover", { context: "Cloud Connections" })} placement={position} absolutePosition={absolutePosition}>
            <p>
                <strong>Cloud connections</strong> are stored as project variables. This makes those credentials available across your entire project. Additionally, cloud targets can be discoverable at deployment time.
            </p>
            <p>Use project variables to scope your variables to environments and more.</p>
            <div className={styles.links}>
                <ExternalLink href="CloudTargetDiscovery">Docs</ExternalLink>
            </div>
        </PopoverHelp>
    );
};

export const WorkerPoolsContextualHelp = ({ position = "right-start", absolutePosition = false }: { position?: Placement; absolutePosition?: boolean }) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();

    return (
        <PopoverHelp trigger="click" onOpen={() => dispatchAction("View Contextual Help Popover", { context: "Worker Pools" })} placement={position} absolutePosition={absolutePosition}>
            <p>
                <strong>Worker pools</strong> are groups of workers. Workers are often known as agents in other DevOps tools.
            </p>
            <p> When a task is assigned to a worker, the task will be executed by one of the workers in the worker pools you've configured.</p>
            <div className={styles.links}>
                <ExternalVideoLink href="WorkerPoolsVideo">Video (7 min)</ExternalVideoLink>
                <ExternalLink href="WorkerPools">Docs</ExternalLink>
            </div>
        </PopoverHelp>
    );
};

export const WorkerPoolsVariableContextualHelp = ({ position = "right-start", absolutePosition = false }: { position?: Placement; absolutePosition?: boolean }) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();

    return (
        <PopoverHelp trigger="click" onOpen={() => dispatchAction("View Contextual Help Popover", { context: "Worker Pools Variable" })} placement={position} absolutePosition={absolutePosition}>
            <p>
                It’s a common practice to use different worker pools for different environments. This enables scenarios where workers are used for authentication (e.g. AWS EC2 instance roles) or network segregation (e.g. a jump box used to access a
                database).
            </p>
        </PopoverHelp>
    );
};

export const DeploymentTargetsContextualHelp = ({ position = "right-start", absolutePosition = false }: { position?: Placement; absolutePosition?: boolean }) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();

    return (
        <PopoverHelp trigger="click" onOpen={() => dispatchAction("View Contextual Help Popover", { context: "Deployment Targets" })} placement={position} absolutePosition={absolutePosition}>
            <p>
                <strong>Deployment targets</strong> are the physical and virtual machines and cloud services that your applications are deployed to.
            </p>

            <p>You can manage your deployment targets in Infrastructure.</p>
            <div className={styles.links}>
                <ExternalVideoLink href="OnboardingDeploymentTargetsVideo">Video (5 min)</ExternalVideoLink>
                <ExternalLink href="DeploymentTargets">Docs</ExternalLink>
            </div>
        </PopoverHelp>
    );
};
