/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import type { AccountResource, ActionProperties, ActionTemplateParameterResource, ActionTemplateResource, CommunityActionTemplateResource, FeedResource, PackageReference, StepPackageInputs } from "@octopusdeploy/octopus-server-client";
import { ControlType, Permission } from "@octopusdeploy/octopus-server-client";
import { cloneDeep, isEqual } from "lodash";
import * as React from "react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import type { RouteComponentProps } from "react-router";
import type { AnyAction } from "redux";
import type { ThunkDispatch } from "redux-thunk";
import { ProcessAccountsContextProvider } from "~/areas/projects/components/Process/Contexts/ProcessAccountsContextProvider";
import { ProcessFeedsContextProvider } from "~/areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import { repository } from "~/clientInstance";
import { ActionTemplatePropertiesEditor } from "~/components/ActionPropertiesEditor/ActionTemplatePropertiesEditor";
import ActionTemplateParameterList from "~/components/ActionTemplateParametersList";
import { default as pluginRegistry } from "~/components/Actions/pluginRegistry";
import type { ActionPlugin } from "~/components/Actions/pluginRegistry";
import { ScriptActionContext } from "~/components/Actions/script/ScriptActionContext";
import DialogOpener from "~/components/Dialog/DialogOpener";
import FeatureEditor from "~/components/FeatureEditor/FeatureEditor";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout";
import Logo from "~/components/Logo/Logo";
import Markdown from "~/components/Markdown";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import OpenFeatureDialog from "~/components/OpenFeatureDialog/OpenFeatureDialog";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import PermissionCheck, { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import { Section } from "~/components/Section/Section";
import { ExpandableFormSection, ExpansionButtons, required, Summary, Text } from "~/components/form";
import { default as LogoEditor } from "~/components/form/LogoEditor/LogoEditor";
import type { LogoEditorSettings } from "~/components/form/LogoEditor/LogoEditor";
import MarkdownEditor from "~/components/form/MarkdownEditor/MarkdownEditor";
import type { SummaryNode } from "~/components/form/Sections/ExpandableFormSection";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import { UrlNavigationTabsContainer } from "~/primitiveComponents/navigation/Tabs";
import TabItem from "~/primitiveComponents/navigation/Tabs/TabItem";
import routeLinks from "~/routeLinks";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { actionTemplateFetch } from "../../../reducers/libraryArea";
import ExportActionTemplateDialog from "../ExportActionTemplateDialog";
import SaveAsDialog from "../SaveAsDialog";

interface ActionTemplateParams {
    templateId?: string;
    actionType?: string;
}

interface ActionTemplateModel {
    template: Partial<ActionTemplateResource>;
    logo: LogoEditorSettings;
}

export interface ActionTemplateState extends OptionalFormBaseComponentState<ActionTemplateModel> {
    redirectTo: string;
    plugin: ActionPlugin;
    isLoaded: boolean;
    isNew: boolean;
    showSaveAs: boolean;
    localNames: string[];
    communityTemplate?: CommunityActionTemplateResource;
    feeds: FeedResource[];
    accounts: AccountResource[];
    packagesInitialized: boolean;
    propertiesInitialized: boolean;
}
interface ActionTemplateInternalProps {
    templateId?: string;
    actionType?: string;
    onFetchActionTemplate(actionTemplate: ActionTemplateResource): void;
}

function isActionTemplateFromStepPackage(model: ActionTemplateModel | undefined) {
    return model?.template.Inputs !== undefined && model?.template.StepPackageVersion !== undefined;
}

class ActionTemplateInternal extends FormBaseComponent<ActionTemplateInternalProps, ActionTemplateState, ActionTemplateModel> {
    constructor(props: ActionTemplateInternalProps) {
        super(props);
        this.state = {
            redirectTo: null!,
            plugin: null!,
            isLoaded: false,
            isNew: true,
            showSaveAs: false,
            localNames: [],
            feeds: [],
            accounts: [],
            packagesInitialized: false,
            propertiesInitialized: false,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(
            async () => {
                let model: ActionTemplateModel | undefined = undefined;
                let plugin: ActionPlugin | undefined = undefined;
                if (this.props.templateId === undefined) {
                    plugin = await pluginRegistry.getAction(this.props.actionType!);
                    model = this.getNewTemplate(plugin);
                } else {
                    model = await this.getExistingTemplate();
                    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                    plugin = await pluginRegistry.getAction(model?.template.ActionType!, model?.template.StepPackageVersion);
                }

                const communityTemplate = model.template.CommunityActionTemplateId ? repository.CommunityActionTemplates.get(model.template.CommunityActionTemplateId) : Promise.resolve(null!);
                const feeds = this.loadFeeds();
                const accounts = this.loadAccounts();

                this.setState({
                    model,
                    cleanModel: cloneDeep(model),
                    communityTemplate: await communityTemplate,
                    plugin,
                    isLoaded: true,
                    isNew: !!!this.props.templateId,
                    localNames: this.toLocalNames(model.template.Parameters!),
                    feeds: await feeds,
                    accounts: await accounts,
                });
            },
            { timeOperationOptions: timeOperationOptions.forInitialLoad() }
        );
    }

    loadFeeds = () => {
        return hasPermission(Permission.FeedView) ? repository.Feeds.all() : Promise.resolve<FeedResource[]>([]);
    };

    loadVariables = () => {
        return repository.Variables.getSpecialVariableNames();
    };

    refreshFeeds = () => {
        return this.doBusyTask(async () => {
            const feeds = await this.loadFeeds();
            this.setState({ feeds });
        });
    };

    loadAccounts = () => (hasPermission(Permission.AccountView) ? repository.Accounts.all() : Promise.resolve<AccountResource[]>([]));

    refreshAccounts = () =>
        this.doBusyTask(async () => {
            const accounts = await this.loadAccounts();
            this.setState({ accounts });
        });

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true} />;
        }

        const excludedControlTypes: ControlType[] = isActionTemplateFromStepPackage(this.state.model)
            ? [ControlType.AmazonWebServicesAccount, ControlType.AzureAccount, ControlType.Certificate, ControlType.GoogleCloudAccount, ControlType.Package, ControlType.WorkerPool]
            : [ControlType.WorkerPool];

        return (
            <FormPaperLayout
                title="Details"
                busy={this.state.busy}
                errors={this.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                savePermission={{ permission: this.state.isNew ? Permission.ActionTemplateCreate : Permission.ActionTemplateEdit }}
                onSaveClick={this.state.isLoaded && this.isBasedOnCommunityTemplate() ? this.handleSaveAsCopyClick : this.handleSaveClick}
                hideExpandAll={true}
                disableDirtyFormChecking={this.state.isLoaded && this.isBasedOnCommunityTemplate() && !this.dataChanged()}
                saveButtonLabel={this.state.isLoaded ? this.saveButtonLabel() : undefined}
                saveText={this.state.isLoaded ? this.saveText() : undefined}
                overFlowActions={this.state.isLoaded ? this.overflowActions() : undefined}
                secondaryAction={this.state.isLoaded ? this.addFeaturesElement() : undefined}
            >
                {this.state.isLoaded && (
                    <ScriptActionContext.Provider value={{ loadVariables: this.loadVariables }}>
                        <ProcessFeedsContextProvider feeds={this.state.feeds} refreshFeeds={this.refreshFeeds}>
                            <ProcessAccountsContextProvider accounts={this.state.accounts} refreshAccounts={this.refreshAccounts}>
                                <div>
                                    <DialogOpener open={this.state.showSaveAs} onClose={() => this.setState({ showSaveAs: false })}>
                                        <SaveAsDialog originalName={this.state.model!.template.Name!} onSave={this.onSaveAsCopy} />
                                    </DialogOpener>
                                    {this.isUpdateAvailable() && (
                                        <Callout type={CalloutType.Warning} title={"New version available"}>
                                            View the new version of the <InternalLink to={routeLinks.library.stepTemplates.communityTemplate(this.state.communityTemplate!.Id).root}>{this.state.model!.template.Name}</InternalLink> step template.
                                        </Callout>
                                    )}
                                    <UrlNavigationTabsContainer defaultValue={this.state.isNew ? "settings" : "step"}>
                                        <TabItem label="Step" value="step">
                                            <ExpansionButtons errors={this.errors?.fieldErrors} expandAllOnMount={this.state.isNew} />
                                            <ActionTemplatePropertiesEditor
                                                properties={this.state.model!.template.Properties!}
                                                packages={this.state.model!.template.Packages!}
                                                isNew={this.state.isNew}
                                                doBusyTask={this.doBusyTask}
                                                busy={this.state.busy!}
                                                plugin={this.state.plugin}
                                                inputs={this.state.model!.template.Inputs!}
                                                setInputs={this.setInputs}
                                                setProperties={this.setProperties}
                                                setPackages={this.setPackages}
                                                getFieldError={this.getFieldError}
                                                localNames={this.state.localNames}
                                                errors={this.errors?.fieldErrors}
                                                expandedByDefault={this.state.isNew}
                                                parameters={this.state.model!.template.Parameters ?? []}
                                            />

                                            {this.hasFeatures() && (
                                                <FeatureEditor
                                                    plugin={this.state.plugin}
                                                    isNew={this.state.isNew}
                                                    doBusyTask={this.doBusyTask}
                                                    busy={this.state.busy!}
                                                    properties={this.state.model!.template.Properties!}
                                                    packages={this.state.model!.template.Packages!}
                                                    setProperties={this.setProperties}
                                                    setPackages={this.setPackages}
                                                    enabledFeatures={(this.state.model!.template.Properties!["Octopus.Action.EnabledFeatures"] as string) || ""}
                                                    getFieldError={this.getFieldError}
                                                    localNames={this.state.localNames}
                                                    errors={this.errors?.fieldErrors}
                                                    expandedByDefault={this.state.isNew}
                                                    openFeaturesElement={this.state.isLoaded && this.addFeaturesElement()}
                                                />
                                            )}
                                        </TabItem>
                                        <TabItem label="Parameters" value="parameters">
                                            <Section>Parameters define variables that are set when the step template is included in a project's deployment process.</Section>
                                            <Section>
                                                <ActionTemplateParameterList
                                                    parameters={this.state.model!.template.Parameters}
                                                    name="parameter"
                                                    excludedControlTypes={excludedControlTypes}
                                                    editPermission={{
                                                        permission: Permission.ActionTemplateEdit,
                                                        project: "*",
                                                        environment: "*",
                                                    }}
                                                    onParametersChanged={this.handleParametersChanged}
                                                />
                                            </Section>
                                        </TabItem>
                                        <TabItem label="Settings" value="settings">
                                            <ExpansionButtons containerKey="settings" errors={this.errors?.fieldErrors} expandAllOnMount={this.state.isNew} />
                                            <ExpandableFormSection
                                                containerKey="settings"
                                                errorKey="Name"
                                                title="Name"
                                                focusOnExpandAll
                                                summary={this.state.model!.template.Name ? Summary.summary(this.state.model!.template.Name) : Summary.placeholder("Please enter a name for your step template")}
                                                help="A short, memorable, unique name for this step template. Example: Success Notification."
                                            >
                                                <Text value={this.state.model!.template.Name!} onChange={(name) => this.setChildState2("model", "template", { Name: name })} label="Name" validate={required("Please enter a step template name")} />
                                            </ExpandableFormSection>
                                            <PermissionCheck permission={Permission.ActionTemplateEdit}>
                                                <ExpandableFormSection
                                                    containerKey="settings"
                                                    errorKey="logo"
                                                    title="Logo"
                                                    summary={this.logoSummary()}
                                                    help={this.isBasedOnCommunityTemplate() ? "The logo of a community step template is read only." : "Choose an image to use as a logo"}
                                                >
                                                    {this.isBasedOnCommunityTemplate() ? (
                                                        <div>
                                                            <Logo url={this.state.model!.template.Links!.Logo} />
                                                            If you want to update it then you have to copy the template first.
                                                        </div>
                                                    ) : (
                                                        <LogoEditor value={this.state.model!.logo} onChange={(logo) => this.setModelState({ logo })} />
                                                    )}
                                                </ExpandableFormSection>
                                            </PermissionCheck>
                                            <ExpandableFormSection
                                                containerKey="settings"
                                                errorKey="Description"
                                                title="Description"
                                                summary={this.descriptionSummary()}
                                                help="This summary will be presented to users when selecting the step template for inclusion in a project."
                                            >
                                                <MarkdownEditor value={this.state.model!.template.Description} onChange={(description) => this.setChildState2("model", "template", { Description: description })} label="Description" />
                                            </ExpandableFormSection>
                                        </TabItem>
                                    </UrlNavigationTabsContainer>
                                </div>
                            </ProcessAccountsContextProvider>
                        </ProcessFeedsContextProvider>
                    </ScriptActionContext.Provider>
                )}
            </FormPaperLayout>
        );
    }

    private handleParametersChanged = (parameters: ActionTemplateParameterResource[]) => {
        this.setState((state) => ({
            model: {
                ...state.model,
                template: {
                    ...state.model!.template,
                    Parameters: parameters,
                },
            },
            localNames: this.toLocalNames(parameters),
        }));
    };

    private setInputs = (inputs: StepPackageInputs) => {
        this.setState((prev) => ({
            model: {
                ...prev.model,
                template: {
                    ...prev.model!.template,
                    Inputs: inputs,
                },
            },
        }));
    };

    private setProperties = (properties: ActionProperties, initialise?: boolean) => {
        this.setState(
            (prev) => ({
                model: {
                    ...prev.model,
                    template: {
                        ...prev.model!.template,
                        Properties: {
                            ...prev.model!.template.Properties,
                            ...properties,
                        },
                    },
                },
            }),
            () => {
                if (initialise && !this.state.propertiesInitialized) {
                    this.setState((prev) => ({
                        propertiesInitialized: true,
                        cleanModel: {
                            ...prev.cleanModel,
                            template: {
                                ...prev.cleanModel!.template,
                                Properties: {
                                    ...prev.cleanModel!.template.Properties,
                                    ...properties,
                                },
                            },
                        },
                    }));
                }
            }
        );
    };

    private setPackages = (packages: PackageReference[], initialise?: boolean) => {
        this.setState(
            (prev) => ({
                model: {
                    ...prev.model,
                    template: {
                        ...prev.model!.template,
                        Packages: [...packages],
                    },
                },
            }),
            () => {
                if (initialise && !this.state.packagesInitialized) {
                    this.setState((prev) => ({
                        packagesInitialized: true,
                        cleanModel: {
                            ...prev.cleanModel,
                            template: {
                                ...prev.cleanModel!.template,
                                Packages: [...packages],
                            },
                        },
                    }));
                }
            }
        );
    };

    private logoSummary(): SummaryNode {
        if (!this.state.model!.template.Links || this.state.model!.logo.reset) {
            return Summary.placeholder("Default logo");
        }
        if (this.state.model!.logo.file) {
            return Summary.summary(this.state.model!.logo.file.name);
        }
        return Summary.summary(<Logo url={this.state.model!.template.Links.Logo} />);
    }

    private descriptionSummary() {
        return this.state.model!.template.Description ? Summary.summary(<Markdown markup={this.state.model!.template.Description} />) : Summary.placeholder("No step template description provided yet");
    }

    private getNewTemplate(plugin: ActionPlugin): ActionTemplateModel {
        return {
            template: {
                ActionType: this.props.actionType,
                Properties: {},
                Parameters: [],
                Packages: [],
                Inputs: plugin.getInitialInputsAndPackages?.()?.inputs,
                StepPackageVersion: plugin.version,
            },
            logo: {
                file: null!,
                reset: false,
            },
        };
    }

    private async getExistingTemplate(): Promise<ActionTemplateModel> {
        const actionTemplate = await repository.ActionTemplates.get(this.props.templateId!);
        return {
            template: actionTemplate,
            logo: {
                file: null!,
                reset: false,
            },
        };
    }

    private saveText() {
        return this.state.isNew ? "Step template created" : "Step template updated";
    }

    private overflowActions() {
        const exportAction = OverflowMenuItems.dialogItem("Export", <ExportActionTemplateDialog template={this.state.model!.template} />);
        return !this.state.isNew && !!this.state.model && !!this.state.model.template
            ? [
                  OverflowMenuItems.navItem("Run", routeLinks.library.stepTemplate(this.state.model.template.Id!).run),
                  exportAction,
                  OverflowMenuItems.deleteItemDefault("step template", this.handleDeleteConfirm, { permission: Permission.ActionTemplateDelete }),
                  [
                      OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([this.state.model.template.Id!]), {
                          permission: Permission.EventView,
                          wildcard: true,
                      }),
                  ],
              ]
            : [exportAction];
    }

    private hasFeatures() {
        return pluginRegistry.hasFeaturesForAction(this.state.plugin.actionType);
    }

    private addFeaturesElement(): React.ReactElement {
        return (
            <React.Fragment>
                {this.hasFeatures() && (
                    <OpenFeatureDialog actionType={this.state.model!.template.ActionType!} properties={this.state.model!.template.Properties!} saveDone={(features) => this.setProperties({ ["Octopus.Action.EnabledFeatures"]: features })} />
                )}
            </React.Fragment>
        );
    }

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const resource = await this.saveTemplate(this.state.model!.template as ActionTemplateResource, this.state.model!.logo);
            this.props.onFetchActionTemplate(resource); //refreshes the usage count
            if (this.state.isNew) {
                this.setState({ redirectTo: routeLinks.library.stepTemplate(resource).root });
            }
        });
    };

    private async saveTemplate(template: ActionTemplateResource, logo: LogoEditorSettings) {
        const savedTemplate = await repository.ActionTemplates.save(template);

        await repository.Logos.saveLogo(savedTemplate, logo.file!, logo.reset);

        // Important: We need to do a GET here so that the new logo url is retrieved
        const actionTemplate = await repository.ActionTemplates.get(savedTemplate.Id);

        const model: ActionTemplateModel = { template: actionTemplate, logo: { file: null!, reset: false } };

        this.setState({
            model,
            cleanModel: cloneDeep(model),
        });

        return actionTemplate;
    }

    private handleSaveAsCopyClick = async () => {
        this.setState({ showSaveAs: true });
    };

    private onSaveAsCopy = async (newName: string) => {
        this.setState({ showSaveAs: false });
        await this.doBusyTask(async () => {
            const clone = cloneDeep(this.state.model!.template);
            clone.Id = null!;
            clone.Name = newName;
            const newTemplate = await this.saveTemplate(clone as ActionTemplateResource, this.state.model!.logo);
            this.setState({ redirectTo: routeLinks.library.stepTemplate(newTemplate).root });
        });
    };

    private handleDeleteConfirm = async () => {
        const result = await repository.ActionTemplates.del(this.state.model!.template as ActionTemplateResource);
        this.setState((state) => {
            return {
                model: null,
                cleanModel: null,
                redirectTo: routeLinks.library.stepTemplates.root,
            };
        });
        return true;
    };

    private isBasedOnCommunityTemplate = () => {
        return !!this.state.model!.template.CommunityActionTemplateId;
    };

    private saveButtonLabel = () => {
        return this.isBasedOnCommunityTemplate() ? "Save as copy" : "Save";
    };

    private dataChanged() {
        return !isEqual(this.state.model, this.state.cleanModel);
    }

    private toLocalNames(parameters: ActionTemplateParameterResource[]) {
        return [...parameters].sort((a, b) => a.Name.localeCompare(b.Name)).map((p) => p.Name);
    }

    private isUpdateAvailable() {
        return (this.state.communityTemplate && this.state.model!.template.Version && this.state.model!.template.Version < this.state.communityTemplate.Version)!;
    }
}

function ActionTemplate(props: RouteComponentProps<ActionTemplateParams>) {
    const dispatch: ThunkDispatch<GlobalState, void, AnyAction> = useDispatch();
    const onFetchActionTemplate = useCallback(
        (actionTemplate: ActionTemplateResource) => {
            return dispatch(actionTemplateFetch(actionTemplate));
        },
        [dispatch]
    );

    return <ActionTemplateInternal actionType={props.match.params.actionType} templateId={props.match.params.templateId} onFetchActionTemplate={onFetchActionTemplate} />;
}

export default ActionTemplate;
