/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { SensitiveValue, ActionTemplateParameterResource, TenantVariableTemplateResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import type { FormFieldProps } from "~/components/form";
import type { ResetValue } from "~/components/form/BoundField/BoundField";
import { BoundSensitive } from "~/components/form/Sensitive/Sensitive";

interface SensitiveInputProps extends FormFieldProps<SensitiveValue> {
    resetValue?: ResetValue<SensitiveValue>;
    label: string;
    warning?: string;
    localNames?: string[];
    defaultValueIndicator?: JSX.Element;
    validate?: ((value: string) => string) | ((value: string | null) => string);
    onValidate?(value: string): void;
    parameter?: ActionTemplateParameterResource | TenantVariableTemplateResource;
}

const SensitiveInput: React.FC<SensitiveInputProps> = (props) => {
    const { value, resetValue, label, warning, localNames, defaultValueIndicator, onChange, parameter, ...rest } = props;
    const formProps = { ...rest, label };

    return (
        <React.Fragment>
            <BoundSensitive
                value={value}
                resetValue={resetValue}
                warning={warning}
                variableLookup={{
                    localNames,
                }}
                parameter={parameter}
                onChange={(x) => onChange(x as SensitiveValue)}
                {...formProps}
            />
            {defaultValueIndicator}
        </React.Fragment>
    );
};

export default SensitiveInput;
