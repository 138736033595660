/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { ResourcesById, EnvironmentResource, LifecycleResource, ProjectResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import SelectLifecycle from "~/areas/projects/components/Lifecycle/SelectLifecycle";
import { repository } from "~/clientInstance";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";

interface ChangeLifecycleDialogProps {
    projectId: string;
    saveDone(): Promise<void>;
}

interface ChangeLifecycleDialogState extends DataBaseComponentState {
    lifecycles: LifecycleResource[];
    project?: ProjectResource;
    environmentsById?: ResourcesById<EnvironmentResource>;
}

class ChangeLifecycleDialog extends DataBaseComponent<ChangeLifecycleDialogProps, ChangeLifecycleDialogState> {
    constructor(props: ChangeLifecycleDialogProps) {
        super(props);
        this.state = {
            lifecycles: [],
        };
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const [lifecycles, project, environmentsById] = await Promise.all([repository.Lifecycles.all(), repository.Projects.get(this.props.projectId), repository.Environments.allById()]);

            this.setState({ project, environmentsById, lifecycles });
        });
    }

    save() {
        return this.doBusyTask(async () => {
            await repository.Projects.modify(this.state.project!);
            await this.props.saveDone();
        });
    }

    render() {
        return (
            <SaveDialogLayout title="Change lifecycle" busy={this.state.busy} errors={this.errors} onSaveClick={() => this.save()}>
                {this.state.project && (
                    <SelectLifecycle
                        busy={this.state.busy}
                        doBusyTask={this.doBusyTask}
                        lifecycles={this.state.lifecycles}
                        selectedLifecycleId={this.state.project.LifecycleId}
                        environmentsById={this.state.environmentsById!}
                        handleLifecycleChange={this.handleLifeCycleChange}
                    />
                )}
            </SaveDialogLayout>
        );
    }

    private handleLifeCycleChange = async (value: string | undefined) => {
        await this.doBusyTask(async () => {
            this.setChildState1("project", { LifecycleId: value });
        });
    };
}

export default ChangeLifecycleDialog;
