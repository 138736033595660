/* eslint-disable @typescript-eslint/no-explicit-any */

import type { ConnectivityCheckResponse, ConnectivityCheckResponseMessage } from "@octopusdeploy/octopus-server-client";
import { ConnectivityCheckResponseMessageCategory } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { AnalyticErrorCallback, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, ActionStatus, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { client } from "~/clientInstance";
import type { DataBaseComponentState, Errors } from "~/components/DataBaseComponent";
import DataBaseComponent from "~/components/DataBaseComponent";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import styles from "./style.module.less";

interface DynamicConnectivityCheckDialogProps {
    title: string;
    url: string;
    values: { [key: string]: any };
    onOkClick: () => void;
}

interface DynamicConnectivityCheckDialogState extends DataBaseComponentState {
    checkComplete?: boolean;
    messages?: ConnectivityCheckResponseMessage[];
}

interface DynamicConnectivityCheckDialogInternalProps extends DynamicConnectivityCheckDialogProps {
    trackAction: AnalyticTrackedActionDispatcher;
}

class DynamicConnectivityCheckDialogInternal extends DataBaseComponent<DynamicConnectivityCheckDialogInternalProps, DynamicConnectivityCheckDialogState> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            let url = this.props.url;
            if (this.props.url.startsWith("/")) {
                url = `~${this.props.url}`;
            }

            const actionEvent = {
                action: Action.Test,
                resource: "Version Control Settings",
            };

            await this.props.trackAction("Test VCS", actionEvent, async (errorCallback: AnalyticErrorCallback) => {
                try {
                    const resp = await client.post<ConnectivityCheckResponse>(url, this.props.values);

                    const determineStatus = () => {
                        if (resp.Messages.some((m) => m.Category == ConnectivityCheckResponseMessageCategory.Error)) {
                            return ActionStatus.Failed;
                        }
                        if (resp.Messages.some((m) => m.Category == ConnectivityCheckResponseMessageCategory.Warning)) {
                            return ActionStatus.SuccessWithWarning;
                        }
                        return ActionStatus.Success;
                    };
                    const status = determineStatus();

                    // Call the dispatcher error callback if the API returned any errors.
                    // The API call doesn't return a 400/500 error code if there is a VCS connectivity problem,
                    // it simply returns some error messages, so we need to check this ourselves.
                    if (status === ActionStatus.Failed) {
                        const errorMsgs = resp.Messages.filter((x) => x.Category === ConnectivityCheckResponseMessageCategory.Error).map((x) => x.Message);
                        const errors: Errors = {
                            fieldErrors: {},
                            message: "Connectivity test failed",
                            errors: errorMsgs,
                        };
                        errorCallback(errors);
                    }

                    this.setState({ checkComplete: true, messages: resp.Messages });
                } catch (err) {
                    this.setState({ checkComplete: true });
                    errorCallback(err.message);
                }
            });
        });
    }

    detailFor(category: ConnectivityCheckResponseMessageCategory, message: string, index: number) {
        let calloutType = CalloutType.Success;
        if (category === ConnectivityCheckResponseMessageCategory.Warning) {
            calloutType = CalloutType.Warning;
        }
        if (category === ConnectivityCheckResponseMessageCategory.Error) {
            calloutType = CalloutType.Danger;
        }
        return (
            <Callout title={category} type={calloutType} key={`callout-${index}`}>
                <div className={styles.testingResult}>{message}</div>
            </Callout>
        );
    }

    render() {
        return (
            <OkDialogLayout title={`Verifying ${this.props.title}`} hideCancel={true} busy={!this.state.checkComplete || this.state.busy} errors={this.errors} onOkClick={this.props.onOkClick}>
                {this.state.checkComplete && this.state.messages && <div>{this.state.messages.map((e, index) => this.detailFor(e.Category, e.Message, index))}</div>}
            </OkDialogLayout>
        );
    }
}

export default (props: DynamicConnectivityCheckDialogProps) => {
    const dispatchAction = useAnalyticTrackedActionDispatch();

    return <DynamicConnectivityCheckDialogInternal {...props} trackAction={dispatchAction} />;
};
