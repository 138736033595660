/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { GoogleContainerRegistryFeedResource, FeedResource } from "@octopusdeploy/octopus-server-client";
import { FeedType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import AdvancedFeedCredentialsFormSection, { AuthenticationType } from "../AdvancedFeedCredentialsFormSection";
import type { FeedEditProps } from "./feedTypeRegistry";
import feedTypeRegistry from "./feedTypeRegistry";

const supportedAuthenticationTypes = [AuthenticationType.GoogleCloudJsonKey];
const googleAuthUsername: string = "_json_key";
class GoogleRegistryContainerFeed extends React.Component<FeedEditProps<GoogleContainerRegistryFeedResource>> {
    render() {
        return (
            <React.Fragment>
                <AdvancedFeedCredentialsFormSection
                    model={{ username: this.props.feed.Username, password: this.props.feed.Password }}
                    isNew={!this.props.feed.Id}
                    onChanged={(model) => this.props.onChange({ ...this.props.feed, Password: model.password, Username: googleAuthUsername })}
                    supportedAuthenticationTypes={supportedAuthenticationTypes}
                />
            </React.Fragment>
        );
    }
}

feedTypeRegistry.registerFeedType({
    type: FeedType.GoogleContainerRegistry,
    edit: GoogleRegistryContainerFeed as React.ComponentType<FeedEditProps<FeedResource>>,
    text: "Google Container Registry",
    uriNotes: (
        <React.Fragment>
            <div>
                See Google Contaner Registry <ExternalLink href="GCRRegistryLinks">documentation</ExternalLink>.
                <br />
                Configure an <ExternalLink href="btwJZI">Google Contaner Registry</ExternalLink> in Octopus.
            </div>
        </React.Fragment>
    ),
    uriDefault: "https://gcr.io",
});
