import type { ResourceCollection, PackageResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { match } from "react-router";
import { client } from "~/clientInstance";
import BaseComponent from "~/components/BaseComponent";
import InternalLink from "~/components/Navigation/InternalLink";
import PagingDataTable from "~/components/PagingDataTable";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import routeLinks from "../../../../routeLinks";
import styles from "./style.module.less";

class PackageResourceDataTable extends PagingDataTable<PackageResource> {}

interface LatestPackagesListProps {
    match: match<{}>;
    initialPackages: ResourceCollection<PackageResource>;
}

export class LatestPackagesList extends BaseComponent<LatestPackagesListProps> {
    render() {
        return (
            !!this.props.initialPackages && (
                <PackageResourceDataTable
                    initialData={this.props.initialPackages}
                    onRow={(item) => this.buildRow(item)}
                    onFilter={this.filter}
                    filterSearchEnabled={true}
                    apiSearchParams={["filter"]}
                    headerColumns={["ID", "Highest version", "Description"]}
                    headerColumnClassNames={["", "", styles.descriptionColumn]}
                    onEmpty={this.handleOnEmpty}
                />
            )
        );
    }

    private handleOnEmpty = () => {
        return <div>No packages found</div>;
    };

    private filter(filter: string, resource: PackageResource) {
        return !filter || filter.length === 0 || (resource.PackageId ? resource.PackageId.toLowerCase().includes(filter.toLowerCase()) : false) || (resource.Title ? resource.Title.toLowerCase().includes(filter.toLowerCase()) : false);
    }

    private buildRow = (pkg: PackageResource) => {
        return [
            <InternalLink to={routeLinks.library.builtInRepository.versions(pkg.PackageId)}>{pkg.PackageId}</InternalLink>,
            <div className={styles.row}>
                <PermissionCheck permission={Permission.BuiltInFeedDownload} project="*">
                    <a href={client.resolve(pkg.Links["Raw"])}>
                        <em className="fa-solid fa-download" />
                    </a>
                    &nbsp;
                </PermissionCheck>
                <InternalLink to={routeLinks.library.builtInRepository.package(pkg.Id)}>{pkg.Version}</InternalLink>
            </div>,
            pkg.Description,
        ];
    };
}

export default LatestPackagesList;
