/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { VariableType } from "@octopusdeploy/octopus-server-client";
import type { ScopeValues } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { FocusableCellType } from "~/areas/variables/CellFocus/CellFocus";
import { EditVariableDialog, FocusField } from "~/areas/variables/EditVariableDialog/EditVariableDialog";
import type { OpenVariableDialogArgs, OpenReferenceVariableDialogArgs } from "~/areas/variables/EditVariableDialog/EditVariableDialog";
import type { ScopeSpecification } from "~/areas/variables/ReadonlyVariableResource";
import type SensitiveFieldStates from "~/areas/variables/SensitiveFieldStates";
import { SingleVariableRowHeight } from "~/areas/variables/SingleVariableRow/SingleVariableRow";
import ActionList from "~/components/ActionList";
import { ActionButton, ActionButtonType } from "~/components/Button";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import type { CertificateIndex } from "~/components/certificates";
import type { SensitiveState } from "~/components/form/Sensitive/Sensitive";
import type { TagIndex } from "~/components/tenantTagsets";
import type { CellAligner } from "~/primitiveComponents/dataDisplay/ScrollTable/ScrollTable";
import type { BorderCss } from "~/utils/BorderCss/BorderCss";
import type { WorkerPoolIndex } from "../../../components/workerPools";
import VariableNameCell from "../VariableNameCell";
import VariableScopeCell from "../VariableScopeCell";
import VariableValueCell from "../VariableValueCell";
import { VariableValueModel } from "../VariablesModel";
import type { VariableModel } from "../VariablesModel";
import styles from "./style.module.less";
const keycode = require("keycode");

export const customTextFieldMargins = "0.475rem 0"; // Custom margins to manage the transition between our PopoverWhenFocused UX.

export interface VariableAddProps {
    availableScopes: ScopeValues;
    tagIndex: TagIndex;
    sensitiveFieldStates: SensitiveFieldStates;
    cellAligner: CellAligner;
    certificateIndex: CertificateIndex;
    poolIndex: WorkerPoolIndex;
    doBusyTask: DoBusyTask;
    borderStyle: BorderCss;
    onChanged: (variable: VariableModel) => void;
    onAdded: () => void;
    focus?: VariableAddRowFocus;
    onBlur: (blurredFrom: VariableAddRowFocus) => void;
    onFocus: (focus?: VariableAddRowFocus) => void; // null onFocus means you focused on something else (like a button)
    isTenanted: boolean;
    variable: VariableModel;
    onNavigateDown: () => void;
    onSensitiveStateChanged: (variableId: string, state: SensitiveState) => void;
    scopeCellWidth: number | undefined;
    gitVariables: boolean;
}

export interface VariableAddState {
    editVariableDialog: OpenVariableDialogArgs | OpenReferenceVariableDialogArgs;
}

export interface VariableAddRowFocus {
    cell: FocusableCellType;
    variableId: string;
}

export default class VariableAdd extends React.Component<VariableAddProps, VariableAddState> {
    constructor(props: VariableAddProps) {
        super(props);
        this.state = {
            editVariableDialog: null!,
        };
    }

    render() {
        const values = this.props.variable.values;
        const variable = this.props.variable;
        const lastValue = values[values.length - 1];
        const canAddAnotherValue = this.canAddAnotherValue(lastValue);
        const canAddVariable = this.canAddVariable(this.props.variable);

        return (
            <div className={styles.addRowsContainer}>
                {values.map((value, index) => {
                    const isRowFocused = this.props.focus && this.props.focus.variableId === value.Id;
                    const isNameCellFocused = isRowFocused && this.props.focus!.cell === FocusableCellType.Name;
                    const isValueCellFocused = isRowFocused && this.props.focus!.cell === FocusableCellType.Value;
                    const isScopeCellEditing = isRowFocused && this.props.focus!.cell === FocusableCellType.ScopeEdit;
                    const isScopeCellViewingAll = isRowFocused && this.props.focus!.cell === FocusableCellType.ScopeViewAll;
                    const isNotFirstRow = index !== 0;

                    return (
                        <div
                            style={{ height: SingleVariableRowHeight, borderBottom: this.props.borderStyle.borderCssString }}
                            className={styles.addRowBackground}
                            key={index}
                            onKeyDown={(ev) => {
                                if (this.props.focus && this.props.focus.cell === FocusableCellType.ScopeEdit) {
                                    return; // Do nothing, because we don't want to additionally handle up down arrows if there are auto completes in the celll
                                }
                                const code = keycode(ev);
                                const newFocusCellType = this.props.focus ? this.props.focus.cell : FocusableCellType.Name;
                                if (code === "up" && index !== 0) {
                                    this.props.onFocus({ variableId: values[index - 1].Id, cell: newFocusCellType });
                                    ev.preventDefault(); // Stops the page from scrolling in some cases
                                } else if (code === "enter") {
                                    ev.preventDefault();
                                    this.onEnterPressed();
                                } else {
                                    if (code === "down") {
                                        if (this.props.focus && this.props.focus.cell === FocusableCellType.Name) {
                                            this.props.onNavigateDown();
                                        } else {
                                            if (index === values.length - 1) {
                                                this.props.onNavigateDown();
                                            } else {
                                                this.props.onFocus({ variableId: values[index + 1].Id, cell: newFocusCellType });
                                            }
                                        }
                                        ev.preventDefault(); // Stops the page from scrolling in some cases
                                    }
                                }
                            }}
                        >
                            {this.props.cellAligner([
                                isNotFirstRow ? (
                                    <div />
                                ) : (
                                    <VariableNameCell
                                        id={variable.name}
                                        name={variable.name}
                                        placeholder="Enter new variable"
                                        onNameChanged={(name) => this.updateName(name)}
                                        onOpenEditorClicked={() => this.setState({ editVariableDialog: this.setupEditVariableDialog(variable, value, FocusField.Name, undefined) })}
                                        isFocused={isNameCellFocused!}
                                        onFocus={() => this.props.onFocus({ variableId: value.Id, cell: FocusableCellType.Name })}
                                        onBlur={() => this.props.onBlur({ variableId: value.Id, cell: FocusableCellType.Name })}
                                        isDuplicate={false}
                                        warningMessages={[]}
                                    />
                                ),
                                <VariableValueCell
                                    id={value.Id}
                                    value={value.Value}
                                    type={value.Type}
                                    sensitiveState={this.props.sensitiveFieldStates[value.Id]}
                                    certificateIndex={this.props.certificateIndex}
                                    poolIndex={this.props.poolIndex}
                                    isPromptedVariable={!!value.Prompt}
                                    gitVariables={this.props.gitVariables}
                                    placeholder={value.Prompt ? "Enter default value" : "Enter value"}
                                    onValueChanged={(Value) => this.updateValue(value, (v) => ({ ...v, Value }))}
                                    onVariableTypeChanged={(Type) =>
                                        this.updateValue(value, (v) => ({
                                            ...v,
                                            Type,
                                            IsSensitive: Type === VariableType.Sensitive,
                                        }))
                                    }
                                    onOpenEditorClicked={() => this.setState({ editVariableDialog: this.setupEditVariableDialog(variable, value, FocusField.Value, undefined) })}
                                    onChangeToReferenceType={(type) => this.setState({ editVariableDialog: this.setupEditVariableDialog(variable, value, FocusField.Value, type) })}
                                    isFocused={isValueCellFocused!}
                                    onFocus={() => this.props.onFocus({ variableId: value.Id, cell: FocusableCellType.Value })}
                                    onBlur={() => this.props.onBlur({ variableId: value.Id, cell: FocusableCellType.Value })}
                                    onSensitiveStateChanged={(state) => this.props.onSensitiveStateChanged(value.Id, state)}
                                    existingSensitiveValue={undefined}
                                />,
                                <div className={styles.scopeCell}>
                                    <VariableScopeCell
                                        scope={value.Scope}
                                        onScopeChanged={(Scope) => this.updateValue(value, (v) => ({ ...v, Scope }))}
                                        availableScopes={this.props.availableScopes}
                                        tagIndex={this.props.tagIndex}
                                        variableType={value.Type}
                                        isEditing={isScopeCellEditing!}
                                        isViewingAll={isScopeCellViewingAll!}
                                        doBusyTask={this.props.doBusyTask}
                                        onFocusEdit={() => this.props.onFocus({ variableId: value.Id, cell: FocusableCellType.ScopeEdit })}
                                        onBlurEdit={() => this.props.onBlur({ variableId: value.Id, cell: FocusableCellType.ScopeEdit })}
                                        onFocusViewAll={() => this.props.onFocus({ variableId: value.Id, cell: FocusableCellType.ScopeViewAll })}
                                        onBlurViewAll={() => this.props.onBlur({ variableId: value.Id, cell: FocusableCellType.ScopeViewAll })}
                                        cellHeight={SingleVariableRowHeight}
                                        onOpenEditorClicked={() => this.setState({ editVariableDialog: this.setupEditVariableDialog(variable, value, FocusField.Name, undefined) })}
                                        containerWidth={this.props.scopeCellWidth}
                                    />
                                </div>,
                            ])}
                        </div>
                    );
                })}
                <div className={styles.actions}>
                    <ActionList
                        actions={[
                            <ActionButton label="Add Another Value" disabled={!canAddAnotherValue} onClick={() => this.addAnotherValue(lastValue.Type)} onFocus={() => this.props.onFocus()} />,
                            <ActionButton label="Add To List" type={ActionButtonType.Primary} onClick={() => this.props.onAdded()} disabled={!canAddVariable} onFocus={() => this.props.onFocus()} />,
                        ]}
                    />
                </div>
                <EditVariableDialog
                    title="Add Variable"
                    openDialogArgs={this.state.editVariableDialog}
                    availableScopes={this.props.availableScopes}
                    onDone={this.updateValueAndName}
                    onClosed={() => this.setState({ editVariableDialog: null! })}
                    isTenanted={this.props.isTenanted}
                />
            </div>
        );
    }

    private onEnterPressed = () => {
        const canAddVariable = this.canAddVariable(this.props.variable);
        if (canAddVariable) {
            //This matches the default debounce delay being used for variable/name cells.
            window.setTimeout(() => this.props.onAdded(), 250);
        }
        return true;
    };

    private updateValueAndName = (value: VariableValueModel, name: string) => {
        this.props.onChanged({
            name,
            values: this.props.variable.values.map((v) => (v.Id === value.Id ? value : v)),
        });
    };

    private updateName = (name: string) => {
        this.props.onChanged({
            name,
            values: this.props.variable.values,
        });
    };

    private updateValue(value: VariableValueModel, update: (variable: VariableValueModel) => VariableValueModel) {
        const isExistingValue = this.props.variable.values.some((v) => v.Id === value.Id);
        const values = isExistingValue ? this.props.variable.values : [...this.props.variable.values, value];
        this.props.onChanged({
            name: this.props.variable.name,
            values: values.map((v) => (v.Id === value.Id ? update(v) : v)),
        });
    }

    private canAddVariable(variableGroup: VariableModel): boolean {
        return !!variableGroup.name;
    }

    private canAddAnotherValue(newVariable: VariableValueModel): boolean {
        return !!newVariable.Value || !scopeIsEmpty(newVariable.Scope);
    }

    private addAnotherValue(newVariableType: VariableType) {
        const newValue = new VariableValueModel(newVariableType);
        const values = this.props.variable.values.concat([newValue]);

        this.props.onChanged({ name: this.props.variable.name, values });
        this.props.onFocus({ cell: FocusableCellType.Value, variableId: newValue.Id });
    }

    private setupEditVariableDialog(variable: VariableModel, value: VariableValueModel, focus: FocusField, referenceType: VariableType | undefined) {
        return { name: variable.name, value, referenceType, focus };
    }
}

function scopeIsEmpty(scope: ScopeSpecification) {
    return ![scope.TenantTag, scope.Channel, scope.Action, scope.Machine, scope.Environment, scope.Role].some(hasValue);
    function hasValue(value?: ReadonlyArray<string | undefined>) {
        return !!value && !!value.length;
    }
}
