import { RunCondition } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { Note, ExpandableFormSection, Summary } from "~/components/form";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import RadioButton from "~/primitiveComponents/form/RadioButton/RadioButton";
import RadioButtonGroup from "~/primitiveComponents/form/RadioButton/RadioButtonGroup";

class RunConditionRadioButtonGroup extends RadioButtonGroup<RunCondition> {}

export const RunConditionText = {
    [RunCondition.Success]: "Success: only run when previous steps succeed",
    [RunCondition.Failure]: "Failure: only run when previous steps fail",
    [RunCondition.Always]: "Always run",
    [RunCondition.Variable]: "Variable: only run when the variable expression is true",
};

export const FirstStepRunConditionText = {
    ...RunConditionText,
    [RunCondition.Success]: "Success: only run when previous steps succeed (or is first step)",
};

export interface RunTriggerExpanderProps {
    isFirstStep: boolean;
    condition: RunCondition;
    variableExpression: string;
    localNames?: string[];
    projectId: string;
    variableExpressionError: string | undefined;
    onConditionChange(condition: RunCondition): void;
    onVariableExpressionChange(expression: string): void;
}

const getRunConditionText = (isFirstStep: boolean, condition: RunCondition) => {
    return isFirstStep ? FirstStepRunConditionText[condition] : RunConditionText[condition];
};

class RunTriggerExpander extends React.Component<RunTriggerExpanderProps> {
    shouldComponentUpdate(nextProps: RunTriggerExpanderProps) {
        return nextProps.condition !== this.props.condition || nextProps.variableExpression !== this.props.variableExpression || this.props.variableExpressionError !== nextProps.variableExpressionError;
    }

    render() {
        const conditions = this.props.isFirstStep ? [RunCondition.Success, RunCondition.Always, RunCondition.Variable] : [RunCondition.Success, RunCondition.Failure, RunCondition.Always, RunCondition.Variable];

        return (
            <ExpandableFormSection
                title="Run Condition"
                help="Limit when this step runs by setting a condition."
                errorKey="runcondition"
                summary={this.props.condition === RunCondition.Success ? Summary.default(getRunConditionText(this.props.isFirstStep, this.props.condition)) : Summary.summary(getRunConditionText(this.props.isFirstStep, this.props.condition))}
            >
                <RunConditionRadioButtonGroup
                    value={this.props.condition}
                    onChange={(condition) => {
                        if (condition !== RunCondition.Variable) {
                            this.props.onVariableExpressionChange("");
                        }
                        this.props.onConditionChange(condition);
                    }}
                    accessibleName="The conditions on which this step should run"
                >
                    {conditions.map((rc) => (
                        <RadioButton value={rc} label={getRunConditionText(this.props.isFirstStep, rc)} key={rc} />
                    ))}
                </RunConditionRadioButtonGroup>
                {this.props.condition === RunCondition.Variable && (
                    <div>
                        <VariableLookupText localNames={this.props.localNames} label="Variable expression" value={this.props.variableExpression} onChange={this.props.onVariableExpressionChange} error={this.props.variableExpressionError} />
                        <Note>
                            Limit when this step runs by setting a boolean variable expression that will be evaluated at run-time. See the <ExternalLink href="VariableSubstitutionSyntax">Variable Substitution Syntax</ExternalLink> documentation for
                            examples.
                        </Note>
                    </div>
                )}
            </ExpandableFormSection>
        );
    }
}

export default RunTriggerExpander;
