import type { RunbookRunResource, RunbookSnapshotResource } from "@octopusdeploy/octopus-server-client";
import { DeploymentCreateGoal } from "../../Releases/ReleasesRoutes/releaseRouteLinks";

function createLink(root: string, runbookSnapshotId: string | RunbookSnapshotResource, goal?: DeploymentCreateGoal | string, previousId?: string, tenantIds?: string | string[]) {
    const previousSegment = previousId ? `/${previousId}` : "";
    const uri = `${root}/${runbookSnapshotId}/runs/create/${goal}${previousSegment}`;

    if (!tenantIds) {
        return uri;
    }
    const tenants = Array.isArray(tenantIds) ? tenantIds : [tenantIds];
    return `${uri}/${tenants.join(",")}`;
}

export default (root: string) => ({
    runbookSnapshots: root,
    runbookSnapshotCreate: `${root}/create`,
    runbookSnapshot: (runbookSnapshotId: string | RunbookSnapshotResource) => {
        if (!!runbookSnapshotId && typeof runbookSnapshotId !== "string") {
            runbookSnapshotId = runbookSnapshotId.Id;
        }
        return {
            root: `${root}/${runbookSnapshotId}`,
            edit: `${root}/${runbookSnapshotId}/edit`,
            runbookRuns: {
                specific: (runbookRunId: string | RunbookRunResource) => {
                    if (typeof runbookRunId !== "string") {
                        runbookRunId = runbookRunId.Id;
                    }
                    return `${root}/${runbookSnapshotId}/runs/${runbookRunId}`;
                },
                retry: (previousRunbookRunId: string) => {
                    return createLink(root, runbookSnapshotId, DeploymentCreateGoal.TryAgain, previousRunbookRunId);
                },
                create: (goal?: DeploymentCreateGoal | string, previousId?: string, tenantIds?: string | string[]) => {
                    return createLink(root, runbookSnapshotId, goal, previousId, tenantIds);
                },
            },
        };
    },
});
