/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */

import { Fade } from "@material-ui/core";
import type { PopoverClassKey } from "@material-ui/core/Popover";
import MuiPopover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import type { TransitionProps } from "@material-ui/core/transitions/transition";
import { noOp } from "@octopusdeploy/utilities";
import * as React from "react";
import type { OctopusTheme } from "~/components/Theme/index";
import { useOctopusTheme } from "~/components/Theme/index";
import { useTransitionComponentOverride } from "~/primitiveComponents/transitions/overrideTransitions";
import type { Theme } from "~/theme/index";

export type Horizontal = "left" | "center" | "right";
export type Vertical = "top" | "center" | "bottom";

export interface Origin {
    horizontal: Horizontal;
    vertical: Vertical;
}

export interface PopoverProps {
    id?: string;
    anchorEl: Element | null;
    anchorOrigin?: Origin;
    className?: string;
    onClose?: () => void;
    open: boolean;
    style?: React.CSSProperties;
    transformOrigin?: Origin;
    disableAutoFocus?: boolean;
    disableEnforceFocus?: boolean;
    getUpdatePosition?: (callback: () => void) => void;
    TransitionComponent?: React.ComponentType<TransitionProps>;
}

const useStyles = makeStyles<Theme, OctopusTheme, PopoverClassKey>({ root: {}, paper: (theme) => ({ backgroundColor: theme.paper2 }) });

export const Popover: React.FC<PopoverProps> = (props) => {
    const theme = useOctopusTheme();
    const transitionComponent = useTransitionComponentOverride() ?? props.TransitionComponent ?? Fade; // Default to the same animation used in our global search.
    const classes = useStyles(theme);

    return (
        <MuiPopover
            id={props.id}
            classes={classes}
            action={(actions) => (props.getUpdatePosition && actions ? props.getUpdatePosition(actions.updatePosition) : noOp())}
            anchorEl={props.anchorEl}
            anchorOrigin={props.anchorOrigin}
            className={props.className}
            onClose={props.onClose}
            open={props.open}
            style={props.style}
            transformOrigin={props.transformOrigin}
            disableAutoFocus={props.disableAutoFocus}
            disableEnforceFocus={props.disableEnforceFocus}
            TransitionComponent={transitionComponent}
        >
            {props.children}
        </MuiPopover>
    );
};
