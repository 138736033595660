/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-eq-null */

import type { ProjectGroupResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { cloneDeep } from "lodash";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import { ContextualHelpLayout } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout/FormPaperLayout";
import Markdown from "~/components/Markdown";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { Text, ExpandableFormSection, Summary, required } from "~/components/form";
import MarkdownEditor from "~/components/form/MarkdownEditor/MarkdownEditor";
import StringHelper from "~/utils/StringHelper";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../../routeLinks";
import styles from "./style.module.less";

interface ProjectGroupProps extends RouteComponentProps<ProjectGroupRouteParams> {
    create?: boolean;
}

interface ProjectGroupRouteParams {
    ProjectGroupId: string;
}

interface ProjectGroupState extends OptionalFormBaseComponentState<ProjectGroupResource> {
    deleted: boolean;
    newId: string;
}

class ProjectGroupLayout extends FormBaseComponent<ProjectGroupProps, ProjectGroupState, ProjectGroupResource> {
    constructor(props: ProjectGroupProps) {
        super(props);
        this.state = {
            deleted: false,
            newId: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const projectGroupPromise = repository.ProjectGroups.get(this.props.match.params.ProjectGroupId);
            const projectGroup = await projectGroupPromise;

            this.setState({
                model: projectGroup,
                cleanModel: cloneDeep(projectGroup),
            });
        });
    }

    descriptionSummary() {
        return this.state.model!.Description ? Summary.summary(<Markdown markup={this.state.model!.Description} />) : Summary.placeholder("No project group description provided");
    }

    render() {
        const title = this.props.create ? "Create project group" : this.state.model ? this.state.model.Name : StringHelper.ellipsis;

        const overFlowActions = [];
        if (!this.props.create && this.state.model) {
            overFlowActions.push(OverflowMenuItems.deleteItemDefault("project group", this.handleDeleteConfirm, { permission: Permission.ProjectGroupDelete, projectGroup: "*" }));
            overFlowActions.push([
                OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([this.state.model.Id]), {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]);
        }

        const saveText: string = this.state.newId ? "Project group created" : "Project group details updated";

        return (
            <main id="maincontent">
                <AreaTitle link={routeLinks.projects.root} title={"Projects"} />
                <ContextualHelpLayout>
                    <div className={styles.container}>
                        <FormPaperLayout
                            title={title}
                            busy={this.state.busy}
                            errors={this.errors}
                            model={this.state.model}
                            cleanModel={this.state.cleanModel}
                            savePermission={{ permission: this.props.create ? Permission.ProjectGroupCreate : Permission.ProjectGroupEdit, projectGroup: "*" }}
                            onSaveClick={this.handleSaveClick}
                            saveText={saveText}
                            expandAllOnMount={this.props.create}
                            overFlowActions={overFlowActions}
                        >
                            {this.state.deleted && <InternalRedirect to={routeLinks.projects.root} />}
                            {this.state.newId && <InternalRedirect to={routeLinks.projectGroup(this.state.newId).root} />}
                            {this.state.model && (
                                <TransitionAnimation>
                                    <ExpandableFormSection
                                        errorKey="Name"
                                        title="Name"
                                        summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your project group")}
                                        help="A short, memorable, unique name for this project group."
                                    >
                                        <Text value={this.state.model.Name} onChange={(Name) => this.setModelState({ Name })} label="Name" validate={required("Please enter a project group name")} autoFocus={true} />
                                    </ExpandableFormSection>

                                    <ExpandableFormSection errorKey="description" title="Description" summary={this.descriptionSummary()} help="Enter a description for your project group.">
                                        <MarkdownEditor value={this.state.model.Description} label="Project group description" onChange={(Description) => this.setModelState({ Description })} />
                                    </ExpandableFormSection>
                                </TransitionAnimation>
                            )}
                        </FormPaperLayout>
                    </div>
                </ContextualHelpLayout>
            </main>
        );
    }

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const isNew = this.state.model!.Id == null;
            const result = await repository.ProjectGroups.save(this.state.model!);
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
                newId: isNew ? result.Id : null!,
            });
        });
    };

    private handleDeleteConfirm = async () => {
        const result = await repository.ProjectGroups.del(this.state.model!);
        this.setState((state) => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true,
            };
        });
        return true;
    };
}

export default ProjectGroupLayout;
