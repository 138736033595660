/* eslint-disable @typescript-eslint/no-explicit-any */

import { TriggerActionCategory } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { Switch } from "react-router-dom";
import { withPage } from "~/components/Page/Page";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import pageIds from "~/pageIds";
import type { EditScheduledTriggerRouteProps } from "../../Triggers/Scheduled/EditScheduledTriggers";
import EditScheduledTrigger from "../../Triggers/Scheduled/EditScheduledTriggers";
import { RunbookTriggers } from "./Triggers";

export const ScheduledTriggerPage = withPage({ page: pageIds.project().runbook.scheduledTrigger })(EditScheduledTrigger);
export const CreateScheduledTriggerPage = withPage({ page: pageIds.project().runbook.scheduledTriggerNew })(EditScheduledTrigger);
export const TriggersPage = withPage({ page: pageIds.project().runbook.triggers })(RunbookTriggers);

export class TriggersRoute extends React.Component<any, any> {
    render() {
        const match = this.props.match;
        return (
            <Switch>
                <ReloadableRoute
                    path={`${match.path}/scheduled/edit/:triggerId`}
                    render={(routeProps: EditScheduledTriggerRouteProps) => <ScheduledTriggerPage create={false} triggerActionCategory={TriggerActionCategory.Runbook} {...routeProps} />}
                />
                <ReloadableRoute
                    path={`${match.path}/scheduled/create/runbooks/:runbookId`}
                    render={(routeProps: EditScheduledTriggerRouteProps) => <CreateScheduledTriggerPage create={true} triggerActionCategory={TriggerActionCategory.Runbook} {...routeProps} />}
                />
                <ReloadableRoute
                    path={`${match.path}/scheduled/create/runbooks`}
                    render={(routeProps: EditScheduledTriggerRouteProps) => <CreateScheduledTriggerPage create={true} triggerActionCategory={TriggerActionCategory.Runbook} {...routeProps} />}
                />
                <ReloadableRoute path={`${match.path}`} component={TriggersPage} />
            </Switch>
        );
    }
}
