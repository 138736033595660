/* eslint-disable @typescript-eslint/consistent-type-assertions */

import type { PropertyValueResource } from "@octopusdeploy/octopus-server-client";
const isBound = (value: PropertyValueResource, fallback = true) => {
    if (value) {
        return (value as string).includes("#{");
    }
    return fallback;
};

export default isBound;
