import { useRouteMatch } from "react-router";

export function useIsPageVersionControlled() {
    const match = useRouteMatch();

    if (!match) {
        return false;
    }

    const paramKeys = Object.keys(match.params);
    const isVersionControlledPage = paramKeys.includes("branchName");

    return isVersionControlledPage;
}
