import * as React from "react";
import type { KubernetesCustomResourceProps } from "~/components/Actions/kubernetes/kubernetesCustomResourceComponent";
import { KubernetesCustomResourceComponent } from "~/components/Actions/kubernetes/kubernetesCustomResourceComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import type { ActionEditProps } from "../../Actions/pluginRegistry";
import pluginRegistry from "../../Actions/pluginRegistry";
import { BaseComponent } from "../../BaseComponent/BaseComponent";

class KubernetesCustomResourceFeature extends BaseComponent<ActionEditProps<KubernetesCustomResourceProps>, DataBaseComponentState> {
    render() {
        return (
            <KubernetesCustomResourceComponent
                properties={this.props.properties}
                packages={this.props.packages}
                plugin={this.props.plugin}
                getFieldError={this.props.getFieldError}
                setProperties={this.props.setProperties}
                setPackages={this.props.setPackages}
                doBusyTask={this.props.doBusyTask}
                busy={this.props.busy}
                errors={this.props.errors}
                projectId={this.props.projectId}
                gitRef={this.props.gitRef}
                expandedByDefault={this.props.expandedByDefault}
            />
        );
    }
}

pluginRegistry.registerFeature({
    featureName: "Octopus.Features.KubernetesCustomResource",
    title: "Custom Resource",
    description: "Create Kubernetes resources from raw YAML",
    edit: KubernetesCustomResourceFeature,
    priority: 20,
});
