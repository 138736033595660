import type { ResourcesById, EnvironmentResource, LifecycleResource, ProjectGroupResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import SelectLifecycle from "~/areas/projects/components/Lifecycle/SelectLifecycle";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { MarkdownEditor, Select } from "~/components/form/index";

type AdvancedProjectSectionProps = {
    description: string;
    onDescriptionChanged: (newValue: string) => void;

    projectGroups: ProjectGroupResource[];
    projectGroupId: string | undefined;
    onProjectGroupChanged: (newValue: string | undefined) => void;

    lifecycles: LifecycleResource[];
    selectedLifecycle: LifecycleResource | undefined;
    onLifecycleChanged: (newValue: string | undefined) => void;
    environmentsById: ResourcesById<EnvironmentResource>;
    doBusyTask: DoBusyTask;
    busy?: Promise<void>;
};

const AdvancedProjectSection = (props: AdvancedProjectSectionProps) => {
    return (
        <React.Fragment>
            <MarkdownEditor label="Project description" value={props.description} onChange={props.onDescriptionChanged} hideMarkdownToggle />
            <Select value={props.projectGroupId} onChange={props.onProjectGroupChanged} items={props.projectGroups.map((pg) => ({ value: pg.Id, text: pg.Name }))} label="Project group" />
            <SelectLifecycle
                busy={props.busy}
                doBusyTask={props.doBusyTask}
                lifecycles={props.lifecycles}
                selectedLifecycleId={props.selectedLifecycle ? props.selectedLifecycle.Id : ""}
                environmentsById={props.environmentsById}
                handleLifecycleChange={props.onLifecycleChanged}
                showNote={true}
            />
        </React.Fragment>
    );
};

export default AdvancedProjectSection;
