import * as React from "react";
import { Action, Plan, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { AuditStreamUpsellDialog } from "~/areas/configuration/components/AuditLayout/AuditStream/AuditStreamUpsellDialog";
import { ActiveStatusIcon } from "~/components/ActiveStatusIcon/ActiveStatusIcon";
import { ActionButtonType } from "~/components/Button/index";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";

export function AuditStreamUpsellButton() {
    const dispatchAction = useAnalyticActionDispatch();

    const handleClick = () => {
        dispatchAction("Stream Audit Log", { resource: "Audit Stream", action: Action.Add, plan: Plan.NonEnterprise });
    };

    return (
        <React.Fragment>
            <ActiveStatusIcon active={false} style={{ marginTop: "15px" }} />
            <OpenDialogButton
                type={ActionButtonType.Ternary}
                label="Stream Audit Log"
                wideDialog={true}
                onClick={handleClick}
                renderDialog={(renderProps) => <AuditStreamUpsellDialog open={renderProps.open} close={() => renderProps.closeDialog()} />}
            />
        </React.Fragment>
    );
}
