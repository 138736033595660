/* eslint-disable @typescript-eslint/no-non-null-assertion */
import cn from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import ActionButton, { ActionButtonType } from "~/components/Button/ActionButton";
import { SetLabelStrategy } from "~/components/LabelStrategy/LabelStrategy";
import { Section } from "~/components/Section/Section";
import SidebarLayout, { SidebarSide } from "~/components/SidebarLayout/SidebarLayout";
import SimpleExpander from "~/components/SimpleExpander";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon";
import styles from "./style.module.less";

export interface FilterSection {
    sectionName?: string; // Often the first section won't have a name. A section without a name is not expandable
    isNotDefaultFilter?: boolean; // Are the values in this filter all default?  This is used to determine if a filter section needs to be expanded automatically.
    render: React.ReactNode;
}

export interface FilterResult {
    numberOfMatches: number;
    singleText: string;
    pluralText: string;
}

interface AdvancedFilterLayoutProps<Filter> {
    filterHeaderCustomStyle?: string;
    filterSections: FilterSection[];
    extendContentToEdges?: boolean;
    // Divider should probably be hidden if not on a paper element
    hideDivider?: boolean;
    // Any fields shown in additionalHeaderFilter should not appear in the filter pane
    // Header text filter inputs should usually be a FilterSearchBox (i.e. they have the search icon, which inputs in the pane don't have)
    additionalHeaderFilters?: React.ReactNode[];
    additionalHeaderControls?: React.ReactNode[];
    filterByChips?: React.ReactNode;
    filter: Filter;
    queryFilter?: Filter;
    defaultFilter: Filter;
    initiallyShowFilter?: boolean; // defaults to false
    overflowXHidden?: boolean;
    filterResult?: FilterResult;
    onFilterReset(filter: Filter): void;
    renderContent(filterPanelIsVisible: boolean): React.ReactNode;
    onToggleFilter?(isOpen: boolean): Promise<void>;
}

interface AdvancedFilterLayoutState {
    isShowingFilter: boolean;
}

const sidebarFilterLabelStrategy = (fieldName: string) => `By ${fieldName}`;
const headerFilterLabelStrategy = (fieldName: string) => `Filter by ${fieldName}`;

//eslint-disable-next-line react/no-unsafe
export default abstract class AdvancedFilterLayout<Filter> extends React.Component<AdvancedFilterLayoutProps<Filter>, AdvancedFilterLayoutState> {
    constructor(props: AdvancedFilterLayoutProps<Filter>) {
        super(props);
        this.state = {
            isShowingFilter: this.props.initiallyShowFilter!,
        };
    }

    public UNSAFE_componentWillReceiveProps(props: AdvancedFilterLayoutProps<Filter>) {
        if (!this.state.isShowingFilter && props.queryFilter && !isEqual(props.queryFilter, this.props.queryFilter) && !this.isDefaultFilter(props.queryFilter, props.defaultFilter)) {
            this.setState({ isShowingFilter: true });
        }
    }

    render() {
        const hasFilterPanelContent = !!this.props.filterSections.length;
        const panelLabel = this.props.additionalHeaderFilters && !!this.props.additionalHeaderFilters.length ? "Advanced Filter" : "Filter";
        const matchCountText = this.props.filterResult ? (
            <div>
                <span className={cn(styles.matchInfo, styles.info)}>
                    {this.props.filterResult.numberOfMatches !== 1 ? `${this.props.filterResult.numberOfMatches} ${this.props.filterResult.pluralText} match` : `1 ${this.props.filterResult.singleText} matches`}
                </span>
            </div>
        ) : null;
        return (
            <div className={styles.fullHeightContainer}>
                <SetLabelStrategy labelStrategy={headerFilterLabelStrategy}>
                    <Section>
                        <div className={styles.headingSection}>
                            <div className={cn(styles.filterHeaderContainer, this.props.filterHeaderCustomStyle)} role="search">
                                {this.props.additionalHeaderFilters &&
                                    this.props.additionalHeaderFilters.map((f, i) => {
                                        return (
                                            f && (
                                                <div key={i} className={styles.additionalHeaderFilter}>
                                                    {f}
                                                </div>
                                            )
                                        );
                                    })}
                                {matchCountText && matchCountText}
                                {hasFilterPanelContent && (
                                    <div className={styles.toggleFilters}>
                                        <ActionButton
                                            type={ActionButtonType.Ternary}
                                            label={`${!this.state.isShowingFilter ? "Show" : "Hide"} ${panelLabel}s`}
                                            icon={<ThirdPartyIcon iconType={ThirdPartyIconType.FilterList} />}
                                            onClick={async () => {
                                                if (this.props.onToggleFilter) {
                                                    await this.props.onToggleFilter(!this.state.isShowingFilter);
                                                }
                                                this.setState({ isShowingFilter: !this.state.isShowingFilter });
                                            }}
                                        />
                                    </div>
                                )}
                                {this.props.additionalHeaderControls &&
                                    this.props.additionalHeaderControls.map((f, i) => {
                                        return (
                                            f && (
                                                <div key={i} className={styles.additionalHeaderControl}>
                                                    {f}
                                                </div>
                                            )
                                        );
                                    })}
                            </div>
                            {!!this.props.filterByChips && (
                                <div className={styles.filteringBy}>
                                    <div>Filtering by: </div>
                                    <div className={styles.filteringByItems}>{this.props.filterByChips}</div>
                                </div>
                            )}
                        </div>
                    </Section>
                </SetLabelStrategy>
                <SidebarLayout
                    sideBar={
                        hasFilterPanelContent &&
                        this.state.isShowingFilter && (
                            <SetLabelStrategy labelStrategy={sidebarFilterLabelStrategy}>
                                <Section>
                                    <h4 className={styles.heading}>
                                        <div className={styles.headingText}>{panelLabel}</div>
                                        <ActionButton
                                            className={styles.resetButton}
                                            disabled={this.isDefaultFilter(this.props.filter, this.props.defaultFilter)}
                                            label="Reset"
                                            type={ActionButtonType.Secondary}
                                            onClick={() => this.props.onFilterReset(this.props.defaultFilter)}
                                        />
                                    </h4>
                                </Section>
                                {this.props.filterSections.map((s, index) => {
                                    if (s.sectionName) {
                                        return (
                                            <SimpleExpander
                                                key={index}
                                                errorKey={s.sectionName}
                                                containerKey={"filter"}
                                                isExpandedByDefault={!!s.isNotDefaultFilter}
                                                title={
                                                    <Section>
                                                        <h4>{s.sectionName}</h4>
                                                    </Section>
                                                }
                                            >
                                                <Section>{s.render}</Section>
                                            </SimpleExpander>
                                        );
                                    }
                                    return <Section key={index}>{s.render}</Section>;
                                })}
                            </SetLabelStrategy>
                        )
                    }
                    side={SidebarSide.Left}
                    extendContentToEdges={this.props.extendContentToEdges}
                    extendSidebarToEdges={true}
                    hideTopDivider={true}
                    hideSidebarDivider={this.props.hideDivider}
                    overflowXHidden={this.props.overflowXHidden}
                >
                    {this.props.renderContent(this.state.isShowingFilter)}
                </SidebarLayout>
            </div>
        );
    }

    private isDefaultFilter(filter: Filter, defaultFilter: Filter) {
        return isEqual(filter, defaultFilter);
    }
}
