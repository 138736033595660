import type { ChangeFailureRateTrends, FailureRateDeployment } from "@octopusdeploy/octopus-server-client";
import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import { DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip";
import routeLinks from "~/routeLinks";
import DateFormatter from "~/utils/DateFormatter";
import { CommonSparklineTrends } from "./CommonSparklineTrends";
import { TrendTable } from "./TrendTable/TrendTable";

interface FailureRateTrendTables {
    trends: ChangeFailureRateTrends;
}

export function FailureRateTrendTables({ trends }: FailureRateTrendTables) {
    return (
        <>
            <CommonSparklineTrends
                trends={trends}
                valueFormatter={(val) => (val ? `${val > 0 ? val.toFixed(1) : 0}%` : "0%")}
                metricDescription={
                    <>
                        average <ExternalLink href="InsightsDeploymentFailureRate">deployment failure rate</ExternalLink>
                    </>
                }
            />
            <RecentFailures data={trends.RecentFailures} />
        </>
    );
}

interface RecentFailuresProps {
    data: FailureRateDeployment[];
}

function RecentFailures({ data }: RecentFailuresProps) {
    const hasTenants = data.some((d) => d.TenantName !== null);

    return (
        <TrendTable
            title="Recent failures"
            isEmpty={data.length === 0}
            note={
                <span>
                    The most recent <ExternalLink href="InsightsDeploymentFailureRate">failed deployment</ExternalLink> for each project, environment and tenant combination. The first 10 in descending order by completed time are shown.
                </span>
            }
            headerContent={
                <DataTableRow>
                    <DataTableHeaderColumn>Project</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Environment</DataTableHeaderColumn>
                    {hasTenants && <DataTableHeaderColumn>Tenant</DataTableHeaderColumn>}
                    <DataTableHeaderColumn>Release</DataTableHeaderColumn>
                    <DataTableHeaderColumn>State</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Guided failure</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Completed</DataTableHeaderColumn>
                </DataTableRow>
            }
            bodyContent={
                <>
                    {data.map((r) => (
                        <DataTableRow key={r.Id}>
                            <DataTableRowColumn>
                                <InternalLink to={routeLinks.project(r.ProjectId).root}>{r.ProjectName}</InternalLink>
                            </DataTableRowColumn>
                            <DataTableRowColumn>{r.EnvironmentName}</DataTableRowColumn>
                            {hasTenants && <DataTableRowColumn>{r.TenantName ?? "Untenanted"}</DataTableRowColumn>}
                            <DataTableRowColumn>
                                <InternalLink to={routeLinks.project(r.ProjectId).release(r.ReleaseVersion).root}>{r.ReleaseVersion}</InternalLink>
                            </DataTableRowColumn>
                            <DataTableRowColumn>{r.State}</DataTableRowColumn>
                            <DataTableRowColumn>{r.HadGuidedFailure ? "True" : "False"}</DataTableRowColumn>
                            <DataTableRowColumn>
                                <ToolTip content={DateFormatter.dateToShortFormat(r.CompletedTime) || undefined}>
                                    <InternalLink to={routeLinks.project(r.ProjectId).release(r.ReleaseVersion).deployments.specific(r.Id)}>{DateFormatter.momentAgo(r.CompletedTime)}</InternalLink>
                                </ToolTip>
                            </DataTableRowColumn>
                        </DataTableRow>
                    ))}
                </>
            }
        />
    );
}
