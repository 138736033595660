import type { InsightsReportResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import AddInsightsReportDialog from "app/areas/insights/components/Reports/AddInsightsReportDialog";
import { InsightsReportCard } from "app/areas/insights/components/Reports/ReportCard/ReportCard";
import type { PropsWithChildren } from "react";
import React from "react";
import { useHistory } from "react-router";
import { useAnalyticSimpleActionDispatch } from "~/analytics/Analytics";
import type { AnalyticSimpleDispatcher } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import { ContextualHelpLayout } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import DataBaseComponent from "~/components/DataBaseComponent";
import DataLoader from "~/components/DataLoader";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
import LoadMoreCard from "~/components/LoadMoreCard/LoadMoreCard";
import { resolveStringPathWithSpaceId } from "~/components/Navigation/resolvePathWithSpaceId";
import PaperLayout from "~/components/PaperLayout";
import { PermissionCheck } from "~/components/PermissionCheck";
import routeLinks from "~/routeLinks";
import { ActionButtonType } from "../../../../../components/Button/ActionButton";
import reportImageDark from "./assets/NewReport_Insights_Dark.svg";
import reportImage from "./assets/NewReport_Insights_Light.svg";
import styles from "./style.module.less";

type InitialData = { reports: InsightsReportResource[]; totalNumberOfReports: number };
const ReportDataLoader = DataLoader<InitialData>();

interface ReportListLayoutProps {
    busy?: boolean;
}

interface ReportListInnerProps {
    initialReports: InsightsReportResource[];
    pageSize: number;
    totalNumberOfReports: number;
    dispatchAction: AnalyticSimpleDispatcher;
}

interface ReportListInnerState {
    reports: InsightsReportResource[];
    currentPage: number;
}

function AddInsightsReportButton() {
    const dispatchAction = useAnalyticSimpleActionDispatch();

    const onClick = () => dispatchAction("Click Create Insights Report Button");

    const history = useHistory();

    const onReportCreated = (report: InsightsReportResource) => {
        history.push(resolveStringPathWithSpaceId(routeLinks.insights.report(report.Id).settings, report.SpaceId));
    };

    return (
        <PermissionCheck permission={Permission.InsightsReportCreate}>
            <OpenDialogButton type={ActionButtonType.Primary} label="Create an Insights Report" onClick={onClick}>
                <AddInsightsReportDialog onReportCreated={onReportCreated} />
            </OpenDialogButton>
        </PermissionCheck>
    );
}

export function ReportOnboarding() {
    return (
        <OnboardingPage
            title="Create an Insights Report"
            intro="Insights reports show data insights for groups of projects and environments."
            image={reportImage}
            imageDark={reportImageDark}
            learnMore={null}
            actionButtons={<AddInsightsReportButton />}
        />
    );
}

function ReportListLayout({ busy, children }: PropsWithChildren<ReportListLayoutProps>) {
    return (
        <>
            <AreaTitle link={routeLinks.insights.reports} title="Insights">
                <AddInsightsReportButton />
            </AreaTitle>
            <ContextualHelpLayout>
                <PaperLayout busy={busy} flatStyle fullWidth>
                    {children}
                </PaperLayout>
            </ContextualHelpLayout>
        </>
    );
}

class ReportListInner extends DataBaseComponent<ReportListInnerProps, ReportListInnerState> {
    constructor(props: ReportListInnerProps) {
        super(props);

        this.state = {
            currentPage: 1,
            reports: [...props.initialReports],
        };
    }

    componentDidMount(): void {
        this.props.dispatchAction("View Insights Report List");
    }

    private async loadMoreReports() {
        const { currentPage, reports } = this.state;
        const { pageSize } = this.props;

        this.doBusyTask(async () => {
            const nextReports = (await repository.InsightsReports.list({ skip: currentPage * pageSize, take: pageSize })).Items;
            this.setState({ currentPage: currentPage + 1, reports: [...reports, ...nextReports] });
        });
    }

    render() {
        const { reports } = this.state;
        const { totalNumberOfReports } = this.props;

        const showLoadMore = reports.length < totalNumberOfReports;

        return (
            <main>
                <>
                    {reports.length === 0 && <ReportOnboarding />}
                    {reports.length > 0 && (
                        <div className={styles.castListContainer}>
                            <div className={styles.cardList}>
                                {reports.map((w) => (
                                    <InsightsReportCard key={w.Id} report={w} />
                                ))}
                                {/* This design is not finalized. This load more button will change */}
                                {showLoadMore && <LoadMoreCard onLoadAll={() => this.loadMoreReports()} onLoadMore={() => this.loadMoreReports()} />}
                            </div>
                        </div>
                    )}
                </>
            </main>
        );
    }
}

export function ReportList() {
    const pageSize = 30;

    const dispatchAction = useAnalyticSimpleActionDispatch();

    return (
        <ReportDataLoader
            load={async () => {
                const { Items: reports, TotalResults: totalNumberOfReports } = await repository.InsightsReports.list({ skip: 0, take: pageSize });
                return { reports, totalNumberOfReports };
            }}
            renderAlternate={({ busy }) => <ReportListLayout busy={busy} />}
            renderWhenLoaded={({ reports, totalNumberOfReports }) => (
                <ReportListLayout>
                    <ReportListInner initialReports={reports} pageSize={pageSize} totalNumberOfReports={totalNumberOfReports} dispatchAction={dispatchAction} />
                </ReportListLayout>
            )}
        />
    );
}
