import { ProcessType } from "@octopusdeploy/octopus-server-client";
import React from "react";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import ExternalVideoLink from "~/components/Navigation/ExternalLink/ExternalVideoLink";
import { OverviewContainer, OverviewHighlightText, TopicsContainer, LearnMoreResources } from "./CommonViews";
const image = require("../../Images/DeploymentProcess.svg");

export const CommonProjectProcessOverview: React.StatelessComponent<{ processType?: ProcessType }> = (props) => {
    const isRunbookProcess = props.processType && props.processType === ProcessType.Runbook;

    let processDescriptionElement = (
        <p>
            The <ExternalLink href="OnboardingDeploymentProcessLearnMore">deployment process</ExternalLink> is like a recipe for deploying your software. You define the recipe by adding steps and variables to a project. Each step contains a specific
            action (or set of actions) that is executed as part of the deployment process each time your software is deployed.
        </p>
    );
    if (isRunbookProcess) {
        processDescriptionElement = (
            <p>
                The <ExternalLink href="OnboardingDeploymentProcessLearnMore">runbook process</ExternalLink> is like a recipe. You define the recipe by adding steps to a runbook. Each step contains a specific action (or set of actions) that is
                executed as part of the runbook process each time your runbook is run.
            </p>
        );
    }
    return (
        <OverviewContainer>
            <OverviewHighlightText>
                {processDescriptionElement}
                {!isRunbookProcess && (
                    <p>
                        <ExternalVideoLink href="OnboardingDeploymentProcessVideo" />
                    </p>
                )}
                <ImageWithPlaceholder src={image} alt={"Deployment process"} />

                {!isRunbookProcess && (
                    <p>
                        If your process is in version control, view <ExternalLink href="ConfigAsCodeEditing">Editing a project with version control enabled</ExternalLink>.
                    </p>
                )}
            </OverviewHighlightText>
        </OverviewContainer>
    );
};

export const CommonProjectProcessTopics: React.StatelessComponent<{}> = (props) => {
    return (
        <TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="OnboardingDeploymentProcessLearnMore">Deployment Process</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="ConfigAsCodeEditing">Editing version-controlled projects</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>
    );
};
