import { OctoLink } from "@octopusdeploy/utilities";
import * as React from "react";
import ActionButton, { ActionButtonType } from "~/components/Button";
import CustomDialog from "~/components/Dialog/CustomDialog";
import { CustomExternalLinkDialogLayout, SmallDialogFrame } from "~/components/DialogLayout/Custom";
import Section from "~/components/Section";

interface Props {
    label: string;
}

interface State {
    showDialog: boolean;
}

export class InviteOctoIdUserDialogLink extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showDialog: false,
        };
    }

    render() {
        return (
            <>
                <CustomDialog
                    open={this.state.showDialog}
                    close={this.closeDialog}
                    render={() => (
                        <CustomExternalLinkDialogLayout
                            title="Invite user"
                            externalLink={OctoLink.Create("OctopusCloudOrganizationAccount")}
                            externalLinkLabel="Go to Accounts"
                            open={this.state.showDialog}
                            close={() => this.setState({ showDialog: false })}
                            frame={SmallDialogFrame}
                        >
                            <Section>Send email invites and access to your instance via Octopus.com</Section>
                        </CustomExternalLinkDialogLayout>
                    )}
                />
                <ActionButton onClick={this.showDialog} label={this.props.label} type={ActionButtonType.Primary} />
            </>
        );
    }

    private showDialog = (e: Event) => {
        e.preventDefault();
        this.setState({ showDialog: true });
    };

    private closeDialog = () => {
        this.setState({ showDialog: false });
    };
}
