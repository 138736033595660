import type { CertificateUsageResource } from "@octopusdeploy/octopus-server-client";
import routeLinks from "../../../../../routeLinks";

export interface CertificateUsageEntry {
    ownerName: string;
    ownerLink: string;
    ownerType: string;
}

export const certificateUsageSummary = (usage: CertificateUsageResource) => {
    const certificateUsages = [
        ...usage.ProjectUsages.map((projectUsage) => {
            const item: CertificateUsageEntry = {
                ownerName: projectUsage.Name,
                ownerLink: routeLinks.project(projectUsage).root,
                ownerType: "Project",
            };
            return item;
        }),
        ...usage.LibraryVariableSetUsages.map((lvsUsage) => {
            const item: CertificateUsageEntry = {
                ownerName: lvsUsage.Name,
                ownerLink: routeLinks.library.variableSet(lvsUsage.Id),
                ownerType: "Variable Set",
            };
            return item;
        }),
        ...usage.TenantUsages.map((tenantUsage) => {
            const item: CertificateUsageEntry = {
                ownerName: tenantUsage.Name,
                ownerLink: routeLinks.tenant(tenantUsage.Id).variables().pathname,
                ownerType: "Tenant",
            };
            return item;
        }),
        ...usage.DeploymentTargetUsages.map((deploymentTarget) => {
            const item: CertificateUsageEntry = {
                ownerName: deploymentTarget.Name,
                ownerLink: routeLinks.infrastructure.deploymentTarget(deploymentTarget.Id).root,
                ownerType: "Deployment Target",
            };
            return item;
        }),
    ];
    return certificateUsages;
};
