import type { ActionTemplateParameterResource, ActionProperties, PackageReference, StepPackageInputs } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useCallback, useEffect, useMemo } from "react";
import { useAccountsFromContext, useRefreshAccountsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessAccountsContextProvider";
import { useFeedsFromContext, useRefreshFeedsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import { ActionPropertiesEditor } from "~/components/ActionPropertiesEditor/ActionPropertiesEditor";
import { enforceNewActionFeatures } from "~/components/ActionPropertiesEditor/enforceNewActionFeatures";
import type { ActionPlugin } from "~/components/Actions/pluginRegistry";
import type { ActionTemplateStepInputDependencies } from "~/components/StepPackageEditor/StepInputDependencies";
import type { FieldErrors } from "../DataBaseComponent/Errors";

interface ActionTemplateEditorInternalProps {
    localNames: string[];
    plugin: ActionPlugin;
    inputs?: StepPackageInputs;
    properties: ActionProperties;
    packages: Array<PackageReference>;
    errors: FieldErrors | undefined; // only used for shouldComponentUpdate
    busy: Promise<unknown> | boolean | undefined;
    expandedByDefault: boolean;
    parameters: ActionTemplateParameterResource[];
    getFieldError(field: string): string;
    setInputs(inputs: StepPackageInputs, callback?: () => void): void;
    setProperties(properties: Partial<ActionProperties>, initialise?: boolean, callback?: () => void): void;
    setPackages(packages: Array<PackageReference>, initialise?: boolean): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    isNew: boolean;
}

export function ActionTemplatePropertiesEditor(props: ActionTemplateEditorInternalProps) {
    const { packages, setPackages, parameters, localNames } = props;
    useEffect(() => {
        const properties = { ...props.properties };

        const enabledFeatures = enforceNewActionFeatures(props.plugin, props.properties, props.isNew);
        if (!!enabledFeatures) {
            // Server will strip empty properties, so only set this if we need to, otherwise this triggers our save-changes dialog unnecessarily.
            properties["Octopus.Action.EnabledFeatures"] = enforceNewActionFeatures(props.plugin, props.properties, props.isNew);
        }

        props.setProperties(properties, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    const accounts = useAccountsFromContext();
    const refreshAccounts = useRefreshAccountsFromContext();
    const stableSetPackages = useCallback((getUpdatedPackages: (prev: Array<PackageReference>) => Array<PackageReference>) => setPackages(getUpdatedPackages(packages)), [packages, setPackages]);
    const stableRefreshFeeds = useCallback(async () => {
        await refreshFeeds();
    }, [refreshFeeds]);
    const stableRefreshAccounts = useCallback(async () => {
        await refreshAccounts();
    }, [refreshAccounts]);
    const dependencies = useMemo<ActionTemplateStepInputDependencies>(
        () => ({
            feeds: feeds,
            refreshFeeds: stableRefreshFeeds,
            parameters: parameters,
            setPackages: stableSetPackages,
            localNames: localNames,
            accounts,
            refreshAccounts: stableRefreshAccounts,
        }),
        [feeds, localNames, parameters, stableRefreshFeeds, stableSetPackages, accounts, stableRefreshAccounts]
    );

    if (!props.plugin.edit) {
        throw new Error(`Plugin ${props.plugin.actionType} is missing 'Edit' property`);
    }

    return (
        <ActionPropertiesEditor
            inputDependencies={dependencies}
            plugin={props.plugin}
            properties={props.properties}
            packages={packages}
            doBusyTask={props.doBusyTask}
            busy={props.busy}
            inputs={props.inputs}
            setInputs={props.setInputs}
            setProperties={props.setProperties}
            setPackages={setPackages}
            getFieldError={props.getFieldError}
            errors={props.errors}
            expandedByDefault={props.expandedByDefault}
        />
    );
}
