import type { InsightsReportResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import type { AnalyticInsightsDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticInsightsDispatch } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import DataBaseComponent from "~/components/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import { required, Text } from "~/components/form";

interface Props {
    onReportCreated: (report: InsightsReportResource) => void;
}

interface InnerProps extends Props {
    dispatchAction: AnalyticInsightsDispatcher;
}

interface AddInsightsReportDialogState {
    name: string;
    description: string;
}

class AddInsightsReportDialogInner extends DataBaseComponent<InnerProps, AddInsightsReportDialogState> {
    constructor(props: InnerProps) {
        super(props);
        this.state = {
            name: "",
            description: "",
        };
    }

    save = async () => {
        return this.doBusyTask(async () => {
            const report = await repository.InsightsReports.create({ Name: this.state.name, Description: this.state.description });

            this.props.dispatchAction("Create Insights Report", { action: Action.Save });

            this.props.onReportCreated(report);
        });
    };

    render() {
        return (
            <SaveDialogLayout title="Add new Insights Report" busy={this.state.busy} errors={this.errors} onSaveClick={this.save}>
                <Text label="New report name" value={this.state.name} onChange={(value) => this.setState({ name: value })} validate={required("Please enter a report name")} autoFocus />
                <Text label="Description" value={this.state.description} onChange={(value) => this.setState({ description: value })} />
            </SaveDialogLayout>
        );
    }
}

function AddInsightsReportDialog(props: Props) {
    const dispatchAction = useAnalyticInsightsDispatch();

    return <AddInsightsReportDialogInner {...props} dispatchAction={dispatchAction} />;
}

export default AddInsightsReportDialog;
