import React from "react";
import { Action } from "~/analytics/Analytics";
import { usePageEventDispatcher } from "~/analytics/PageEventDispatcher";
import { createActionTypeField, createBoolField, createResourceTypeField } from "~/analytics/fields";
import { GitEventFieldKeys } from "~/areas/projects/components/VersionControl/Analytics/GitEventFields";

export interface SaveDeploymentSettingsOptions {
    isVersionControlled: boolean;
    isDefaultBranch: boolean;
    hasCommitMessage: boolean;
}

export type SaveDeploymentSettingsDispatcher = (options: SaveDeploymentSettingsOptions) => void;

export function useNotifySaveDeploymentSettings(): SaveDeploymentSettingsDispatcher {
    const dispatcher = usePageEventDispatcher();

    return React.useCallback(
        (options: SaveDeploymentSettingsOptions) =>
            dispatcher("Save DeploymentSettings", {
                ...createResourceTypeField("Deployment settings"),
                ...createActionTypeField(options.isVersionControlled ? Action.Commit : Action.Save),
                ...createBoolField(GitEventFieldKeys.IsDefaultBranch, options.isDefaultBranch),
                ...createBoolField(GitEventFieldKeys.CommitMessage, options.hasCommitMessage),
            }),
        [dispatcher]
    );
}
