import React from "react";
import { BooleanRadioButtonGroup, ExpandableFormSection, RadioButton, Summary } from "~/components/form";

interface Props {
    isEnabled: boolean;
    onChange: (isEnabled: boolean) => void;
}

export function GitHubStatusCheckPublishingFeature({ isEnabled, onChange }: Props) {
    return (
        <ExpandableFormSection
            key="IsGitHubStatusCheckPublishingEnabled"
            errorKey="IsGitHubStatusCheckPublishingEnabled"
            title="GitHub Status Check Publishing"
            summary={isEnabled ? Summary.default("Enabled") : Summary.summary("Disabled")}
            help="Enable/Disable publishing deployment events as GitHub Status Checks"
        >
            <BooleanRadioButtonGroup value={isEnabled} onChange={onChange}>
                <RadioButton value={true} label="Enabled" />
                <RadioButton value={false} label="Disabled" isDefault={true} />
            </BooleanRadioButtonGroup>
        </ExpandableFormSection>
    );
}
