import { SpaceSwitcher as DesignSystemSpaceSwitcher } from "@octopusdeploy/main-navigation";
import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import type { MouseEventHandler } from "react";
import { useCallback, useState } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { client } from "~/clientInstance";
import Dialog from "~/components/Dialog/Dialog";
import { SpaceLimitReachedDialogLayout } from "~/components/LicenseLimitReachedDialogLayout/index";
import { SpaceSwitcher } from "~/components/Navbar/SpaceSwitcher/SpaceSwitcher";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { isSpaceNotFound, isSpecificSpaceContext } from "~/components/StandardLayout/SpaceLoader";
import type { SpaceContext } from "~/components/StandardLayout/SpaceLoader";
import { useMenuState } from "~/primitiveComponents/navigation/Menu/useMenuState";
import routeLinks from "~/routeLinks";
import styles from "./SpacesMenu.module.less";
const defaultLogo = require("~/resources/images/Octopus-96x96.png");

interface SpacesMenuProps {
    spaceContext: SpaceContext;
    isNavigationVisualUpliftEnabled: boolean;
}

type SpacesMenuInternalProps = Omit<SpacesMenuProps, "isNavigationVisualUpliftEnabled">;

function SpacesMenuInternal({ spaceContext }: SpacesMenuInternalProps) {
    const spaces = useSelector((state: GlobalState) => (state.configurationArea.spaces ? state.configurationArea.spaces.usersAccessibleSpaces : null)) ?? [];
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
    const [openMenu, menuState, targetAriaAttributes] = useMenuState();

    const hasAccessToMultipleSpacesOrCanAddSpace = spaces.length > 1 || isAllowed({ permission: Permission.SpaceCreate });

    const onClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (event) => {
            event.preventDefault();
            if (hasAccessToMultipleSpacesOrCanAddSpace) {
                openMenu(event);
            }
        },
        [hasAccessToMultipleSpacesOrCanAddSpace, openMenu]
    );

    const selectedSpace = isSpecificSpaceContext(spaceContext) ? spaces.find((s) => s.Id === spaceContext.Id) : undefined;
    const logoUrl = selectedSpace === undefined ? defaultLogo : client.resolve(selectedSpace.Links["Logo"]);

    const onClose = menuState.onClose;
    const onUpgradeSpacesDialogRequested = useCallback(() => {
        onClose();
        setOpenUpgradeDialog(true);
    }, [onClose]);

    return (
        <div className={styles.spaceMenu}>
            <a onClick={onClick} href="#" aria-controls={targetAriaAttributes["aria-controls"]} aria-haspopup={targetAriaAttributes["aria-haspopup"]} aria-expanded={targetAriaAttributes["aria-expanded"]}>
                <SpaceIcon logo={logoUrl} />
                <span className={styles.title}>{selectedSpace && selectedSpace.Name}</span>
                {hasAccessToMultipleSpacesOrCanAddSpace ? <i className={cn("fa-solid fa-caret-down", styles.caret)} /> : ""}
            </a>

            {hasAccessToMultipleSpacesOrCanAddSpace && <SpaceSwitcher onUpgradeSpacesDialogRequested={onUpgradeSpacesDialogRequested} spaces={spaces} selected={selectedSpace} spaceContext={spaceContext} menuState={menuState} />}

            <Dialog open={openUpgradeDialog}>
                <SpaceLimitReachedDialogLayout onActionClick={() => setOpenUpgradeDialog(false)} />
            </Dialog>
        </div>
    );
}

function SpacesMenuUpliftInternal({ spaceContext }: SpacesMenuInternalProps) {
    const navigation = useSpaceAwareNavigation();
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);

    const currentSpace = isSpecificSpaceContext(spaceContext) ? spaceContext : undefined;

    const onUpgradeSpacesDialogRequested = useCallback(() => {
        setOpenUpgradeDialog(true);
    }, []);

    const onNewSpaceRequested = useCallback(() => {
        const routeLinksRoot = isSpaceNotFound(spaceContext) ? routeLinks.withoutSpace() : routeLinks;
        navigation.navigate(routeLinksRoot.configuration.spaces.root);
    }, [navigation, spaceContext]);

    return (
        <div className={styles.spaceMenuUplift}>
            <DesignSystemSpaceSwitcher currentSpace={currentSpace} onNewSpaceRequested={onNewSpaceRequested} onUnlockMoreSpacesRequested={onUpgradeSpacesDialogRequested} getSpaceHref={getSpaceHref} />
            <Dialog open={openUpgradeDialog}>
                <SpaceLimitReachedDialogLayout onActionClick={() => setOpenUpgradeDialog(false)} />
            </Dialog>
        </div>
    );
}

export function SpacesMenu({ isNavigationVisualUpliftEnabled, ...props }: SpacesMenuProps) {
    return isNavigationVisualUpliftEnabled ? <SpacesMenuUpliftInternal {...props} /> : <SpacesMenuInternal {...props} />;
}

interface SpaceIconProps {
    logo: string;
}

const SpaceIcon = (props: SpaceIconProps) => (
    <span className={styles.spaceIcon}>
        <img src={props.logo} />
    </span>
);

function getSpaceHref(space: SpaceResource) {
    return routeLinks.space(space.Id);
}
