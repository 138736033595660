/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Permission } from "@octopusdeploy/octopus-server-client";
import { compact } from "lodash";
import moment from "moment";
import * as React from "react";
import { repository, session, client } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { NewFeatureChip } from "~/components/Chips";
import NavigationSidebarLayout, { Navigation } from "~/components/NavigationSidebarLayout";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import routeLinks from "../../../../routeLinks";
import { TelemetryHasBeenIntroducedLocalStorageKey } from "../Telemetry/TelemetryLayout";

interface ConfigurationLayoutState {
    hideAnyAuthenticationProvidersSupportPasswordManagement: boolean;
}

class ConfigurationLayout extends BaseComponent<{}, ConfigurationLayoutState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            // flipped to 'hide' so it'll just disappear in the case where it's not supported
            // instead of showing up after the data fetch, and without delaying display of whole menu for data load
            hideAnyAuthenticationProvidersSupportPasswordManagement: false,
        };
    }

    async componentDidMount() {
        if (isAllowed({ permission: Permission.AdministerSystem })) {
            // we need to use local storage here as every nav item click remounts this whole component which sets state value back to null
            // we can't call repository in the constructor. Therefore the new chip will keep appearing and dissapearing.
            // Using localstorge means once it's set we don't need to worry about this, also this value should not
            // change after being set. so this is only so that we can do a smooth rendering of the new chip
            const telemetryConfiguration = await repository.TelemetryConfiguration.get();
            localStorage.setItem("telemetryShowAsNewUntil", telemetryConfiguration.ShowAsNewUntil);
        }

        const document = await repository.Authentication.get();
        this.setState({
            hideAnyAuthenticationProvidersSupportPasswordManagement: !document.AnyAuthenticationProvidersSupportPasswordManagement,
        });
    }

    render() {
        const showAsNew = moment(localStorage.getItem("telemetryShowAsNewUntil") || moment().subtract(1, "seconds")).isAfter(moment());
        const TelemetryHasBeenIntroduced = localStorage.getItem(TelemetryHasBeenIntroducedLocalStorageKey) === "true";

        const navLinks = compact([
            Navigation.navItem("Audit", routeLinks.configuration.audit(client.spaceId), undefined, { permission: [Permission.AdministerSystem, Permission.EventView], wildcard: true }),
            Navigation.navItem("Backup", routeLinks.configuration.backup, undefined, { permission: Permission.AdministerSystem }),
            Navigation.navItem("Diagnostics", routeLinks.configuration.diagnostics.root, undefined, { permission: Permission.AdministerSystem }),
            Navigation.navItem("Features", routeLinks.configuration.features, undefined, { permission: [Permission.AdministerSystem, Permission.ConfigureServer] }),
            Navigation.navItem("Git", routeLinks.configuration.git, undefined, { permission: [Permission.AdministerSystem, Permission.ConfigureServer] }),
            client.getSystemLink((link) => link.LetsEncryptConfiguration) ? Navigation.navItem("Let's Encrypt", routeLinks.configuration.letsEncrypt.root, undefined, { permission: Permission.AdministerSystem }) : null,
            Navigation.navItem("License", routeLinks.configuration.license, undefined, { permission: Permission.AdministerSystem }),
            Navigation.navItem("Maintenance", routeLinks.configuration.maintenance, undefined, { permission: Permission.AdministerSystem }),
            Navigation.navItem("Nodes", routeLinks.configuration.nodes.root, undefined, { permission: Permission.AdministerSystem }),
            Navigation.navItem("Performance", routeLinks.configuration.performance, undefined, { permission: Permission.ConfigureServer }),
            Navigation.navItem("Settings", routeLinks.configuration.settings.root, undefined, { permission: [Permission.AdministerSystem, Permission.ConfigureServer] }),
            Navigation.navItem("Extensions", routeLinks.configuration.extensions.root, undefined, { permission: [Permission.ConfigureServer] }),
            Navigation.navItem("SMTP", routeLinks.configuration.smtp, undefined, { permission: [Permission.AdministerSystem, Permission.ConfigureServer] }),
            Navigation.navItem("Subscriptions", routeLinks.configuration.subscriptions.root, undefined, { permission: [Permission.SubscriptionView] }),
            session.currentPermissions!.scopeToSpace(repository.spaceId).hasAnyPermissions()
                ? Navigation.navItem("Spaces", routeLinks.configuration.spaces.root, undefined)
                : Navigation.navItem("Spaces", routeLinks.configuration.spaces.root, undefined, { permission: [Permission.SpaceView, Permission.SpaceEdit, Permission.SpaceCreate, Permission.SpaceDelete] }),
            Navigation.navItem("Teams", routeLinks.configuration.teams.root(), undefined, { permission: [Permission.TeamEdit, Permission.TeamView] }),
            Navigation.navItem(<>Telemetry {TelemetryHasBeenIntroduced ? "" : showAsNew && <NewFeatureChip />}</>, routeLinks.configuration.telemetry, undefined, { permission: [Permission.AdministerSystem] }),
            Navigation.navItem("Test Permissions", routeLinks.configuration.testPermissions, undefined, { permission: [Permission.TeamEdit, Permission.UserView] }),
            Navigation.navItem("Thumbprint", routeLinks.configuration.thumbprint, undefined, { permission: [Permission.AdministerSystem, Permission.MachineEdit], wildcard: true }),
            Navigation.navItem("Users", routeLinks.configuration.users.root, undefined, { permission: [Permission.AdministerSystem, Permission.UserView] }),
            !this.state.hideAnyAuthenticationProvidersSupportPasswordManagement && isAllowed({ permission: Permission.UserInvite })
                ? Navigation.navItem("User Invites", routeLinks.configuration.userInvites, undefined, { permission: [Permission.AdministerSystem, Permission.UserInvite] })
                : undefined,
            Navigation.navItem("User Roles", routeLinks.configuration.roles.root, undefined, { permission: [Permission.AdministerSystem, Permission.UserRoleView] }),
        ]);

        return (
            <main id="maincontent">
                <AreaTitle link={routeLinks.configuration.root} title="Configuration" />
                <NavigationSidebarLayout navLinks={navLinks!} content={this.props.children} />
            </main>
        );
    }
}

export default ConfigurationLayout;
