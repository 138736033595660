import React from "react";
import styles from "./style.module.less";

export type DynamicEnvironmentDeprovisionedIconProps = {
    title: string;
};

export function DynamicEnvironmentDeprovisionedIcon({ title }: DynamicEnvironmentDeprovisionedIconProps) {
    return (
        <svg viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.dynamicEnvironmentStateIcon}>
            <title>{title}</title>
            <path
                d="M4.225 0.553125L4 1H1C0.446875 1 0 1.44687 0 2C0 2.55312 0.446875 3 1 3H13C13.5531 3 14 2.55312 14 2C14 1.44687 13.5531 1 13 1H10L9.775 0.553125C9.60625 0.2125 9.25938 0 8.88125 0H5.11875C4.74062 0 4.39375 0.2125 4.225 0.553125ZM13 4H1L1.6625 14.5938C1.7125 15.3844 2.36875 16 3.15937 16H10.8406C11.6312 16 12.2875 15.3844 12.3375 14.5938L13 4Z"
                fill="#68778D"
            />
        </svg>
    );
}
