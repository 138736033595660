import type { AccountResource, GitRefResource } from "@octopusdeploy/octopus-server-client";
import { AccountType, ControlType, VariableType } from "@octopusdeploy/octopus-server-client";
import type { AccountReference, EmptyInitialValue } from "@octopusdeploy/step-inputs";
import type { AccountTypeDefinition, ObjectRuntimeInputs, PathToInput, PlainObjectTypeDefinition } from "@octopusdeploy/step-runtime-inputs";
import { createInputValueAccessor, getPathToInput, isNotBoundValue } from "@octopusdeploy/step-runtime-inputs";
import type { AccountSelectorComponent } from "@octopusdeploy/step-ui";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import React from "react";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { convertFromRuntimeAccountSelection, convertToRuntimeAccountSelection } from "~/components/StepPackageEditor/Inputs/Components/AccountSelector/AccountSelectionConverters";
import { Note } from "~/components/StepPackageEditor/Inputs/Note/Note";
import type { StepInputDependencies } from "~/components/StepPackageEditor/StepInputDependencies";
import { isProjectStepInputDependencies } from "~/components/StepPackageEditor/StepInputDependencies";
import type { InputSummary } from "~/components/StepPackageEditor/Summary/InputSummary";
import { AccountSelect, BoundAccountSelect } from "~/components/form/AccountSelect/AccountSelect";
import type { VariableSelectProps } from "~/components/form/VariableSelect/VariableSelect";
import { VariableSelect } from "~/components/form/VariableSelect/VariableSelect";
import { loadProjectVariableNames } from "~/utils/LoadProjectVariables/loadProjectVariables";
import { getSchemaForInputAtPath } from "../../schemaTraversal";

export function getAccountSelectorSummary<StepInputs>(content: AccountSelectorComponent, inputs: ObjectRuntimeInputs<StepInputs>, accounts: AccountResource[]): InputSummary {
    const inputAccessor = createInputValueAccessor(content.input);
    const inputValue = inputAccessor.getInputValue(inputs);
    if (isNotBoundValue(inputValue)) {
        const accountId = convertToRuntimeAccountSelection(inputValue);
        const account = accounts.find((acc) => acc.Id === accountId);
        return { isDefaultValue: false, value: account?.Name ?? accountId };
    } else {
        return { isDefaultValue: false, value: inputValue.expression };
    }
}

interface BindableAccountSelectorProps<StepInputs> {
    configuredStepUIProps: AccountSelectorComponent;
    inputs: ObjectRuntimeInputs<StepInputs>;
    getInputSchema: (inputs: ObjectRuntimeInputs<StepInputs>) => PlainObjectTypeDefinition;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    dependencies: StepInputDependencies;
    getFieldError: (path: PathToInput) => string;
    localNames: string[] | undefined;
    doBusyTask: DoBusyTask;
}

export function getErrorPathForAccountReference(component: AccountSelectorComponent): PathToInput[] {
    return [getPathToInput(component.input)];
}

function getSchemaForAccountInputAtPath<StepInputs>(pathToInput: PathToInput, inputSchema: PlainObjectTypeDefinition): AccountTypeDefinition[] {
    const inputSchemaAtPath = getSchemaForInputAtPath(pathToInput, inputSchema);

    if (inputSchemaAtPath.type === "account") return [inputSchemaAtPath];
    else throw Error("The provided input to the schema is not an account type");
}

const cloudControlTypes = [ControlType.GoogleCloudAccount, ControlType.AzureAccount, ControlType.AmazonWebServicesAccount];
const cloudVariableTypes = [VariableType.GoogleCloudAccount, VariableType.AzureAccount, VariableType.AmazonWebServicesAccount];

type CloudAccountVariableSelectorProps = Omit<VariableSelectProps, "fetchVariables">;

function CloudAccountVariableSelector(props: CloudAccountVariableSelectorProps) {
    return <VariableSelect {...props} fetchVariables={() => loadProjectVariableNames(props.projectId, props.gitRef, cloudVariableTypes, cloudControlTypes)} />;
}

export function BindableAccountSelector<StepInputs>(props: BindableAccountSelectorProps<StepInputs>) {
    const inputAccessor = createInputValueAccessor<StepInputs, AccountReference | EmptyInitialValue>(props.configuredStepUIProps.input);
    const inputValue = inputAccessor.getInputValue(props.inputs);
    const inputPath = getPathToInput(props.configuredStepUIProps.input);
    const inputAccountSchemas = getSchemaForAccountInputAtPath(inputPath, props.getInputSchema(props.inputs));

    const accountTypes = inputAccountSchemas.map((schema) => {
        switch (schema.accountType) {
            case "AmazonWebServicesAccount":
                return AccountType.AmazonWebServicesAccount;
            case "AzureServicePrincipal":
                return AccountType.AzureServicePrincipal;
            case "GoogleCloudAccount":
                return AccountType.GoogleCloudAccount;
            case "SshKeyPair":
                return AccountType.SshKeyPair;
            case "Token":
                return AccountType.Token;
            case "UsernamePassword":
                return AccountType.UsernamePassword;
            default:
                exhaustiveCheck(schema.accountType, "Unsupported account type");
        }
    });

    const onBoundAccountChange = (newValue: string) => {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        if (typeof newValue === "string" && (newValue as string).startsWith("#{")) {
            const updatedInputs = inputAccessor.changeInputValue(props.inputs, {
                type: "bound",
                expression: newValue,
            });
            props.setInputs(updatedInputs);
        } else {
            const updatedInputs = inputAccessor.changeInputValue(props.inputs, convertFromRuntimeAccountSelection(newValue));
            props.setInputs(updatedInputs);
        }
    };

    const renderAccountVariableSelector = (projectId: string, gitRef: GitRefResource | undefined) => (
        <CloudAccountVariableSelector projectId={projectId} gitRef={gitRef} allowClear={true} value={convertToRuntimeAccountSelection(inputValue)} onChange={onBoundAccountChange} />
    );

    const renderAccountSelector = () => (
        <>
            <AccountSelect
                label={props.configuredStepUIProps.label}
                onRequestRefresh={props.dependencies.refreshAccounts}
                value={convertToRuntimeAccountSelection(inputValue)}
                type={accountTypes}
                allowClear={true}
                onChange={(newValue) => {
                    const updatedInputs = inputAccessor.changeInputValue(props.inputs, convertFromRuntimeAccountSelection(newValue));
                    props.setInputs(updatedInputs);
                }}
                items={props.dependencies.accounts}
                error={props.getFieldError(inputPath)}
            />
            <Note note={props.configuredStepUIProps.note} />
        </>
    );

    const supportsAccountVariables = (accountType: AccountType) => accountType === AccountType.AmazonWebServicesAccount || accountType === AccountType.AzureServicePrincipal || accountType === AccountType.GoogleCloudAccount;
    const hasOnlyAccountTypesThatSupportAccountVariables = accountTypes.every(supportsAccountVariables);

    let projectId = "";
    let gitRef: GitRefResource | undefined = undefined;

    if (isProjectStepInputDependencies(props.dependencies)) {
        projectId = props.dependencies.projectId;
        gitRef = props.dependencies.gitRef;
    }

    return projectId && hasOnlyAccountTypesThatSupportAccountVariables ? (
        <>
            <BoundAccountSelect
                label={props.configuredStepUIProps.label}
                onRequestRefresh={props.dependencies.refreshAccounts}
                value={convertToRuntimeAccountSelection(inputValue)}
                type={accountTypes}
                allowClear={true}
                onChange={onBoundAccountChange}
                items={props.dependencies.accounts}
                variableComponent={renderAccountVariableSelector(projectId, gitRef)}
                error={props.getFieldError(inputPath)}
            />
            <Note note={props.configuredStepUIProps.note} />
        </>
    ) : (
        renderAccountSelector()
    );
}
