import type { VariableSetResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { CloudConnectionStatus } from "~/areas/projects/components/CloudConnection/CloudConnectionStatusIcon";
import CloudConnectionTile from "~/areas/projects/components/CloudConnection/CloudConnectionTile";
import { getVariable } from "~/areas/projects/components/CloudConnection/CloudConnectionUtilities";
import { VariableNames } from "~/areas/projects/components/CloudConnection/CloudConnectionVariables";
import type { CloudConnectionItem, ConfigurationStatus } from "~/areas/projects/components/CloudConnection/CloudConnections";
import { CloudConnectionUsageType } from "~/areas/projects/components/CloudConnection/CloudConnections";
import type { LoadedLibraryVariableSets } from "~/areas/projects/components/Variables/AllVariables/AllVariables";

export function AwsTileStatus(connectionType: CloudConnectionUsageType, variables: VariableSetResource | undefined, libraryVariableSets: LoadedLibraryVariableSets[] | undefined): ConfigurationStatus {
    if (variables && connectionType === CloudConnectionUsageType.TargetDiscovery) {
        if (getVariable(VariableNames.Aws.Regions, variables, libraryVariableSets)) {
            return "Configured";
        } else {
            const account = getVariable(VariableNames.Aws.Account, variables, libraryVariableSets);
            const roleArn = getVariable(VariableNames.Aws.RoleArn, variables, libraryVariableSets);
            const roleSessionName = getVariable(VariableNames.Aws.RoleSessionName, variables, libraryVariableSets);
            const roleSessionDuration = getVariable(VariableNames.Aws.RoleSessionDuration, variables, libraryVariableSets);
            const roleExternalId = getVariable(VariableNames.Aws.RoleExternalId, variables, libraryVariableSets);

            if (account || roleArn || roleSessionName || roleSessionDuration || roleExternalId) {
                return "Region Required";
            }
        }
    }
    return "Configure Now";
}

const AwsCloudConnectionTile: React.FC<CloudConnectionItem> = (props) => {
    let configurationWords = "Configure";
    let connectionStatus: CloudConnectionStatus | null = null;
    if (props.connectionType === CloudConnectionUsageType.TargetDiscovery && props.tileDetails === "Configured") {
        configurationWords = "Cloud target discovery enabled";
        connectionStatus = CloudConnectionStatus.Ok;
    } else if (props.connectionType === CloudConnectionUsageType.TargetDiscovery && props.tileDetails === "Region Required") {
        configurationWords = "Region not specified";
        connectionStatus = CloudConnectionStatus.Warning;
    }

    return <CloudConnectionTile provider={"Amazon Web Services"} configurationStatus={configurationWords} connectionStatus={connectionStatus}></CloudConnectionTile>;
};

export default AwsCloudConnectionTile;
