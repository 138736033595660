import { Permission } from "@octopusdeploy/octopus-server-client";
import type { TagSetResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { cloneDeep } from "lodash";
import * as React from "react";
import { TenantTagMultiSelect } from "~/components/MultiSelect/TenantTagMultiSelect";
import InternalLink from "~/components/Navigation/InternalLink";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import routeLinks from "~/routeLinks";
import { repository } from "../../../clientInstance";
import type { DataBaseComponentState } from "../../../components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "../../../components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "../../../components/DialogLayout/SaveDialogLayout";

interface AddTagsToTenantDialogProps {
    tenant: TenantResource;
    tagSets: TagSetResource[];
    onUpdated(tenant: TenantResource): void;
}

interface AddTagsToTenantDialogState extends DataBaseComponentState {
    selectedTags: string[];
}

export default class AddTagsToTenantDialog extends DataBaseComponent<AddTagsToTenantDialogProps, AddTagsToTenantDialogState> {
    constructor(props: AddTagsToTenantDialogProps) {
        super(props);
        this.state = {
            selectedTags: props.tenant.TenantTags,
        };
    }

    save = async () => {
        return this.doBusyTask(async () => {
            let tenant = cloneDeep(this.props.tenant);
            tenant.TenantTags = this.state.selectedTags;
            tenant = await repository.Tenants.save(tenant);
            setTimeout(() => this.props.onUpdated(tenant), 0);
            return true;
        });
    };

    render() {
        const tagSetsEmpty = this.props.tagSets.length > 0 && !this.props.tagSets.some((t) => t.Tags.length > 0);
        return (
            <SaveDialogLayout
                title={"Manage Tenant Tags"}
                busy={this.state.busy}
                errors={this.errors}
                onSaveClick={this.save}
                savePermission={{ permission: Permission.TenantEdit, tenant: this.props.tenant.Id }}
                saveButtonDisabled={this.props.tagSets.length === 0 || tagSetsEmpty}
            >
                {this.props.tagSets && this.props.tagSets.length === 0 && (
                    <Callout type={CalloutType.Information} title="No Tenant Tag Sets">
                        Create your first <InternalLink to={routeLinks.library.tagSets.root}>Tenant Tag Set</InternalLink> now.
                    </Callout>
                )}
                {this.props.tagSets && tagSetsEmpty && (
                    <Callout type={CalloutType.Information} title="Tag Sets Are Empty">
                        Add tags to your <InternalLink to={routeLinks.library.tagSets.root}>Tenant Tag Sets</InternalLink> now.
                    </Callout>
                )}
                <TenantTagMultiSelect onChange={(tags) => this.setState({ selectedTags: tags })} doBusyTask={this.doBusyTask} autoFocus value={this.state.selectedTags} items={this.props.tagSets} />
            </SaveDialogLayout>
        );
    }
}
