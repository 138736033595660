/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { SensitiveValue } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { FormFieldProps } from "~/components/form";
import type { SensitiveOtherProps, StatelessSensitiveProps } from "~/components/form/Sensitive/Sensitive";

interface IncludeVariableSubstitutionState {
    warning: string | null;
}

const getWarningIfHasVariableSubstitutionSyntax = (value: string | null | undefined) => {
    if (value && value.includes("##{")) {
        return "The '##{' characters will be escaped and won't be evaluated for variable substitution.";
    }

    return null;
};

export const IncludeVariableSubstitutionWarning = (Comp: React.ComponentClass<StatelessSensitiveProps | (FormFieldProps<SensitiveValue> & SensitiveOtherProps)>) => {
    type TProps = StatelessSensitiveProps;

    return class extends React.Component<TProps, IncludeVariableSubstitutionState> {
        constructor(props: TProps) {
            super(props);

            this.state = {
                warning: "",
            };
        }

        render() {
            const { warning, onChange, ...rest } = this.props;

            let resultWarning = this.state.warning;
            if (warning) {
                resultWarning += warning;
            }

            return <Comp {...rest} onChange={this.onChange} warning={resultWarning!} />;
        }

        private onChange = (newValue: SensitiveValue) => {
            let warning: string | null = "";
            if (newValue.HasValue) {
                warning = getWarningIfHasVariableSubstitutionSyntax(newValue.NewValue);
                if (warning) {
                    warning += " ";
                }
            }

            if (this.state.warning !== warning) {
                this.setState({ warning });
            }

            if (this.props.onChange) {
                this.props.onChange(newValue);
            }
        };
    };
};
