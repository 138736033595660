import type { AuditArchiveResource, PagingCollection } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import OpenDeleteDialogButton from "app/components/Button/OpenDeleteDialogButton";
import * as React from "react";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList/ActionList";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import PagingDataTable from "~/components/PagingDataTable";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import PermissionCheck, { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import { Text } from "~/components/form";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import ScrollToTop from "~/primitiveComponents/navigation/ScrollToTop";
import routeLinks from "~/routeLinks";
import ByteSizeFormatter from "~/utils/ByteSizeFormatter/ByteSizeFormatter";
import DateFormatter from "~/utils/DateFormatter";

const title = "Manage archived audit logs";

const AuditArchivePage: React.FC = () => (
    <FeatureToggleVisibility toggle={"EventRetentionFeatureToggle"} disabledContent={<RedirectAs404 />}>
        <AuditArchiveLayout />
    </FeatureToggleVisibility>
);

interface AuditLayoutState extends DataBaseComponentState {
    selected: Array<AuditArchiveResource>;
    confirmationInputValue: string;
    isSure: boolean;
    initialData?: PagingCollection<AuditArchiveResource>;
    currentItems: Array<AuditArchiveResource>;
}

class AuditArchiveLayout extends DataBaseComponent<{}, AuditLayoutState> {
    private readonly headerColumns = ["Audit log file name", "Archived on", "Size"];
    private deleteConfirmPhrase: string = "delete audit log files";

    constructor(props: {}) {
        super(props);

        this.state = {
            selected: [],
            confirmationInputValue: "",
            isSure: false,
            currentItems: [],
        };
    }

    async componentDidMount() {
        await this.doBusyTask(() => this.reload());
    }

    render() {
        const actions = [this.deleteButton()];

        return (
            <PaperLayout busy={this.state.busy} errors={this.errors} sectionControl={<ActionList actions={actions} />} title={title} breadcrumbTitle={"Audit"} breadcrumbPath={routeLinks.configuration.auditBaseRoute}>
                <PermissionCheck
                    permission={Permission.EventRetentionView}
                    wildcard={true}
                    alternate={
                        <Callout type={CalloutType.Information} title={"Permission required"}>
                            The {Permission.EventRetentionView} permission is required to view the audit log
                        </Callout>
                    }
                >
                    {this.renderContent()}
                </PermissionCheck>
                <ScrollToTop />
            </PaperLayout>
        );
    }

    private renderContent() {
        return (
            this.state.initialData && (
                <PagingDataTable<AuditArchiveResource>
                    showPagingInNumberedStyle={true}
                    headerColumns={this.headerColumns}
                    initialData={this.state.initialData}
                    onRow={(row) => this.onRow(row)}
                    onItemsChecked={hasPermission(Permission.EventRetentionDelete) ? (checked) => this.onItemsChecked(checked) : undefined}
                    onNewItems={async (items) => {
                        this.setState({ currentItems: items });
                        return items;
                    }}
                />
            )
        );
    }

    private onRow(resource: AuditArchiveResource) {
        return [
            <a href={resource.Links.Self}>
                <em className="fa-solid fa-download" />
                &nbsp;
                {resource.Name}
            </a>,
            DateFormatter.dateToShortFormat(resource.ModifiedDate),
            ByteSizeFormatter(resource.FileBytes),
        ];
    }

    private deleteButton() {
        const multipleSelected = this.state.selected.length > 1;
        return (
            <PermissionCheck permission={Permission.EventRetentionDelete} wildcard={true}>
                <OpenDeleteDialogButton
                    dialogTitle="Are you sure you want to delete audit log files?"
                    label="Delete selected"
                    disableDeleteButton={!this.state.isSure}
                    disabled={!this.state.selected.length}
                    onDeleteClick={() => this.delete()}
                    dialogOnClose={() => this.setState({ confirmationInputValue: "", isSure: false })}
                >
                    <Callout title="This is a destructive action" type={CalloutType.Danger}>
                        This action <strong>cannot</strong> be undone. This will permanently delete the selected audit log {multipleSelected ? "files" : "file"}.
                    </Callout>
                    <div>
                        <p>
                            Please type in <strong>{this.deleteConfirmPhrase}</strong> to confirm.
                        </p>
                        <Text value={this.state.confirmationInputValue} onChange={this.confirmationOnChange} />
                    </div>
                </OpenDeleteDialogButton>
            </PermissionCheck>
        );
    }

    private confirmationOnChange = (value: string) => {
        const isSure = value === this.deleteConfirmPhrase;
        this.setState({ confirmationInputValue: value, isSure });
    };

    private onItemsChecked(selected: string[]) {
        const selectedIds = new Set(selected);
        this.setState({
            selected: this.state.currentItems.filter((resource) => selectedIds.has(resource.Id)),
        });
    }

    private async reload() {
        const archivesResponse = await repository.AuditArchive.list();
        this.setState({
            initialData: archivesResponse,
        });
    }

    private async delete() {
        await this.doBusyTask(async () => {
            await Promise.all(this.state.selected.map(async (resource) => await repository.AuditArchive.del(resource)));
            await this.reload();
        });
        return true;
    }
}

export default AuditArchivePage;
