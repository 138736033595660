/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */

import { List as MaterialList, ListItem } from "material-ui";
import * as React from "react";
import type { match } from "react-router-dom";
import ListInternalLink from "~/components/Navigation/InternalLink/ListInternalLink";
import { NoResults } from "~/components/NoResults/NoResults";
import type { PagingBaseProps, PagingBaseState, HasId } from "~/components/PagingBaseComponent";
import PagingBaseComponent from "~/components/PagingBaseComponent";
import { Section } from "~/components/Section/Section";
import type { OctopusTheme } from "~/components/Theme";
import { withTheme } from "~/components/Theme";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { Note } from "~/components/form";
import Divider from "../../primitiveComponents/dataDisplay/Divider/Divider";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import { getNavigationUrl } from "../PagingBaseComponent/PagingBaseComponent";
import styles from "./style.module.less";

interface ListProps<R extends HasId> extends PagingBaseProps<R> {
    empty?: React.ReactNode;
    match?: match<any>;
    wrapRowsInListItem?: boolean;
    onRowAccessibleName?(item: R): string;
}

interface ListState<R extends HasId> extends PagingBaseState<R> {
    redirectTo: string;
}

export abstract class List<R extends HasId> extends PagingBaseComponent<R, ListProps<R>, ListState<R>> {
    private empty: React.ReactNode = (<NoResults />);

    constructor(props: ListProps<R>) {
        super(props);
        this.empty = props.empty || this.empty;
    }

    componentDidUpdate() {
        if (this.empty !== this.props.empty) {
            return (this.empty = this.props.empty);
        }
    }

    render() {
        return withTheme((theme) => {
            if (this.state.redirectTo) {
                return <InternalRedirect to={this.state.redirectTo} push={true} />;
            }

            if (!this.state.data || !this.state.data.Items) {
                return <Section />;
            }

            return (
                <TransitionAnimation>
                    {this.renderFilterSearchComponents()}
                    {this.state.data.Items.length === 0 ? (
                        <Section>
                            <Note>{this.empty}</Note>
                        </Section>
                    ) : (
                        <MaterialList role="list">
                            {this.state.data.Items.filter((item: any) => this.onFilter(this.state.filter, item)).map((item: any, index: number) => this.buildItem(item, index, theme))}
                            {this.state.data.Items.length < this.state.data.TotalResults ? (this.props.showPagingInNumberedStyle ? this.showPagingInNumberedStyle() : this.showPagingInLoadMoreStyle()) : null}
                        </MaterialList>
                    )}
                </TransitionAnimation>
            );
        });
    }

    private buildItem(item: R, index: number, theme: OctopusTheme) {
        const accessibleName = this.props.onRowAccessibleName?.(item);
        const redirectUrl = getNavigationUrl(this.props as any, item);
        let nodeRow = this.props.onRow(item);
        if (redirectUrl) {
            // Make these actual href links, so people can still right-click > open new window (this is expected usability for things you can click).
            nodeRow = (
                <ListInternalLink accessibleName={accessibleName} to={redirectUrl}>
                    {nodeRow}
                </ListInternalLink>
            );
        }
        const nodes: JSX.Element[] = [
            this.props.wrapRowsInListItem ? (
                <ListItem role="listitem" key={item.Id} className={styles.listItem} onClick={(e) => this.navigate(item)} hoverColor={theme.hover} disableFocusRipple={DISABLE_LEGACY_MUI_RIPPLES} disableTouchRipple={DISABLE_LEGACY_MUI_RIPPLES}>
                    {nodeRow}
                </ListItem>
            ) : (
                <div onClick={(e) => this.navigate(item)}>{nodeRow}</div>
            ),
        ];

        if (index < this.state.data!.Items.length - 1) {
            nodes.push(<Divider />);
        }

        return React.Children.toArray(nodes);
    }
}

(List as any).defaultProps = {
    showFilterWithinSection: true,
    autoFocusOnFilterSearch: true,
    wrapRowsInListItem: true,
};
