import type { ActionTemplateSearchResource } from "@octopusdeploy/octopus-server-client";
import { ExecutionLocation } from "@octopusdeploy/octopus-server-client";
import type { SampleStepInputs } from "~/components/Actions/sample/SampleStepInputs";
import { sampleStepInputSchema } from "~/components/Actions/sample/SampleStepInputs";
import { sampleStepUI } from "~/components/Actions/sample/SampleStepUI";
import type { StepPackage } from "~/components/StepPackageEditor/StepPackage/StepPackage";

export const sampleStepPackage: StepPackage<SampleStepInputs> = {
    stepUI: sampleStepUI,
    version: "0.0.1",
    executionLocation: ExecutionLocation.ServerOrWorkerOrTarget,
    canRunInExecutionContainer: true,
    requiresTargetRole: false,
    isLegacyStep: false,
    name: "Sample Step",
    description: "A sample step that can't be executed and only has a UI",
    inputSchema: sampleStepInputSchema,
    targetDiscoveryCloudConnectionTypes: ["Aws", "Azure"],
};

export function createSampleStepActionTemplateResource(spaceId: string): ActionTemplateSearchResource {
    return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        Author: null!,
        Version: null,
        Categories: ["BuiltInStep"],
        Category: "BuiltInStep",
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        CommunityActionTemplateId: null!,
        Description: "A sample step that can't be executed and only has a UI",
        HasUpdate: false,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        Id: null!,
        IsBuiltIn: true,
        IsInstalled: true,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        Keywords: null!,
        Links: {
            Logo: `/api/${spaceId}/actiontemplates/Sample.Step/logo`,
        },
        Name: "Sample Step",
        Type: "sample-step",
        Prerelease: true,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        Website: null!,
    };
}
