/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { LicenseStatusResource, UserRoleResource } from "@octopusdeploy/octopus-server-client";
import { PermissionsMode, Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { ReactNode } from "react";
import type { RouteComponentProps } from "react-router";
import { repository } from "~/clientInstance";
import { NavigationButton, NavigationButtonType } from "~/components/Button/NavigationButton";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import { SimpleList } from "~/components/List";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import { RestrictedPermissionsCallout } from "~/components/RestrictedPermissionsCallout";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import routeLinks from "../../../../routeLinks";

interface RolesState extends DataBaseComponentState {
    roles?: UserRoleResource[];
    licenseStatus?: LicenseStatusResource;
}

class RoleList extends SimpleList<UserRoleResource> {}

export class Roles extends DataBaseComponent<RouteComponentProps<{}>, RolesState> {
    constructor(props: RouteComponentProps<{}>) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        return this.doBusyTask(
            async () => {
                const roles = await repository.UserRoles.all();
                const licenseStatus = await repository.Licenses.getCurrentStatus();
                this.setState({
                    roles,
                    licenseStatus,
                });
            },
            { timeOperationOptions: timeOperationOptions.forInitialLoad() }
        );
    }

    renderRole(role: UserRoleResource): ReactNode {
        return (
            <div key={role.Id}>
                <ListTitle>{role.Name}</ListTitle>
                <div>{role.Description}</div>
            </div>
        );
    }

    render() {
        const addButton = (
            <PermissionCheck permission={Permission.UserRoleEdit}>
                <NavigationButton type={NavigationButtonType.Primary} label="Add Custom Role" href={routeLinks.configuration.roles.create} />
            </PermissionCheck>
        );
        return (
            <PaperLayout title="User Roles" busy={this.state.busy} errors={this.errors} sectionControl={addButton}>
                <RestrictedPermissionsCallout isVisible={(this.state.licenseStatus && this.state.licenseStatus.PermissionsMode === PermissionsMode.Restricted)!} />
                <RoleList
                    items={this.state.roles!}
                    onRowRedirectUrl={(role: UserRoleResource) => routeLinks.configuration.role(role.Id)}
                    onRow={this.renderRole}
                    match={this.props.match}
                    autoFocusOnFilterSearch={true}
                    onFilter={(filter, item) => item.Name.toLowerCase().indexOf(filter.toLowerCase()) !== -1}
                />
            </PaperLayout>
        );
    }
}

export default Roles;
