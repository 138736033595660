import type { TaskResource } from "@octopusdeploy/octopus-server-client";
import { TaskState } from "@octopusdeploy/octopus-server-client";
import React from "react";
import TaskQueueRow from "~/areas/tasks/components/Task/TaskQueueRow";
import type { TaskQueueFilterState } from "~/areas/tasks/components/Task/TaskQueueStateFilter";
import TaskQueueStateFilter, { Interruption, filterStatePredicate } from "~/areas/tasks/components/Task/TaskQueueStateFilter";
import { SimpleList } from "~/components/List/index";
import routeLinks from "~/routeLinks";

interface TaskQueueOrderedListProps {
    currentTask: TaskResource;
    tasksInQueue: TaskResource[];
}

export interface TaskWithPosition extends TaskResource {
    Position: number;
    Current: boolean;
}

class TasksList extends SimpleList<TaskWithPosition> {}

function handleRowRedirect(item: TaskResource) {
    return routeLinks.forSpace(item.SpaceId).task(item).root;
}

function getTasksWithPosition(allTasks: TaskResource[], currentTask: TaskResource, selectedState: TaskQueueFilterState): TaskWithPosition[] {
    return allTasks
        .map((task, index) => ({
            Position: index + 1,
            Current: false,
            ...task,
        }))
        .filter(filterStatePredicate(selectedState))
        .concat({
            Position: allTasks.length + 1,
            Current: true,
            ...currentTask,
        });
}

const TaskQueueOrderedList: React.FC<TaskQueueOrderedListProps> = ({ currentTask, tasksInQueue }: TaskQueueOrderedListProps) => {
    const [selectedState, selectState] = React.useState<TaskQueueFilterState>(null);

    const allTasksWithPosition = getTasksWithPosition(tasksInQueue, currentTask, selectedState);

    return (
        <>
            <div>
                <TaskQueueStateFilter stateToFilter={Interruption.ManualIntervention} tasks={tasksInQueue} selectState={selectState} isSelected={selectedState === Interruption.ManualIntervention} />
                <TaskQueueStateFilter stateToFilter={TaskState.Queued} tasks={tasksInQueue} selectState={selectState} isSelected={selectedState === TaskState.Queued} />
            </div>
            <TasksList items={allTasksWithPosition} selectedIndex={allTasksWithPosition.length - 1} onRow={(task) => <TaskQueueRow task={task} />} onRowRedirectUrl={handleRowRedirect} />
        </>
    );
};

export default TaskQueueOrderedList;
