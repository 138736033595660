import React from "react";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList";
import ActionButton, { ActionButtonType } from "~/components/Button";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import ConfirmationDialog from "~/components/Dialog/ConfirmationDialog";
import Section from "~/components/Section";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";

interface ConfirmTentacleUpgradePanelState {
    showUpgradeAllTentaclesConfirmation: boolean;
    showUpgrade: boolean;
}

interface ConfirmTentacleUpgradePanelProps {
    doBusyTask: DoBusyTask;
    calloutDescriptionElement: React.ReactNode;
    onTentacleUpgradeComplete(): void;
}

export class ConfirmTentacleUpgradePanel extends React.Component<ConfirmTentacleUpgradePanelProps, ConfirmTentacleUpgradePanelState> {
    private titleForUpgradeTentacles = "Upgrade all Tentacles";
    private localStorageKeyForIgnoreUpgradeVersion = "ignoreUpgradeVersiona";

    constructor(props: ConfirmTentacleUpgradePanelProps) {
        super(props);
        const octopusVersion = repository.getServerInformation().version;
        this.state = {
            showUpgradeAllTentaclesConfirmation: false,
            showUpgrade: localStorage.getItem(this.localStorageKeyForIgnoreUpgradeVersion) !== octopusVersion,
        };
    }

    render() {
        return (
            this.state.showUpgrade && (
                <React.Fragment>
                    <Section key={`showUpgrade-${this.state.showUpgrade.toString()}`}>
                        <Callout type={CalloutType.Warning} title="Tentacle upgrade required">
                            {this.props.calloutDescriptionElement}
                            <ActionList
                                actions={[
                                    <ActionButton type={ActionButtonType.Primary} label={this.titleForUpgradeTentacles} onClick={() => this.setState({ showUpgradeAllTentaclesConfirmation: true })} />,
                                    <ActionButton type={ActionButtonType.Secondary} label="Dismiss this update" onClick={() => this.dismissTentacleUpgrade()} />,
                                ]}
                            />
                        </Callout>
                    </Section>
                    <ConfirmationDialog
                        title={this.titleForUpgradeTentacles}
                        open={this.state.showUpgradeAllTentaclesConfirmation}
                        onClose={() => this.setState({ showUpgradeAllTentaclesConfirmation: false })}
                        onContinueClick={() => this.performTentacleUpgrade()}
                    >
                        <div>
                            Please note: This is a blocking task and will prevent deployments during the upgrade.
                            <br />
                            <br />
                            Are you sure?
                        </div>
                    </ConfirmationDialog>
                </React.Fragment>
            )
        );
    }

    private async performTentacleUpgrade() {
        return this.props.doBusyTask(async () => {
            await repository.Machines.upgradeAllTentacles();
            this.props.onTentacleUpgradeComplete();
        });
    }

    private dismissTentacleUpgrade() {
        const octopusVersion = repository.getServerInformation().version;
        localStorage.setItem(this.localStorageKeyForIgnoreUpgradeVersion, octopusVersion);
        this.setState({ showUpgrade: false });
    }
}

export default ConfirmTentacleUpgradePanel;
