import type { PackageReference } from "@octopusdeploy/octopus-server-client";
import type { ContainerImageReference, PackageReference as InputPackageReference } from "@octopusdeploy/step-inputs";
import type { InputProperty, NonDiscriminatorTypeDefinition, ObjectRuntimeInputs, PathToInput, PlainObjectTypeDefinition, RootInputSchema } from "@octopusdeploy/step-runtime-inputs";
import { createInputValueAccessor, createPathToInput, isBoundValue } from "@octopusdeploy/step-runtime-inputs";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import { GeneratePackageNameFromId } from "../../Actions/commonActionHelpers";
import { convertToRuntimeContainerImageSelection } from "./Components/ContainerImageSelector/ContainerImageConverters";
import { convertToRuntimePackageSelection } from "./Components/PackageSelector/PackageSelectionConverters";

export function getPackagesFromInputs<StepInputs>(inputs: ObjectRuntimeInputs<StepInputs>, schema: RootInputSchema, existingPackages: PackageReference[]) {
    const inputObjectSchema: PlainObjectTypeDefinition = { type: "object", nonDiscriminatorProperties: schema.properties, discriminatorProperties: [] };

    return getPackageReferences(inputObjectSchema, []);

    function getPackageReferences(typeDefinition: NonDiscriminatorTypeDefinition, path: PathToInput): PackageReference[] {
        switch (typeDefinition.type) {
            case "account":
            case "string":
            case "primitive":
            case "sensitive":
                return [];
            case "object":
                return typeDefinition.nonDiscriminatorProperties.reduce(function (acc: PackageReference[], property: InputProperty): PackageReference[] {
                    return [...acc, ...getPackageReferences(property.type, [...path, property.name])];
                }, []);
            case "array":
                return typeDefinition.itemTypes.reduce(function (acc: PackageReference[], property: NonDiscriminatorTypeDefinition, i: number): PackageReference[] {
                    return [...acc, ...getPackageReferences(property, [...path, i])];
                }, []);

            case "container-image": {
                const pathToInput = createPathToInput<ContainerImageReference>(path);
                const inputAccessor = createInputValueAccessor<StepInputs, ContainerImageReference>(pathToInput);
                const inputValue = inputAccessor.getInputValue(inputs);
                if (isBoundValue(inputValue)) {
                    throw new Error("Bound values for Container Image inputs are not yet supported");
                }
                const runtimeContainerImageReference = convertToRuntimeContainerImageSelection(inputValue);
                return [
                    {
                        PackageId: runtimeContainerImageReference.imageName ?? "",
                        FeedId: runtimeContainerImageReference.feedId ?? "",
                        Name: GeneratePackageNameFromId(runtimeContainerImageReference.imageName ?? ""),
                        AcquisitionLocation: "NotAcquired",
                        Properties: {},
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        Id: existingPackages.find((p) => p.StepPackageInputsReferenceId === runtimeContainerImageReference.referenceId)?.Id ?? null!,
                        StepPackageInputsReferenceId: runtimeContainerImageReference.referenceId,
                    },
                ];
            }

            case "package": {
                const pathToInput = createPathToInput<InputPackageReference>(path);
                const inputAccessor = createInputValueAccessor<StepInputs, InputPackageReference>(pathToInput);
                const inputValue = inputAccessor.getInputValue(inputs);
                if (isBoundValue(inputValue)) {
                    throw new Error("Bound values for Package inputs are not yet supported");
                }
                const runtimePackageReference = convertToRuntimePackageSelection(inputValue);
                return [
                    {
                        PackageId: runtimePackageReference.packageId ?? "",
                        FeedId: runtimePackageReference.feedId ?? "",
                        Name: GeneratePackageNameFromId(runtimePackageReference.packageId ?? ""),
                        AcquisitionLocation: "ExecutionTarget",
                        Properties: {},
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        Id: existingPackages.find((p) => p.StepPackageInputsReferenceId === runtimePackageReference.referenceId)?.Id ?? null!,
                        StepPackageInputsReferenceId: runtimePackageReference.referenceId,
                    },
                ];
            }
        }

        exhaustiveCheck(typeDefinition, "One or more of the typeDefinition cases were not handled");
        return [];
    }
}
