import type { ValidateGitRefV2Response } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { VcsErrorPanel } from "~/areas/projects/components/VersionControl/VcsErrorPanel";
import { useProjectContext } from "~/areas/projects/context/index";
import ExternalLink from "~/components/Navigation/ExternalLink";
import SectionNote from "~/components/SectionNote/SectionNote";

interface SettingsNoteSectionProps {
    isVersionControlled: boolean;
}

function SettingsNoteSectionInternal(props: SettingsNoteSectionProps & { gitError: ValidateGitRefV2Response | undefined }) {
    const vcsSection = (
        <SectionNote>
            <div>
                Version control is configured. Please provide <ExternalLink href={"CaCEAPFeedbackForm"}>feedback</ExternalLink> on the Config as Code feature.
            </div>
        </SectionNote>
    );

    const nonVcsSection = (
        <SectionNote>
            <div>
                Configuring a project to use version control is an irreversible process. Please read the <ExternalLink href={"ConfigAsCodeEap"}>documentation</ExternalLink> first.
            </div>
        </SectionNote>
    );

    const vcsErrorSection = <VcsErrorPanel content={<>{props.gitError?.Message}</>} />;

    return (
        <>
            {props.isVersionControlled && !props.gitError && vcsSection}
            {props.isVersionControlled && props.gitError && vcsErrorSection}
            {!props.isVersionControlled && nonVcsSection}
        </>
    );
}

export function SettingsNoteSection(props: SettingsNoteSectionProps) {
    const projectContext = useProjectContext();
    return <SettingsNoteSectionInternal {...props} gitError={projectContext.state.gitRefValidationError} />;
}
