import type { DynamicEnvironmentOverviewState } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { DynamicEnvironmentActiveIcon } from "./DynamicEnvironmentActiveIcon";
import { DynamicEnvironmentDeprovisionedIcon } from "./DynamicEnvironmentDeprovisionedIcon";
import { DynamicEnvironmentDeprovisioningIcon } from "./DynamicEnvironmentDeprovisioningIcon";
import { DynamicEnvironmentErrorIcon } from "./DynamicEnvironmentErrorIcon";
import { getDynamicEnvironmentStateDescription } from "./getDynamicEnvironmentStateDescription";

export type DynamicEnvironmentStateIconProps = {
    state: DynamicEnvironmentOverviewState;
};

export function DynamicEnvironmentStateIcon({ state }: DynamicEnvironmentStateIconProps) {
    switch (state) {
        case "Active":
            return <DynamicEnvironmentActiveIcon title={getDynamicEnvironmentStateDescription("Active")} />;

        case "Deprovisioned":
            return <DynamicEnvironmentDeprovisionedIcon title={getDynamicEnvironmentStateDescription("Deprovisioned")} />;

        case "Deprovisioning":
            return <DynamicEnvironmentDeprovisioningIcon title={getDynamicEnvironmentStateDescription("Deprovisioning")} />;

        case "DeprovisioningFailed":
            return <DynamicEnvironmentErrorIcon title={getDynamicEnvironmentStateDescription("DeprovisioningFailed")} />;

        default:
            return null;
    }
}
