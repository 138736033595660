import URI from "urijs";

/*
We resolve the root url by getting the path before the last occurrence of `/app`, since server will always
redirect to this location. Similarly, for development servers we only care about the path leading to the
actual file to know what the base url is.
 */
export function resolveApplicationRootUri(locationOrString: Location | string) {
    const location = new URI(locationOrString);
    const segments = location.segment();

    if (isFileSegment(tail(segments))) {
        segments.pop();
    }

    if (tail(segments) === "app") {
        segments.pop();
    }

    const root = `${location.origin()}/${segments.join("/")}`;
    return tail(root) === "/" ? root : `${root}/`;
}

function tail<T>(values: ArrayLike<T>) {
    return values[values.length - 1];
}

function isFileSegment(segment: string): boolean {
    return segment.indexOf(".") >= 0;
}
