import { CommonEventFieldKeys } from "app/analytics/CommonEventFields";
import type { Action } from "~/analytics/Analytics";
import { toBoolFieldValue } from "~/analytics/fieldConverters";

// For creating commonly-used fields

export function createResourceTypeField(value: string) {
    return { [CommonEventFieldKeys.ResourceType]: value } as const;
}

export function createActionTypeField(value: Action) {
    return { [CommonEventFieldKeys.ActionType]: value } as const;
}

// For adding ad-hoc fields of varying types

export function createStringField(name: string, value?: string) {
    return value === undefined ? {} : ({ [name]: value } as const);
}

export function createBoolField(name: string, value: boolean) {
    return { [name]: toBoolFieldValue(value) } as const;
}
