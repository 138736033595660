/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { TenantedDeploymentMode, TriggerActionCategory, TriggerFilterType, Permission } from "@octopusdeploy/octopus-server-client";
import type {
    ChannelResource,
    DeploymentProcessResource,
    EnvironmentResource,
    EventCategoryResource,
    EventGroupResource,
    RunbookResource,
    ProjectResource,
    ResourceCollection,
    RunRunbookActionResource,
    TenantResource,
    TriggerResource,
    DeploymentActionPackageResource,
} from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import { flatten } from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import { useProjectContext } from "~/areas/projects/context";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/withProjectContext";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList";
import { AdvancedFilterCheckbox } from "~/components/AdvancedFilterLayout";
import AdvancedFilterLayout from "~/components/AdvancedFilterLayout/AdvancedFilterLayout";
import NavigationButton, { NavigationButtonType } from "~/components/Button/NavigationButton";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import FilterSearchBox from "~/components/FilterSearchBox";
import List from "~/components/List";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { NoResults } from "~/components/NoResults/NoResults";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import PaperLayout from "~/components/PaperLayout/index";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import SidebarLayout from "~/components/SidebarLayout/SidebarLayout";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import Select from "~/primitiveComponents/form/Select/Select";
import routeLinks from "~/routeLinks";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { ProjectStatus } from "../ProjectStatus/ProjectStatus";
import RunbooksOnboarding from "../Runbooks/RunbooksOnboarding";
import { AddTriggerButton } from "./AddTriggerButton";
import ScheduledTrigger from "./Scheduled/ScheduledTrigger";
import SideBar from "./SideBar";
import Trigger from "./Trigger";
import styles from "./Triggers.module.less";

interface TriggersState extends DataBaseComponentState {
    project: ProjectResource;
    triggersResponse: ResourceCollection<TriggerResource>;
    environments: EnvironmentResource[];
    categories: EventCategoryResource[];
    groups: EventGroupResource[];
    actionPackages: DeploymentActionPackageResource[];
    deploymentProcess: DeploymentProcessResource;
    builtInPackageRepositoryInUse: boolean;
    showAutomaticReleaseCreation: boolean;
    channels: ChannelResource[];
    open: boolean;
    anchor?: any;
    filter: TriggersFilter;
    isSearching: boolean;
    runbooksInProject: RunbookResource[];
    tenants: TenantResource[];
}

interface TriggersFilter {
    searchText: string;
    showDeploymentTargetTriggers: boolean;
    showScheduledTriggers: boolean;
    runbookId: string;
}

interface GlobalConnectedProps {
    isMultiTenancyEnabled?: boolean;
}

type TriggersProps = {
    triggerActionCategory: TriggerActionCategory;
} & RouteComponentProps<ProjectRouteParams> &
    GlobalConnectedProps &
    WithProjectContextInjectedProps;

class FilterLayout extends AdvancedFilterLayout<TriggersFilter> {}
class TriggersList extends List<TriggerResource> {}

class TriggersInternal extends DataBaseComponent<TriggersProps, TriggersState> {
    static defaultProps: Partial<TriggersProps> = {
        triggerActionCategory: TriggerActionCategory.Deployment,
    };

    private match: any = null!;

    constructor(props: TriggersProps) {
        super(props);
        this.match = this.props.match;
        this.state = {
            project: null!,
            triggersResponse: null!,
            environments: null!,
            categories: null!,
            deploymentProcess: null!,
            groups: null!,
            actionPackages: null!,
            builtInPackageRepositoryInUse: null!,
            showAutomaticReleaseCreation: null!,
            channels: null!,
            open: false,
            filter: null!,
            isSearching: false,
            runbooksInProject: null!,
            tenants: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(
            async () => {
                const { model: project, projectContextRepository, gitRef } = this.props.projectContext.state;

                const isForDeployments = this.props.triggerActionCategory == TriggerActionCategory.Deployment;
                const isForRunbooks = this.props.triggerActionCategory == TriggerActionCategory.Runbook;

                // prettier-ignore
                const deploymentProcessPromise = isForDeployments && isAllowed({ permission: Permission.ProcessView, project: project.Id, tenant: "*" })
                    ? projectContextRepository.DeploymentProcesses.get()
                    : Promise.resolve<DeploymentProcessResource>(null!);

                // prettier-ignore
                const channelsPromise = isForDeployments && isAllowed({ permission: Permission.ProcessView, project: project.Id, tenant: "*" })
                    ? repository.Projects.getChannels(project)
                    : Promise.resolve<ResourceCollection<ChannelResource>>(null!);

                const builtInFeedPromise = repository.Feeds.getBuiltIn();
                const environmentsPromise = repository.Environments.all();
                const categoriesPromise = repository.Events.categories({ appliesTo: "Machine" });
                const groupsPromise = repository.Events.groups({ appliesTo: "Machine" });
                const triggersPromise = repository.Projects.getTriggers(project, gitRef, 0, 30, null!, this.props.triggerActionCategory);

                // prettier-ignore
                const runbooksPromise = isForRunbooks
                ? repository.Runbooks.all({ projectIds: [project.Id] })
                : Promise.resolve<RunbookResource[]>(null!);

                // prettier-ignore
                const tenantsPromise = isAllowed({ permission: Permission.TenantView, tenant: "*" }) && this.props.isMultiTenancyEnabled && project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted
                    ? repository.Tenants.all({ projectId: project.Id })
                    : Promise.resolve([]);

                const deploymentProcess = await deploymentProcessPromise;
                const channels = await channelsPromise;
                const builtInFeed = await builtInFeedPromise;

                const actions = deploymentProcess && flatten(deploymentProcess.Steps.map((step) => step.Actions)).filter((a) => !a.IsDisabled);
                const builtInFeedPackageActions =
                    actions &&
                    // start with the steps
                    _.chain(deploymentProcess.Steps)
                        // Get the step actionPackages
                        .flatMap((step) => step.Actions)
                        // Filter by built-in feed id
                        // Convert them to deployment-action-packages
                        .flatMap((action) =>
                            _.chain(action.Packages)
                                .filter((pkg) => pkg.FeedId === builtInFeed.Id)
                                .map((pkg) => ({ DeploymentAction: action.Name, PackageReference: pkg.Name! }))
                                .value()
                        )
                        .value();

                const showAutomaticReleaseCreation = (builtInFeedPackageActions && builtInFeedPackageActions.length > 0) || project.AutoCreateRelease === true;

                this.setState({
                    triggersResponse: await triggersPromise,
                    environments: await environmentsPromise,
                    categories: await categoriesPromise,
                    deploymentProcess,
                    groups: await groupsPromise,
                    project,
                    builtInPackageRepositoryInUse: builtInFeedPackageActions && builtInFeedPackageActions.length > 0,
                    actionPackages: builtInFeedPackageActions!,
                    showAutomaticReleaseCreation,
                    channels: channels && channels.Items,
                    filter: this.createDefaultFilter(),
                    runbooksInProject: await runbooksPromise,
                    tenants: await tenantsPromise,
                });
            },
            { timeOperationOptions: timeOperationOptions.forInitialLoad(this.props.projectContext.state.model.IsVersionControlled) }
        );
    }

    handleTouchTap = (event: any) => {
        event.preventDefault();
        this.setState({
            open: true,
            anchor: event.currentTarget,
        });
    };

    handleRequestClose = () => {
        this.setState({
            open: false,
        });
    };

    filterTriggers = (searchText: string, trigger: TriggerResource) => {
        const filter = this.state.filter;
        const deploymentTargetTriggerFilterTypes = [TriggerFilterType.MachineFilter];
        const scheduledTriggerFilterTypes = [TriggerFilterType.OnceDailySchedule, TriggerFilterType.ContinuousDailySchedule, TriggerFilterType.DaysPerMonthSchedule, TriggerFilterType.CronExpressionSchedule];
        return (
            (filter.searchText === "" || (filter.searchText !== "" && trigger.Name.toLowerCase().includes(filter.searchText.toLowerCase()))) &&
            (filter.showDeploymentTargetTriggers || (!filter.showDeploymentTargetTriggers && !deploymentTargetTriggerFilterTypes.includes(trigger.Filter.FilterType))) &&
            (filter.showScheduledTriggers || (!filter.showScheduledTriggers && !scheduledTriggerFilterTypes.includes(trigger.Filter.FilterType))) &&
            (!filter.runbookId || (!!filter.runbookId && (trigger.Action as any as RunRunbookActionResource).RunbookId === filter.runbookId))
        );
    };

    render() {
        const list = (
            <TriggersList
                initialData={this.state.triggersResponse}
                additionalRequestParams={new Map([["triggerActionCategory", [this.props.triggerActionCategory]]])}
                onRow={(item: any) => this.buildTriggerRow(item)}
                match={this.match}
                onRowRedirectUrl={(trigger: TriggerResource) => (trigger.Filter.FilterType === TriggerFilterType.MachineFilter ? `${this.match.url}/edit/${trigger.Id}` : `${this.match.url}/scheduled/edit/${trigger.Id}`)}
                onFilter={this.filterTriggers}
                empty={<NoResults />}
            />
        );

        const showRunbooksOnboarding = this.props.triggerActionCategory === TriggerActionCategory.Runbook && this.state.runbooksInProject && this.state.runbooksInProject.length === 0;

        const project = this.state.project;
        const addTriggerButton =
            project &&
            (this.props.triggerActionCategory === TriggerActionCategory.Deployment ? (
                <AddTriggerButton internalPath={this.props.match.url} />
            ) : (
                <PermissionCheck permission={Permission.TriggerCreate} project={project.Id}>
                    <NavigationButton type={NavigationButtonType.Primary} label="Add Scheduled Trigger" href={routeLinks.project(project.Slug).operations.scheduledTriggers.new} disabled={showRunbooksOnboarding} />
                </PermissionCheck>
            ));

        return (
            <PaperLayout busy={this.state.busy} errors={this.errors} title="Triggers" breadcrumbTitle={this.state.project?.Name} sectionControl={addTriggerButton} statusSection={<ProjectStatus doBusyTask={this.doBusyTask} />}>
                {showRunbooksOnboarding ? (
                    <RunbooksOnboarding />
                ) : (
                    <SidebarLayout
                        sideBar={
                            this.state.project &&
                            isAllowed({ permission: Permission.ProcessView, project: this.state.project.Id, tenant: "*" }) &&
                            this.props.triggerActionCategory === TriggerActionCategory.Deployment && (
                                <SideBar
                                    project={this.state.project}
                                    builtInPackageRepositoryInUse={this.state.builtInPackageRepositoryInUse}
                                    actionPackages={this.state.actionPackages}
                                    showAutomaticReleaseCreation={this.state.showAutomaticReleaseCreation}
                                    channels={this.state.channels}
                                    deploymentProcess={this.state.deploymentProcess}
                                    onProjectUpdated={(p) => this.onProjectUpdated(p)}
                                />
                            )
                        }
                    >
                        {this.getInvalidConfigurationCallout()}
                        {this.state.triggersResponse && (
                            <FilterLayout
                                filter={this.state.filter}
                                defaultFilter={this.createDefaultFilter()}
                                filterSections={
                                    this.props.triggerActionCategory === TriggerActionCategory.Deployment
                                        ? [
                                              {
                                                  render: (
                                                      <>
                                                          <AdvancedFilterCheckbox
                                                              label="Show deployment target triggers"
                                                              value={this.state.filter.showDeploymentTargetTriggers}
                                                              onChange={(x) =>
                                                                  this.setFilterState({ showDeploymentTargetTriggers: x }, async () => {
                                                                      await this.onFilterChange();
                                                                  })
                                                              }
                                                          />
                                                          <AdvancedFilterCheckbox
                                                              label="Show scheduled triggers"
                                                              value={this.state.filter.showScheduledTriggers}
                                                              onChange={(x) =>
                                                                  this.setFilterState({ showScheduledTriggers: x }, async () => {
                                                                      await this.onFilterChange();
                                                                  })
                                                              }
                                                          />
                                                      </>
                                                  ),
                                              },
                                          ]
                                        : []
                                }
                                additionalHeaderFilters={[
                                    <FilterSearchBox
                                        placeholder="Filter by name..."
                                        value={this.state.filter.searchText}
                                        autoFocus={true}
                                        onChange={(searchText) =>
                                            this.setFilterState({ searchText }, async () => {
                                                await this.onFilterChange();
                                            })
                                        }
                                    />,
                                    this.props.triggerActionCategory === TriggerActionCategory.Runbook && (
                                        <ActionList
                                            actions={[
                                                <Select
                                                    label=""
                                                    placeholder="Filter by Runbook"
                                                    allowFilter={true}
                                                    allowClear={true}
                                                    value={this.state.filter.runbookId}
                                                    onChange={(x) => {
                                                        this.setFilterState({ runbookId: x! }, async () => {
                                                            await this.onFilterChange();
                                                        });
                                                    }}
                                                    items={this.state.runbooksInProject.map((r) => ({ value: r.Id, text: r.Name }))}
                                                    className={styles.filterControl}
                                                />,
                                            ]}
                                        />
                                    ),
                                ]}
                                onFilterReset={(filter: TriggersFilter) => {
                                    this.setState({ filter }, async () => {
                                        await this.onFilterChange();
                                        const location = { ...this.props.history, search: null! as any };
                                        this.props.history.replace(location);
                                    });
                                }}
                                renderContent={() => list}
                            />
                        )}
                    </SidebarLayout>
                )}
            </PaperLayout>
        );
    }

    async reloadTriggers() {
        const project = this.state.project;
        const gitRef = this.props.projectContext.state.gitRef;
        await this.doBusyTask(async () => {
            this.setState({
                triggersResponse: await repository.Projects.getTriggers(project, gitRef, 0, 30, null!, this.props.triggerActionCategory, this.state.filter.runbookId ? [this.state.filter.runbookId] : null!, this.state.filter.searchText),
            });
        });
    }

    private getInvalidConfigurationCallout() {
        if (this.props.triggerActionCategory !== TriggerActionCategory.Deployment) {
            return;
        }

        if (this.state.deploymentProcess && this.state.project.AutoCreateRelease) {
            if (!this.state.project.ReleaseCreationStrategy || !this.state.project.ReleaseCreationStrategy.ReleaseCreationPackage) {
                return (
                    <Callout type={CalloutType.Warning} title="Invalid Configuration">
                        This project is configured to use Automatic Release Creation, but the step is missing. Please adjust or disable the Automatic Release Creation configuration.
                    </Callout>
                );
            } else {
                const action = flatten(this.state.deploymentProcess.Steps.map((step) => step.Actions)).filter((a) => a.Name === this.state.project.ReleaseCreationStrategy.ReleaseCreationPackage.DeploymentAction);
                if (action && action.length > 0 && action[0].IsDisabled) {
                    return (
                        <Callout type={CalloutType.Warning} title="Invalid Configuration">
                            Step <InternalLink to={routeLinks.project(this.state.project).deployments.process.step(action[0].Id)}>{action[0].Name}</InternalLink> is currently used for Automatic Release Creation, but it has been disabled.
                            <br />
                            Please re-enable the step, disable Automatic Release Creation, or choose a different step.
                        </Callout>
                    );
                }
            }
        }
        return null!;
    }

    private async onFilterChange() {
        this.setState({ isSearching: true }, async () => {
            await this.reloadTriggers();
            this.setState({ isSearching: false });
        });
    }

    private setFilterState<K extends keyof TriggersFilter>(state: Pick<TriggersFilter, K>, callback?: () => void) {
        this.setState(
            (prev) => ({
                filter: { ...(prev!.filter as object), ...(state as object) },
            }),
            callback
        );
    }

    private createDefaultFilter() {
        return {
            searchText: "",
            showDeploymentTargetTriggers: true,
            showScheduledTriggers: true,
            runbookId: "",
        };
    }

    private buildTriggerRow(trigger: TriggerResource) {
        const overflowMenuItems: any = this.getOverflowMenuItems(trigger);
        if (trigger.Filter.FilterType === TriggerFilterType.MachineFilter) {
            return <Trigger key={trigger.Id} trigger={trigger} menuItems={overflowMenuItems} environments={this.state.environments} categories={this.state.categories} groups={this.state.groups} />;
        }

        return <ScheduledTrigger key={trigger.Id} trigger={trigger} menuItems={overflowMenuItems} runbooks={this.state.runbooksInProject} environments={this.state.environments} channels={this.state.channels} />;
    }

    private getOverflowMenuItems(trigger: TriggerResource) {
        const menuItems = [];
        const triggerEditPermission = { permission: Permission.TriggerEdit, project: this.state.project.Id };

        menuItems.push(OverflowMenuItems.item(trigger.IsDisabled ? "Enable" : "Disable", () => (trigger.IsDisabled ? this.enable(trigger) : this.disable(trigger)), triggerEditPermission));

        return menuItems;
    }

    private async enable(trigger: TriggerResource) {
        trigger.IsDisabled = false;
        await this.saveTrigger(trigger);
    }

    private async disable(trigger: TriggerResource) {
        trigger.IsDisabled = true;
        await this.saveTrigger(trigger);
    }

    private async saveTrigger(trigger: TriggerResource) {
        const gitRef = this.props.projectContext.state.gitRef;
        const isSuccess = await this.doBusyTask(async () => {
            await repository.ProjectTriggers.modify(trigger);
            const triggersResponse = await repository.Projects.getTriggers(this.state.project, gitRef, 0, 30, null!, this.props.triggerActionCategory);
            this.setState({
                triggersResponse,
            });
        });

        if (!isSuccess) {
            await this.doBusyTask(
                async () => {
                    this.setState({
                        triggersResponse: await repository.Projects.getTriggers(this.state.project, gitRef, 0, 30, null!, this.props.triggerActionCategory),
                    });
                },
                { preserveCurrentErrors: true, timeOperationOptions: timeOperationOptions.forInitialLoad() }
            );
        }
    }

    private onProjectUpdated(project: ProjectResource) {
        this.setState({
            project,
        });
    }
}

const isMultiTenancyEnabledSelector = (state: GlobalState) => state.configurationArea.currentSpace.isMultiTenancyEnabled;

const Triggers: React.FC<TriggersProps> = (props) => {
    const isMultiTenancyEnabled = useSelector(isMultiTenancyEnabledSelector);
    const projectContext = useProjectContext();

    return <TriggersInternal {...props} isMultiTenancyEnabled={isMultiTenancyEnabled} projectContext={projectContext} />;
};

export default withRouter(Triggers);
