/* eslint-disable @typescript-eslint/consistent-type-assertions */

import type { WorkerPoolResource, StaticWorkerPoolResource, DynamicWorkerPoolResource } from "@octopusdeploy/octopus-server-client";
import { WorkerPoolType } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { useParams } from "react-router";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import DataBaseComponent from "~/components/DataBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import { InfrastructureLayoutBusy } from "../../InfrastructureLayout/InfrastructureLayout";
import DynamicWorkerPoolEdit from "./DynamicWorkerPoolEdit";
import StaticWorkerPoolEdit from "./StaticWorkerPoolEdit";

interface WorkerPoolRouteParams {
    workerPoolId: string;
}

interface WorkerPoolProps {
    initialData: InitialData;
}

interface WorkerPoolEditState extends DataBaseComponentState {
    deleted: boolean;
}

interface InitialData {
    workerPool: WorkerPoolResource;
}

const Title = "Worker Pool";
const WorkerPoolFormPage = FormPage<InitialData>();

const WorkerPoolPage: React.FC = () => {
    const parameters = useParams<WorkerPoolRouteParams>();
    return (
        <WorkerPoolFormPage
            title={Title}
            load={async () => {
                const workerPoolPromise = repository.WorkerPools.get(parameters.workerPoolId);
                return {
                    workerPool: await workerPoolPromise,
                };
            }}
            renderWhenLoaded={(initialData) => <WorkerPoolEditInternal initialData={initialData} />}
            renderAlternate={(args) => <InfrastructureLayoutBusy title={Title} {...args} />}
        />
    );
};

class WorkerPoolEditInternal extends DataBaseComponent<WorkerPoolProps, {}> {
    constructor(props: WorkerPoolProps) {
        super(props);
    }

    render() {
        switch (this.props.initialData.workerPool.WorkerPoolType) {
            case WorkerPoolType.Static:
                return <StaticWorkerPoolEdit workerPool={this.props.initialData.workerPool as StaticWorkerPoolResource} />;
            case WorkerPoolType.Dynamic:
                return <DynamicWorkerPoolEdit workerPool={this.props.initialData.workerPool as DynamicWorkerPoolResource} />;
        }
    }
}

export { WorkerPoolEditState };
export default WorkerPoolPage;
