import type { InsightsReportResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import Card from "~/components/Card";
import Logo from "~/components/Logo";
import InternalLink from "~/components/Navigation/InternalLink";
import routeLinks from "~/routeLinks";

interface InsightsReportCardProps {
    report: InsightsReportResource;
}

export function InsightsReportCard({ report }: InsightsReportCardProps) {
    return <Card logo={<Logo url={report.Links.Logo} />} header={report.Name} link={<InternalLink to={routeLinks.insights.report(report.Id).overview} />} content={report.Description} />;
}
