import { Permission } from "@octopusdeploy/octopus-server-client";
import { client } from "~/clientInstance";
import { pageId } from "~/utils/pageId";
import taskRouteLinks from "./taskRouteLinks";

const taskLinks = taskRouteLinks(client.spaceId ?? "");

export default () => {
    return {
        tasks: {
            root: pageId({ area: "Tasks", id: "Tasks", name: "Tasks", routeLink: taskLinks.tasks.root, routePermission: { permission: [Permission.AdministerSystem, Permission.TaskView], wildcard: true } }),
            console: pageId({ area: "Tasks", id: "Tasks.ScriptConsole", name: "Script Console", routeLink: taskLinks.tasks.console, routePermission: { permission: [Permission.AdministerSystem, Permission.TaskCreate], wildcard: true } }),
        },
        task: () => {
            return {
                root: pageId({ area: "Tasks", id: "Task", name: "Task", routeLink: null }),
                raw: pageId({ area: "Tasks", id: "Task.Raw", name: "Raw Task Log", routeLink: null }),
            };
        },
    };
};
