import type { MetadataTypeCollection, DataContext } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import { default as CodeEditor, TextFormat } from "~/components/CodeEditor/CodeEditor";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import SourceCodeDialog from "~/components/SourceCodeDialog/SourceCodeDialog";
import { default as ExpandableFormSection } from "~/components/form/Sections/ExpandableFormSection";
import Summary from "~/components/form/Sections/Summary";
import Note from "~/primitiveComponents/form/Note/Note";

export interface KubernetesCustomResourceProps {
    "Octopus.Action.KubernetesContainers.CustomResourceYaml": string;
}

export class KubernetesCustomResourceComponent extends DataBaseComponent<ActionEditProps<KubernetesCustomResourceProps>, DataBaseComponentState> {
    constructor(props: ActionEditProps<KubernetesCustomResourceProps>) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <ExpandableFormSection
                errorKey="Octopus.Action.KubernetesContainers.CustomResourceYaml"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Custom resource YAML"
                summary={this.resourceYamlSummary()}
                help={"Define the YAML for additional Kubernetes resources to be managed as part of this deployment."}
            >
                <Note>A new instance of each resource listed in the editor below will be created with each deployment, and old resources will be cleaned up.</Note>
                <Note>
                    Multiple resources can be defined by separating the individual YAML documents with a triple dash i.e. <code>---</code>.
                </Note>
                <Note>
                    Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#custom-resources-yaml">custom resources</ExternalLink>.
                </Note>
                <CodeEditor value={this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYaml"]} language={TextFormat.YAML} allowFullScreen={false} readOnly={true} />
                <div>
                    <OpenDialogButton
                        label={this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYaml"] ? "Edit Resource" : "Add Resource"}
                        renderDialog={({ open, closeDialog }) => (
                            <SourceCodeDialog
                                title="Edit Kubernetes Resource"
                                open={open}
                                close={closeDialog}
                                value={this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYaml"]}
                                validate={this.validateTemplate}
                                autocomplete={[]}
                                saveDone={(value) => {
                                    this.props.setProperties({ ["Octopus.Action.KubernetesContainers.CustomResourceYaml"]: value });
                                }}
                                language={TextFormat.YAML}
                            />
                        )}
                    />
                </div>
            </ExpandableFormSection>
        );
    }

    private getMetadata = (value: string): Promise<{ Metadata: MetadataTypeCollection; Values: DataContext }> => {
        return repository.CloudTemplates.getMetadata(value, "Kubernetes");
    };

    private validateTemplate = async (value: string) => {
        try {
            await this.getMetadata(value);
        } catch (err) {
            return err;
        }
        return null;
    };

    private resourceYamlSummary() {
        if (this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYaml"]) {
            return Summary.summary("Additional resources have been defined");
        }
        return Summary.default("No additional resources have been defined");
    }
}
