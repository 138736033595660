import { logger } from "@octopusdeploy/logging";
import { useCallback, useEffect, useState } from "react";
import * as React from "react";
import { useAnalyticSimpleActionDispatch } from "~/analytics/Analytics";
import { useProjectContext } from "~/areas/projects/context/index";
import { repository } from "~/clientInstance";
import { useEnabledFeatureToggle } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { GitRefDisplay } from "~/components/GitRefDisplay/GitRefDisplay";

export function ProjectCurrentBranchDisplay() {
    const projectContext = useProjectContext();
    const branchProtectionsAreEnabled = useEnabledFeatureToggle("BranchProtectionsFeatureToggle");
    const [pullRequestLink, setPullRequestLink] = useState<string | undefined>(undefined);
    const dispatchAction = useAnalyticSimpleActionDispatch();

    const onPullRequestLinkClicked = useCallback(() => {
        dispatchAction("Create Pull Request");
    }, [dispatchAction]);

    useEffect(() => {
        if (!branchProtectionsAreEnabled || !projectContext.state.gitRef) {
            return;
        }

        repository.Projects.getPullRequestLink(projectContext.state.model, projectContext.state.gitRef.CanonicalName)
            .then((resp) => {
                if (resp.Link) {
                    setPullRequestLink(resp.Link);
                }
            })
            .catch((e) => {
                logger.warn(e, "Failed to get pull request link");
            });
    }, [projectContext.state.model, projectContext.state.gitRef, branchProtectionsAreEnabled]);

    if (!projectContext.state.model.IsVersionControlled) {
        return <></>;
    }

    return <GitRefDisplay currentHead={projectContext.state.gitRef?.CanonicalName} isDefaultBranch={projectContext.state.isDefaultBranch} pullRequestLink={pullRequestLink} onPullRequestLinkClick={onPullRequestLinkClicked} />;
}
