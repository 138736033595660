import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { HasVariablesInGit, HasGitPersistenceSettings } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import * as React from "react";
import { useState } from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { useProjectContext } from "~/areas/projects/context";
import ActionButton, { ActionButtonType } from "~/components/Button";
import CustomDialog from "~/components/Dialog/CustomDialog";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import MigrateProjectVariablesWizard from "./MigrateProjectVariablesDialog";
import styles from "./style.module.less";

interface NewFeatureCalloutTitleProps {
    title: string;
}

const NewFeatureCalloutTitle: React.FC<NewFeatureCalloutTitleProps> = (props: NewFeatureCalloutTitleProps) => {
    return (
        <>
            <NewFeatureChip /> {props.title}
        </>
    );
};

const NewFeatureChip: React.FC<{}> = () => {
    const iconClassName = `fa-solid fa-star`;
    const iconClass = styles.icon + " " + iconClassName;

    return (
        <span className={cn(styles.newChip, styles.lightPulse)} aria-hidden="true">
            <em className={iconClass} />
            New
        </span>
    );
};

interface MigrageProjectVariablesToGitProps {
    project?: ProjectResource;
}

const MigrateProjectVariablesBanner: React.FC<MigrageProjectVariablesToGitProps> = (props: MigrageProjectVariablesToGitProps) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    const dispatchAction = useAnalyticActionDispatch();

    // If this is a database project, or variables are already in Git, don't show the banner
    if (!HasGitPersistenceSettings(project.PersistenceSettings) || HasVariablesInGit(project.PersistenceSettings)) {
        return <></>;
    }

    const openDialogWithAnalytics = () => {
        dispatchAction("Start Moving Variables to Git", { resource: "Variables", action: Action.Configure });
        setDialogOpen(true);
    };

    return (
        <Callout type={CalloutType.Generic} title={<NewFeatureCalloutTitle title="Store variables in Git" />}>
            <p>Variables can now be stored in your Git repository alongside your deployment process.</p>
            <ActionButton type={ActionButtonType.Secondary} label="Migrate variables to Git" onClick={() => openDialogWithAnalytics()} />
            <CustomDialog open={dialogOpen} close={() => setDialogOpen(false)} render={(props) => <MigrateProjectVariablesWizard {...props} />} />
        </Callout>
    );
};

export default MigrateProjectVariablesBanner;
