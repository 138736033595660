/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type {
    ActionTemplateResource,
    ActionTemplateUsageResource,
    ActionUpdateResultResource,
    ActionProperties,
    PackageReference,
    ActionTemplateParameterResource,
    ActionUpdateRemovedPackageUsage,
    ActionsUpdateProcessResource,
} from "@octopusdeploy/octopus-server-client";
import { ActionUpdateOutcome, ActionUpdatePackageUsedBy, ControlType, ProcessType } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import { groupBy, keyBy, uniq, reduce, flatMap } from "lodash";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { PackageSourceItems } from "~/components/ActionTemplateParameterInput/ActionTemplateParameterInput";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import SimpleDataTable from "~/components/SimpleDataTable";
import ExpansionButtons from "~/components/form/Sections/ExpansionButtons";
import { UrlNavigationTabsContainer } from "~/primitiveComponents/navigation/Tabs";
import TabItem from "~/primitiveComponents/navigation/Tabs/TabItem";
import ActionTemplateParameterInputExpandableFormElement from "../../../../components/ActionTemplateParameterInput/ActionTemplateParameterInputExpandableFormElement";
import routeLinks from "../../../../routeLinks";
import { ActionTemplateUsageProcessLink, ActionTemplateUsageStepLink } from "./ActionTemplateUsageLinks";
import styles from "./style.module.less";

type Tab = "auto" | "defaults" | "manual" | "packages";

type Default = ActionTemplateUsageResource & { NamesOfNewParametersMissingDefaultValue: string[] };
type Manual = ActionTemplateUsageResource & { ManualMergeRequiredReasonsByPropertyName: Record<string, string[]> };
type Packages = ActionTemplateUsageResource & { RemovedPackageUsages: ActionUpdateRemovedPackageUsage[] };

interface MergeConflictResolutionDialogState extends DataBaseComponentState {
    showAutoDetails: boolean;
    showDefaultsDetails: boolean;
    showManualDetails: boolean;
    showPackagesDetails: boolean;
    auto: ActionTemplateUsageResource[];
    defaults: Default[];
    manual: Manual[];
    packages: Packages[];
    defaultPropertyValues: ActionProperties;
    appliesToSingleStep: boolean;
    newParametersMissingDefaultValue: ActionTemplateParameterResource[];
    tab: Tab;
    sourceItems: PackageSourceItems;
}

interface MergeConflictResolutionDialogProps {
    usages: ActionTemplateUsageResource[];
    mergeResults: ActionUpdateResultResource[];
    actionTemplate: ActionTemplateResource;
}

export default class MergeConflictResolutionDialog extends DataBaseComponent<MergeConflictResolutionDialogProps, MergeConflictResolutionDialogState> {
    constructor(props: MergeConflictResolutionDialogProps) {
        super(props);

        const mergeResultsByActionId = keyBy(props.mergeResults, "Id");
        const auto = props.usages.filter((usage) => mergeResultsByActionId[usage.ActionId].Outcome === ActionUpdateOutcome.Success);
        const defaults = props.usages
            .filter((usage) => mergeResultsByActionId[usage.ActionId].Outcome === ActionUpdateOutcome.DefaultParamterValueMissing)
            .map((usage) => {
                return {
                    ...usage,
                    NamesOfNewParametersMissingDefaultValue: mergeResultsByActionId[usage.ActionId].NamesOfNewParametersMissingDefaultValue,
                };
            });

        const namesOfNewParameterMissingDefaultValue = uniq<string>(flatMap(defaults.map((d) => d.NamesOfNewParametersMissingDefaultValue)));
        const newParametersMissingDefaultValue = props.actionTemplate.Parameters.filter((p) => namesOfNewParameterMissingDefaultValue.indexOf(p.Name) !== -1);
        const defaultPropertyValues: ActionProperties = newParametersMissingDefaultValue.reduce((acc: Record<string, string>, p) => {
            const type = p.DisplaySettings["Octopus.ControlType"];
            const name = p.Name;
            if (type === ControlType.Package && props.actionTemplate.Packages[0]) {
                acc[name] = props.actionTemplate.Packages[0].PackageId;
            } else {
                acc[name] = null!;
            }
            return acc;
        }, {});

        const manual = props.usages
            .filter((usage) => mergeResultsByActionId[usage.ActionId].Outcome === ActionUpdateOutcome.ManualMergeRequired)
            .map((usage) => {
                return {
                    ...usage,
                    ManualMergeRequiredReasonsByPropertyName: mergeResultsByActionId[usage.ActionId].ManualMergeRequiredReasonsByPropertyName,
                };
            });

        const packages = props.usages
            .filter((usage) => mergeResultsByActionId[usage.ActionId].Outcome === ActionUpdateOutcome.RemovedPackageInUse)
            .map((usage) => {
                return {
                    ...usage,
                    RemovedPackageUsages: mergeResultsByActionId[usage.ActionId].RemovedPackageUsages,
                };
            });

        const appliesToSingleStep = auto.length + manual.length + defaults.length === 1;
        const tab = auto.length ? "auto" : defaults.length ? "defaults" : manual.length ? "manual" : "packages";

        this.state = {
            showAutoDetails: false,
            showDefaultsDetails: false,
            showManualDetails: false,
            showPackagesDetails: false,
            auto,
            defaults,
            manual,
            packages,
            defaultPropertyValues,
            appliesToSingleStep,
            newParametersMissingDefaultValue,
            tab,
            sourceItems: {
                items: props.actionTemplate.Packages || [],
                feeds: [],
                onRequestRefresh: this.refreshFeeds,
                setPackages: this.setPackages,
            },
        };
    }

    setPackages = (packages: PackageReference[]) => {
        this.setState({
            sourceItems: {
                ...this.state.sourceItems,
                items: packages,
            },
        });
    };

    refreshFeeds = async () => {
        await this.doBusyTask(async () => {
            const feedsGetter = repository.Feeds.all();
            try {
                const feeds = await feedsGetter;
                this.setState({
                    sourceItems: {
                        ...this.state.sourceItems,
                        feeds,
                    },
                });
            } catch (error) {
                if (error.StatusCode !== 404) {
                    throw error;
                }
            }
        });
    };

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const feedsGetter = repository.Feeds.all();
            try {
                const feeds = await feedsGetter;
                this.setState({
                    sourceItems: {
                        ...this.state.sourceItems,
                        feeds,
                    },
                });
            } catch (error) {
                if (error.StatusCode !== 404) {
                    throw error;
                }
            }
        });
    }

    render() {
        return (
            <SaveDialogLayout
                onSaveClick={this.onSave}
                hideSave={this.state.tab === "manual" || this.state.tab === "packages"}
                title={this.state.appliesToSingleStep ? "Update step" : "Update all projects"}
                saveButtonLabel={this.state.tab === "auto" ? "Update steps automatically" : "Update steps using default values"}
                errors={this.errors}
                busy={this.state.busy}
            >
                <div>
                    See <ExternalLink href="StepTemplateUpdate">our documentation</ExternalLink> for more information regarding possible reasons why the update couldn't be done automatically.
                </div>
                {this.renderTabs()}
                {this.renderAuto()}
                {this.renderDefaults()}
                {this.renderManual()}
                {this.renderPackages()}
            </SaveDialogLayout>
        );
    }

    private renderTabs() {
        return (
            <ul className={styles.mergeTabs}>
                {this.renderAutoTab()}
                {this.renderDefaultsTab()}
                {this.renderManualTab()}
                {this.renderPackagesTab()}
            </ul>
        );
    }

    private renderAutoTab() {
        const autoLength = this.state.auto.length;
        if (autoLength === 0) {
            return null;
        }
        return (
            <li className={cn({ [styles.active]: this.state.tab === "auto" })}>
                <div className={cn(styles.navTabs, styles.green)}>
                    <p>
                        <strong>
                            {autoLength} {this.step(autoLength)}
                        </strong>
                        <br />
                        {this.have(autoLength)} all the information to update automatically.
                    </p>
                    <a href="#" className={styles.showDetails} onClick={(e) => this.openTab(e, "auto")}>
                        View details
                    </a>
                </div>
            </li>
        );
    }

    private renderDefaultsTab() {
        const defaultsLength = this.state.defaults.length;
        if (defaultsLength === 0) {
            return null;
        }
        return (
            <li className={cn({ [styles.active]: this.state.tab === "defaults" })}>
                <div className={styles.navTabs}>
                    <p>
                        <strong>
                            {defaultsLength} {this.step(defaultsLength)}
                        </strong>
                        <br />
                        {this.have(defaultsLength)} default values to be updated automatically.
                    </p>
                    <a href="#" className={styles.showDetails} onClick={(e) => this.openTab(e, "defaults")}>
                        View parameters and steps
                    </a>
                </div>
            </li>
        );
    }

    private renderManualTab() {
        const manualLength = this.state.manual.length;
        if (manualLength === 0) {
            return null;
        }
        return (
            <li className={cn({ [styles.active]: this.state.tab === "manual" })}>
                <div className={styles.navTabs}>
                    <p>
                        <strong>
                            {manualLength} {this.step(manualLength)}
                        </strong>
                        <br />
                        {this.have(manualLength)} more detail and must be updated manually.
                    </p>
                    <a href="#" className={styles.showDetails} onClick={(e) => this.openTab(e, "manual")}>
                        View steps
                    </a>
                </div>
            </li>
        );
    }

    private renderPackagesTab() {
        const packagesLength = this.state.packages.length;
        if (packagesLength === 0) {
            return null;
        }
        return (
            <li className={cn({ [styles.active]: this.state.tab === "packages" })}>
                <div className={styles.navTabs}>
                    <p>
                        <strong>
                            {packagesLength} {this.step(packagesLength)}
                        </strong>
                        <br />
                        {this.is(packagesLength)} in a project which reference a package which has been removed from the template.
                    </p>
                    <a href="#" className={styles.showDetails} onClick={(e) => this.openTab(e, "packages")}>
                        View steps
                    </a>
                </div>
            </li>
        );
    }

    private renderAuto() {
        if (this.state.tab !== "auto") {
            return null;
        }

        return (
            <SimpleDataTable
                data={this.state.auto}
                headerColumns={["Project", "Process", "Step", "Version"]}
                onRow={(u: ActionTemplateUsageResource) => [
                    <InternalLink to={routeLinks.project(u.ProjectSlug).root} openInSelf={false}>
                        {u.ProjectName}
                    </InternalLink>,
                    <ActionTemplateUsageProcessLink usage={u} />,
                    <ActionTemplateUsageStepLink usage={u} />,
                    u.Version,
                ]}
            />
        );
    }

    private renderDefaults() {
        if (this.state.tab !== "defaults") {
            return null;
        }

        return (
            <div>
                <p>
                    Please provide default values for the following parameters. Default values will be used to update all steps automatically. If you wish to provide different values for each step, please update each step manually. Empty values are
                    accepted as default values.
                </p>
                <UrlNavigationTabsContainer defaultValue="parameters">
                    <TabItem label="Parameters" value="parameters">
                        <ExpansionButtons />
                        {this.state.newParametersMissingDefaultValue.map((param) => {
                            return (
                                <ActionTemplateParameterInputExpandableFormElement
                                    parameter={param}
                                    key={param.Name}
                                    sourceItems={this.state.sourceItems}
                                    doBusyTask={this.doBusyTask}
                                    value={this.state.defaultPropertyValues[param.Name]}
                                    onChange={(x) => this.setState((state) => ({ defaultPropertyValues: { ...state.defaultPropertyValues, [param.Name]: x } }))}
                                    customHelpText={(label) => `Provide a default value for ${label}`}
                                    actionType={this.props.actionTemplate.ActionType}
                                />
                            );
                        })}
                    </TabItem>
                    <TabItem label="Process" value="process">
                        <SimpleDataTable
                            data={this.state.defaults}
                            headerColumns={["Project", "Process", "Step", "Parameters", "Version"]}
                            onRow={(u: Default) => [
                                <InternalLink to={routeLinks.project(u.ProjectSlug).root} openInSelf={false}>
                                    {u.ProjectName}
                                </InternalLink>,
                                <ActionTemplateUsageProcessLink usage={u} />,
                                <ActionTemplateUsageStepLink usage={u} />,
                                <ul className={styles.reasons}>
                                    {u.NamesOfNewParametersMissingDefaultValue.map((nameOfNewParameterMissingDefaultValue, index) => (
                                        <li key={`defaultValue-${index}`}>nameOfNewParameterMissingDefaultValue</li>
                                    ))}
                                </ul>,
                                u.Version,
                            ]}
                        />
                    </TabItem>
                </UrlNavigationTabsContainer>
            </div>
        );
    }

    private renderPackages() {
        if (this.state.tab !== "packages") {
            return null;
        }

        return (
            <div>
                <p>
                    {this.state.packages.length} {this.step(this.state.packages.length)} {this.is(this.state.packages.length)} in a project which reference a package on the step which has been removed in the latest version of the step template. These
                    references must be removed before the step can be updated. The details are provided below:
                </p>
                <SimpleDataTable
                    data={this.state.packages}
                    headerColumns={["Project", "Process", "Step", "Referenced By", "Version"]}
                    onRow={(u: Packages) => [
                        <InternalLink to={routeLinks.project(u.ProjectSlug).root} openInSelf={false}>
                            {u.ProjectName}
                        </InternalLink>,
                        <ActionTemplateUsageProcessLink usage={u} />,
                        <ActionTemplateUsageStepLink usage={u} />,
                        <ul className={styles.reasons}>
                            {u.RemovedPackageUsages.map((pkg, index) => (
                                <li key={`packageConflicts-${index}`}>{this.renderRemovedPackageUsageReason(pkg)}</li>
                            ))}
                        </ul>,
                        u.Version,
                    ]}
                />
            </div>
        );
    }

    private renderManual() {
        if (this.state.tab !== "manual") {
            return null;
        }

        return (
            <div>
                <p>
                    {this.state.manual.length} {this.step(this.state.manual.length)} must be updated manually because we don't have enough information to do it automatically. This might be a bit of work but in most cases once the update is completed
                    we will have all required data for future updates to be automated.
                </p>
                <SimpleDataTable
                    data={this.state.manual}
                    headerColumns={["Project", "Process", "Step", "Reasons", "Version", ""]}
                    onRow={(u: Manual) => [
                        <InternalLink to={routeLinks.project(u.ProjectSlug).root} openInSelf={false}>
                            {u.ProjectName}
                        </InternalLink>,
                        <ActionTemplateUsageProcessLink usage={u} />,
                        <ActionTemplateUsageStepLink usage={u} />,
                        <ul className={styles.reasons}>
                            {Object.keys(u.ManualMergeRequiredReasonsByPropertyName).map((property, index) => (
                                <li key={`manualMerge-${index}`}>
                                    {property && `${property}: `}
                                    {u.ManualMergeRequiredReasonsByPropertyName[property].join()}
                                </li>
                            ))}
                        </ul>,
                        u.Version,
                        <InternalLink
                            to={
                                u.ProcessType === ProcessType.Runbook
                                    ? routeLinks.project(u.ProjectSlug).operations.runbook(u.RunbookId).runbookProcess.runbookProcess(u.ProcessId).process.step(u.ActionId)
                                    : routeLinks.project(u.ProjectSlug).deployments.process.step(u.ActionId)
                            }
                            openInSelf={false}
                        >
                            Update
                        </InternalLink>,
                    ]}
                />
            </div>
        );
    }

    private openTab = async (e: React.MouseEvent, tab: Tab) => {
        // do through doBusyTask so we trigger our dialog resize fix
        await this.doBusyTask(async () => {
            e.preventDefault();
            this.setState({ tab });
        });
    };

    private onSave = async () => {
        return this.doBusyTask(async (): Promise<boolean> => {
            switch (this.state.tab) {
                case "auto": {
                    await this.updateActions(this.props.actionTemplate, this.state.auto);
                    this.setState({ auto: [], tab: this.nextTab() });
                    const allDone = this.state.manual.length === 0 && this.state.defaults.length === 0;
                    return allDone ? true : false;
                }
                case "defaults": {
                    await this.updateActions(this.props.actionTemplate, this.state.defaults, this.state.defaultPropertyValues);
                    this.setState({ defaults: [], tab: this.nextTab() });
                    const allDone = this.state.manual.length === 0 && this.state.auto.length === 0;
                    return allDone ? true : false;
                }
            }
            return false;
        });
    };

    private updateActions = async (actionTemplate: ActionTemplateResource, usages: ActionTemplateUsageResource[], defaults: ActionProperties = null!) => {
        const usagesByProcessId = groupBy(usages, (x) => x.ProcessId);

        const initialUpdateValue: ActionsUpdateProcessResource = null!; //needed since our ts rules don't allow us to specify the seed value type inline
        const updates = Object.keys(usagesByProcessId).reduce((prev: ActionsUpdateProcessResource[], processId) => {
            return [
                ...prev,
                reduce(
                    usagesByProcessId[processId],
                    (update, usage) => {
                        return !update ? { ProcessType: usage.ProcessType, ProcessId: usage.ProcessId, ActionIds: [usage.ActionId] } : { ...update, ActionIds: [...update.ActionIds, usage.ActionId] };
                    },
                    initialUpdateValue
                ),
            ];
        }, []);

        await repository.ActionTemplates.updateActions(actionTemplate, updates, defaults);
    };

    private have = (n: number) => {
        return n === 1 ? "has" : "have";
    };

    private need = (n: number) => {
        return n === 1 ? "needs" : "need";
    };

    private step = (n: number) => {
        return n === 1 ? "step" : "steps";
    };

    private is = (n: number) => {
        return n === 1 ? "is" : "are";
    };

    private nextTab = () => {
        return this.state.auto.length ? "auto" : this.state.defaults.length ? "defaults" : "manual";
    };

    private renderRemovedPackageUsageReason = (pkgUsage: ActionUpdateRemovedPackageUsage) => {
        switch (pkgUsage.UsedBy) {
            case ActionUpdatePackageUsedBy.ProjectVersionStrategy:
                return (
                    <React.Fragment>
                        Versioning strategy in project{" "}
                        <InternalLink to={routeLinks.project(pkgUsage.UsedById).settings.root} openInSelf={false}>
                            {pkgUsage.UsedByName}
                        </InternalLink>
                    </React.Fragment>
                );
            case ActionUpdatePackageUsedBy.ProjectReleaseCreationStrategy:
                return (
                    <React.Fragment>
                        Release creation strategy in project{" "}
                        <InternalLink to={routeLinks.project(pkgUsage.UsedById).triggers} openInSelf={false}>
                            {pkgUsage.UsedByName}
                        </InternalLink>
                    </React.Fragment>
                );
            case ActionUpdatePackageUsedBy.ChannelRule:
                return (
                    <React.Fragment>
                        Rule in channel{" "}
                        <InternalLink to={routeLinks.channel(pkgUsage.UsedById)} openInSelf={false}>
                            {pkgUsage.UsedByName}
                        </InternalLink>
                    </React.Fragment>
                );
        }
    };
}
