import type { DynamicEnvironmentOverviewState } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { DynamicEnvironmentStateIcon } from "./DynamicEnvironmentStateIcon";
import { getDynamicEnvironmentStateDescription } from "./getDynamicEnvironmentStateDescription";
import styles from "./style.module.less";

type DynamicEnvironmentStateIconWithDescriptionProps = {
    state: DynamicEnvironmentOverviewState;
};

export function DynamicEnvironmentStateIconWithDescription({ state }: DynamicEnvironmentStateIconWithDescriptionProps) {
    return (
        <div className={styles.dynamicEnvironmentStateIconWithDescription}>
            <DynamicEnvironmentStateIcon state={state} />
            <span>{getDynamicEnvironmentStateDescription(state)}</span>
        </div>
    );
}
