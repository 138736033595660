import type { BranchSpecifier, LicenseStatusResource, ProjectGroupResource, ValidateGitRefV2Response } from "@octopusdeploy/octopus-server-client";
import { HostingEnvironment, Permission, UseDefaultBranch, ValidateGitRefV2ResponseType } from "@octopusdeploy/octopus-server-client";
import { Environment } from "@octopusdeploy/utilities";
import type { PropsWithChildren } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { BranchSelector } from "~/areas/projects/components/BranchSelector/BranchSelector";
import { alwaysHideOnboardingQuestionnaireKey } from "~/areas/projects/components/Projects/OnboardingQuestionnaire/AlwaysHideOnboardingQuestionnaire";
import { NewlyCreatedProjectWizardDialog } from "~/areas/projects/components/Projects/OnboardingQuestionnaire/NewlyCreatedProjectWizardDialog";
import { OnboardingQuestionnaireDialog } from "~/areas/projects/components/Projects/OnboardingQuestionnaire/OnboardingQuestionnaireDialog";
import { VcsErrorPanel } from "~/areas/projects/components/VersionControl/VcsErrorPanel";
import { useIsPageVersionControlled } from "~/areas/projects/context/useIsPageVersionControlled";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import BusyFromPromise from "~/components/BusyFromPromise/BusyFromPromise";
import BusyIndicator from "~/components/BusyIndicator/BusyIndicator";
import { DisabledChip } from "~/components/Chips";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import ErrorPanel from "~/components/ErrorPanel/ErrorPanel";
import { useEnabledFeatureToggle } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { GettingStartedFooter } from "~/components/GettingStarted/GettingStartedFooter";
import { SomethingsWrong } from "~/components/Images/NotFound/SomethingsWrong";
import InternalLink from "~/components/Navigation/InternalLink/index";
import NavigationSidebarLayout from "~/components/NavigationSidebarLayout/index";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import routeLinks from "~/routeLinks";
import type { ProjectContextState } from "../../context";
import { ProjectContextProvider } from "../../context";
import { CreateReleaseButton } from "../Releases";
import { ProjectLinks } from "./ProjectLinks";
import styles from "./style.module.less";

interface ProjectLayoutProps {
    projectSlug: string;
    branchName: string | undefined;
    isNewlyCreatedProject?: string | null;
}

export function ProjectLayout({ projectSlug, isNewlyCreatedProject, branchName, children }: PropsWithChildren<ProjectLayoutProps>) {
    const isMultiTenancyEnabled = useSelector((state: GlobalState) => state.configurationArea.currentSpace.isMultiTenancyEnabled);

    const isPageVersionControlled = useIsPageVersionControlled();
    return (
        <ErrorContextProvider>
            <ProjectLayoutInternal projectSlug={projectSlug} branchName={branchName} isNewlyCreatedProject={isNewlyCreatedProject} isMultiTenancyEnabled={isMultiTenancyEnabled} isPageVersionControlled={isPageVersionControlled}>
                {children}
            </ProjectLayoutInternal>
        </ErrorContextProvider>
    );
}

type ProjectLayoutInternalProps = PropsWithChildren<{
    projectSlug: string;
    branchName: string | undefined;
    isNewlyCreatedProject: string | null | undefined;
    isMultiTenancyEnabled: boolean;
    isPageVersionControlled: boolean;
}>;

interface ProjectLayoutState extends DataBaseComponentState {
    projectGroups: ProjectGroupResource[] | null;
    licenseStatus: LicenseStatusResource | null;
    hasEnvironments: boolean | null;
    newlyCreatedDialogOpen: boolean;
}

class ProjectLayoutInternal extends DataBaseComponent<ProjectLayoutInternalProps, ProjectLayoutState> {
    constructor(props: ProjectLayoutInternalProps) {
        super(props);
        this.state = {
            projectGroups: null,
            licenseStatus: null,
            hasEnvironments: null,
            newlyCreatedDialogOpen: props.isNewlyCreatedProject === "true",
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const projectGroups = repository.ProjectGroups.all();
            const licenseStatus = repository.Licenses.getCurrentStatus();
            const hasEnvironments = repository.Environments.list({ take: 0 }).then((response) => response.TotalResults !== 0);

            this.setState({ projectGroups: await projectGroups, licenseStatus: await licenseStatus, hasEnvironments: await hasEnvironments });
        });
    }

    toBranchSpecifier(branch: string | undefined): BranchSpecifier {
        return branch ? branch : UseDefaultBranch;
    }

    render() {
        const projectSlug = this.props.projectSlug;
        const branchName = this.props.branchName;
        const branchSpecifier = this.toBranchSpecifier(branchName);
        const { isMultiTenancyEnabled } = this.props;
        const logoEditLink = routeLinks.project(projectSlug).settings.root;
        return (
            <ProjectContextProvider doBusyTask={this.doBusyTask} projectIdOrSlug={projectSlug} gitRef={branchSpecifier}>
                {({ state }) => {
                    const isProjectBasedActivationFeatureEnabled = useEnabledFeatureToggle("ProjectBasedActivationFeatureToggle");
                    const projectLogo = state.model && state.model && state.model.Links.Logo;
                    const showBusyIndicator = !(state.model || (this.state && this.errors));
                    return state.model ? (
                        <main id="maincontent">
                            {this.renderNewlyCreatedProjectDialog(isProjectBasedActivationFeatureEnabled)}
                            {this.areaTitle(state, false)}
                            {this.renderErrors()}
                            <NavigationSidebarLayout
                                logoEditLink={logoEditLink}
                                logoUrl={projectLogo}
                                name={state.model.Name}
                                description={state.model.Description}
                                preNavbarComponent={
                                    <div>
                                        {state.model.IsDisabled && (
                                            <div>
                                                <DisabledChip />
                                            </div>
                                        )}
                                        {(state.summary?.HasDeploymentProcess || !state.summary?.HasRunbooks) && <CreateReleaseButton projectId={state.model.Id} projectSlug={state.model.Slug} />}
                                        <div className={styles.branchSelector}>
                                            <BranchSelector isPageVersionControlled={this.props.isPageVersionControlled} />
                                        </div>
                                    </div>
                                }
                                navLinks={ProjectLinks(projectSlug, branchSpecifier, state.model, state.summary, isMultiTenancyEnabled, state.gitRefValidationError?.Type === ValidateGitRefV2ResponseType.ConnectionFailed)}
                                content={state.model.IsVersionControlled && this.props.isPageVersionControlled && state.gitRefValidationError ? this.getGitErrorPanel(state.gitRefValidationError) : this.props.children}
                            />
                            <GettingStartedFooter />
                        </main>
                    ) : (
                        <main id="maincontent">
                            <AreaTitle link={routeLinks.projects.root} title="Projects" busyIndicator={this.renderBusy(showBusyIndicator)} />
                            {this.renderErrors()}
                            <NavigationSidebarLayout logoEditLink={logoEditLink} logoUrl={projectLogo} navLinks={[]} content={<></>} />
                        </main>
                    );
                }}
            </ProjectContextProvider>
        );
    }

    private getGitErrorPanel(error: ValidateGitRefV2Response) {
        const projectLinks = routeLinks.project(this.props.projectSlug);
        return (
            <div className={styles.vcsErrorPanelContainer}>
                <VcsErrorPanel
                    content={
                        error.Type === ValidateGitRefV2ResponseType.ConnectionFailed ? (
                            <>
                                Unable to establish a connection to git. <InternalLink to={projectLinks.settings.versionControl}>Please check your version control settings</InternalLink>.
                            </>
                        ) : (
                            <>{error.Message}</>
                        )
                    }
                />
                <SomethingsWrong height="7em" margin="2rem 0" />
            </div>
        );
    }

    renderErrors() {
        const errors = this.state && this.errors;
        if (!errors) {
            return null;
        }
        return <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink} />;
    }

    private renderBusy(forceBusy: boolean) {
        return <BusyFromPromise promise={this.state.busy || forceBusy}>{(busy: boolean) => <BusyIndicator show={busy || forceBusy} />}</BusyFromPromise>;
    }

    private renderNewlyCreatedProjectDialog(isProjectBasedActivationFeatureEnabled: boolean) {
        if (!isProjectBasedActivationFeatureEnabled) {
            return this.shouldShowOnboardingQuestionnaire() && <OnboardingQuestionnaireDialog doBusyTask={this.doBusyTask} />;
        }

        return (
            <NewlyCreatedProjectWizardDialog
                open={this.state.newlyCreatedDialogOpen}
                close={() => this.setState({ newlyCreatedDialogOpen: false })}
                showOnboardingQuestionnaire={this.shouldShowOnboardingQuestionnaire()}
                showCreateEnvironments={this.shouldShowCreateEnvironments()}
                doBusyTask={this.doBusyTask}
            />
        );
    }

    private areaTitle(state: ProjectContextState, forceBusy: boolean) {
        const hasAccessibleProjectGroup = this.state.projectGroups && state.model.ProjectGroupId && this.state.projectGroups.find((pg) => pg.Id === state.model.ProjectGroupId);
        return !hasAccessibleProjectGroup ? (
            <AreaTitle link={routeLinks.projects.root} title="Projects" busyIndicator={this.renderBusy(forceBusy)} />
        ) : (
            <AreaTitle breadcrumbTitle="Projects" breadcrumbPath={routeLinks.projects.root} link={routeLinks.projects.filteredByGroup(state.model.ProjectGroupId)} title={hasAccessibleProjectGroup.Name} busyIndicator={this.renderBusy(forceBusy)} />
        );
    }

    private shouldShowOnboardingQuestionnaire() {
        const canShowQuestionnaire = Environment.isInDevelopmentMode() || (this.state.licenseStatus !== null && this.state.licenseStatus.HostingEnvironment !== HostingEnvironment.SelfHosted);
        if (!canShowQuestionnaire) {
            return false;
        }
        const alwaysHideQuestionnaire = localStorage.getItem(alwaysHideOnboardingQuestionnaireKey);
        return alwaysHideQuestionnaire !== "true" && this.props.isNewlyCreatedProject === "true";
    }

    private shouldShowCreateEnvironments() {
        const hasEnvironmentCreatePermission = isAllowed({ permission: Permission.EnvironmentCreate });
        return hasEnvironmentCreatePermission && !this.state.hasEnvironments;
    }
}
