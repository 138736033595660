/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */

import { Drawer } from "@material-ui/core";
import { OctoLink } from "@octopusdeploy/utilities";
import classNames from "classnames";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import MediaQuery from "react-responsive";
import { createHtmlPortalNode, InPortal, OutPortal } from "react-reverse-portal";
import { AnalyticLinkProvider } from "~/analytics/AnalyticLink";
import configurationSelectors from "~/areas/configuration/reducers/selectors";
import drawerActions from "~/components/ContextualHelpLayout/reducers/actions";
import * as drawerSelectors from "~/components/ContextualHelpLayout/reducers/selectors";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { pageConfigSelector } from "~/components/PageLayout/reducers/pageLayoutArea";
import { Section } from "~/components/Section/Section";
import { ControlledTabsContainer } from "~/primitiveComponents/navigation/Tabs";
import TabItem from "~/primitiveComponents/navigation/Tabs/TabItem";
import type { GlobalState } from "../../store/reducers";
import styles from "./ContextualHelpLayout.module.less";
import { PageHelpOverviewView, PageHelpTopicsView } from "./PageHelpRegistry/PageHelpSelector";

export const drawerBreakpointWidth = 900;

export function ContextualHelpLayout(props: React.PropsWithChildren<{}>) {
    const dispatch = useDispatch();
    const toggleDrawer: () => void = React.useCallback(() => dispatch(drawerActions.toggleDrawer()), [dispatch]);
    const isDrawerOpen = useSelector(drawerSelectors.isDrawerOpen);
    const isHelpSidebarEnabled = useSelector(configurationSelectors.createFeatureEnabledSelector((x) => x.isHelpSidebarEnabled));
    const portalNode = React.useMemo(() => createHtmlPortalNode(), []);

    return (
        <React.Fragment>
            <InPortal node={portalNode}>{props.children}</InPortal>
            <MediaQuery minWidth={drawerBreakpointWidth}>
                {(matches: boolean) => {
                    if (matches && isHelpSidebarEnabled) {
                        return (
                            <div id="drawerwrapper">
                                <div className={styles.root}>
                                    <main
                                        className={classNames(styles.mainContent, {
                                            [styles.mainContentShift]: isDrawerOpen,
                                        })}
                                    >
                                        <OutPortal node={portalNode} />
                                    </main>
                                    <ContextualHelpDrawer open={isDrawerOpen} toggleDrawer={toggleDrawer} />
                                </div>
                            </div>
                        );
                    } else {
                        return <OutPortal node={portalNode} />;
                    }
                }}
            </MediaQuery>
        </React.Fragment>
    );
}

enum DrawerTabKey {
    Overview = "overview",
    HelpTopics = "helpTopics",
}

interface ContextualHelpSidebarContentProps {
    additionalTabsElement: React.ReactElement | undefined;
}

function ContextualHelpSidebarContent(props: ContextualHelpSidebarContentProps) {
    const page = useSelector(pageConfigSelector);
    const helpSidebarSupportLink = useSelector((state: GlobalState) => state.configurationArea.features && state.configurationArea.features.helpSidebarSupportLink);
    const [tab, setTab] = React.useState<string>(DrawerTabKey.Overview);

    return (
        <AnalyticLinkProvider location="Help sidebar">
            <div className={styles.content}>
                <ControlledTabsContainer value={tab} onChange={setTab} afterTabsElement={props.additionalTabsElement}>
                    <TabItem label="Help" value={DrawerTabKey.Overview}>
                        <Section>
                            <PageHelpOverviewView pageId={page?.page?.Id} />
                        </Section>
                    </TabItem>
                    <TabItem label="Resources" value={DrawerTabKey.HelpTopics}>
                        <Section>
                            <PageHelpTopicsView pageId={page?.page?.Id} />
                        </Section>
                    </TabItem>
                </ControlledTabsContainer>
            </div>
            <a href={helpSidebarSupportLink ? helpSidebarSupportLink : OctoLink.Create("HelpGeneral")} target="_blank" rel="noopener noreferrer">
                <div className={styles.footer}>
                    <h4>Having issues?</h4>
                    <span>
                        {helpSidebarSupportLink ? "Get support" : "Our support team is here to help"}
                        <em style={{ marginLeft: "0.25rem" }} className="fa-solid fa-external-link" aria-hidden="true" />
                    </span>
                </div>
            </a>
        </AnalyticLinkProvider>
    );
}

interface ContextualHelpDrawerProps {
    open: boolean;
    toggleDrawer: () => void;
}

function ContextualHelpDrawer({ open, toggleDrawer }: ContextualHelpDrawerProps) {
    const [height, setHeight] = React.useState(0);
    const drawerDiv = React.useRef<HTMLDivElement>(null);

    const calculateHeight = React.useCallback(() => {
        if (drawerDiv.current !== null) {
            const top = drawerDiv.current.getBoundingClientRect().top;
            const approxHeightOfPaddingAndOtherJunk = 0;
            setHeight(window.innerHeight - top - approxHeightOfPaddingAndOtherJunk);
        }
    }, []);

    //The material drawer in this layout uses a static position and a height of 100% shifts things like footers off screen
    //we therefore calculate the necessary height and set it
    React.useLayoutEffect(calculateHeight, [calculateHeight]);

    //Since we are manually handling the height, we also need to adjust things based on when the browser resizes
    React.useEffect(() => {
        window.addEventListener("resize", calculateHeight);
        return () => window.removeEventListener("resize", calculateHeight);
    }, [calculateHeight]);

    return (
        <Drawer
            className={classNames(styles.drawer, {
                [styles.drawerHidden]: !open,
            })}
            variant="persistent"
            anchor="right"
            open={open}
            classes={{
                paper: classNames(styles.drawerPaper),
            }}
        >
            <div className={styles.container} ref={drawerDiv} style={{ height }}>
                {open && <ContextualHelpSidebarContent additionalTabsElement={<OverflowMenu menuItems={[OverflowMenuItems.item("Close", toggleDrawer)]} tabIndex={-1} />} />}
            </div>
        </Drawer>
    );
}
