/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import type { AwsDeployCloudFormationProperties } from "@octopusdeploy/legacy-action-properties";
import { ActionExecutionLocation, GetPrimaryPackageReference, InitialisePrimaryPackageReference, RemovePrimaryPackageReference, SetPrimaryPackageReference } from "@octopusdeploy/octopus-server-client";
import type { DataContext, MetadataTypeCollection, TypeMetadata } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useFeedsFromContext, useRefreshFeedsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { repository } from "~/clientInstance";
import Roles from "~/components/Actions/Roles";
import type { ActionSummaryProps } from "~/components/Actions/actionSummaryProps";
import type { ActionEditProps, ActionPlugin } from "~/components/Actions/pluginRegistry";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import DeletableChip from "~/components/Chips/DeletableChip";
import { default as CodeEditor, TextFormat } from "~/components/CodeEditor/CodeEditor";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import DynamicForm from "~/components/DynamicForm/DynamicForm";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import PackageSelector from "~/components/PackageSelector/PackageSelector";
import SourceCodeDialog from "~/components/SourceCodeDialog/SourceCodeDialog";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import { ExpandableFormSection, Summary } from "~/components/form";
import { CardFill } from "~/components/form/Sections/ExpandableFormSection";
import { default as FormSectionHeading } from "~/components/form/Sections/FormSectionHeading";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import Checkbox from "~/primitiveComponents/form/Checkbox/Checkbox";
import { BoundStringCheckbox } from "~/primitiveComponents/form/Checkbox/StringCheckbox";
import Note from "~/primitiveComponents/form/Note/Note";
import RadioButton from "~/primitiveComponents/form/RadioButton/RadioButton";
import RadioButtonGroup from "~/primitiveComponents/form/RadioButton/RadioButtonGroup";
import { JsonUtils } from "~/utils/jsonUtils";
import StructuredConfigurationVariablesToggle from "../../../components/Features/structuredConfigurationVariables/structuredConfigurationVariablesToggle";
import { KeyValueEditList } from "../../EditList/KeyValueEditList";
import type { KeyValuePair } from "../../EditList/KeyValueEditList";
import { toggleFeature } from "../../Features/enabledFeaturesHelpers";
import type { ActionWithFeeds } from "../commonActionHelpers";
import DockerReferenceList from "../packageReferences";
import type { ScriptPackageProperties } from "../script/ScriptPackageReferenceDialog";
import { CloudFormationChangesetFeature } from "./awsCloudFormationChangesetFeature";
import { default as AwsLoginComponent } from "./awsLoginComponent";
import CommonSummaryHelper from "~/components/../utils/CommonSummaryHelper/CommonSummaryHelper";

class AwsCloudFormationActionSummary extends BaseComponent<ActionSummaryProps> {
    render() {
        return (
            <div>
                Deploy an AWS CloudFormation template
                {this.props.targetRolesAsCSV && (
                    <span>
                        {" "}
                        on behalf of targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} />
                    </span>
                )}
            </div>
        );
    }
}

interface AwsParameter {
    ParameterKey: string;
    ParameterValue: any;
}

const knownCapabilities = [
    { Id: "CAPABILITY_IAM", Name: "The template has IAM resources (CAPABILITY_IAM)" },
    { Id: "CAPABILITY_NAMED_IAM", Name: "The template has IAM resources with custom names (CAPABILITY_NAMED_IAM)" },
    { Id: "CAPABILITY_AUTO_EXPAND", Name: "The template contains macros (CAPABILITY_AUTO_EXPAND)" },
];

const CapabilityMultiselect = MultiSelect<SelectItem>();

type Tag = KeyValuePair;

interface AwsCloudFormationMetadata {
    tags: Tag[];
}

interface AwsCloudFormationActionEditState extends AwsCloudFormationMetadata {
    capabilities: string[];
    parameterTypes?: TypeMetadata[];
    parameterValues?: any;
}

type AwsCloudFormationActionEditInternalProps = ActionEditProps<AwsDeployCloudFormationProperties, ScriptPackageProperties> & ActionWithFeeds;

class AwsCloudFormationActionEditInternal extends BaseComponent<AwsCloudFormationActionEditInternalProps, AwsCloudFormationActionEditState> {
    parameterValues: {};
    parameters: {};
    source: any;

    constructor(props: AwsCloudFormationActionEditInternalProps) {
        super(props);
        this.state = {
            capabilities: [],
            tags: [],
        };
        this.parameterValues = {};
        this.parameters = {};
        this.source = { octopus: "octopus" };
    }

    async componentDidMount() {
        if (!this.props.properties["Octopus.Action.Aws.WaitForCompletion"]) {
            this.props.setProperties({ ["Octopus.Action.Aws.WaitForCompletion"]: "True" }, true);
        }

        if (!this.props.properties["Octopus.Action.Aws.AssumeRole"]) {
            this.props.setProperties({ ["Octopus.Action.Aws.AssumeRole"]: "False" }, true);
        }

        if (!this.props.properties["Octopus.Action.AwsAccount.UseInstanceRole"]) {
            this.props.setProperties({ ["Octopus.Action.AwsAccount.UseInstanceRole"]: "False" }, true);
        }

        if (!this.props.properties["Octopus.Action.Aws.CloudFormationTemplateParameters"]) {
            this.props.setProperties({ ["Octopus.Action.Aws.CloudFormationTemplateParameters"]: "" }, true);
        }

        if (!this.props.properties["Octopus.Action.Aws.TemplateSource"]) {
            this.props.setProperties({ ["Octopus.Action.Aws.TemplateSource"]: "Inline" }, true);
        }

        // If CloudFormationTemplateParametersRaw isn't set, default it to the value of
        // CloudFormationTemplateParameters. This accounts for cases where people have
        // populated CloudFormationTemplateParameters and upgraded to an Octopus version that
        // expects the UI input to be saved in CloudFormationTemplateParametersRaw.
        if (!this.props.properties["Octopus.Action.Aws.CloudFormationTemplateParametersRaw"]) {
            this.props.setProperties(
                {
                    ["Octopus.Action.Aws.CloudFormationTemplateParametersRaw"]: this.props.properties["Octopus.Action.Aws.CloudFormationTemplateParameters"],
                },
                true
            );
        }
    }

    async UNSAFE_componentWillMount() {
        if (this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"] && this.props.properties["Octopus.Action.Aws.TemplateSource"] === "Inline") {
            await this.refreshParametersFromTemplate(this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"]);
        }

        if (this.props.properties["Octopus.Action.Aws.IamCapabilities"]) {
            this.setState({ capabilities: JSON.parse(this.props.properties["Octopus.Action.Aws.IamCapabilities"]) });
        }

        if (this.props.properties["Octopus.Action.Aws.CloudFormation.Tags"]) {
            this.setState({ tags: JSON.parse(this.props.properties["Octopus.Action.Aws.CloudFormation.Tags"]) });
        }
    }

    templateSourceSummary() {
        const source = this.props.properties["Octopus.Action.Aws.TemplateSource"];
        if (source === "Inline") {
            return Summary.summary("Source code");
        }
        if (source === "Package") {
            return Summary.summary("File inside a package");
        }
        if (source === "S3URL") {
            return Summary.summary("S3 URL");
        }

        return Summary.placeholder("Template source not specified");
    }

    templatePathSummary() {
        if (this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"]) {
            return Summary.summary(
                <span>
                    Using the template in <strong>{this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"]}</strong>
                </span>
            );
        } else {
            return Summary.placeholder(
                <span>
                    <em>No template path specified</em>
                </span>
            );
        }
    }

    parameterPathSummary() {
        if (this.props.properties["Octopus.Action.Aws.CloudFormationTemplateParametersRaw"]) {
            return Summary.summary(
                <span>
                    Using parameters from <strong>{this.props.properties["Octopus.Action.Aws.CloudFormationTemplateParametersRaw"]}</strong>
                </span>
            );
        } else {
            return Summary.placeholder(
                <span>
                    <em>No parameters path specified</em>
                </span>
            );
        }
    }

    templateS3PathSummary() {
        if (this.props.properties["Octopus.Action.Aws.CloudFormationTemplateS3URL"]) {
            return Summary.summary(
                <span>
                    Using the template from <strong>{this.props.properties["Octopus.Action.Aws.CloudFormationTemplateS3URL"]}</strong>
                </span>
            );
        } else {
            return Summary.placeholder(
                <span>
                    <em>No S3 template URL specified</em>
                </span>
            );
        }
    }

    parameterS3PathSummary() {
        if (this.props.properties["Octopus.Action.Aws.CloudFormationParametersS3URL"]) {
            return Summary.summary(
                <span>
                    Using the parameters from <strong>{this.props.properties["Octopus.Action.Aws.CloudFormationParametersS3URL"]}</strong>
                </span>
            );
        } else {
            return Summary.placeholder(
                <span>
                    <em>No S3 parameters URL specified</em>
                </span>
            );
        }
    }

    onChangeTemplateSource(value: string | undefined) {
        this.props.setProperties({
            ["Octopus.Action.Aws.TemplateSource"]: value,
            ["Octopus.Action.Aws.CloudFormationTemplate"]: "",
            ["Octopus.Action.Aws.CloudFormationTemplateParametersRaw"]: "",
            ["Octopus.Action.Aws.CloudFormationTemplateParameters"]: "",
        });

        if (value === "Inline") {
            this.props.setPackages(RemovePrimaryPackageReference(this.props.packages));
        } else {
            this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, this.props.feeds));
        }
    }

    setCapabilityProperty = () => {
        this.props.setProperties({ "Octopus.Action.Aws.IamCapabilities": JSON.stringify(this.state.capabilities) });
    };

    setTagsProperty = () => {
        this.props.setProperties({ "Octopus.Action.Aws.CloudFormation.Tags": JSON.stringify(this.state.tags) });
    };

    setTags = (tags: KeyValuePair[]) => {
        this.setState(
            (prev) => ({
                ...prev,
                tags: [...tags],
            }),
            this.setTagsProperty
        );
    };

    setCapabilities = (capabilites: string[]) => {
        this.setState(
            (prev) => ({
                ...prev,
                capabilities: [...capabilites],
            }),
            this.setCapabilityProperty
        );
    };

    removeCapability = (capability: string) => {
        this.setState(
            (prev, props) => ({
                ...prev,
                capabilities: prev!.capabilities.filter((x) => x !== capability),
            }),
            this.setCapabilityProperty
        );
    };

    getEnabledFeatures = () => {
        const enabledFeatures = this.props.properties["Octopus.Action.EnabledFeatures"];
        return enabledFeatures ? enabledFeatures : "";
    };

    getTargetPaths = () => {
        const templatePath = this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"];
        const parametersPath = this.props.properties["Octopus.Action.Aws.CloudFormationTemplateParametersRaw"];
        if (!templatePath) {
            return;
        }
        if (!parametersPath) {
            return templatePath;
        }
        return `${templatePath}, ${parametersPath}`;
    };

    setTargetPaths = () => {
        if (this.props.properties["Octopus.Action.Aws.TemplateSource"] === "Package") {
            this.props.setProperties({ ["Octopus.Action.Package.JsonConfigurationVariablesTargets"]: this.getTargetPaths() });
        } else {
            /*
             * template.yaml is the filename assigned to inline CloudFormation templates
             * in source/Octopus.Aws/CloudFormation/Presets/CloudFormationCalamariPresets.cs
             */
            this.props.setProperties({ ["Octopus.Action.Package.JsonConfigurationVariablesTargets"]: "template.yaml" });
        }
    };

    handleChangeSetEnabledChange = () => {
        this.props.setProperties({ ["Octopus.Action.EnabledFeatures"]: toggleFeature(this.props.properties["Octopus.Action.EnabledFeatures"], "Octopus.Features.CloudFormation.ChangeSet.Feature") });
    };

    handleTemplatePathChange = (val: string) => {
        this.props.setProperties({ ["Octopus.Action.Aws.CloudFormationTemplate"]: val });
        this.setTargetPaths();
    };

    handleParameterPathChange = (val: string) => {
        this.props.setProperties({ ["Octopus.Action.Aws.CloudFormationTemplateParametersRaw"]: val });
        this.setTargetPaths();
    };

    render() {
        const properties = this.props.properties;
        const pkg = GetPrimaryPackageReference(this.props.packages);
        const changeSetsEnabled = this.getEnabledFeatures().includes("Octopus.Features.CloudFormation.ChangeSet.Feature");

        return (
            <div>
                <FormSectionHeading title="AWS" />
                <AwsLoginComponent
                    projectId={this.props.projectId}
                    gitRef={this.props.gitRef}
                    properties={this.props.properties}
                    packages={this.props.packages}
                    plugin={this.props.plugin}
                    setProperties={this.props.setProperties}
                    setPackages={this.props.setPackages}
                    doBusyTask={this.props.doBusyTask}
                    busy={this.props.busy}
                    getFieldError={this.props.getFieldError}
                    errors={this.props.errors}
                    expandedByDefault={this.props.expandedByDefault}
                />
                <ExpandableFormSection
                    errorKey="Octopus.Action.Aws.Region|Octopus.Action.Aws.CloudFormationStackName"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="CloudFormation"
                    summary={this.cloudFormationSummary()}
                    help={"Specify the details of the CloudFormation stack"}
                >
                    <VariableLookupText
                        label="Region"
                        localNames={this.props.localNames}
                        value={this.props.properties["Octopus.Action.Aws.Region"]}
                        onChange={(val) => this.props.setProperties({ ["Octopus.Action.Aws.Region"]: val })}
                        error={this.props.getFieldError("Octopus.Action.Aws.Region")}
                    />
                    <Note>
                        View the <ExternalLink href="AWSRegions">AWS Regions and Endpoints</ExternalLink> documentation for a current list of the available region codes.
                    </Note>
                    <VariableLookupText
                        label="CloudFormation stack name"
                        localNames={this.props.localNames}
                        value={this.props.properties["Octopus.Action.Aws.CloudFormationStackName"]}
                        onChange={(val) => this.props.setProperties({ ["Octopus.Action.Aws.CloudFormationStackName"]: val })}
                        error={this.props.getFieldError("Octopus.Action.Aws.CloudFormationStackName")}
                    />
                    <VariableLookupText
                        label="Role ARN"
                        localNames={this.props.localNames}
                        value={this.props.properties["Octopus.Action.Aws.CloudFormation.RoleArn"]}
                        onChange={(val) => this.props.setProperties({ "Octopus.Action.Aws.CloudFormation.RoleArn": val })}
                        error={this.props.getFieldError("Octopus.Action.Aws.CloudFormation.RoleArn")}
                    />
                    <Note>The Amazon Resource Name (ARN) of an AWS Identity and Access Management (IAM) role that AWS CloudFormation assumes when executing any operations. This role will be used for any future operations on the stack.</Note>

                    <CapabilityMultiselect
                        items={knownCapabilities}
                        label="Acknowledged IAM Capabilities"
                        placeholder="Select IAM Capability"
                        renderChip={(x) => (
                            <DeletableChip deleteButtonAccessibleName={`Delete ${x.Name}`} onRequestDelete={() => this.removeCapability(x.Id)} description={x.Name}>
                                {x.Id}
                            </DeletableChip>
                        )}
                        value={this.state.capabilities}
                        onChange={this.setCapabilities}
                    />
                    <Note>
                        Additional capabilities are required for templates that have IAM resources or named IAM resources. Refer to the <ExternalLink href="AwsDocsControllingIAM">AWS documentation</ExternalLink> for more information.
                    </Note>
                    <KeyValueEditList
                        projectId={this.props.projectId}
                        gitRef={this.props.gitRef}
                        localNames={this.props.localNames}
                        separator="="
                        keyLabel="Key"
                        valueLabel="Value"
                        key="tags"
                        name="tags"
                        onChange={this.setTags}
                        items={() => this.state.tags || []}
                    />
                    <Note>
                        Stack-level tags to be propagated to resources that CloudFormation supports. If no tags are specified and the stack already exists current tags will be preserved. Otherwise tags specified here will replace existing tags. Refer
                        to the <ExternalLink href="AwsDocsCloudFormationTags">AWS documentation</ExternalLink> for more information.
                    </Note>
                    <BoundStringCheckbox
                        resetValue={"False"}
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        value={properties["Octopus.Action.Aws.DisableRollback"]}
                        onChange={(x) => this.props.setProperties({ ["Octopus.Action.Aws.DisableRollback"]: x })}
                        label="Disable rollback"
                    />
                    <Note>Select this checkbox to disable the automatic rollback of a CloudFormation stack if it failed to be created successfully. This has no effect if the stack exists and is being updated.</Note>
                    <BoundStringCheckbox
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        resetValue={"True"}
                        value={properties["Octopus.Action.Aws.WaitForCompletion"]}
                        onChange={(x) => this.props.setProperties({ ["Octopus.Action.Aws.WaitForCompletion"]: x })}
                        label="Wait for completion"
                    />
                    <Note>
                        Select this checkbox to force the step to wait until the CloudFormation stack has been completed before getting the outputs and finishing the step. Be aware that unselecting this option can mean that no output variables are
                        created, or that output variables may contain outdated values, as the CloudFormation outputs may not have been created or updated before this step completed. Unselecting this option also means the step will not indicate an
                        error if the stack was rolled back during deployment.
                    </Note>
                </ExpandableFormSection>

                <FormSectionHeading title="Template" />
                <ExpandableFormSection
                    errorKey="Octopus.Action.Aws.TemplateSource|Octopus.Action.Aws.CloudFormationTemplate"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Template Source"
                    fillCardWidth={CardFill.FillRight}
                    summary={this.templateSourceSummary()}
                    help={"Select the source of the template."}
                >
                    <Note>Templates can be entered as source-code, or contained in a package.</Note>
                    <RadioButtonGroup value={this.props.properties["Octopus.Action.Aws.TemplateSource"]} onChange={(val: string) => this.onChangeTemplateSource(val)} error={this.props.getFieldError("Octopus.Action.Aws.TemplateSource")}>
                        <RadioButton value={"Inline"} label="Source code" />
                        <RadioButton value={"Package"} label="File inside a package" />
                        <RadioButton value={"S3URL"} label="S3 URL" />
                    </RadioButtonGroup>
                    {this.props.properties["Octopus.Action.Aws.TemplateSource"] === "Inline" && (
                        <div>
                            <br />
                            {this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"] && (
                                <CodeEditor
                                    value={this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"]}
                                    language={JsonUtils.tryParseJson(this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"]) ? TextFormat.JSON : TextFormat.YAML}
                                    allowFullScreen={false}
                                    readOnly={true}
                                />
                            )}
                            <div>
                                <OpenDialogButton
                                    label={this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"] ? "Edit Source Code" : "Add Source Code"}
                                    renderDialog={({ open, closeDialog }) => (
                                        <SourceCodeDialog
                                            title="Edit CloudFormation Template"
                                            open={open}
                                            close={closeDialog}
                                            value={this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"]}
                                            autocomplete={[]}
                                            validate={this.validateTemplate}
                                            saveDone={async (value) => {
                                                this.props.setProperties({ ["Octopus.Action.Aws.CloudFormationTemplate"]: value });
                                                await this.refreshParametersFromMetadata(await this.getMetadata(value));
                                            }}
                                            language={
                                                !this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"] || JsonUtils.tryParseJson(this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"]) ? TextFormat.JSON : TextFormat.YAML
                                            }
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    )}
                </ExpandableFormSection>

                {this.props.properties["Octopus.Action.Aws.TemplateSource"] === "Package" && (
                    <div>
                        <ExpandableFormSection
                            errorKey="Octopus.Action.Package.PackageId|Octopus.Action.Package.FeedId"
                            isExpandedByDefault={this.props.expandedByDefault}
                            title="Package"
                            summary={CommonSummaryHelper.packageSummary(pkg, this.props.feeds)}
                            help={"Choose the package that contains the template source."}
                        >
                            <PackageSelector
                                packageId={pkg?.PackageId}
                                feedId={pkg?.FeedId}
                                onPackageIdChange={(packageId) => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))}
                                onFeedIdChange={(feedId) => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))}
                                packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")}
                                feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")}
                                projectId={this.props.projectId}
                                feeds={this.props.feeds}
                                localNames={this.props.localNames}
                                refreshFeeds={this.loadFeeds}
                            />
                        </ExpandableFormSection>

                        <ExpandableFormSection
                            errorKey="Octopus.Action.Aws.CloudFormationTemplate"
                            isExpandedByDefault={this.props.expandedByDefault}
                            title="Template File Path"
                            summary={this.templatePathSummary()}
                            help={"Enter the relative paths for the template file in the package."}
                        >
                            <VariableLookupText
                                label="Template path"
                                localNames={this.props.localNames}
                                value={this.props.properties["Octopus.Action.Aws.CloudFormationTemplate"]}
                                onChange={this.handleTemplatePathChange}
                                error={this.props.getFieldError("Octopus.Action.Aws.CloudFormationTemplate")}
                            />

                            <Note>Relative path to the JSON template file contained in the package</Note>
                        </ExpandableFormSection>

                        <ExpandableFormSection
                            errorKey="Octopus.Action.Aws.CloudFormationTemplateParametersRaw"
                            isExpandedByDefault={this.props.expandedByDefault}
                            title="Parameters File Path"
                            summary={this.parameterPathSummary()}
                            help={"Enter the relative paths for the parameters file in the package."}
                        >
                            <VariableLookupText
                                label="Template parameters path"
                                localNames={this.props.localNames}
                                value={this.props.properties["Octopus.Action.Aws.CloudFormationTemplateParametersRaw"]}
                                onChange={this.handleParameterPathChange}
                                error={this.props.getFieldError("Octopus.Action.Aws.CloudFormationTemplateParametersRaw")}
                            />
                            <Note>Relative path to the JSON parameters file contained in the package</Note>
                        </ExpandableFormSection>
                    </div>
                )}

                {this.props.properties["Octopus.Action.Aws.TemplateSource"] === "S3URL" && (
                    <div>
                        <ExpandableFormSection
                            errorKey="Octopus.Action.Aws.CloudFormationTemplateS3URL"
                            isExpandedByDefault={this.props.expandedByDefault}
                            title="Template S3 URL"
                            summary={this.templateS3PathSummary()}
                            help={"Enter the S3 URL to the CloudFormation template."}
                        >
                            <VariableLookupText
                                label="Template S3 URL"
                                localNames={this.props.localNames}
                                value={this.props.properties["Octopus.Action.Aws.CloudFormationTemplateS3URL"]}
                                onChange={(val) => this.props.setProperties({ "Octopus.Action.Aws.CloudFormationTemplateS3URL": val })}
                                error={this.props.getFieldError("Octopus.Action.Aws.CloudFormationTemplateS3URL")}
                            />
                            <Note>CloudFormation templates hosted in S3 are not processed by Octopus, and so can not make use of variable substitutions.</Note>
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            errorKey="Octopus.Action.Aws.CloudFormationParametersS3URL"
                            isExpandedByDefault={this.props.expandedByDefault}
                            title="Parameter S3 URL"
                            summary={this.parameterS3PathSummary()}
                            help={"Enter the S3 URL to the CloudFormation parameters file."}
                        >
                            <VariableLookupText
                                label="Parameters S3 URL"
                                localNames={this.props.localNames}
                                value={this.props.properties["Octopus.Action.Aws.CloudFormationParametersS3URL"]}
                                onChange={(val) => this.props.setProperties({ "Octopus.Action.Aws.CloudFormationParametersS3URL": val })}
                                error={this.props.getFieldError("Octopus.Action.Aws.CloudFormationParametersS3URL")}
                            />
                            <Note>Parameter files hosted in S3 are downloaded and processed during deployment, and so can make use of variable substitutions.</Note>
                        </ExpandableFormSection>
                    </div>
                )}

                {this.props.properties["Octopus.Action.Aws.TemplateSource"] !== "S3URL" && (
                    <div>
                        <FormSectionHeading title="Configuration files" />
                        <StructuredConfigurationVariablesToggle {...this.props} />
                    </div>
                )}

                <FormSectionHeading title="CloudFormation change sets" />
                <ExpandableFormSection
                    summary={changeSetsEnabled ? Summary.summary(<span>Change sets are enabled</span>) : Summary.default(<span>Change sets are disabled</span>)}
                    title="Enable"
                    errorKey="ChangesetsEnable"
                    help="Enable CloudFormation change sets"
                >
                    <Checkbox label="Enable" value={changeSetsEnabled} onChange={this.handleChangeSetEnabledChange} />
                </ExpandableFormSection>
                {changeSetsEnabled && <CloudFormationChangesetFeature {...this.props} expandedByDefault />}

                {this.props.properties["Octopus.Action.Aws.TemplateSource"] === "Inline" && this.state.parameterTypes && (
                    <div>
                        <FormSectionHeading title="Parameters" />
                        <DynamicForm
                            types={this.state.parameterTypes}
                            values={this.state.parameterValues}
                            isBindable={true}
                            onChange={(data) => this.updateParameters(data)}
                            getBoundFieldProps={() => ({ projectId: this.props.projectId, gitRef: this.props.gitRef, localNames: this.props.localNames })}
                        />
                    </div>
                )}
                <DockerReferenceList
                    projectId={this.props.projectId}
                    gitRef={this.props.gitRef}
                    packages={this.props.packages}
                    plugin={this.props.plugin}
                    setPackages={this.props.setPackages}
                    doBusyTask={this.props.doBusyTask}
                    busy={this.props.busy}
                    getFieldError={this.props.getFieldError}
                    errors={this.props.errors}
                    expandedByDefault={this.props.expandedByDefault}
                    feeds={this.props.feeds}
                    refreshFeeds={this.props.refreshFeeds}
                    setProperties={this.props.setProperties}
                    properties={this.props.properties}
                    parameters={this.props.parameters}
                />
            </div>
        );
    }

    private getMetadata = (value: string): Promise<{ Metadata: MetadataTypeCollection; Values: DataContext }> => {
        return repository.CloudTemplates.getMetadata(value, "CloudFormation");
    };

    private validateTemplate = async (value: string) => {
        try {
            await this.getMetadata(value);
        } catch (err) {
            return err;
        }
        return null;
    };

    private cloudFormationSummary() {
        const properties = this.props.properties;

        if (properties["Octopus.Action.Aws.CloudFormationStackName"]) {
            return Summary.summary(
                <span>
                    Creating stack <strong>{properties["Octopus.Action.Aws.CloudFormationStackName"]}</strong>
                    {properties["Octopus.Action.Aws.Region"] && (
                        <span>
                            {" "}
                            in <strong>{properties["Octopus.Action.Aws.Region"]}</strong>
                        </span>
                    )}
                    {properties["Octopus.Action.Aws.WaitForCompletion"] !== "False" && <span> waiting for completion</span>}
                    {properties["Octopus.Action.Aws.WaitForCompletion"] === "False" && <span> not waiting for completion</span>}
                    {properties["Octopus.Action.Aws.DisableRollback"] === "True" && <span>, with rollback disabled</span>}
                    {properties["Octopus.Action.Aws.IamCapabilities"] && properties["Octopus.Action.Aws.IamCapabilities"] !== "NONE" && <span>, and with IAM capabilities</span>}
                    {!properties["Octopus.Action.Aws.IamCapabilities"] || (properties["Octopus.Action.Aws.IamCapabilities"] === "NONE" && <span>, and with no IAM capabilities</span>)}
                </span>
            );
        }

        return Summary.placeholder("Specify the details of the CloudFormation stack");
    }

    private async refreshParametersFromTemplate(template: string) {
        await this.props.doBusyTask(async () => {
            const response = await repository.CloudTemplates.getMetadata(template, "CloudFormation");
            await this.refreshParametersFromMetadata(response);
        });
    }

    private async refreshParametersFromMetadata(metadataResponse: { Metadata: MetadataTypeCollection; Values: DataContext }) {
        await this.props.doBusyTask(async () => {
            // merge stored parameter values from step data with default values from template
            const storedParameters: DataContext = this.flattenParameters();
            Object.keys(metadataResponse.Values).forEach((key) => {
                if (storedParameters[key]) {
                    metadataResponse.Values[key] = storedParameters[key];
                }
            });
            this.props.setProperties({
                ["Octopus.Action.Aws.CloudFormationTemplateParametersRaw"]: JSON.stringify(Object.keys(metadataResponse.Values).map((k) => ({ ParameterKey: k, ParameterValue: metadataResponse.Values[k] }))),
            });
            this.syncParameters(metadataResponse.Values);
            this.setState({ parameterTypes: metadataResponse.Metadata.Types, parameterValues: metadataResponse.Values });
        });
    }

    private flattenParameters(): DataContext {
        const parameters: DataContext = {};
        if (this.props.properties["Octopus.Action.Aws.CloudFormationTemplateParametersRaw"]) {
            const storedParameters = JSON.parse(this.props.properties["Octopus.Action.Aws.CloudFormationTemplateParametersRaw"]);
            storedParameters.forEach((p: AwsParameter) => {
                parameters[p.ParameterKey] = p.ParameterValue;
            });
        }

        return parameters;
    }

    private updateParameters(data: DataContext) {
        const objectKeys = Object.keys(data);
        this.props.setProperties({
            ["Octopus.Action.Aws.CloudFormationTemplateParametersRaw"]: JSON.stringify(objectKeys.map((k) => ({ ParameterKey: k, ParameterValue: data[k] }))),
        });

        this.syncParameters(data);
    }

    private syncParameters(data: DataContext) {
        const objectKeys = Object.keys(data);
        /*
            Arrays are presented as new line separated strings, but are saved in the properties files
            as comma separated lists. So while Octopus.Action.Aws.CloudFormationTemplateParametersRaw
            retains the data input by the user, Octopus.Action.Aws.CloudFormationTemplateParameters
            is processed to contain the lists that CloudFormation expects.

            See https://github.com/aws/aws-cli/issues/1529 for details.
         */
        this.props.setProperties({
            ["Octopus.Action.Aws.CloudFormationTemplateParameters"]: JSON.stringify(
                objectKeys.map((k) => {
                    if (Array.isArray(data[k])) {
                        return { ParameterKey: k, ParameterValue: data[k].join(",") };
                    } else {
                        // We can't have null values, only empty strings
                        return { ParameterKey: k, ParameterValue: data[k] || "" };
                    }
                })
            ),
        });
    }

    private loadFeeds = async () => {
        await this.props.refreshFeeds();
    };
}

type AwsCloudFormationActionEditProps = ActionEditProps<AwsDeployCloudFormationProperties, ScriptPackageProperties>;

function AwsCloudFormationActionEdit(props: React.PropsWithChildren<AwsCloudFormationActionEditProps>) {
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();

    return <AwsCloudFormationActionEditInternal {...props} feeds={feeds} refreshFeeds={refreshFeeds} />;
}

export default (): ActionPlugin => ({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.AwsRunCloudFormation",
    summary: (properties, targetRolesAsCSV) => <AwsCloudFormationActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    canHaveChildren: (step) => true,
    canBeChild: true,
    edit: AwsCloudFormationActionEdit,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => false,
});
