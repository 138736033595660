import type { OctopusError } from "@octopusdeploy/octopus-server-client";
import { cloneDeep } from "lodash";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import matchErrorsToFieldNames from "./matchErrorsToFieldNames";

// "Optional" here means that the model is optional
export interface OptionalFormBaseComponentState<TModel extends object> extends DataBaseComponentState {
    model?: TModel;
    cleanModel?: TModel;
}

// This base class is deprecated. Use `FormBaseComponent` instead which does not have an optional model
export default class OptionalFormBaseComponent<Props, State extends OptionalFormBaseComponentState<ModelState>, ModelState extends object> extends DataBaseComponent<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    protected setModel(model: ModelState) {
        this.setState({
            model,
            cleanModel: cloneDeep(model),
        });
    }

    protected setModelState<K extends keyof ModelState>(state: Pick<ModelState, K>, callback?: () => void) {
        this.setChildState1("model", state, callback);
    }

    protected mapToOctopusError(err: OctopusError) {
        const error = super.mapToOctopusError(err);
        error.fieldErrors = matchErrorsToFieldNames(err, this.state.model);

        return error;
    }
}

// This should replace the old OptionalFormBaseComponentState,
// because having the model not optional makes it much easier to get type safety with `strictNullChecks`
export interface FormBaseComponentState<TModel extends object> extends DataBaseComponentState {
    model: TModel;
    cleanModel: TModel;
}

export class FormBaseComponent<Props, State extends FormBaseComponentState<ModelState>, ModelState extends object> extends DataBaseComponent<Props, State> {
    protected setModel(model: ModelState) {
        this.setState({
            model,
            cleanModel: cloneDeep(model),
        });
    }

    protected setModelState<K extends keyof ModelState>(state: Pick<ModelState, K>, callback?: () => void) {
        this.setChildState1("model", state, callback);
    }

    protected mapToOctopusError(err: OctopusError) {
        const error = super.mapToOctopusError(err);
        error.fieldErrors = matchErrorsToFieldNames(err, this.state.model);

        return error;
    }
}
