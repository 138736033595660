/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-eq-null */

import type { FeedResource } from "@octopusdeploy/octopus-server-client";
import { FeedType, getFeedTypeLabel, isOctopusProjectFeed, OctopusError, feedTypeCanSearchEmpty } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { AutoCompleteOption } from "~/components/AutoComplete/AutoComplete";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { BoundAutoComplete } from "~/components/BoundAutoComplete/BoundAutoComplete";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { required } from "~/components/form";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import Note from "~/primitiveComponents/form/Note/Note";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
import routeLinks from "../../routeLinks";
import ActionButton, { ActionButtonType } from "../Button";
import { createErrorsFromOctopusError } from "../DataBaseComponent/Errors";
import InternalLink from "../Navigation/InternalLink";
import { BoundSelectWithAddRefresh } from "../form/SelectWithAddRefresh/SelectWithAddRefresh";
import { VariableLookupText } from "../form/VariableLookupText";

export enum PackageSelectorType {
    AutoComplete = "AutoComplete",
    FreeText = "FreeText",
}
export interface PackageSelectorProps {
    packageId: string | undefined;
    feedId: string | undefined;
    projectId: string | undefined;
    feeds: FeedResource[];
    localNames: string[] | undefined;
    feedType?: FeedType[];
    autoFocus?: boolean;
    packageIdError?: string;
    feedIdError?: string;
    packageSelectorType?: PackageSelectorType;
    feedSelectLabel?: string;
    packageSelectLabel?: string;
    onPackageIdChange(value: string): void;
    onFeedIdChange(value: string): void;
    refreshFeeds(): Promise<void>;
}

interface PackageSelectorState {
    looksLikeVersion: boolean;
    feedDoesNotSupportSearchError?: string;
}

export const noFeedsWarning = (feedType?: FeedType[], refreshFeeds?: () => Promise<void>) => {
    //We can't just use the passed in refresh feeds handler since it may not expect an event - it's probably more likely to expect a callback
    const handleRefresh = async () => {
        if (refreshFeeds) {
            return refreshFeeds();
        }
    };

    return (
        <Callout type={CalloutType.Warning} title={`${getFeedTypeLabel(feedType)} Feed required`}>
            Please add an appropriate feed in the{" "}
            <InternalLink to={routeLinks.library.feeds} openInSelf={false}>
                External Feeds
            </InternalLink>{" "}
            area and try again.
            <div>
                <ActionButton type={ActionButtonType.Ternary} label="Refresh" onClick={handleRefresh} />
            </div>
        </Callout>
    );
};

export const possibleFeeds = (feeds: FeedResource[], feedType?: FeedType[]) => {
    return feeds.filter(
        (f) =>
            !isOctopusProjectFeed(f.FeedType) && // We will never show the OctopusProject feed when selecting packages
            (!feedType || feedType.length === 0 || feedType.some((feedTypeToCheck) => feedTypeToCheck === f.FeedType))
    );
};

export const getDefaultFeedId = (feeds: FeedResource[]): string | undefined => {
    if (feeds.length === 0) {
        return undefined;
    }

    const buildtIn = feeds.find((f) => f.FeedType === FeedType.BuiltIn);
    if (buildtIn) {
        return buildtIn.Id;
    }

    return feeds[0].Id;
};

class PackageSelector extends BaseComponent<PackageSelectorProps, PackageSelectorState> {
    constructor(props: PackageSelectorProps) {
        super(props);

        this.state = {
            looksLikeVersion: false,
        };
    }

    componentDidMount() {
        // Default to built-in feed.
        if (!this.props.feedId) {
            const defaultFeedId = getDefaultFeedId(possibleFeeds(this.props.feeds, this.props.feedType));
            if (defaultFeedId) {
                this.props.onFeedIdChange(defaultFeedId);
            }
        }
    }

    checkIfLooksLikeVersion = () => {
        let looksLikeVersion = false;
        const packageId = this.props.packageId;
        if (packageId) {
            if (packageId.match(/\.[0-9]+\.?$/)) {
                looksLikeVersion = true;
            }
        }
        this.setState({ looksLikeVersion });
    };

    render() {
        const feeds = possibleFeeds(this.props.feeds, this.props.feedType);

        if (feeds == null || feeds.length === 0) {
            return noFeedsWarning(this.props.feedType, this.props.refreshFeeds);
        }

        const defaultFeedId = getDefaultFeedId(feeds);
        let selectedFeedId = this.props.feedId;
        if (selectedFeedId === null) {
            selectedFeedId = defaultFeedId!;
        }
        const selectedFeed: FeedResource = this.getSelectedFeed(feeds)!;

        const feedSelectLabel = this.props.feedSelectLabel || "Package feed";

        return (
            <div>
                <Note>
                    Packages can be uploaded directly to the Octopus{" "}
                    <InternalLink to={routeLinks.library.builtInRepository.root} openInSelf={false}>
                        built-in repository
                    </InternalLink>
                    , or external package feeds can be configured in{" "}
                    <InternalLink to={routeLinks.library.feeds} openInSelf={false}>
                        Library
                    </InternalLink>
                    . Learn more about <ExternalLink href="DocumentationPackaging">what your packages should contain, and how to create them</ExternalLink>.
                </Note>
                <BoundSelectWithAddRefresh
                    variableLookup={{
                        localNames: this.props.localNames,
                    }}
                    resetValue={defaultFeedId!}
                    value={selectedFeedId}
                    onChange={this.feedChanged}
                    items={feeds.map((f) => ({ value: f.Id, text: f.Name }))}
                    error={this.props.feedIdError}
                    autoFocus={this.props.autoFocus}
                    label={feedSelectLabel}
                    addUrl={`#${routeLinks.library.feeds}`}
                    onRequestRefresh={this.props.refreshFeeds}
                />
                <Note>
                    The feed containing this package. Learn about <ExternalLink href="DynamicPackageFeeds">Dynamically Selecting Packages at Deployment Time</ExternalLink>.
                </Note>
                {this.renderPackageIdSelector(selectedFeed)}
            </div>
        );
    }

    private renderPackageIdSelector(selectedFeed: FeedResource) {
        const label = this.props.packageSelectLabel || "Package ID";
        const showAutoComplete = !this.state.feedDoesNotSupportSearchError;

        if (this.props.packageSelectorType && this.props.packageSelectorType === PackageSelectorType.FreeText) {
            return (
                <>
                    <DebounceText label={this.props.packageSelectLabel} value={this.props.packageId!} onChange={this.packageChanged} validate={required("Please enter the ID of the package.")} autoFocus={false} />
                </>
            );
        }

        return (
            <>
                {this.state.feedDoesNotSupportSearchError && (
                    <Callout type={CalloutType.Information} title="Search not supported">
                        {this.state.feedDoesNotSupportSearchError}
                        <p>Auto-complete is disabled in the field below, but this feed may still be able to be used successfully.</p>
                    </Callout>
                )}
                {showAutoComplete ? (
                    <BoundAutoComplete
                        name="PackageID"
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        resetValue=""
                        label={label}
                        value={this.props.packageId || ""}
                        onChange={this.packageChanged}
                        placeholder="Enter package ID"
                        allowAnyTextValue={true}
                        getOptions={(searchText) => this.getPackageOptions(searchText, selectedFeed)}
                        error={this.props.packageIdError}
                        showEmpty={selectedFeed && feedTypeCanSearchEmpty(selectedFeed.FeedType)}
                    />
                ) : (
                    <VariableLookupText label={label} value={this.props.packageId || ""} onChange={this.packageChanged} localNames={this.props.localNames} />
                )}
                <Note>
                    Enter the ID of the package.
                    {selectedFeed && selectedFeed.FeedType === FeedType.Maven && (
                        <span>
                            {" "}
                            The format of the package ID is <em>Group:Artifact</em> for example <em>com.google.guava:guava</em> or <em>junit:junit</em>.
                        </span>
                    )}
                </Note>
                {this.state.looksLikeVersion && (
                    <Callout type={CalloutType.Information} title="Package version not required">
                        Hey there! It looks like you might be including the package version number in this field. You shouldn't include the package version number here, just the package ID.
                    </Callout>
                )}
            </>
        );
    }

    private getPackageOptions = async (searchText: string, feed: FeedResource) => {
        if (feed === null) {
            const items: AutoCompleteOption[] = [];
            return {
                items,
                containsAllResults: true,
            };
        }

        try {
            const packages = await repository.Feeds.searchPackages(feed, { term: searchText || "", take: 11, skip: 0 });
            return {
                items: packages.Items.slice(0, 10).map((p) => ({ Id: p.Id, Name: p.Id })),
                containsAllResults: packages.Items.length !== 11,
            };
        } catch (e) {
            if (e instanceof OctopusError) {
                const errors = createErrorsFromOctopusError(e);
                if (errors.statusCode === 404) {
                    this.setState({ feedDoesNotSupportSearchError: errors.message });
                    return {
                        items: [],
                        containsAllResults: true,
                    };
                }
            }
            throw e;
        }
    };

    private packageChanged = (packageId: string) => {
        this.props.onPackageIdChange(packageId);
        if (this.props.packageSelectorType && this.props.packageSelectorType !== PackageSelectorType.FreeText) {
            this.checkIfLooksLikeVersion();
        }
    };

    private feedChanged = (feedId: string | undefined) => {
        this.props.onFeedIdChange(feedId!);
    };

    private getSelectedFeed = (feeds: FeedResource[]) => {
        if (feeds == null || feeds.length === 0) {
            return null;
        }

        if (this.props.feedId) {
            const feed = feeds.find((f) => f.Id === this.props.feedId);
            if (feed) {
                return feed;
            }
        }

        return null;
    };
}

export default PackageSelector;
