import type { VersionControlCompatibilityResponse } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import Markdown from "~/components/Markdown";
import { Note, UnstructuredFormSection } from "~/components/form";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import styles from "./style.module.less";

interface UnsupportedFeaturesCalloutProps {
    vcsCompatibilityReport: VersionControlCompatibilityResponse;
}

export const UnsupportedFeaturesSection: React.FC<UnsupportedFeaturesCalloutProps> = ({ vcsCompatibilityReport }) => {
    if (vcsCompatibilityReport.Errors.length < 1 && vcsCompatibilityReport.Warnings.length < 1 && vcsCompatibilityReport.Notices.length < 1) {
        return null;
    }

    return (
        <UnstructuredFormSection>
            <div>
                <h3>Version Control Compatibility</h3>
                <Note>We've run a check to see whether this project is capable of supporting version control and have listed some things for you to take into consideration below.</Note>
            </div>
            {vcsCompatibilityReport.Errors.length > 0 && (
                <div className={styles.calloutContainer}>
                    <Callout type={CalloutType.Danger} title={"Errors"}>
                        {vcsCompatibilityReport.Errors.map((x, index) => (
                            <Markdown key={index} markup={x} />
                        ))}
                    </Callout>
                </div>
            )}
            {vcsCompatibilityReport.Warnings.length > 0 && (
                <div className={styles.calloutContainer}>
                    <Callout type={CalloutType.Warning} title={"Warnings"}>
                        {vcsCompatibilityReport.Warnings.map((x, index) => (
                            <Markdown key={index} markup={x} />
                        ))}
                    </Callout>
                </div>
            )}
            {vcsCompatibilityReport.Notices.length > 0 && (
                <div className={styles.calloutContainer}>
                    <Callout type={CalloutType.Information} title={"Notices"}>
                        {vcsCompatibilityReport.Notices.map((x, index) => (
                            <Markdown key={index} markup={x} />
                        ))}
                    </Callout>
                </div>
            )}
        </UnstructuredFormSection>
    );
};
