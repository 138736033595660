/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import type { DoBusyTask, DoBusyTaskOptions } from "./DataBaseComponent";

export const useDoBusyTaskEffect = <T>(doBusyTask: DoBusyTask, fetch: () => Promise<T>, dependencies: React.DependencyList, options?: DoBusyTaskOptions) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const refresh = React.useCallback(() => doBusyTask(fetch, options), dependencies);

    // https://reactjs.org/docs/hooks-reference.html#useeffect
    React.useEffect(() => {
        // It's important not to make useEffect async or to return a promise here, since useEffect can return a cleanup function.
        // eslint-disable-next-line: no-floating-promises
        refresh();
    }, [refresh]);

    return refresh;
};
