import type { GitRefResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { useProjectContext } from "~/areas/projects/context";
import type { NavigationButtonProps } from "~/components/Button/NavigationButton";
import NavigationButton from "~/components/Button/NavigationButton";
import { PermissionCheck } from "~/components/PermissionCheck";
import routeLinks from "~/routeLinks";
import { isRunbookProcessState, processScopedEditPermission } from "../Process/Common/CommonProcessHelpers";
import { useProcessContext } from "../Process/Contexts/ProcessContext";

type AddRunbookStepButtonPropChanges = Omit<NavigationButtonProps, "href" | "label"> & { href?: NavigationButtonProps["href"]; label?: NavigationButtonProps["label"] };
interface WithLabel {
    label?: string;
}

type AddRunbookStepButtonProps = AddRunbookStepButtonPropChanges &
    WithLabel & {
        projectId: string;
        processId: string;
        runbookId: string;
        projectSlug: string;
    };

const AddRunbookStepButton: React.FC<AddRunbookStepButtonProps> = ({ projectId, projectSlug, processId, runbookId, label, ...rest }) => {
    const href = routeLinks.project(projectSlug).operations.runbook(runbookId).runbookProcess.runbookProcess(processId).process.stepTemplates;
    const processContext = useProcessContext();
    const dispatchAction = useAnalyticActionDispatch();

    return (
        <PermissionCheck permission={processScopedEditPermission(processContext.selectors.getProcessType())} project={projectId} wildcard={true}>
            <NavigationButton label={label ?? "Add Step"} href={href} {...rest} onClick={() => dispatchAction("Add Deployment Step", { resource: "Runbook Process", action: Action.Add })} />
        </PermissionCheck>
    );
};

type AddDeploymentsStepButtonProps = {
    projectId: string;
    projectSlug: string;
    gitRef: GitRefResource | undefined;
} & WithLabel;

const AddDeploymentsStepButton: React.FC<AddDeploymentsStepButtonProps> = ({ projectId, projectSlug, gitRef, label, ...rest }) => {
    const href = routeLinks.projectBranch(projectSlug, gitRef).deploymentProcess.stepTemplates;
    const processContext = useProcessContext();
    const dispatchAction = useAnalyticActionDispatch();

    return (
        <PermissionCheck permission={processScopedEditPermission(processContext.selectors.getProcessType())} project={projectId} wildcard={true}>
            <NavigationButton label={label ?? "Add Step"} href={href} {...rest} onClick={() => dispatchAction("Add Deployment Step", { resource: "Deployment Process", action: Action.Add })} />
        </PermissionCheck>
    );
};

export type ContextAddStepNavigationButtonProps = AddRunbookStepButtonPropChanges & WithLabel;
const ContextAddStepNavigationButton: React.FC<ContextAddStepNavigationButtonProps> = (props) => {
    const processContext = useProcessContext();
    const project = useProjectContext();
    const process = processContext.state.model.process;

    if (isRunbookProcessState(process)) {
        return <AddRunbookStepButton projectSlug={project.state.model.Slug} projectId={project.state.model.Id} processId={process.Id} runbookId={process.RunbookId} {...props} label={props.label} />;
    } else {
        return <AddDeploymentsStepButton projectSlug={project.state.model.Slug} projectId={project.state.model.Id} gitRef={project.state.gitRef} {...props} label={props.label} />;
    }
};

export default ContextAddStepNavigationButton;
