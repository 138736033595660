/* eslint-disable @typescript-eslint/consistent-type-assertions */

import type { TriggerResource, MachineFilterResource, EnvironmentResource, EventCategoryResource, EventGroupResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import TriggerDescription from "~/areas/projects/components/Triggers/TriggerDescription";
import type { OverflowMenuDeleteItem, OverflowMenuNavLink, OverflowMenuDialogItem } from "~/components/OverflowMenu/OverflowMenu";
import ListItem from "~/primitiveComponents/dataDisplay/ListItem/ListItem";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import styles from "./style.module.less";

type MenuItem = OverflowMenuNavLink | OverflowMenuDialogItem | OverflowMenuDeleteItem;

interface TriggerProps {
    trigger: TriggerResource;
    environments: EnvironmentResource[];
    categories: EventCategoryResource[];
    groups: EventGroupResource[];
    menuItems?: Array<MenuItem | MenuItem[]>;
}

export default class Trigger extends React.PureComponent<TriggerProps> {
    render() {
        const filter = this.props.trigger.Filter as MachineFilterResource;

        return (
            <div className={this.props.trigger.IsDisabled ? styles.disabled : ""}>
                <ListItem overflowMenuItems={this.props.menuItems}>
                    <ListTitle>{this.props.trigger.Name}</ListTitle>
                    {this.buildListItemBody(filter)}
                </ListItem>
            </div>
        );
    }

    private buildListItemBody(filter: MachineFilterResource) {
        const description = new TriggerDescription(filter, this.props.environments, this.props.categories, this.props.groups);
        return <div className={styles.description}>{description.buildDescription()}</div>;
    }
}
