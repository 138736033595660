/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */

import type { ActivityElement } from "@octopusdeploy/octopus-server-client";
import { ActivityStatus, TaskState } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import { isEqual, startsWith } from "lodash";
import LinearProgress from "material-ui/LinearProgress";
import * as React from "react";
import * as ReactDOM from "react-dom";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import DurationBetweenLabel from "~/components/TimeLabels/DurationBetweenLabel";
import { DurationSinceLabel } from "~/components/TimeLabels/DurationSinceLabel";
import TaskLogLines from "./TaskLogLines";
import styles from "./taskLogBlock.module.less";

interface TaskLogBlockComponentProps {
    element: UniqueActivityElement;
    taskState: TaskState;
    expandedIds: string[];
    collapsible: boolean;
    focusId?: string;
    showRunTime: boolean;

    showAdditional(): void;

    setExpanded(id: string, expanded: boolean): void;
}

type UniqueActivityElement = ActivityElement & { uniqueId: string; Children: UniqueActivityElement[] };
export { UniqueActivityElement };

type TaskLogBlockProps = TaskLogBlockComponentProps & RouteComponentProps;

class TaskLogBlockInternal extends React.Component<TaskLogBlockProps> {
    constructor(props: TaskLogBlockProps) {
        super(props);
    }

    shouldComponentUpdate(nextProps: TaskLogBlockComponentProps) {
        return !isEqual(
            { element: this.props.element, taskState: this.props.taskState, expandedIds: this.props.expandedIds, focusId: this.props.focusId },
            { element: nextProps.element, taskState: nextProps.taskState, expandedIds: nextProps.expandedIds, focusId: nextProps.focusId }
        );
    }

    scrollToNodeIfRequired() {
        if (this.props.focusId === this.props.element.Id || this.props.focusId === this.props.element.uniqueId) {
            // eslint-disable-next-line react/no-find-dom-node
            const node = ReactDOM.findDOMNode(this) as Element;
            const approximateHeightOfStickyHeaders = 180;
            if (node) {
                //scroll so that its in the client viewport
                node.scrollIntoView();

                //now, make sure its not hiding under the sticky headers
                const scrolledY = window.pageYOffset;
                const clientHeight = document.documentElement.clientHeight;
                const scrollHeight = document.documentElement.scrollHeight;

                if (scrolledY && clientHeight && scrollHeight) {
                    if ((node as HTMLElement).offsetTop + clientHeight < scrollHeight) {
                        window.scroll(0, scrolledY - approximateHeightOfStickyHeaders);
                    }
                }
            }
        }
    }

    componentDidMount() {
        this.scrollToNodeIfRequired();
    }

    componentDidUpdate() {
        this.scrollToNodeIfRequired();
    }

    render() {
        const element = this.props.element;
        const expanded = !this.props.collapsible || this.props.expandedIds.indexOf(element.uniqueId) >= 0 || this.props.expandedIds.indexOf(element.Id) >= 0;
        if (!element.Status) {
            return null;
        }

        const onClickHandler = this.props.collapsible ? () => this.props.setExpanded(element.uniqueId, !expanded) : undefined;

        return (
            <div className={cn(styles["block" + element.Status], styles.logEntryChild)}>
                <div className={styles.blockHeader} onClick={onClickHandler}>
                    <div className={styles.blockTitle}>
                        {this.props.collapsible ? <em className={cn(styles.blockExpanderIcon, "fa", expanded ? "fa-minus-square" : "fa-plus-square")} /> : null}
                        {element.Name}
                    </div>
                    <div className={styles.blockTime}>
                        {element.ProgressPercentage && (
                            <div className={styles.progress}>
                                {element.ProgressMessage} ({element.ProgressPercentage}%)
                                <LinearProgress mode="determinate" value={element.ProgressPercentage} />
                            </div>
                        )}
                        <div>{this.getRunTime()}</div>
                    </div>
                </div>
                {expanded && (
                    <div className={styles.body}>
                        <TaskLogLines lines={element.LogElements} showAdditional={this.props.showAdditional} />
                        {element.Children && element.Children.length > 0 && (
                            <div className={styles.children}>
                                {(element.Children as UniqueActivityElement[]).map((e) => (
                                    <TaskLogBlock
                                        key={e.uniqueId}
                                        focusId={this.props.focusId}
                                        element={e}
                                        expandedIds={this.getExpandedIds(e)}
                                        collapsible={this.props.collapsible}
                                        taskState={this.props.taskState}
                                        showRunTime={this.props.showRunTime}
                                        setExpanded={this.props.setExpanded}
                                        showAdditional={this.props.showAdditional}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }

    getExpandedIds(element: UniqueActivityElement) {
        return this.props.expandedIds.filter((i) => startsWith(i, element.uniqueId) || startsWith(i, element.Id));
    }

    getRunTime() {
        if (!this.props.showRunTime) {
            return null;
        }
        const started = this.props.element.Started;
        const ended = this.props.element.Ended;

        if (this.props.taskState === TaskState.Executing && this.props.element.Status === ActivityStatus.Running) {
            return ended ? (
                <span>
                    Started <DurationBetweenLabel from={started} to={ended} /> ago
                </span>
            ) : (
                <span>
                    Started <DurationSinceLabel from={started} /> ago
                </span>
            );
        }

        if (!ended) {
            return null;
        }

        return (
            <span>
                Ran for <DurationBetweenLabel from={started} to={ended} />
            </span>
        );
    }
}

const TaskLogBlock = withRouter(TaskLogBlockInternal);
export default TaskLogBlock;
