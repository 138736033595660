import type { MachineModelHealthStatus } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { DisabledMachineIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/DisabledMachineIcon";
import { MachineHealthStatusIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/MachineHealthStatusIcon";
import { SmallCloseButton } from "~/areas/infrastructure/components/MachinesLayout/BaseAllMachinesSummary";
import ActionButton, { ActionButtonType } from "~/components/Button/ActionButton";
import MachineHealthStatusHelper from "~/utils/MachineHealthStatusHelper";
import styles from "./styles.module.less";

export interface MachineHealthSummaryLinkProps {
    count?: number;
    healthStatus: MachineModelHealthStatus;
    getLinkText: (status: MachineModelHealthStatus, count: number, friendlyName: string) => string;
    allowSelection: boolean;
    isSelected: boolean;
    onSelect: (e: React.MouseEvent, healthStatus: MachineModelHealthStatus) => void;
    onClearSelect?: (e: React.MouseEvent) => void;
}

export function MachineHealthSummaryLink({ count, healthStatus, getLinkText, allowSelection, isSelected, onSelect, onClearSelect }: MachineHealthSummaryLinkProps) {
    if (!count || count === 0) {
        // Don't show links if there's nothing to report.
        return null;
    }

    const text = getLinkText(healthStatus, count, MachineHealthStatusHelper.getFriendlyName(healthStatus).toLowerCase());

    // If filtering health statuses from the sidebar, just show the health statuses that they've chosen to filter (and don't show them as links).
    if (!allowSelection) {
        return <MachineHealthSummaryText icon={<MachineHealthStatusIcon healthStatus={healthStatus} />} text={text} />;
    }

    return <SelectableMachineHealthSummaryButton healthStatus={healthStatus} linkText={text} isSelected={isSelected} onSelect={onSelect} onClearSelect={onClearSelect} />;
}

export interface DisabledMachineHealthSummaryLinkProps {
    count?: number;
    allowSelection: boolean;
    isSelected: boolean;
    onSelect: (e: React.MouseEvent) => void;
    onClearSelect?: (e: React.MouseEvent) => void;
}

export function DisabledMachineHealthSummaryLink({ count, isSelected, onSelect, onClearSelect, allowSelection }: DisabledMachineHealthSummaryLinkProps) {
    if (!count || count === 0) {
        // Don't show links if there's nothing to report.
        return null;
    }

    const text = `${count.toLocaleString()} disabled`;

    if (!allowSelection) {
        return <MachineHealthSummaryText icon={<DisabledMachineIcon />} text={text} onClearSelect={onClearSelect} />;
    }

    return <DisabledSelectableMachineHealthSummaryButton linkText={text} isSelected={isSelected} onSelect={onSelect} onClearSelect={onClearSelect} />;
}

interface MachineHealthSummaryTextProps {
    icon: React.ReactNode;
    text: string;
    onClearSelect?: (e: React.MouseEvent) => void;
}

function MachineHealthSummary({ children }: React.PropsWithChildren<{}>) {
    return <div className={styles.summary}>{children}</div>;
}

function MachineHealthSummaryText({ icon, text, onClearSelect }: MachineHealthSummaryTextProps) {
    return (
        <MachineHealthSummary>
            {icon}
            {text}
            {onClearSelect && <SmallCloseButton onClose={onClearSelect} />}
        </MachineHealthSummary>
    );
}

interface MachineHealthSummaryButtonProps {
    healthStatus: MachineModelHealthStatus;
    label: string;
    onSelect: (e: React.MouseEvent, healthStatus: MachineModelHealthStatus) => void;
}

function MachineHealthSummaryButton({ healthStatus, onSelect, label }: MachineHealthSummaryButtonProps) {
    const handleSelect = React.useCallback(
        (ev: React.MouseEvent) => {
            onSelect(ev, healthStatus);
        },
        [onSelect, healthStatus]
    );

    return <ActionButton icon={<MachineHealthStatusIcon healthStatus={healthStatus} />} className={styles.summary} type={ActionButtonType.Ternary} label={label} onClick={handleSelect} />;
}

interface SelectableMachineHealthSummaryButtonProps {
    healthStatus: MachineModelHealthStatus;
    linkText: string;
    isSelected: boolean;
    onClearSelect?: (e: React.MouseEvent) => void;
    onSelect: (e: React.MouseEvent, status: MachineModelHealthStatus) => void;
}

function SelectableMachineHealthSummaryButton({ healthStatus, isSelected, linkText, onClearSelect, onSelect }: SelectableMachineHealthSummaryButtonProps) {
    return isSelected ? (
        <MachineHealthSummaryText text={linkText} icon={<MachineHealthStatusIcon healthStatus={healthStatus} />} onClearSelect={onClearSelect} />
    ) : (
        <MachineHealthSummaryButton label={linkText} healthStatus={healthStatus} onSelect={onSelect} />
    );
}

function DisabledMachineHealthSummaryButton({ label, onSelect }: { label: string; onSelect: React.MouseEventHandler }) {
    return <ActionButton icon={<DisabledMachineIcon />} className={styles.summary} type={ActionButtonType.Ternary} label={label} onClick={onSelect} />;
}

interface DisabledSelectableMachineHealthSummaryButtonProps {
    linkText: string;
    isSelected: boolean;
    onClearSelect?: (e: React.MouseEvent) => void;
    onSelect: (e: React.MouseEvent) => void;
}

function DisabledSelectableMachineHealthSummaryButton({ isSelected, linkText, onClearSelect, onSelect }: DisabledSelectableMachineHealthSummaryButtonProps) {
    return isSelected ? <MachineHealthSummaryText text={linkText} icon={<DisabledMachineIcon />} onClearSelect={onClearSelect} /> : <DisabledMachineHealthSummaryButton label={linkText} onSelect={onSelect} />;
}
