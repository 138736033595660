/* eslint-disable @typescript-eslint/no-explicit-any */

import { getTaskStartTime } from "@octopusdeploy/octopus-server-client";
import type { PhaseResource, ResourcesById, DeploymentResource, EnvironmentResource, TaskResource } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import * as React from "react";
import MediaQuery from "react-responsive";
import CardExpandable from "~/components/form/Sections/CardExpandable";
import { DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import DateFormatter from "~/utils/DateFormatter";
import InternalLink from "../../../../../components/Navigation/InternalLink/InternalLink";
import PhaseStatusIcon from "../../../../../components/PhaseStatusIcon/PhaseStatusIcon";
import routeLinks from "../../../../../routeLinks";
import type { LifecycleStatus } from "../../../../../utils/MapProgressionToStatus/MapProgressionToStatus";
import DeploymentDetailsIcon from "./DeploymentDetailsIcon";
import styles from "./style.module.less";
const breakpoint = 600;

interface DeploymentRowProps {
    phase: PhaseResource;
    lifecycleStatus: LifecycleStatus;
    deployment: DeploymentResource;
    task: TaskResource<{ DeploymentId: string }>;
    title?: string;
    showChildRows: boolean;
    environmentsById: ResourcesById<EnvironmentResource>;
    actionButton?: JSX.Element | null;
    onToggleChildRows(): void;
    [others: string]: any;
}

const DeploymentRow: React.StatelessComponent<DeploymentRowProps> = (props) => {
    const childRowExpander = () => {
        return (
            <div className={cn(styles.deploymentExpander, styles.expandCollapse)}>
                <CardExpandable expanded={props.showChildRows} onExpanding={props.onToggleChildRows} />
            </div>
        );
    };

    return (
        <DataTableRow key={`${props.deployment.Id}`} className={cn(props.className, styles.deploymentsTableRow)}>
            <DataTableRowColumn>
                <PhaseStatusIcon phase={props.phase} status={props.lifecycleStatus} environmentId={props.environmentsById[props.deployment.EnvironmentId].Id} />
                <span>{props.title || props.environmentsById[props.deployment.EnvironmentId].Name}</span>
                <MediaQuery maxWidth={breakpoint - 1}>{props.deployments.length > 1 ? childRowExpander() : null}</MediaQuery>
            </DataTableRowColumn>
            <DataTableRowColumn>
                <InternalLink to={routeLinks.task(props.deployment).root} accessibleName={`View deployment task for ${props.deployment.Name} queued for or started at ${getTaskStartTime(props.task)}`}>
                    <DeploymentDetailsIcon task={props.task} deployment={props.deployment} stripTopBottomPadding={true} />
                </InternalLink>
            </DataTableRowColumn>
            <DataTableRowColumn>
                <span className={styles.deploymentDate}>{DateFormatter.dateToLongFormat(getTaskStartTime(props.task))}</span>
                {props.task.Duration && <div className={styles.deploymentDuration}>Duration: {props.task.Duration}</div>}
            </DataTableRowColumn>
            <DataTableRowColumn className={styles.deploymentRowButton}>
                {props.actionButton}
                {props.deployments.length > 1 ? childRowExpander() : <div className={styles.deploymentExpander} />}
            </DataTableRowColumn>
        </DataTableRow>
    );
};

DeploymentRow.displayName = "DeploymentRow";
export default DeploymentRow;
