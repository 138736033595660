import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Fade from "@material-ui/core/Fade";
import Popper from "@material-ui/core/Popper";
import cn from "classnames";
import React, { useRef } from "react";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import styles from "./style.module.less";

export type Placement = "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end" | "top-start";

export interface ContextualHelpProps {
    trigger: "click" | "hover";
    startOpened?: boolean;
    placement: Placement;
    children: React.ReactNode;
    absolutePosition?: boolean; // this helps keep the icon interactive even when the parent element would otherwise take precedence
    onOpen?: () => void;
}

const PopoverHelp = ({ trigger, children, placement, absolutePosition = false, startOpened = false, onOpen }: ContextualHelpProps): JSX.Element => {
    const isDarkMode = useThemePaletteType() === "dark";
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const iconContainerRef = useRef<HTMLDivElement>(null);

    const toggleOpen = (target: EventTarget & HTMLElement) => {
        const isOpen = anchorEl !== null;
        setAnchorEl(isOpen ? null : target);
        if (!isOpen) onOpen?.();
    };

    const handleClick = (event: React.FormEvent<HTMLElement>) => {
        if (trigger !== "click") return;

        event.stopPropagation();
        toggleOpen(event.currentTarget);
    };

    const handleSpace = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.keyCode === 32) {
            event.preventDefault();
            handleClick(event);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.keyCode === 32) {
            event.preventDefault();
        } else if (event.keyCode === 13) {
            event.preventDefault();
            handleClick(event);
        }
    };

    const handleMouseIn = (event: React.FormEvent<HTMLElement>) => {
        if (trigger !== "hover") return;

        if (anchorEl) {
            iconContainerRef.current?.blur();
        }

        toggleOpen(event.currentTarget);
    };

    const handleMouseOut = (event: React.FormEvent<HTMLElement>) => {
        if (trigger !== "hover") return;

        setAnchorEl(null);
        iconContainerRef.current?.blur();
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const open = startOpened ? true : Boolean(anchorEl);
    const id = open ? "transitions-popper" : undefined;

    const offsetValue = getOffsetValue(placement);

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={styles.globalContainer}>
                <div
                    className={cn(styles.iconContainer, { [styles.absolute]: absolutePosition })}
                    aria-haspopup="true"
                    aria-label="Display contextual help"
                    role="button"
                    tabIndex={0}
                    aria-pressed="false"
                    onClick={handleClick}
                    onKeyUp={handleSpace}
                    onKeyDown={handleKeyDown}
                    onMouseEnter={handleMouseIn}
                    onMouseLeave={handleMouseOut}
                    ref={iconContainerRef}
                >
                    <div className={styles.circle}>
                        <em className={cn("fa-solid fa-info", styles.infoIcon)} />
                    </div>
                </div>
                <Popper
                    id={id}
                    className={cn(styles.popper, { dark: isDarkMode })}
                    open={open}
                    anchorEl={anchorEl}
                    placement={placement}
                    modifiers={{ offset: { enabled: true, offset: offsetValue } }}
                    transition
                    tabIndex={1}
                    role="dialog"
                    aria-modal="true"
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <div className={cn(styles.paper, styles[placement], { [styles.dark]: isDarkMode })}>{children}</div>
                        </Fade>
                    )}
                </Popper>
            </div>
        </ClickAwayListener>
    );
};

const getOffsetValue = (placement: Placement): string => {
    switch (placement) {
        case "left-end":
            return "35,15";
        case "left-start":
            return "-35,15";
        case "right-end":
            return "35,15";
        case "right-start":
            return "-35,15";
        case "top-end":
            return "35,15";
        case "top-start":
            return "-35,15";
        case "bottom-end":
            return "35,15";
        case "bottom-start":
            return "-35,15";
    }
};

export default PopoverHelp;
