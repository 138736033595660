import * as React from "react";
import { AnalyticLinkProvider } from "~/analytics/AnalyticLink";
import AuditStreamUpsellDialogLayout from "~/areas/configuration/components/AuditLayout/AuditStream/AuditStreamUpsellDialogLayout";
import upsellImageDark from "~/areas/configuration/components/AuditLayout/assets/img-upsell-dark.svg";
import upsellImageLight from "~/areas/configuration/components/AuditLayout/assets/img-upsell-light.svg";
import DialogWithHelpPanel from "~/components/DialogWithHelpPanel/DialogWithHelpPanel";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import styles from "./style.module.less";

export interface AuditStreamUpsellDialogProps {
    open: boolean;
    close: () => void;
}

export function AuditStreamUpsellDialog({ open, close }: AuditStreamUpsellDialogProps) {
    const isDarkMode = useThemePaletteType() === "dark";
    return (
        <AnalyticLinkProvider location="Audit Stream Upsell Dialog">
            <DialogWithHelpPanel
                showToggleHelpOptions={false}
                helpPanelImage={isDarkMode ? upsellImageDark : upsellImageLight}
                contentPanelClassName={styles.upsellContentPanel}
                helpPanelClassName={isDarkMode ? styles.upsellHelpPanelDark : styles.upsellHelpPanelLight}
                helpPanelAltImageText={"Audit Stream"}
                open={open}
                close={close}
            >
                <AuditStreamUpsellDialogLayout onClose={close} />
            </DialogWithHelpPanel>
        </AnalyticLinkProvider>
    );
}
