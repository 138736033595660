import type { AccountResource, AccountType } from "@octopusdeploy/octopus-server-client";
import { keyBy } from "lodash";
import type { Reducer, Dispatch, Action } from "redux";
import { combineReducers } from "redux";
import type { ActionType } from "typesafe-actions";
import { createAction, getType } from "typesafe-actions";
import { repository } from "~/clientInstance";

export const accountActions = {
    fetchAll: createAction("ACCOUNTS_FETCH_ALL_REQUEST")(),
    fetch: createAction("ACCOUNTS_FETCH_REQUEST")(),
    fetchError: createAction("ACCOUNTS_FETCH_ERROR", (error: string) => error)(),
    fetchAllSuccess: createAction("ACCOUNTS_FETCH_ALL_SUCCESS", (accounts: AccountResource[]) => accounts)(),
    fetchSuccess: createAction("ACCOUNT_FETCH_SUCCESS", (account: AccountResource) => account)(),
};

export interface AccountState {
    accountId: string;
    name: string;
    description: string;
    accountType: AccountType;
}

export interface AccountByIdState {
    [index: string]: AccountState;
}

type AllAccountIds = string[];

export interface AccountsState {
    byId: AccountByIdState;
    allIds: AllAccountIds;
}

type AccountAction = ActionType<typeof accountActions>;

const accountIdsReducer: Reducer<AllAccountIds> = (state = [], action: AccountAction): AllAccountIds => {
    switch (action.type) {
        case getType(accountActions.fetchAllSuccess):
            return action.payload.map((x) => x.Id);
        case getType(accountActions.fetchSuccess):
            return [...state, action.payload.Id];
        default:
            return state;
    }
};

const convertResource = (account: AccountResource): AccountState => ({
    accountId: account.Id,
    accountType: account.AccountType,
    name: account.Name,
    description: account.Description,
});

const accountsByIdReducer: Reducer<AccountByIdState> = (state: AccountByIdState = {}, action: AccountAction): AccountByIdState => {
    switch (action.type) {
        case getType(accountActions.fetchAllSuccess):
            return { ...state, ...keyBy(action.payload.map(convertResource), (x) => x.accountId) };
        case getType(accountActions.fetchSuccess):
            return { ...state, ...{ [action.payload.Id]: convertResource(action.payload) } };
        default:
            return state;
    }
};

export function fetchAllAccounts() {
    return (dispatch: Dispatch<Action>) => {
        dispatch(accountActions.fetchAll());
        return repository.Accounts.all()
            .then((result: AccountResource[]) => {
                dispatch(accountActions.fetchAllSuccess(result));
            })
            .catch((error) => {
                dispatch(accountActions.fetchError(error));
            });
    };
}

export default combineReducers<AccountsState>({ byId: accountsByIdReducer, allIds: accountIdsReducer });
