import DividerMaterialUI from "material-ui/Divider";
import * as React from "react";
import { withTheme } from "~/components/Theme";

interface DividerProps {
    isDarkColor?: boolean;
    fullHeight?: boolean;
}

export const Divider: React.FC<DividerProps> = ({ fullHeight = true, isDarkColor = false }) =>
    withTheme((theme) => {
        const margin = fullHeight ? { marginTop: 0 } : {};
        const dividerBgColor = isDarkColor ? theme.divider : theme.dividerLight;
        return <DividerMaterialUI style={{ backgroundColor: dividerBgColor, ...margin }} />;
    });

export default Divider;
