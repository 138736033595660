import * as React from "react";
import { Provider } from "react-redux";
import { UnauthenticatedAnalyticSessionProvider } from "~/analytics/UnauthenticatedAnalyticSessionProvider";
import DynamicExtensionsLoader from "~/areas/dynamicExtensions/dynamicExtensionsLoader";
import { ClientConnector } from "~/areas/infrastructure/components/ClientConnector/ClientConnector";
import { DevToolsContextProvider } from "~/components/DevTools/DevToolsContext";
import ErrorBoundary from "~/components/ErrorBoundary/ErrorBoundary";
import { ErrorContextProvider } from "~/components/ErrorContext/ErrorContext";
import { LogErrorDisplayer } from "~/components/LogErrors/LogErrorDisplayer";
import { LogEventCapturer } from "~/components/LogErrors/LogEventCapturer";
import { OctopusRouter } from "~/components/OctopusRouter/OctopusRouter";
import store from "~/store";
import { ThemeFromLocalStorage } from "./components/Theme/ThemeFromLocalStorage";

const App: React.FC = () => {
    return (
        <LogEventCapturer>
            <UnauthenticatedAnalyticSessionProvider>
                <ErrorBoundary>
                    <ErrorContextProvider>
                        <Provider store={store}>
                            <ThemeFromLocalStorage>
                                <LogErrorDisplayer />
                                <ClientConnector
                                    renderWhenConnected={() => (
                                        <DevToolsContextProvider>
                                            <DynamicExtensionsLoader />
                                            <OctopusRouter />
                                        </DevToolsContextProvider>
                                    )}
                                />
                            </ThemeFromLocalStorage>
                        </Provider>
                    </ErrorContextProvider>
                </ErrorBoundary>
            </UnauthenticatedAnalyticSessionProvider>
        </LogEventCapturer>
    );
};

export default App;
