import type { StaticWorkerPoolResource } from "@octopusdeploy/octopus-server-client";
import { cloneDeep } from "lodash";
import type { WorkerPoolEditState } from "./WorkerPoolEditBase";
import WorkerPoolEditBase from "./WorkerPoolEditBase";

type StaticWorkerPoolEditState = WorkerPoolEditState<StaticWorkerPoolResource>;

class StaticWorkerPoolEdit extends WorkerPoolEditBase<StaticWorkerPoolResource, StaticWorkerPoolEditState> {
    getDefaultState(): StaticWorkerPoolEditState {
        return {
            deleted: false,
            model: this.props.workerPool,
            cleanModel: cloneDeep(this.props.workerPool),
        };
    }

    customExpandableFormSections(): JSX.Element[] {
        return [];
    }
}

export default StaticWorkerPoolEdit;
