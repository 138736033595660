import type { WellKnownPropertyValues, GlobalLoggingContextProvider } from "@octopusdeploy/logging";
import { logger } from "@octopusdeploy/logging";
import UAParser from "ua-parser-js";

type StaticBrowserGlobalLoggingContext = WellKnownPropertyValues<{
    OS?: OS;
    Browser?: Browser[];
}>;

type DynamicBrowserGlobalLoggingContext = WellKnownPropertyValues<{
    Screen: Screen;
    Language: string;
    Timezone: string;
}>;

const BrowserLoggerContext: StaticBrowserGlobalLoggingContext = {
    OS: undefined,
    Browser: undefined,
};

type BrowserGlobalLoggingContext = StaticBrowserGlobalLoggingContext & DynamicBrowserGlobalLoggingContext;
export const browserLoggingContextProvider: GlobalLoggingContextProvider<BrowserGlobalLoggingContext> = {
    getProperties: () => ({
        ...BrowserLoggerContext,
        Screen: getScreen(),
        Language: navigator.language,
        Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }),
};

export async function setupGlobalBrowserLogContext() {
    const os = await getOS();
    const browser = getBrowsers();

    BrowserLoggerContext.OS = os;
    BrowserLoggerContext.Browser = browser;
}

interface OS {
    Name: string | undefined;
    Version: string | undefined;
}

interface Browser {
    Name: string | undefined;
    Version: string | undefined;
}

interface Screen {
    Width: number;
    Height: number;
}

const parsedUserAgent = new UAParser(navigator.userAgent);

async function getOS(): Promise<OS> {
    if (navigator.userAgentData) {
        try {
            const { platform } = navigator.userAgentData;
            const highEntropyValues = await navigator.userAgentData.getHighEntropyValues(["platformVersion"]);
            const { platformVersion } = highEntropyValues;

            return {
                Name: platform,
                Version: platformVersion,
            };
        } catch (e) {
            logger.error(e, "Failed to get OS from navigator.userAgentData", navigator.userAgentData);
        }
    }

    const os = parsedUserAgent.getOS();
    return {
        Name: os.name,
        Version: os.version,
    };
}

function getBrowsers(): Browser[] {
    if (navigator.userAgentData) {
        try {
            return navigator.userAgentData.brands?.map((brandVersion) => ({
                Name: brandVersion.brand,
                Version: brandVersion.version,
            }));
        } catch (e) {
            logger.error(e, "Failed to get browsers from navigator.userAgentData", navigator.userAgentData);
        }
    }

    const browser = parsedUserAgent.getBrowser();
    return [
        {
            Name: browser.name,
            Version: browser.version,
        },
    ];
}

function getScreen(): Screen {
    return {
        Width: window.screen.width,
        Height: window.screen.height,
    };
}
