import type { ProjectResource, RunbookResource } from "@octopusdeploy/octopus-server-client";
import { DynamicEnvironmentSettings, Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useRouteMatch } from "react-router-dom";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/index";
import { useProjectContext } from "~/areas/projects/context/index";
import { repository } from "~/clientInstance";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent/index";
import { FormBaseComponent } from "~/components/FormBaseComponent/index";
import FormPaperLayout from "~/components/FormPaperLayout/index";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import type { SummaryNode } from "~/components/form/index";
import { ExpandableFormSection, FormSection, Select, Summary } from "~/components/form/index";

interface DynamicEnvironmentSettingsState extends OptionalFormBaseComponentState<DynamicEnvironmentSettings> {
    runbooks: RunbookResource[] | null;
    project: ProjectResource | null;
}

type MatchProps = { match: NonNullable<ReturnType<typeof useRouteMatch>> | undefined };
type DynamicEnvironmentSettingsInternalProps = MatchProps & WithProjectContextInjectedProps;

class InternalDynamicEnvironmentSettings extends FormBaseComponent<DynamicEnvironmentSettingsInternalProps, DynamicEnvironmentSettingsState, DynamicEnvironmentSettings> {
    hasRunbookViewPermission: boolean = false;

    constructor(props: DynamicEnvironmentSettingsInternalProps) {
        super(props);
        this.state = {
            runbooks: null,
            project: null,
        };
    }

    async componentDidMount() {
        this.hasRunbookViewPermission = hasPermission(Permission.RunbookView);
        await this.doBusyTask(async () => {
            const { model: project } = this.props.projectContext.state;

            const dynamicEnvironmentsSettings = await repository.Projects.getDynamicEnvironmentSettings(project);

            this.setModel(dynamicEnvironmentsSettings);

            const runbooks = this.hasRunbookViewPermission ? await repository.Projects.getRunbooks(project).then((r) => r.Items) : [];

            this.setState({
                project,
                runbooks,
            });
        });
    }

    render() {
        const provisioningRunbookSelectComponent = this.createRunbookExpandableFormSelectionComponent(
            (model) => model?.ProvisioningRunbook,
            (id) => this.setModelState({ ProvisioningRunbook: id }),
            "Provisioning"
        );
        const deprovisioningRunbookSelectComponent = this.createRunbookExpandableFormSelectionComponent(
            (model) => model?.DeprovisioningRunbook,
            (id) => this.setModelState({ DeprovisioningRunbook: id }),
            "Deprovisioning"
        );
        return (
            <FormPaperLayout
                title="Dynamic Environment Settings"
                breadcrumbTitle={this.props.projectContext.state.model.Name}
                busy={this.state.busy}
                errors={this.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                savePermission={this.editPermission()}
                onSaveClick={this.handleSaveClick}
                saveText="Project details updated"
                forceDisableFormSaveButton={false}
                hideExpandAll={!this.hasRunbookViewPermission}
            >
                {provisioningRunbookSelectComponent}
                {deprovisioningRunbookSelectComponent}
            </FormPaperLayout>
        );
    }

    private createRunbookExpandableFormSelectionComponent(runbookIdGetter: (model?: DynamicEnvironmentSettings) => string | undefined, runbookIdSetter: (runbookId: string | undefined) => void, runbookType: "Provisioning" | "Deprovisioning") {
        const runbookId = runbookIdGetter(this.state.model);
        const lowerRunbookType = runbookType.toLowerCase();
        if (this.hasRunbookViewPermission) {
            return (
                <ExpandableFormSection
                    errorKey={`${lowerRunbookType}Runbook`}
                    title={`${runbookType} Runbook`}
                    summary={this.getRunbookSummary(runbookId, runbookType)}
                    help={`Select which runbook will be used when ${lowerRunbookType} a dynamic environment`}
                >
                    <Select
                        placeholder={`No ${lowerRunbookType} runbook selected`}
                        allowClear={true}
                        value={runbookId}
                        onChange={runbookIdSetter}
                        items={this.state.runbooks?.map((rb) => ({ value: rb.Id, text: rb.Name })) ?? []}
                        label={`${runbookType} Runbook`}
                    />
                </ExpandableFormSection>
            );
        }

        return <FormSection title={`${runbookType} Runbook`} help={`You need the '${Permission.RunbookView}' permission to view or edit this setting.`} includeBorder={true} />;
    }

    private handleSaveClick = async () =>
        await this.doBusyTask(async () => {
            if (!this.state.project) {
                throw new Error("No Project loaded");
            }

            if (!this.state.model) {
                throw Error("Tried to save with an empty model");
            }

            const model = this.state.model;

            if (!model) {
                throw new Error("No Model loaded");
            }

            const updatedModel = await repository.Projects.updateDynamicEnvironmentSettings(this.state.project, model);

            this.setModel(updatedModel);
        });

    private editPermission(): PermissionCheckProps {
        return {
            permission: [Permission.ProjectEdit, Permission.RunbookView],
            project: this.state.project?.Id,
            tenant: "*",
        };
    }

    private getRunbookSummary(runbookId: string | undefined, runbookType: "Deprovisioning" | "Provisioning"): SummaryNode {
        const runbook = this.state.runbooks?.find((r) => r.Id === runbookId);
        return Summary.summary(runbook ? runbook.Name : `No ${runbookType.toLowerCase()} runbook selected`);
    }
}

const DynamicEnvironmentSettings: React.FC = () => {
    const match = useRouteMatch() ?? undefined;
    const projectContext = useProjectContext();
    return <InternalDynamicEnvironmentSettings match={match} projectContext={projectContext} />;
};

export default DynamicEnvironmentSettings;
