import React from "react";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip";
import styles from "./CloseButton.module.less";

export const CloseButton = ({ onClick }: { onClick: () => void }) => (
    <div className={styles.closeIcon} onClick={onClick}>
        <ToolTip content={`Close project status`} position="bottom" distance={25}>
            <em className={`fa fa-times-circle`} />
        </ToolTip>
    </div>
);
