import { VariableType } from "@octopusdeploy/octopus-server-client";
import type { VariableResource, VariableSetResource } from "@octopusdeploy/octopus-server-client";
import type { LoadedLibraryVariableSets } from "~/areas/projects/components/Variables/AllVariables/AllVariables";

export function getVariable(name: string, projectVariables?: VariableSetResource, libraryVariableSets?: LoadedLibraryVariableSets[]): VariableResource | null {
    if (!projectVariables) return null;

    let result = getVariableInternal(projectVariables, name);

    if (!result) {
        if (!libraryVariableSets) return null;
        for (const libraryVariableSet of libraryVariableSets) {
            result = getVariableInternal(libraryVariableSet.variableSet, name);
            if (result) break;
        }
    }
    return result;
}

function getVariableInternal(variableSet: VariableSetResource, name: string): VariableResource {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return variableSet.Variables.find((v) => v.Name === name)!;
}

function addEmptyVariable(variableSet: VariableSetResource, name: string, type: VariableType) {
    const emptyVariable: VariableResource = {
        Id: "",
        Name: name,
        Value: "",
        Description: "",
        Scope: {},
        IsEditable: false,
        Prompt: null,
        Type: type,
        IsSensitive: false,
    };
    variableSet.Variables.push(emptyVariable);
    return emptyVariable;
}

export function setVariableValue(variableSet: VariableSetResource, name: string, value: string, type: VariableType = VariableType.String) {
    const variable = getVariableInternal(variableSet, name) || addEmptyVariable(variableSet, name, type);
    variable.Value = value;
}
