import type { WorkerPoolResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import type { FormFieldProps } from "~/components/form";
import WorkerPoolSelect from "~/components/form/WorkerPoolSelect/WorkerPoolSelect";

interface WorkPoolInputProps extends FormFieldProps<string> {
    label: string;
    warning?: string;
    allowClear: boolean;
    defaultValueIndicator: JSX.Element | undefined;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    validate?: ((value: string) => string) | ((value: string | null) => string);
    onValidate?(value: string): void;
    items: () => Promise<WorkerPoolResource[]>;
    onRequestRefresh: () => Promise<boolean>;
}

const WorkerPoolInput: React.FC<WorkPoolInputProps> = (props) => {
    const { value, label, warning, allowClear, defaultValueIndicator, doBusyTask, ...rest } = props;
    const formProps = { ...rest, label };

    return (
        <React.Fragment>
            <WorkerPoolSelect value={value} warning={warning} allowClear={allowClear} doBusyTask={doBusyTask} {...formProps} />
            {defaultValueIndicator}
        </React.Fragment>
    );
};

export default WorkerPoolInput;
