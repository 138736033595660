import React from "react";
import ReactDiffViewer from "react-diff-viewer";
import type { ReactDiffViewerStylesVariables } from "react-diff-viewer/lib/styles";
import { useOctopusTheme } from "~/components/Theme";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";

export interface DisplayDiffProps {
    oldValue: string;
    newValue: string;
    splitView?: boolean;
}

export const DisplayDiff: React.FC<DisplayDiffProps> = React.memo((props) => {
    const theme = useOctopusTheme();
    const palette = useThemePaletteType();
    const isDarkTheme = palette === "dark";

    const baseEditorColors: ReactDiffViewerStylesVariables = {
        diffViewerBackground: theme.primaryBackground,
        diffViewerColor: theme.primaryText,
        addedBackground: theme.diffAddedBackground,
        addedColor: theme.diffAddedColor,
        removedBackground: theme.diffRemovedBackground,
        removedColor: theme.diffRemovedColor,
        wordAddedBackground: theme.diffWordAddedBackground,
        wordRemovedBackground: theme.diffWordRemovedBackground,
        addedGutterBackground: theme.diffAddedGutterBackground,
        addedGutterColor: theme.diffAddedGutterColor,
        removedGutterBackground: theme.diffRemovedGutterBackground,
        removedGutterColor: theme.diffRemovedGutterColor,
        gutterBackground: theme.paper0,
        highlightBackground: theme.diffHighlight,
        highlightGutterBackground: theme.diffHighlight,
        codeFoldGutterBackground: theme.primaryBackground,
        codeFoldBackground: theme.primaryBackground,
        emptyLineBackground: theme.primaryBackground,
        gutterColor: theme.diffGutterColor,
        codeFoldContentColor: theme.primaryText,
        diffViewerTitleBackground: theme.primaryBackground,
        diffViewerTitleColor: theme.primaryText,
    };

    return (
        <ReactDiffViewer
            styles={{
                variables: {
                    dark: baseEditorColors,
                    light: baseEditorColors,
                },
                gutter: {
                    "& pre": {
                        wordBreak: "keep-all",
                    },
                    "&:hover": {
                        color: theme.linkTextHover,
                    },
                },
                contentText: {
                    color: theme.SectionBodyText,
                },
                //There aren't currently variables for these, so we set the colors for text here from our current theme
                codeFoldGutter: {
                    color: theme.diffGutterColor,
                },
                wordAdded: {
                    color: theme.diffWordAddedColor,
                },
                wordRemoved: {
                    color: theme.diffWordRemovedColor,
                },
                diffContainer: {
                    pre: {
                        lineHeight: "0.875rem",
                        background: "transparent",
                    },
                },
            }}
            useDarkTheme={isDarkTheme}
            oldValue={props.oldValue}
            newValue={props.newValue}
            splitView={props.splitView}
        />
    );
});

export default DisplayDiff;
