import { Permission, ControlType } from "@octopusdeploy/octopus-server-client";
import type { ActionTemplateParameterResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ActionTemplateParameterList from "~/components/ActionTemplateParametersList/ActionTemplateParametersList";
import { Section } from "~/components/Section/Section";

interface VariableSetTemplatesProps {
    parameters: ActionTemplateParameterResource[];
    onParametersChanged(parameters: ActionTemplateParameterResource[]): void;
}

export default class VariableSetTemplates extends React.Component<VariableSetTemplatesProps> {
    render() {
        return (
            <Section>
                <ActionTemplateParameterList
                    parameters={this.props.parameters}
                    name="template"
                    excludedControlTypes={[ControlType.StepName, ControlType.Package]}
                    editPermission={{
                        permission: Permission.VariableEdit,
                        wildcard: true,
                    }}
                    onParametersChanged={(parameters) => this.props.onParametersChanged(parameters)}
                />
            </Section>
        );
    }
}
