/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import type { SensitiveValue } from "@octopusdeploy/octopus-server-client";
import { noOp } from "@octopusdeploy/utilities";
import { compact } from "lodash";
import * as React from "react";
import IconButton, { Icon } from "~/components/IconButton/IconButton";
import IconButtonList from "~/components/IconButtonList/IconButtonList";
import InputWithActions from "~/components/InputWithActions/InputWithActions";
import Text from "~/primitiveComponents/form/Text/Text";
import ByteSizeFormatter from "~/utils/ByteSizeFormatter";
import type FormFieldProps from "../FormFieldProps";

interface SensitiveFileUploadProps extends FormFieldProps<SensitiveValue> {
    placeholder?: string;
    availablePlaceholder?: string;
    label?: string | JSX.Element;
    error?: string;
    warning?: string;
}

interface SensitiveFileState {
    error?: string | null;
    selectedFile?: File | null;
}

const MaxFileSize = Math.pow(1024, 5);
export default class SensitiveFileUpload extends React.Component<SensitiveFileUploadProps, SensitiveFileState> {
    input: HTMLInputElement | null = undefined!;

    constructor(props: SensitiveFileUploadProps) {
        super(props);
        this.state = {};
    }

    openSelector = () => {
        this.input!.click();
    };

    handleFileChange = async (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({ error: null });

        const files = e.currentTarget.files ?? [];

        if (files.length < 1) {
            return;
        }

        const file = files[0];

        if (file.size === 0) {
            this.reportError("Attached file is empty. Please select a different file.");
            return;
        }

        if (file.size > MaxFileSize) {
            this.reportError("Selected file is too big!");
            return;
        }

        try {
            const bytes = await this.loadFileBytes(file);
            this.props.onChange!({ HasValue: true, NewValue: bytes });
            this.setState({ selectedFile: file });
        } catch (err) {
            this.setState({ error: err });
        }
    };

    reportError(error: string) {
        this.setState({ error, selectedFile: null });
    }

    loadFileBytes(file: File): Promise<string> {
        return new Promise((res, rej) => {
            const reader = new FileReader();
            reader.onload = (evt) => {
                const target = evt.target;
                if (target!.readyState === FileReader.DONE) {
                    const b6 = (target!.result as string).indexOf("base64,") + 7;
                    const ress = (target!.result as string).slice(b6);
                    res(ress);
                }
            };
            reader.onerror = (evt) => {
                //eslint-disable-next-line @typescript-eslint/no-explicit-any
                rej((evt as any).message);
            };
            reader.readAsDataURL(file);
        });
    }

    currentValue = (): SensitiveValue => {
        return (
            this.props.value || {
                HasValue: false,
            }
        );
    };

    onFileInputClicked = (e: React.FormEvent<HTMLInputElement>) => {
        e.stopPropagation();
    };

    handleRemove = (e: React.MouseEvent) => {
        this.input!.value = null!;
        this.setState(
            {
                selectedFile: null!,
            },
            () => {
                this.props.onChange!({
                    HasValue: false,
                });
            }
        );
    };

    getName() {
        if (this.props.value && this.props.value.HasValue && this.props.value.NewValue === null) {
            return this.props.availablePlaceholder || "A file exists";
        }

        const selectedFile = this.state.selectedFile;
        if (selectedFile) {
            return `${selectedFile.name} (${ByteSizeFormatter(selectedFile.size)})`;
        }

        return this.props.placeholder || "";
    }

    render() {
        const currentValue = this.currentValue();
        const setOrChange = currentValue.HasValue ? "Change file" : "Choose file";

        const { error, warning, label, onChange, value, placeholder: placeholder } = this.props;
        const err = this.state.error || error;
        const errorText = err || warning;

        return (
            <div>
                <InputWithActions
                    input={<Text type="text" placeholder={placeholder} value={this.getName()} label={label} onChange={noOp} onClick={this.openSelector} error={errorText} />}
                    actions={<IconButtonList buttons={this.buttons(setOrChange, currentValue)} />}
                />
                <input type="file" style={{ display: "none" }} onChange={this.handleFileChange} ref={(input) => (this.input = input)} onClick={this.onFileInputClicked} />
            </div>
        );
    }

    private buttons(uploadText: string, currentValue: SensitiveValue) {
        return compact([<IconButton toolTipContent={uploadText} onClick={this.openSelector} icon={Icon.Upload} />, currentValue.HasValue ? <IconButton toolTipContent="Remove file" onClick={this.handleRemove} icon={Icon.Remove} /> : null]);
    }
}
