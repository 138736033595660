import type { StepPackage, StepPackageDetailsResponse, StepUI } from "@octopusdeploy/octopus-server-client";
import { repository } from "~/clientInstance";
import { importFromDynamicExpression } from "./importFromDynamicExpression";

class StepPackageResolver {
    async getStepPackage(stepPackageId: string): Promise<StepPackage> {
        const response = await repository.StepPackageRepository.getStepPackage(stepPackageId);
        return await this.resolveStepPackageDetails(response);
    }

    async getStepPackageByIdAndVersion(stepPackageId: string, stepPackageVersion: string): Promise<StepPackage> {
        const response = await repository.StepPackageRepository.getStepPackageByIdAndVersion(stepPackageId, stepPackageVersion);
        return await this.resolveStepPackageDetails(response);
    }

    async getLatestStepPackageByDeploymentTargetType(deploymentTargetTypeId: string): Promise<StepPackage> {
        const response = await repository.StepPackageDeploymentTarget.getLatestStepPackageByDeploymentTargetType(deploymentTargetTypeId);
        return this.resolveStepPackageDetails(response);
    }

    async getStepPackageByDeploymentTargetType(deploymentTargetTypeId: string, version: string): Promise<StepPackage> {
        const response = await repository.StepPackageDeploymentTarget.getStepPackageByDeploymentTargetType(deploymentTargetTypeId, version);
        return this.resolveStepPackageDetails(response);
    }

    async resolveStepPackageDetails(stepPackage: StepPackageDetailsResponse): Promise<StepPackage> {
        const schema = await repository.StepPackageRepository.getStepPackageSchema(stepPackage);
        const stepUI = await this.resolveStepPackageUI(stepPackage);

        return {
            name: stepPackage.Name,
            description: stepPackage.Description,
            version: stepPackage.Version,
            stepUI: stepUI,
            schema: schema,
            prerelease: stepPackage.Prerelease,
            releaseNotesUrl: stepPackage.ReleaseNotesUrl,
            executionLocation: stepPackage.ExecutionLocation,
            canRunInExecutionContainer: stepPackage.CanRunInExecutionContainer,
            requiresTargetRole: stepPackage.RequiresTargetRole,
            targetDiscoveryCloudConnectionTypes: stepPackage.TargetDiscoveryCloudConnectionTypes,
        };
    }

    async resolveStepPackageUI(stepPackage: StepPackageDetailsResponse): Promise<unknown> {
        const stepUIModule = await importFromDynamicExpression(stepPackage.Links.StepUI);
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const convertedStepUIModule = stepUIModule as { default: StepUI };
        return convertedStepUIModule.default;
    }
}

export const stepPackageResolver = new StepPackageResolver();
