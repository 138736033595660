/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { DeploymentPreviewBaseResource, ReleaseChanges, IProcessResource, Form, TenantResource } from "@octopusdeploy/octopus-server-client";
import { isDeploymentPreviewResource } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import type { SFC } from "react";
import * as React from "react";
import { DeploymentModelType } from "~/areas/projects/components/Runbooks/RunbookRunNowLayout";
import { DataTable } from "~/primitiveComponents/dataDisplay/DataTable/DataTable";
import { DataTableHeader } from "~/primitiveComponents/dataDisplay/DataTable/DataTableHeader";
import { DataTableHeaderColumn } from "~/primitiveComponents/dataDisplay/DataTable/DataTableHeaderColumn";
import { DataTableRow } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRow";
import type { DeploymentMachineInfo, DeploymentStepsDetails } from "../Preview";
import type { DeploymentRequestModel } from "../deploymentRequestModel";
import DeploymentResultItem from "./DeploymentResultItem";

interface DeploymentResultListProps {
    deployments: DeploymentRequestModel[];
    stepActionIdsToSkip: string[];
    tenants: TenantResource[];
    promptVariableForm: Form;
    tenantsWithMissingVariables: string[];
    process: IProcessResource;
    deploymentsAndSteps: DeploymentStepsDetails[];
    modelType: DeploymentModelType;
    isExpandedByDefault?: boolean;
    getDeploymentPreview: (environmentId: string, tenantId: string) => DeploymentPreviewBaseResource | undefined;
    onIncludeSpecificMachinesSelected(deployment: DeploymentMachineInfo): void;
    onExcludeSpecificMachinesSelected(deployment: DeploymentMachineInfo): void;
    onAllTargetsSelected(deployment: DeploymentMachineInfo): void;
}

const DeploymentResults: SFC<DeploymentResultListProps> = (props) => {
    const isTenanted = _.some(props.deployments, (deployment) => deployment.tenantId);

    const renderHeaderRow = () => {
        return (
            <DataTableRow>
                <DataTableHeaderColumn>{isTenanted ? "Tenant" : "Environment"}</DataTableHeaderColumn>
                <DataTableHeaderColumn>Current Version</DataTableHeaderColumn>
                <DataTableHeaderColumn>{props.modelType === DeploymentModelType.Deployment ? "Deployment Process" : "Runbook Process"}</DataTableHeaderColumn>
                <DataTableHeaderColumn>Targets</DataTableHeaderColumn>
                <DataTableHeaderColumn>Target Status</DataTableHeaderColumn>
            </DataTableRow>
        );
    };

    const renderDeploymentRows = () => {
        if (props.deploymentsAndSteps) {
            return props.deploymentsAndSteps.map((d) => {
                const preview = props.getDeploymentPreview(d.deployment.request.EnvironmentId, d.deployment.tenantId!);
                let releaseChanges: ReleaseChanges[] = [];
                let changesMarkdown = null;
                if (preview && isDeploymentPreviewResource(preview)) {
                    releaseChanges = preview.Changes;
                    changesMarkdown = preview ? preview.ChangesMarkdown : null;
                }
                return (
                    <DeploymentResultItem
                        isMissingVariable={props.tenantsWithMissingVariables.indexOf(d.deployment.tenantId!) !== -1}
                        key={d.deployment.tenantId ? d.deployment.tenantId : d.deployment.request.EnvironmentId}
                        deployment={d.deployment}
                        tenant={d.deployment.tenantId ? props.tenants.find((t) => t.Id === d.deployment.tenantId) : null!}
                        stepsForSelectedDeployment={d.stepsForSelectedDeployment}
                        actions={d.actions}
                        releaseChanges={releaseChanges}
                        changesMarkdown={changesMarkdown!}
                        isExpandedByDefault={props.isExpandedByDefault}
                        {...props}
                    />
                );
            });
        }
    };
    return (
        <DataTable>
            <DataTableHeader>{renderHeaderRow()}</DataTableHeader>
            {renderDeploymentRows()}
        </DataTable>
    );
};
DeploymentResults.displayName = "DeploymentResults";

export default DeploymentResults;
