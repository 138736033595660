import { Permission } from "@octopusdeploy/octopus-server-client";
import type { DashboardItemResource, RunbooksDashboardItemResource, ProjectDynamicEnvironmentOverview } from "@octopusdeploy/octopus-server-client";
import React, { useCallback, useState } from "react";
import type { ReactElement } from "react";
import { Column, Table, WindowScroller } from "react-virtualized";
import { TaskStatusDetails } from "~/areas/projects/components/ProjectDashboard/TaskStatusDetails/TaskStatusDetails";
import { repository } from "~/clientInstance";
import { ActionButton } from "~/components/Button/index";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import "react-virtualized/styles.css";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import InternalLink from "~/components/Navigation/InternalLink";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { Select } from "~/components/form/index";
import { useRefreshLoop } from "~/hooks/useRefreshLoop";
import type { DropdownMenuOption } from "~/primitiveComponents/form/Select/DropDownMenu";
import routeLinks from "~/routeLinks";
import RunbookTaskStatusDetails from "../Runbooks/RunbookTaskStatusDetails/RunbookTaskStatusDetails";
import styles from "./DynamicDeploymentsOverview.module.less";

interface DynamicDeploymentOverviewProps {
    doBusyTask: DoBusyTask;
    spaceId: string;
    projectSlug: string;
}

interface DynamicDeploymentOverviewHeaderProps {
    sortOrder: string | undefined;
    onSortOrderChange: (newValue: string | undefined) => void;
    onSearchTermChange: (newValue: string) => void;
}

interface DynamicEnvironmentsTableProps {
    projectSlug: string;
    displayRows: ProjectDynamicEnvironmentOverview[];
    totalRowCount: number;
    onEnvironmentDelete: (environmentId: string) => void;
}

interface DynamicDeploymentOverviewFooterProps {
    onShowAll: () => void;
}

export function DynamicDeploymentsOverview(props: DynamicDeploymentOverviewProps): ReactElement | null {
    const [rows, setRows] = useState<ProjectDynamicEnvironmentOverview[] | null>(null);
    const [filterTerm, setFilterTerm] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string | undefined>("deployed");
    const [showAll, setShowAll] = useState<boolean>(false);
    const memoizedRefreshFunction = useCallback(refreshOverview, [props.projectSlug, props.spaceId]);
    useRefreshLoop(memoizedRefreshFunction, 6000);

    async function refreshOverview() {
        const latestOverview = await repository.Projects.getProjectDynamicEnvironmentsOverview(props.projectSlug, props.spaceId);
        const newRows = latestOverview.DynamicEnvironmentOverviews;
        setRows(newRows);
    }

    function onEnvironmentDelete(environmentId: string) {
        if (rows) {
            setRows(rows.filter((r) => r.EnvironmentId != environmentId));
        }
    }

    useDoBusyTaskEffect(
        props.doBusyTask,
        async () => {
            await refreshOverview();
        },
        [props.projectSlug, props.spaceId]
    );

    if (rows === null) return <></>;

    const upperCaseFilterTerm = filterTerm.toUpperCase();
    const filteredRows = filterTerm ? rows.filter((row) => row.EnvironmentName.toUpperCase().includes(upperCaseFilterTerm)) : rows;
    const orderedRows = filteredRows.sort(getSortFunction(sortOrder));
    const displayRows = showAll ? orderedRows : orderedRows.slice(0, truncationLength);
    const showShowAllButton = !showAll && orderedRows.length > truncationLength;

    return (
        <>
            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
            <Header sortOrder={sortOrder} onSortOrderChange={(order) => setSortOrder(order)} onSearchTermChange={(term) => setFilterTerm(term)} />
            <DynamicEnvironmentsTable projectSlug={props.projectSlug} totalRowCount={filteredRows.length} displayRows={displayRows} onEnvironmentDelete={onEnvironmentDelete} />
            {showShowAllButton && <Footer onShowAll={() => setShowAll(true)} />}
        </>
    );
}

function Header(props: DynamicDeploymentOverviewHeaderProps) {
    return (
        <header className={styles.header}>
            <div className={styles.title}>
                <h2>Dynamic Environments</h2>
            </div>
            <div className={styles.filter}>
                <FilterSearchBox placeholder={"Search..."} onChange={props.onSearchTermChange} />
            </div>
            <label className={styles.sort}>
                <span className={styles.sortLabel}>Sort:</span>
                <Select allowClear={false} items={sortOrders} value={props.sortOrder} onChange={props.onSortOrderChange} />
            </label>
        </header>
    );
}

function DynamicEnvironmentsTable(props: DynamicEnvironmentsTableProps) {
    return (
        <WindowScroller>
            {({ height, isScrolling, onChildScroll, scrollTop }) => (
                <Table
                    className={styles.table}
                    autoHeight
                    height={height}
                    isScrolling={isScrolling}
                    scrollTop={scrollTop}
                    width={1500}
                    padding={1}
                    headerHeight={40}
                    rowHeight={70}
                    rowCount={props.displayRows.length}
                    rowGetter={({ index }) => props.displayRows[index]}
                >
                    <Column
                        label={`Environment (${props.totalRowCount})`}
                        dataKey="EnvironmentName"
                        width={250}
                        flexGrow={1}
                        cellRenderer={({ rowData }) => {
                            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                            const environmentRow = rowData as ProjectDynamicEnvironmentOverview;

                            return (
                                <InternalLink to={routeLinks.infrastructure.dynamicEnvironment(environmentRow.EnvironmentId).overview} className={styles.environmentName}>
                                    <span title={environmentRow.EnvironmentName}>{environmentRow.EnvironmentName}</span>
                                </InternalLink>
                            );
                        }}
                    />
                    <Column
                        label="Deployment"
                        dataKey="LatestDeployment"
                        width={200}
                        flexGrow={1}
                        cellRenderer={({ cellData }) => {
                            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                            const resource = cellData as DashboardItemResource;
                            if (resource)
                                return (
                                    <div className={styles.dynamicDashboardTaskStatusDetailContainer}>
                                        <TaskStatusDetails
                                            item={resource}
                                            channelName={undefined}
                                            projectSlug={props.projectSlug}
                                            deploymentId={resource.DeploymentId}
                                            additionalDetails={false}
                                            releaseVersionClassName={styles.deploymentReleaseVersion}
                                        />
                                    </div>
                                );

                            return null;
                        }}
                    />
                    <Column
                        label="Provisioned"
                        dataKey="ProvisioningRunbookRun"
                        width={200}
                        flexGrow={1}
                        cellRenderer={({ cellData }) => {
                            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                            const resource = cellData as RunbooksDashboardItemResource;

                            if (resource)
                                return (
                                    <div className={styles.dynamicDashboardTaskStatusDetailContainer}>
                                        <RunbookTaskStatusDetails item={resource} runbookSnapshotNameClassName={styles.runbookSnapshotName} />
                                    </div>
                                );

                            return null;
                        }}
                    />
                    <Column
                        label="Deprovisioned"
                        dataKey="DeprovisioningRunbookRun"
                        width={200}
                        flexGrow={1}
                        cellRenderer={({ cellData }) => {
                            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                            const resource = cellData as RunbooksDashboardItemResource;

                            if (resource)
                                return (
                                    <div className={styles.dynamicDashboardTaskStatusDetailContainer}>
                                        <RunbookTaskStatusDetails item={resource} runbookSnapshotNameClassName={styles.runbookSnapshotName} />
                                    </div>
                                );

                            return null;
                        }}
                    />
                    <Column
                        label=""
                        dataKey="EnvironmentId"
                        width={20}
                        flexGrow={1}
                        className={styles.overflowMenuColumn}
                        cellRenderer={({ cellData }) => {
                            return <OverflowMenu menuItems={CreateOverflowMenuItems(cellData, props.onEnvironmentDelete)} />;
                        }}
                    />
                </Table>
            )}
        </WindowScroller>
    );
}

function Footer(props: DynamicDeploymentOverviewFooterProps) {
    return (
        <footer className={styles.footer}>
            <ActionButton label="Show all" key="secondary" onClick={props.onShowAll} />
        </footer>
    );
}

function CreateOverflowMenuItems(environmentId: string, onDelete: (environmentId: string) => void) {
    return [
        OverflowMenuItems.destructiveItem(
            "Deprovision environment",
            "Are you sure you want to deprovision this dynamic environment?",
            "Deprovision",
            "Deprovisioning",
            async () => {
                await handleDeprovisionConfirm(environmentId);
                return true;
            },
            <div>
                <p>This action will deprovision both the environment and all associated projects.</p>
                <p>Do you wish to continue?</p>
            </div>,
            { permission: Permission.EnvironmentDelete, environment: "*" }
        ),
        OverflowMenuItems.deleteItem(
            "Delete environment",
            "Are you sure you want to delete this dynamic environment?",
            async () => {
                return await handleDeleteConfirm(environmentId, onDelete);
            },
            <div>
                <p>Deleting this environment is permanent. There is no going back.</p>
                <p>Do you wish to continue?</p>
            </div>,
            { permission: Permission.EnvironmentDelete, environment: "*" }
        ),
    ];
}

const compareIso8601UtcDateStrings = (dateA: string | undefined, dateB: string | undefined): number => {
    if (!dateA && !dateB) return 0;
    if (!dateA) return -1;
    if (!dateB) return +1;
    // Simple string comparison is good enough here since we are only dealing with ISO8601 date strings in the same
    // timezone and format.
    if (dateA < dateB) return -1;
    if (dateB < dateA) return 1;
    return 0;
};

const compareIso8601UtcDateStringsDescending = (dateA: string | undefined, dateB: string | undefined): number => {
    return compareIso8601UtcDateStrings(dateB, dateA);
};

function getSortFunction(sortOrder: string | undefined): (a: ProjectDynamicEnvironmentOverview, b: ProjectDynamicEnvironmentOverview) => number {
    if (sortOrder === "name") {
        return (a, b) => a.EnvironmentName.localeCompare(b.EnvironmentName);
    }
    if (sortOrder === "deployed") {
        return (a, b) => compareIso8601UtcDateStringsDescending(a?.LatestDeployment?.CompletedTime, b?.LatestDeployment?.CompletedTime);
    }

    return (a, b) => 0;
}

const sortOrders: DropdownMenuOption[] = [
    { text: "Most recently deployed to", value: "deployed" },
    { text: "Environment name", value: "name" },
];

const truncationLength = 5;

const handleDeleteConfirm = async (environmentId: string, callback: (environmentId: string) => void) => {
    await repository.DynamicEnvironments.del(environmentId);
    callback(environmentId);
    return true;
};

const handleDeprovisionConfirm = async (environmentId: string) => {
    await repository.DynamicEnvironments.deprovision(environmentId);
};
