/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { TomcatCertificateProperties } from "@octopusdeploy/legacy-action-properties";
import { ActionExecutionLocation } from "@octopusdeploy/octopus-server-client";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { repository } from "~/clientInstance";
import Roles from "~/components/Actions/Roles";
import type { ActionSummaryProps } from "~/components/Actions/actionSummaryProps";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import { default as pluginRegistry } from "~/components/Actions/pluginRegistry";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import ExternalLink from "~/components/Navigation/ExternalLink";
import CertificateVariableSelect from "~/components/form/CertificateSelect/CertificateVariableSelect";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import Summary from "~/components/form/Sections/Summary";
import { BoundSensitive } from "~/components/form/Sensitive/Sensitive";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import Note from "~/primitiveComponents/form/Note/Note";
import RadioButton from "~/primitiveComponents/form/RadioButton/RadioButton";
import { BoundStringRadioButtonGroup } from "~/primitiveComponents/form/RadioButton/RadioButtonGroup";

class DeployCertificateToTomcatActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        return (
            <div>
                Deploy a certificate to Tomcat 7+{" "}
                {this.props.targetRolesAsCSV && (
                    <React.Fragment>
                        to deployment targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} />
                    </React.Fragment>
                )}
            </div>
        );
    }
}

interface CertificateImportActionEditState {
    project: ProjectResource;
}

export class DeployCertificateToTomcatActionEdit extends BaseComponent<ActionEditProps<TomcatCertificateProperties>, CertificateImportActionEditState> {
    constructor(props: ActionEditProps<TomcatCertificateProperties>) {
        super(props);
        this.state = {
            project: null!,
        };
    }

    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            const project = await repository.Projects.get(this.props.projectId!);
            this.setState({ project });
        });

        const properties = this.props.properties;
        if (!properties["Tomcat.Certificate.Service"]) {
            properties["Tomcat.Certificate.Service"] = "Catalina";
        }
        if (!properties["Tomcat.Certificate.Implementation"]) {
            properties["Tomcat.Certificate.Implementation"] = "NIO";
        }
        if (!properties["Tomcat.Certificate.Default"]) {
            properties["Tomcat.Certificate.Default"] = "False";
        }
        this.props.setProperties(properties);
    }

    tomcatLocationSummary() {
        if (this.props.properties["Tomcat.Certificate.CatalinaHome"]) {
            /*
                Guess the path splitter based on the kinds of slashes that exist in
                the base location
             */
            const pathSplit = this.props.properties["Tomcat.Certificate.CatalinaHome"].indexOf("/") !== -1 ? "/" : "\\";
            return Summary.summary(
                <span>
                    Adding the certificate to the{" "}
                    <strong>
                        {this.props.properties["Tomcat.Certificate.CatalinaBase"] || this.props.properties["Tomcat.Certificate.CatalinaHome"]}
                        {pathSplit}config{pathSplit}server.xml
                    </strong>{" "}
                    configuration file
                </span>
            );
        }

        return Summary.placeholder(<span>Define the CATALINA_BASE directory</span>);
    }

    tomcatCertificateSummary() {
        if (this.props.properties["Java.Certificate.Variable"]) {
            return Summary.summary(
                <span>
                    Deploying the {this.props.properties["Java.Certificate.Variable"]} certificate
                    {this.props.properties["Tomcat.Certificate.Service"] && (
                        <span>
                            {" "}
                            to the service named <strong>{this.props.properties["Tomcat.Certificate.Service"]}</strong>
                        </span>
                    )}
                    {this.props.properties["Tomcat.Certificate.Port"] && (
                        <span>
                            {" "}
                            under the connector listening on port <strong>{this.props.properties["Tomcat.Certificate.Port"]}</strong>
                        </span>
                    )}
                    {this.props.properties["Tomcat.Certificate.Hostname"] && (
                        <span>
                            {" "}
                            configured with the SNI hostname <strong>{this.props.properties["Tomcat.Certificate.Hostname"]}</strong>
                        </span>
                    )}
                </span>
            );
        }

        return Summary.placeholder(<span>Define the certificate to deploy</span>);
    }

    tomcatSNISummary() {
        if (this.props.properties["Tomcat.Certificate.Hostname"]) {
            return Summary.summary(<span>Mapping certificate to the {this.props.properties["Tomcat.Certificate.Hostname"]} host name</span>);
        }
        return Summary.placeholder(<span>Map a certificate to a host name (Tomcat 8.5+ only)</span>);
    }

    tomcatOptionsSummary() {
        if (
            this.props.properties["Java.Certificate.Password"] ||
            this.props.properties["Tomcat.Certificate.PrivateKeyFilename"] ||
            this.props.properties["Tomcat.Certificate.PublicKeyFilename"] ||
            this.props.properties["Java.Certificate.KeystoreFilename"] ||
            this.props.properties["Java.Certificate.KeystoreAlias"]
        ) {
            return Summary.summary(
                <span>
                    Deploying the certificate
                    {(this.props.properties["Tomcat.Certificate.PrivateKeyFilename"] || this.props.properties["Tomcat.Certificate.PublicKeyFilename"] || this.props.properties["Java.Certificate.KeystoreFilename"]) && (
                        <span> to a custom location</span>
                    )}
                    {this.props.properties["Java.Certificate.Password"] && (
                        <span>
                            {" "}
                            with a custom password
                            {this.props.properties["Java.Certificate.KeystoreAlias"] && <span> and</span>}
                        </span>
                    )}
                    {this.props.properties["Java.Certificate.KeystoreAlias"] && <span> with a custom alias</span>}
                </span>
            );
        }

        return Summary.placeholder(<span>Define the optional certificate properties</span>);
    }

    render() {
        const properties = this.props.properties;
        const serverDetailsErrorKey = "Tomcat.Certificate.CatalinaHome|" + "Tomcat.Certificate.CatalinaBase";
        const certificateDetailsErrorKey = "Java.Certificate.Variable|" + "Tomcat.Certificate.Service|" + "Tomcat.Certificate.Implementation|" + "Tomcat.Certificate.Port";

        return (
            <div>
                <ExpandableFormSection
                    errorKey={serverDetailsErrorKey}
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Tomcat Location"
                    summary={this.tomcatLocationSummary()}
                    help="Specify the path to the Tomcat CATALINA_HOME and CATALINA_BASE directories"
                >
                    <VariableLookupText
                        localNames={this.props.localNames}
                        value={properties["Tomcat.Certificate.CatalinaHome"]}
                        onChange={(x) => this.props.setProperties({ ["Tomcat.Certificate.CatalinaHome"]: x })}
                        label="Tomcat CATALINA_HOME path"
                        error={this.props.getFieldError("Tomcat.Certificate.CatalinaHome")}
                    />
                    <Note>This is the root directory of the "binary" distribution of Tomcat.</Note>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        value={properties["Tomcat.Certificate.CatalinaBase"]}
                        onChange={(x) => this.props.setProperties({ ["Tomcat.Certificate.CatalinaBase"]: x })}
                        label="Tomcat CATALINA_BASE path"
                        error={this.props.getFieldError("Java.Certificate.TomcatLocation")}
                    />
                    <Note>
                        This is the root directory of the "active configuration" of Tomcat. This can be left blank if the Tomcat root directory CATALINA_HOME directory also holds the configuration (i.e. leave this blank if the CATALINA_HOME and
                        CATALINA_BASE directories are the same).
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey={certificateDetailsErrorKey}
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Tomcat Certificate"
                    summary={this.tomcatCertificateSummary()}
                    help="Specify the details of the certificate to install"
                >
                    {this.props.projectId ? (
                        <CertificateVariableSelect
                            doBusyTask={this.props.doBusyTask}
                            projectId={this.props.projectId}
                            gitRef={this.props.gitRef}
                            value={this.props.properties["Java.Certificate.Variable"]}
                            onChange={(val) => this.props.setProperties({ ["Java.Certificate.Variable"]: val })}
                            error={this.props.getFieldError("Java.Certificate.Variable")}
                            allowClear={true}
                        />
                    ) : (
                        <VariableLookupText
                            label="Certificate variable"
                            localNames={this.props.localNames}
                            value={this.props.properties["Java.Certificate.Variable"]}
                            onChange={(val) => this.props.setProperties({ ["Java.Certificate.Variable"]: val })}
                            error={this.props.getFieldError("Java.Certificate.Variable")}
                        />
                    )}
                    <Note>
                        A project variable that refers to a certificate.{" "}
                        <span>
                            <ExternalLink href="CertificatesDocumentation">Learn more</ExternalLink>.
                        </span>
                    </Note>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        value={properties["Tomcat.Certificate.Service"]}
                        onChange={(x) => this.props.setProperties({ ["Tomcat.Certificate.Service"]: x })}
                        label="Tomcat service name"
                        error={this.props.getFieldError("Tomcat.Certificate.Service")}
                    />
                    <Note>
                        This is the name of the Service element in the server.xml file e.g. {"<"}Service name="Catalina"{">"}.
                    </Note>
                    <BoundStringRadioButtonGroup
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        resetValue={"NIO"}
                        value={properties["Tomcat.Certificate.Implementation"]}
                        onChange={(x) => this.props.setProperties({ ["Tomcat.Certificate.Implementation"]: x })}
                        label="SSL implementation"
                    >
                        <RadioButton value={"BIO"} label="Blocking IO (BIO - Tomcat 8 and below only)" />
                        <RadioButton value={"NIO"} label="Non-Blocking IO (NIO)" />
                        <RadioButton value={"NIO2"} label="Non-Blocking IO 2 (NIO2 - Tomcat 8 and above only)" />
                        <RadioButton value={"APR"} label="Apache Portable Runtime (APR)" />
                    </BoundStringRadioButtonGroup>
                    <Note>These are the standard implementations provided by Tomcat to support HTTPS. Note that not all versions of Tomcat support all implementations.</Note>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        value={properties["Tomcat.Certificate.Port"]}
                        onChange={(x) => this.props.setProperties({ ["Tomcat.Certificate.Port"]: x })}
                        label="HTTPS port"
                        error={this.props.getFieldError("Tomcat.Certificate.Port")}
                    />
                    <Note>This is the HTTPS port that this certificate will be bound to.</Note>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="tomcatSNIOptions" isExpandedByDefault={this.props.expandedByDefault} title="Tomcat SNI Options (Tomcat 8.5+ only)" summary={this.tomcatSNISummary()} help="Specify the SNI details">
                    <VariableLookupText localNames={this.props.localNames} value={properties["Tomcat.Certificate.Hostname"]} onChange={(x) => this.props.setProperties({ ["Tomcat.Certificate.Hostname"]: x })} label="Certificate SNI hostname" />
                    <Note>
                        In Tomcat 8.5 and above certificates can be bound to a host name using SNI. This field defines the host name that this certificate is bound to. Leave this field blank when deploying to Tomcat 7 or 8. If you leave this field
                        blank when deploying to Tomcat 8.5 and above, the certificate will be assigned a default host name by Tomcat.
                    </Note>
                    <BoundStringRadioButtonGroup
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        resetValue={""}
                        value={properties["Tomcat.Certificate.Default"]}
                        onChange={(x) => this.props.setProperties({ ["Tomcat.Certificate.Default"]: x })}
                        label="Default certificate"
                    >
                        <RadioButton value={"True"} label="Make this the default certificate" />
                        <RadioButton value={"False"} label="Leave this certificate's default status unchanged" />
                    </BoundStringRadioButtonGroup>
                    <Note>
                        In Tomcat 8.5 and above a certificate can be designated as the default one to respond to any request to a host that does not have a specific certificate assigned. Selecting <em>Make this the default certificate</em> will
                        configure the certificate to be the default one. This field has no effect when deploying to Tomcat 7 or Tomcat 8.
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="tomcatCertificateOptions" isExpandedByDefault={this.props.expandedByDefault} title="Tomcat Certificate Options" summary={this.tomcatOptionsSummary()} help="Specify the optional certificate details">
                    <BoundSensitive
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        resetValue={""}
                        value={properties["Java.Certificate.Password"]}
                        onChange={(x) => this.props.setProperties({ ["Java.Certificate.Password"]: x })}
                        label="Private key password"
                    />
                    <Note>You can set an optional password which will be assigned to the private key. If this field is left blank, the default password defined by Tomcat will be used where possible, or the private key will remain unencrypted.</Note>
                    {this.props.properties["Tomcat.Certificate.Implementation"] === "APR" && (
                        <div>
                            <VariableLookupText
                                localNames={this.props.localNames}
                                value={properties["Tomcat.Certificate.PrivateKeyFilename"]}
                                onChange={(x) => this.props.setProperties({ ["Tomcat.Certificate.PrivateKeyFilename"]: x })}
                                label="Private key filename"
                            />
                            <Note>
                                You can optionally set the path of the private key that will be generated when configuring the APR implementation. If this file exists it will be overwritten. If this field is left blank a new file will be created in
                                the Tomcat <em>conf</em> directory.
                            </Note>
                            <VariableLookupText
                                localNames={this.props.localNames}
                                value={properties["Tomcat.Certificate.PublicKeyFilename"]}
                                onChange={(x) => this.props.setProperties({ ["Tomcat.Certificate.PublicKeyFilename"]: x })}
                                label="Public key filename"
                            />
                            <Note>
                                You can optionally set the path of the public key that will be generated when configuring the APR implementation. If this file exists it will be overwritten. If this field is left blank a new file will be created in
                                the Tomcat <em>conf</em> directory.
                            </Note>
                        </div>
                    )}
                    {this.props.properties["Tomcat.Certificate.Implementation"] !== "APR" && (
                        <div>
                            <VariableLookupText
                                localNames={this.props.localNames}
                                value={properties["Java.Certificate.KeystoreFilename"]}
                                onChange={(x) => this.props.setProperties({ ["Java.Certificate.KeystoreFilename"]: x })}
                                label="Keystore filename"
                            />
                            <Note>
                                <p>
                                    You can optionally set the path of the keystore that will be generated when configuring the BIO, NIO and NIO2 implementations. If this file exists it will be overwritten. If this field is left blank a keystore file
                                    will be created in the Tomcat <em>conf</em> directory.
                                </p>
                                <p>If the specified file exists it will be overwritten.</p>
                            </Note>
                            <VariableLookupText localNames={this.props.localNames} value={properties["Java.Certificate.KeystoreAlias"]} onChange={(x) => this.props.setProperties({ ["Java.Certificate.KeystoreAlias"]: x })} label="Keystore alias" />
                            <Note>
                                You can optionally set the alias under which the certificate information will be stored when configuring the BIO, NIO and NIO2 implementations. If this field is left blank the default alias of <em>octopus</em> will be
                                used.
                            </Note>
                        </div>
                    )}
                </ExpandableFormSection>
            </div>
        );
    }
}

pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    actionType: "Octopus.TomcatDeployCertificate",
    summary: (properties, targetRolesAsCSV) => <DeployCertificateToTomcatActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    edit: DeployCertificateToTomcatActionEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => false,
});
