import type { ProjectResource, RunbookResource, ResourceCollection } from "@octopusdeploy/octopus-server-client";
import { HasVariablesInGit, RunbookEnvironmentScope, GuidedFailureMode } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { CommitMessageWithDetails } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context";
import { useProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import { required, Text, MarkdownEditor, Select, Note } from "~/components/form";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";

export interface AddRunbookProps {
    projectId: string;
    cloneId?: string;
    onProcessCreated(id: string): Promise<void>;
}

interface AddRunbookState extends DataBaseComponentState {
    name: string;
    description: string;
    selectedCloneId: string | undefined;
    projectRunbooks: ResourceCollection<RunbookResource> | undefined;
    showAdvanced: boolean;
    commitMessage: CommitMessageWithDetails;
}

type Props = AddRunbookProps & WithProjectContextInjectedProps;

const defaultCommitSummary = "Create new Runbook";

class AddRunbookInternal extends DataBaseComponent<Props, AddRunbookState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            name: "",
            description: "",
            selectedCloneId: this.props.cloneId,
            projectRunbooks: undefined,
            commitMessage: {
                details: "",
                summary: "",
            },
            showAdvanced: false,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = this.props.projectContext.state.model;
            const projectRunbooks: ResourceCollection<RunbookResource> | undefined = await this.getProjectRunbooks(project);
            this.setState((_) => ({
                projectRunbooks,
            }));
        });
    }

    getProjectRunbooks = async (project: ProjectResource): Promise<ResourceCollection<RunbookResource> | undefined> => {
        if (this.props.cloneId) {
            return await repository.Projects.getRunbooks(project);
        }
    };

    save() {
        const args = { clone: this.state.selectedCloneId };
        const newRunbookResource = {
            Name: this.state.name,
            Description: this.state.description,
            MultiTenancyMode: this.props.projectContext.state.model.TenantedDeploymentMode, //Default to the project's setting
            EnvironmentScope: RunbookEnvironmentScope.All,
            DefaultGuidedFailureMode: GuidedFailureMode.EnvironmentDefault,
            RunRetentionPolicy: {
                QuantityToKeep: 100,
                ShouldKeepForever: false,
            },
            ForcePackageDownload: false,
        };

        return this.doBusyTask(async () => {
            const result = await repository.Runbooks.create(
                {
                    ProjectId: this.props.projectId,
                    ...newRunbookResource,
                },
                args
            );
            await this.props.onProcessCreated(result.Id);
            // Update project summary
            await this.props.projectContext.actions.onProjectUpdated(this.props.projectContext.state.model, this.props.projectContext.state.gitRef);
        });
    }

    render() {
        return (
            <SaveDialogLayout title={"Add New Runbook"} busy={this.state.busy} errors={this.errors} onSaveClick={() => this.save()} saveButtonLabel={"Save"}>
                {HasVariablesInGit(this.props.projectContext.state.model.PersistenceSettings) && (
                    <Callout type={CalloutType.Information} title="Version control project">
                        Runbooks will use variables from the <code>{this.props.projectContext.state.model.PersistenceSettings.DefaultBranch}</code> branch.
                    </Callout>
                )}
                <Text label="Name" accessibleName="New runbook name" value={this.state.name} onChange={(name) => this.setState({ name })} validate={required("Please enter a Runbook name")} autoFocus={true} />
                <MarkdownEditor
                    value={this.state.description}
                    label="Description"
                    accessibleName="New runbook description"
                    bottomNoteText="Any description will be shown at the top of this Runbook's Overview page."
                    onChange={(description) => this.setState({ description })}
                />
                {this.props.cloneId && this.state.projectRunbooks && this.renderRunbooks(this.state.projectRunbooks)}
            </SaveDialogLayout>
        );
    }

    private renderRunbooks(runbooks: ResourceCollection<RunbookResource>) {
        const runbookItems: Array<RunbookResource> = runbooks.Items;
        return [
            <Select allowClear={true} allowFilter={true} value={this.state.selectedCloneId} onChange={(cloneId) => this.setState({ selectedCloneId: cloneId })} items={runbookItems.map((pg) => ({ value: pg.Id, text: pg.Name }))} label="Clone from" />,
            <Note>Select an existing Runbook to clone. The steps in the other Runbook will be copied into the new Runbook when it is created.</Note>,
        ];
    }
}

const AddRunbook: React.FC<AddRunbookProps> = (props) => {
    const projectContext = useProjectContext();
    return <AddRunbookInternal {...props} projectContext={projectContext} />;
};

export default AddRunbook;
