import type { FeedResource } from "@octopusdeploy/octopus-server-client";

export interface ActionWithFeeds {
    feeds: FeedResource[];
    refreshFeeds: () => Promise<unknown>;
}

/**
 * Some package ID's are invalid names, so we will try and sanitize them into valid a name.
 * Names can only contain letters, numbers, periods, commas, dashes, underscores or hashes.
 * @param name The supplied package name
 * @return The sanitized package name
 */
export const GeneratePackageNameFromId = (name: string) => {
    if (!name) {
        return name;
    }

    // IDs are truncated before the first slash, colon, or comma, to retain backwards compatibility with an earlier
    // sanitization implementation.

    // Container image ID's may contain `/`
    // Maven package ID's will contain `:`
    // We will take everything to the right of the last invalid-char as the name.
    // e.g. hashicorp/http-echo -> http-echo
    // e.g. org.acme:acme-web -> acme-web
    const firstTruncationCharIndex = name.search("[/,:]");

    // If a character triggering truncation is found, perform the truncation.
    if (firstTruncationCharIndex >= 0) {
        name = name.substring(firstTruncationCharIndex + 1);
    }

    // Replace any invalid characters with underscores.
    return name.replace(/[^A-Za-z0-9.,\-_#]/g, "_");
};
