import type { LicenseStatusResource, SpaceResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { useDoBusyTaskEffect } from "app/components/DataBaseComponent/useDoBusyTaskEffect";
import { sortBy } from "lodash";
import * as React from "react";
import { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import isWithinLicenceLimit from "~/areas/configuration/components/License/isWithinLicenceLimit";
import { configurationActions } from "~/areas/configuration/reducers/configurationArea";
import { repository, session } from "~/clientInstance";
import BusyFromPromise from "~/components/BusyFromPromise/index";
import BusyIndicator from "~/components/BusyIndicator/BusyIndicator";
import ActionButton, { ActionButtonType } from "~/components/Button/index";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent/index";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import ErrorPanel from "~/components/ErrorPanel/index";
import FilterSearchBox from "~/components/FilterSearchBox/index";
import Logo from "~/components/Logo/Logo";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { Section } from "~/components/Section/Section";
import type { SpaceContext } from "~/components/StandardLayout/SpaceLoader";
import { isSpaceNotFound } from "~/components/StandardLayout/SpaceLoader";
import { Note } from "~/components/form/index";
import { Popover } from "~/primitiveComponents/dataDisplay/Popover/Popover";
import type { MenuState } from "~/primitiveComponents/navigation/Menu/CustomMenu";
import { MenuItemInternalLink } from "~/primitiveComponents/navigation/MenuItems/MenuItemInternalLink/MenuItemInternalLink";
import { MenuList } from "~/primitiveComponents/navigation/MenuList/MenuList";
import routeLinks from "~/routeLinks";
import StringHelper from "~/utils/StringHelper/StringHelper";
import { UseDoBusyTask } from "~/utils/UseDoBusyTask/UseDoBusyTask";
import styles from "./spaceSwitcher.module.less";

export interface SpaceSwitcherProps {
    spaces: SpaceResource[];
    onUpgradeSpacesDialogRequested: () => void;
    selected: SpaceResource | undefined;
    spaceContext: SpaceContext;
    menuState: MenuState;
}

export function SpaceSwitcher(props: SpaceSwitcherProps) {
    return (
        <ErrorContextProvider>
            <UseDoBusyTask renderWithDoBusyTask={(doBusyTask, busy, errors) => <SpaceSwitcherInternal externalProps={props} busy={busy} doBusyTask={doBusyTask} errors={errors} />} />
        </ErrorContextProvider>
    );
}

interface SpaceSwitcherInternalProps {
    externalProps: SpaceSwitcherProps;
    doBusyTask: DoBusyTask;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
}

function SpaceSwitcherInternal({ externalProps: { spaceContext, spaces, onUpgradeSpacesDialogRequested, selected, menuState }, busy, errors, doBusyTask }: SpaceSwitcherInternalProps) {
    const navigation = useSpaceAwareNavigation();
    const [licenseStatus, setLicenseStatus] = useState<LicenseStatusResource | null>(null);
    const onUserAccessibleSpacesLoaded = useOnUserAccessibleSpacesLoaded();

    useDoBusyTaskEffect(
        doBusyTask,
        async () => {
            setLicenseStatus(await repository.Licenses.getCurrentStatus());
        },
        []
    );

    useDoBusyTaskEffect(
        doBusyTask,
        async () => {
            if (menuState.isOpen) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const spaces = await repository.Users.getSpaces(session.currentUser!);
                onUserAccessibleSpacesLoaded(spaces);
            }
        },
        [onUserAccessibleSpacesLoaded, menuState.isOpen]
    );

    const closeMenu = menuState.onClose;

    const onKeyEsc = useCallback(
        (event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.key === "Escape") {
                closeMenu();
            }
        },
        [closeMenu]
    );

    const addSpace = useCallback(() => {
        closeMenu();
        const routeLinksRoot = isSpaceNotFound(spaceContext) ? routeLinks.withoutSpace() : routeLinks;
        navigation.navigate(routeLinksRoot.configuration.spaces.root);
    }, [navigation, closeMenu, spaceContext]);

    const canSwitchBetweenSpaces = spaces.length > 1;

    return (
        <Popover
            id={menuState.menuId}
            style={{ overflowY: "hidden" }}
            open={menuState.isOpen}
            anchorEl={menuState.anchorElement}
            onClose={menuState.onClose}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
        >
            <BusyFromPromise promise={busy}>{(busy: boolean) => <BusyIndicator show={busy} />}</BusyFromPromise>
            {errors && <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink} scrollToPanel={false} />}
            <div className={styles.container} onKeyDown={onKeyEsc}>
                {selected && <div className={styles.selected}>{selected.Name}</div>}
                {!canSwitchBetweenSpaces && <OnboardingSpaceSwitcherContent licenseStatus={licenseStatus} addSpace={addSpace} onUpgradeSpacesDialogRequested={onUpgradeSpacesDialogRequested} />}
                {canSwitchBetweenSpaces && <SpaceSwitcherContent licenseStatus={licenseStatus} spaces={spaces} onAddSpaceRequested={addSpace} onRequestClose={menuState.onClose} />}
            </div>
            {canSwitchBetweenSpaces && <FooterButton licenseStatus={licenseStatus} onUpgradeSpacesDialogRequested={onUpgradeSpacesDialogRequested} />}
        </Popover>
    );
}

function useOnUserAccessibleSpacesLoaded() {
    const dispatch = useDispatch();
    return useCallback((spaces: SpaceResource[]) => dispatch(configurationActions.userAccessibleSpacesFetched(spaces)), [dispatch]);
}

interface SpaceSwitcherContentProps {
    spaces: SpaceResource[];
    licenseStatus: LicenseStatusResource | null;
    onRequestClose: () => void;
    onAddSpaceRequested: () => void;
}

function SpaceSwitcherContent({ spaces, licenseStatus, onRequestClose, onAddSpaceRequested }: SpaceSwitcherContentProps) {
    const firstItemRef = useRef<HTMLAnchorElement | null>(null);
    const [filter, setFilter] = useState("");

    const filteredList = filterList(spaces, filter);

    const onArrowDown = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === "ArrowDown" || event.key === "Tab") {
                if (filteredList.length === 0) {
                    return;
                }

                firstItemRef.current?.focus();
                event.preventDefault();
            }
        },
        [filteredList.length]
    );

    const orderedFilteredList = sortBy(filteredList, (s) => s.Name.toLowerCase());
    return (
        <>
            <Section bodyClassName={styles.filterContainer}>
                <FilterSearchBox autoFocus={true} value={filter} placeholder="Jump to space..." onChange={setFilter} fullWidth={true} onKeyDown={onArrowDown} containerClassName={styles.filterFieldContainer} />
                {isWithinLicenceLimit(licenseStatus, "Spaces") && isAllowed({ permission: Permission.SpaceCreate }) && <ActionButton className={styles.addSpaceButton} type={ActionButtonType.Ternary} label="ADD SPACE" onClick={onAddSpaceRequested} />}
            </Section>
            <div className={styles.menuContainer}>
                <MenuList accessibleName={"Switch space"}>
                    {orderedFilteredList.map((space, index) => {
                        const description = space.Description ? (space.Description.length > MaxDescriptionCharactersCount ? space.Description.substr(0, MaxDescriptionCharactersCount) + StringHelper.ellipsis : space.Description) : undefined;
                        return (
                            <MenuItemInternalLink
                                key={space.Id}
                                size={"default"}
                                ref={index === 0 ? firstItemRef : undefined}
                                path={routeLinks.space(space.Id)}
                                label={space.Name}
                                onClick={onRequestClose}
                                icon={<Logo size="2rem" url={space.Links.Logo} />}
                                shortDescription={description}
                                showLinkAsActive={"if path partially matches"}
                            />
                        );
                    })}
                </MenuList>
            </div>
        </>
    );
}

function filterList(spaces: SpaceResource[], filter: string): SpaceResource[] {
    const matchesFilter = (n: string) => n.toLowerCase().includes(filter.toLowerCase());

    return filter.length > 0 ? spaces.filter((p) => matchesFilter(p.Name) || matchesFilter(p.Description || "")) : spaces;
}

function OnboardingSpaceSwitcherContent({ onUpgradeSpacesDialogRequested, addSpace, licenseStatus }: { licenseStatus: LicenseStatusResource | null; addSpace: () => void; onUpgradeSpacesDialogRequested: () => void }) {
    return (
        <div className={styles.empty}>
            <h1>Create Spaces for your teams</h1>
            <p className={styles.description}>Spaces allow teams to group their projects, infrastructure, tenants and library assets.</p>
            <UpgradeJourneyForOnboarding licenseStatus={licenseStatus} onAddSpaceRequested={addSpace} onUpgradeSpacesDialogRequested={onUpgradeSpacesDialogRequested} />
            <img src={require("./onboarding-spaces.svg")} alt="spaces" width={250} height={250} />
        </div>
    );
}

interface FooterButtonProps {
    licenseStatus: LicenseStatusResource | null;
    onUpgradeSpacesDialogRequested: () => void;
}

function FooterButton({ licenseStatus, onUpgradeSpacesDialogRequested }: FooterButtonProps) {
    const canCreateSpaces = isAllowed({ permission: Permission.SpaceCreate });
    const isWithinSpaceLimit = isWithinLicenceLimit(licenseStatus, "Spaces");

    if (!canCreateSpaces || isWithinSpaceLimit) return null;

    return (
        <div className={styles.footer}>
            <ActionButton type={ActionButtonType.Primary} label="UNLOCK MORE SPACES" onClick={onUpgradeSpacesDialogRequested} />
        </div>
    );
}

function UpgradeJourneyForOnboarding({ licenseStatus, onAddSpaceRequested, onUpgradeSpacesDialogRequested }: { licenseStatus: LicenseStatusResource | null; onAddSpaceRequested: () => void; onUpgradeSpacesDialogRequested: () => void }) {
    const isWithinSpaceLimit = isWithinLicenceLimit(licenseStatus, "Spaces");
    return isWithinSpaceLimit ? (
        <div>
            <div className={styles.emptyButton}>
                <ActionButton type={ActionButtonType.Primary} label="ADD SPACE" onClick={onAddSpaceRequested} />
            </div>
            <Note>
                Learn more about <ExternalLink href="spaces">Spaces</ExternalLink>
            </Note>
        </div>
    ) : (
        <div>
            <div className={styles.emptyButton}>
                <ActionButton label="UNLOCK MORE SPACES" type={ActionButtonType.Primary} onClick={onUpgradeSpacesDialogRequested} />
            </div>
            <Note>You've hit the maximum number of Spaces.</Note>
        </div>
    );
}

const MaxDescriptionCharactersCount = 70;
