import type { ActivityElement } from "@octopusdeploy/octopus-server-client";
import { TaskState } from "@octopusdeploy/octopus-server-client";
import { noOp } from "@octopusdeploy/utilities";
import cn from "classnames";
import React, { useEffect, useState } from "react";
import { repository } from "~/clientInstance";
import styles from "~/components/ActionList/style.module.less";
import ActionButton from "~/components/Button/ActionButton";
import type { UniqueActivityElement } from "~/components/TaskLogLines/TaskLogBlock";
import TaskLogBlock from "~/components/TaskLogLines/TaskLogBlock";
import FileUploadDropzone from "~/primitiveComponents/form/FileUploadDragDrop/FileUploadDragDrop";

export const TaskLogViewer = () => {
    const [files, setFiles] = useState<File[]>([]);
    const [taskLogElements, setTaskLogElements] = useState<ActivityElement[]>();
    const [expandedIds, setExpandedIds] = useState<string[]>([]);

    useEffect(() => {
        (async () => {
            if (files.length > 0) {
                const parseTaskLogsResponse = await repository.Diagnostics.parseTaskLogs(files);
                setTaskLogElements(parseTaskLogsResponse.TaskLogs);
            }
        })();
    }, [files]);

    const setIdPrefix = (ele: ActivityElement, n: number): UniqueActivityElement => {
        return {
            ...ele,
            uniqueId: `${n}/${ele.Id}`,
            Children: ele.Children ? ele.Children.map((c) => setIdPrefix(c, n)) : [],
        };
    };

    const uniqueElements = taskLogElements ? taskLogElements.map((e, i) => setIdPrefix(e, i)) : [];

    const reset = () => {
        setFiles([]);
        setTaskLogElements([]);
    };

    return (
        <>
            {uniqueElements.length > 0 ? (
                <>
                    <div className={cn(styles.actionList, styles.alignEnd)}>
                        <ActionButton label="Reset" onClick={reset} />
                    </div>
                    {uniqueElements.map((e) => (
                        <TaskLogBlock
                            key={e.uniqueId}
                            element={e}
                            taskState={TaskState.Success}
                            collapsible={true}
                            expandedIds={expandedIds}
                            focusId={undefined}
                            showRunTime={true}
                            setExpanded={(id, expanded) => setExpandedIds((prev) => (expanded ? [...prev, id] : prev.filter((v) => v !== id)))}
                            showAdditional={noOp}
                        />
                    ))}
                </>
            ) : (
                <FileUploadDropzone showAlerts={false} filesLimit={10} label={"Drag and drop task log files or select to browse (max limit of 10)"} onFilesChanged={(files) => setFiles(files)} showFileChips={false} />
            )}
        </>
    );
};
