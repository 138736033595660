import type { WorkerPoolSummaryResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import InternalLink from "~/components/Navigation/InternalLink";
import type { OctopusTheme } from "~/components/Theme";
import { useOctopusTheme } from "~/components/Theme";
import { OctopusIcon, OctopusIconType } from "~/primitiveComponents/dataDisplay/Icon";
import routeLinks from "~/routeLinks";
import styles from "../style.module.less";

interface WorkerPoolsContentRowWithThemeProps {
    workerSummary: WorkerPoolSummaryResource;
    theme: OctopusTheme;
}

const WorkerPoolsContentRowWithTheme = ({ workerSummary, theme }: WorkerPoolsContentRowWithThemeProps) => {
    const totalMachines = workerSummary.TotalMachines || 0;
    return (
        <div className={styles.workerpoolRowsContainer} key={workerSummary.WorkerPool.Id}>
            <div className={styles.workerpoolIcon}>
                <OctopusIcon iconType={OctopusIconType.WorkerPool} color={theme.iconDark} />
            </div>
            <div className={styles.workerpoolName}>
                <InternalLink to={routeLinks.infrastructure.workerPools.filtered({ workerPoolIds: workerSummary.WorkerPool.Id })}>{workerSummary.WorkerPool.Name}</InternalLink>
            </div>
            <div className={styles.workerpoolMachinesCount}>{totalMachines.toLocaleString()}</div>
        </div>
    );
};

interface WorkerPoolsContentRowProps {
    workerSummary: WorkerPoolSummaryResource;
}

export const WorkerPoolsContentRow = (props: WorkerPoolsContentRowProps) => {
    const theme = useOctopusTheme();
    return <WorkerPoolsContentRowWithTheme {...props} theme={theme} />;
};
